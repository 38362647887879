import axios from 'helpers/axios';
import config from 'helpers/config';
import React from 'react';
import { BsChevronDown, BsPrinter } from 'react-icons/bs';

interface Props {
    RO_ID: string;
}

interface State {
    isPrintOptionsOpen: boolean;
}

class PrintPicker extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isPrintOptionsOpen: false,
        };

        this.togglePicker = this.togglePicker.bind(this);
        this.getInvoice = this.getInvoice.bind(this);
        this.onClickOutside = this.onClickOutside.bind(this);
    }

    onClickOutside(e: any) {
        const target = document.querySelector('printOptionsPicker777');
        const withinBoundaries = e.composedPath().includes(target);

        if (withinBoundaries) {
        } else {
            this.togglePicker(false);
        }
    }

    togglePicker(open: boolean) {
        this.setState({ isPrintOptionsOpen: open }, () => {
            open
                ? document.addEventListener('click', this.onClickOutside)
                : document.removeEventListener('click', this.onClickOutside);
        });
    }

    async getInvoice() {
        const response = await axios.get(`${config.apiUrl}/repairOrder/${this.props.RO_ID}/invoice`, {
            responseType: 'arraybuffer',
        });

        const blob = new Blob([response.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);

        // Open the PDF in a new tab
        window.open(url, '_blank');

        // const file = '';
        // const name = 'document.pdf';
        // const mimetype = 'application/text';
        // // prettier-ignore
        // // @ts-ignore
        // const downloadFile = new File([file], name, { type: mimetype });
        // const url = window.URL.createObjectURL(blob);
        // window.open(url, '_blank');
        // URL.revokeObjectURL(url);
    }

    render() {
        const { isPrintOptionsOpen } = this.state;
        return (
            <div className="relative inline">
                <button
                    onClick={() => this.togglePicker(!isPrintOptionsOpen)}
                    className="mr-2 border-gray-500 border text-gray-500 bg-white uppercase px-3 p-1 rounded-2xl font-medium text-base  opacity-80 ml-auto hover:opacity-100"
                >
                    <BsPrinter className="inline mr-2 align-baseline" />
                    <span className="align-baseline">Print</span>
                    <BsChevronDown className="inline align-baseline ml-1 pt-1" />
                </button>
                {isPrintOptionsOpen && (
                    <div
                        className="rounded absolute bg-white border border-gray-500 p-2 right-0 w-max"
                        id={'printOptionsPicker777'}
                    >
                        <div className="inline border-b-2 border-gray-400"></div>
                        <div className="clickable hover:bg-gray-200">Print Estimate</div>
                        <div className="clickable hover:bg-gray-200" onClick={this.getInvoice}>
                            Print Invoice
                        </div>
                        <div className="clickable hover:bg-gray-200">Print Repair Order</div>
                        <div className="clickable hover:bg-gray-200">Print Inspection</div>
                    </div>
                )}
            </div>
        );
    }
}

export default PrintPicker;
