import HoverTooltip from 'components/common/HoverTooltip';
import React, { useState } from 'react';
import { GiKeyCard } from 'react-icons/gi';

interface Props {
    keyTag: string;
    onSave: (keyTag: string) => void;
}

const KeyTag: React.FC<Props> = (props: Props) => {
    const [isKeyTagInputOpen, setIsKeyTagInputOpen] = useState(false);
    const [keyTag, setKeyTag] = useState(props.keyTag);

    const toggleState = () => {
        if (isKeyTagInputOpen) {
            props.onSave(keyTag);
        }
        setIsKeyTagInputOpen(!isKeyTagInputOpen);
    };

    return (
        <div className="rounded-lg border-2 inline-block p-2 ml-2">
            <HoverTooltip content={<div className="bg-white p-4 font-semibold rounded-lg">Key Tag</div>} isSmall>
                <GiKeyCard
                    className="clickable inline mr-2 text-2xl"
                    onClick={() => setIsKeyTagInputOpen(!isKeyTagInputOpen)}
                />
            </HoverTooltip>
            <input
                className="border border-blue-200 rounded-lg px-2 pt-0 w-24 disabled:border-0 mr-4"
                disabled={!isKeyTagInputOpen}
                value={keyTag || undefined}
                onChange={(e: any) => {
                    setKeyTag(e.target.value);
                }}
                autoFocus
            />

            <button className="p-1 rounded-lg bg-green-600 font-semibold text-sm px-2" onClick={() => toggleState()}>
                {isKeyTagInputOpen ? 'Save' : 'Edit'}
            </button>
        </div>
    );
};

export default KeyTag;
