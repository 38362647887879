import { Dispatch } from 'redux';
import axios from '../helpers/axios';
import actionTypes from '../helpers/actionTypes';
import config from '../helpers/config';

const { apiUrl } = config;

export const getCurrentUser = () => (dispatch: Dispatch) => {
    const success = (payload: any) => ({ type: actionTypes.CURRENT_USER_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.CURRENT_USER_FAILURE, payload });

    axios
        .get(`${apiUrl}/user/currentUser`)
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};

export const getUsersAccessPoints = () => (dispatch: Dispatch) => {
    const success = (payload: any) => ({ type: actionTypes.USER_ACCESS_POINTS_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.USER_ACCESS_POINTS_FAILURE, payload });

    axios
        .get(`${apiUrl}/user/accessPoints`)
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};
