import React from 'react';
import { FaBriefcase, FaDollarSign, FaSms } from 'react-icons/fa';
import { connect } from 'react-redux';
import User from '../models/UserModel';

interface Props {
    dispatch: any;
    currentUser: User;
}

interface State {}

class Home extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        const { currentUser } = this.props;

        return (
            <div className="list-page">
                <div className="mb-10">
                    <div className="text-2xl">
                        <span className="font-bold text-green-600 font-sans">
                            Hello <b>{currentUser?.firstName}!</b>
                        </span>
                    </div>
                    <div className="text-2xl">
                        <span className="font-bold text-gray-600">
                            Yesterday the shop generated <b className="text-blue-500">23 invoices</b> totaling{' '}
                            <b className="text-green-500">$8,654</b> in revenue
                        </span>
                    </div>
                </div>

                <div>
                    <div className="grid grid-cols-3">
                        <div className="p-4 rounded-lg mr-2 my-2 bg-white hover:bg-blue-200 hover:cursor-pointer clickable border">
                            <div className="flex">
                                <div className="rounded-lg bg-green-500 p-4 inline-block">
                                    <FaDollarSign className=" text-white text-xl" />
                                </div>
                                <div className="w-3/4 text-center">
                                    <div>
                                        <span>Todays Sales</span>
                                    </div>
                                    <div>
                                        <span className="text-xl font-bold">$541.59</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 rounded-lg mr-2 my-2 bg-white hover:bg-blue-200 hover:cursor-pointer clickable border">
                            <div className="flex">
                                <div className="rounded-lg bg-green-500 p-4 inline-block">
                                    <FaDollarSign className=" text-white text-xl" />
                                </div>
                                <div className="w-3/4 text-center">
                                    <div>
                                        <span>Weeks Sales</span>
                                    </div>
                                    <div>
                                        <span className="text-xl font-bold">$2486.40</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 rounded-lg mr-2 my-2 bg-white hover:bg-blue-200 hover:cursor-pointer clickable border">
                            <div className="flex">
                                <div className="rounded-lg bg-green-500 p-4 inline-block">
                                    <FaDollarSign className=" text-white text-xl" />
                                </div>
                                <div className="w-3/4 text-center">
                                    <div>
                                        <span>Months Sales</span>
                                    </div>
                                    <div>
                                        <span className="text-xl font-bold">$9458.10</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 rounded-lg mr-2 my-2 bg-white hover:bg-blue-200 hover:cursor-pointer clickable border">
                            <div className="flex">
                                <div className="rounded-lg bg-blue-400 p-4 inline-block">
                                    <FaBriefcase className=" text-white text-xl" />
                                </div>
                                <div className="w-3/4 text-center">
                                    <div>
                                        <span>Open bookings</span>
                                    </div>
                                    <div>
                                        <span className="text-xl font-bold">8</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 rounded-lg mr-2 my-2 bg-white hover:bg-blue-200 hover:cursor-pointer clickable border">
                            <div className="flex">
                                <div className="rounded-lg bg-blue-400 p-4 inline-block">
                                    <FaBriefcase className=" text-white text-xl" />
                                </div>
                                <div className="w-3/4 text-center">
                                    <div>
                                        <span>Open bookings</span>
                                    </div>
                                    <div>
                                        <span className="text-xl font-bold">8</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 rounded-lg mr-2 my-2 bg-white hover:bg-blue-200 hover:cursor-pointer clickable border">
                            <div className="flex">
                                <div className="rounded-lg bg-blue-400 p-4 inline-block">
                                    <FaBriefcase className=" text-white text-xl" />
                                </div>
                                <div className="w-3/4 text-center">
                                    <div>
                                        <span>Open Jobs</span>
                                    </div>
                                    <div>
                                        <span className="text-xl font-bold">12</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 rounded-lg mr-2 my-2 bg-white hover:bg-blue-200 hover:cursor-pointer clickable border">
                            <div className="flex">
                                <div className="rounded-lg bg-blue-400 p-4 inline-block">
                                    <FaSms className=" text-white text-xl" />
                                </div>
                                <div className="w-3/4 text-center">
                                    <div>
                                        <span>Service reminders</span>
                                    </div>
                                    <div>
                                        <span className="text-xl font-bold">34</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 rounded-lg mr-2 my-2 bg-white hover:bg-blue-200 hover:cursor-pointer clickable border">
                            <div className="flex">
                                <div className="rounded-lg bg-blue-400 p-4 inline-block">
                                    <FaSms className=" text-white text-xl" />
                                </div>
                                <div className="w-3/4 text-center">
                                    <div>
                                        <span>Yearly reminders</span>
                                    </div>
                                    <div>
                                        <span className="text-xl font-bold">49</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 rounded-lg mr-2 my-2 bg-white hover:bg-blue-200 hover:cursor-pointer clickable border">
                            <div className="flex">
                                <div className="rounded-lg bg-blue-400 p-4 inline-block">
                                    <FaSms className=" text-white text-xl" />
                                </div>
                                <div className="w-3/4 text-center">
                                    <div>
                                        <span>Open Jobs</span>
                                    </div>
                                    <div>
                                        <span className="text-xl font-bold">12</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-10">
                        <div className="py-2 w-full bg-yellow-400 text-center text-white font-bold clickable hover:bg-yellow-500">
                            Upgrade your subscription! Click here to find out more
                        </div>
                    </div>
                    <div className="mt-5">
                        <div className="py-2 w-full bg-green-400 text-center text-white font-bold clickable hover:bg-green-500">
                            Go yearly and save!
                        </div>
                    </div>
                    <div className="mt-10">
                        <div className="py-2 w-full text-center text-white font-bold">
                            <div className="bg-blue-400">Recent activity</div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(Home);
