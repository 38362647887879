import React from 'react';
import { Dispatch } from 'redux';

import axios from 'helpers/axios';
import config from 'helpers/config';
import UserLogin from 'models/UserLoginModel';
import RelativeTime from 'components/common/RelativeTime';
import User from 'models/UserModel';
import moment from 'moment';
import TableColumn, { TableColumOrderStatus } from 'models/TableColumnModel';
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import PaginationRequestModel from 'models/PaginationRequestModel';
import Pagination from 'components/common/Pagination';

interface Props {
    dispatch: Dispatch;
}

interface State {
    isEditMode: boolean;
    activeTab: number;

    userLogins: UserLogin[];
    isLoading: boolean;
    user?: User;

    userLoginsPagination: PaginationRequestModel;
}

class UserDetailsPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            activeTab: 0,
            isEditMode: false,

            userLogins: [],
            isLoading: true,
            userLoginsPagination: {
                page: 1,
                limit: 5,
                totalCount: 0,
            },
        };
    }

    componentDidMount() {
        this.getUserLogins();
        this.getUser();
    }

    getUserLogins() {
        // @ts-ignore
        const { userId } = this.props.match.params;

        axios
            .get(`${config.apiUrl}/auth/userlogins/${userId}`, {
                params: this.state.userLoginsPagination,
            })
            .then((res) => {
                this.setState({ userLogins: res.data.rows, isLoading: false });
                this.setState((prevState) => ({
                    userLoginsPagination: {
                        ...prevState.userLoginsPagination,
                        totalCount: res.data.count,
                    },
                }));
            });
    }

    handleSort(column: TableColumn) {
        if (column.orderStatus === TableColumOrderStatus.None) {
            column.orderStatus = TableColumOrderStatus.Ascending;
        } else if (column.orderStatus === TableColumOrderStatus.Ascending) {
            column.orderStatus = TableColumOrderStatus.Descending;
        } else {
            column.orderStatus = TableColumOrderStatus.None;
        }

        const newPagination = {
            ...this.state.userLoginsPagination,
            orderBy: column.name,
            orderType: column.orderStatus,
        };

        this.setState({ userLoginsPagination: newPagination }, () => {
            this.getUserLogins();
        });
    }

    getUser() {
        // @ts-ignore
        const { userId } = this.props.match.params;

        axios.get(`${config.apiUrl}/user/${userId}`).then((res) => {
            this.setState({ user: res.data });
        });
    }

    getTabs() {
        return [
            {
                title: 'Repair Orders',
            },
            {
                title: 'Payments',
                component: (
                    // <CustomerOrVehiclePayments repairOrders={this.props.vehicle?.repairOrders!} isForCustomer={false} />
                    <div></div>
                ),
            },
            {
                title: 'Files',
            },
        ];
    }

    render() {
        const { userLogins, activeTab, isLoading, user, userLoginsPagination } = this.state;
        const tabs = this.getTabs();

        return (
            <div className="bg-white mb-40">
                {!!isLoading && <b>Loading...</b>}
                <div className="w-full bg-blue-500 p-8">
                    <h3 className="text-blue-100">User</h3>
                    <h2 className="text-blue-50 text-2xl font-semibold">
                        {user?.firstName} {user?.lastName}
                    </h2>
                </div>
                <div className="mt-8 px-8">
                    <div className="mt-4 overflow-x-auto w-full min-w-fit border border-gray-500 rounded-lg p-4 bg-slate-50">
                        <h2 className="font-semibold text-lg">User logins</h2>
                        <table className="items-center bg-transparent border w-full mb-0.5">
                            <thead className="border">
                                <tr className=" bg-blue-600 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                                    {userLoginsListColumns.map(
                                        (column, index) =>
                                            column.active && (
                                                <th
                                                    key={index}
                                                    className={`pl-6 py-3 ${index === 0 && 'rounded-tl-md'} ${
                                                        index === userLoginsListColumns.length - 1 && 'rounded-tr-md'
                                                    }`}
                                                >
                                                    <span
                                                        className="clickable"
                                                        onClick={() => column.sortable && this.handleSort(column)}
                                                    >
                                                        <span>{column.text}</span>
                                                        {!!column.sortable &&
                                                            (column?.orderStatus === TableColumOrderStatus.None ? (
                                                                <FaSort className="inline ml-1" />
                                                            ) : column?.orderStatus ===
                                                              TableColumOrderStatus.Ascending ? (
                                                                <FaSortDown className="inline ml-1" />
                                                            ) : (
                                                                <FaSortUp className="inline ml-1" />
                                                            ))}
                                                    </span>
                                                </th>
                                            ),
                                    )}
                                </tr>
                            </thead>

                            <tbody>
                                {userLogins?.map((login, index) => (
                                    <tr
                                        className={`border group font-medium hover:bg-blue-200 clickable ${
                                            index % 2 === 0 ? 'bg-gray-100' : 'bg-blue-100'
                                        }`}
                                        key={login.id}
                                    >
                                        <td className="px-6 align-middle whitespace-nowrap py-1.5 text-blue-400 font-semibold">
                                            <span className="float-left">
                                                <RelativeTime dateTime={login.createdAt} floatRight={false} />
                                            </span>
                                            <br />
                                            <span>
                                                <span className="text-sm">
                                                    {moment(login.createdAt).format('MM/DD/YYYY HH:mm')}
                                                </span>
                                            </span>
                                        </td>
                                        <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2 font-semibold">
                                            <span>{login.ipAddress}</span>
                                        </td>
                                        <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                            <span>{login.userAgent} </span>
                                        </td>
                                        <td className="px-6 align-middle whitespace-nowrap  non-clickable">
                                            <span className="font-semibold">{login.deviceType}</span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {userLogins?.length! < 1 && (
                            <div className="border py-4 px-4 wb-white text-gray-600 text-lg font-semibold text-center">
                                This user has not logged in yet.
                            </div>
                        )}

                        <Pagination
                            currentPage={userLoginsPagination.page}
                            totalItems={userLoginsPagination.totalCount!}
                            pageSize={userLoginsPagination.limit}
                            pageChanged={(newPage: number) => {
                                this.setState(
                                    { userLoginsPagination: { ...userLoginsPagination, page: newPage } },
                                    () => {
                                        this.getUserLogins();
                                    },
                                );
                            }}
                        />
                    </div>
                </div>

                {!isLoading && user && (
                    <div className="pl-8 flex flex-col">
                        {/* <VehicleStats vehicle={vehicle} />
                        <VehicleDetailsForm
                            vehicle={isEditMode ? this.state.vehicle! : vehicle}
                            handleChange={this.handleChange}
                            toggleEditMode={this.toggleEditMode}
                            isEditMode={isEditMode}
                        /> */}
                        <div className="mt-8">
                            {tabs.map((tab, index) => {
                                return (
                                    <div
                                        className={`inline-flex px-5 border-b-4 ${
                                            activeTab === index
                                                ? ' border-blue-500 '
                                                : '  border-gray-500 hover:text-blue-400'
                                        }`}
                                        key={index}
                                    >
                                        <span
                                            className={`clickable text-lg ${
                                                activeTab === index
                                                    ? 'font-bold text-blue-500 border-blue-500 '
                                                    : ' font-medium text-gray-400 border-gray-500 hover:text-blue-400'
                                            }`}
                                            onClick={() => this.setState({ activeTab: index })}
                                        >
                                            {tab.title}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                        {tabs[activeTab].component}
                    </div>
                )}
            </div>
        );
    }
}

export default UserDetailsPage;

const userLoginsListColumns: TableColumn[] = [
    {
        name: 'createdAt',
        active: true,
        text: 'Date',
        sortable: true,
        orderStatus: TableColumOrderStatus.None,
    },
    {
        name: 'ipAddress',
        active: true,
        text: 'IP Address',
        sortable: true,
        orderStatus: TableColumOrderStatus.None,
    },
    {
        name: 'userAgent',
        active: true,
        text: 'User Agent',
        sortable: true,
        orderStatus: TableColumOrderStatus.None,
    },
    {
        name: 'deviceType',
        active: true,
        text: 'Device Type',
        sortable: true,
        orderStatus: TableColumOrderStatus.None,
    },
];
