import React from 'react';
import { connect } from 'react-redux';
import Avatar from 'react-avatar';
import { FaTimesCircle, FaTrash } from 'react-icons/fa';

import Fee from 'models/FeeModel';
import LaborRate from 'models/LaborRateModel';
import User from 'models/UserModel';
import ServiceItemType from 'models/types/ServiceItemType';
import ServiceItem from 'models/ServiceItemModel';
import FormStateType from 'models/generic/FormState';
import InventoryItem from 'models/InventoryItemModel';
import PartsMatrix from 'models/PartsMatrixModel';

import NewDropdown from 'components/common/NewDropdown';
import UserPicker from 'components/common/UserPicker';
import Price from 'components/common/Price';
import LabelPicker from 'components/common/LabelPicker';
import PriceInputCents from 'components/common/PriceInputCents';
import ServiceItemInput from './ServiceItemInput';

interface Props {
    item: ServiceItem;
    index: number;
    fees: Fee[];
    laborRates: LaborRate[];
    updateAsignees: (users: User[], index: number) => void;
    removeLabel: (serviceItemId: string, labelIndex: number) => void;
    updateLabels: (labels: any, index: number) => void;
    handleServiceItemDelete: (serviceItemId: string) => void;

    state?: FormStateType;
    saveServiceItem: (serviceItem: ServiceItem) => void;

    partsMatrices: PartsMatrix[];
}

interface State {
    editableServiceItem: ServiceItem;
}

class ServiceCardItemRow extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            editableServiceItem: props.item,
        };

        this.handleInventoryItemSelected = this.handleInventoryItemSelected.bind(this);
        this.handleQuantityChange = this.handleQuantityChange.bind(this);
        this.handlePriceChange = this.handlePriceChange.bind(this);
        this.handleInventoryItemDeselectedSelected = this.handleInventoryItemDeselectedSelected.bind(this);
    }

    componentDidMount() {
        this.setState({ editableServiceItem: this.props.item });
    }

    handleInventoryItemDeselectedSelected() {
        const editableServiceItem = { ...this.state.editableServiceItem };

        editableServiceItem.type = ServiceItemType.Part;
        editableServiceItem.quantity = 1;
        editableServiceItem.price = 0.0;
        editableServiceItem.total = 0.0;
        editableServiceItem.subtotal = 0.0;
        editableServiceItem.inventoryItem = null;
        editableServiceItem.inventoryItemId = null;
        editableServiceItem.description = '';
        this.setState({ editableServiceItem });
    }

    handleInventoryItemSelected(part: InventoryItem) {
        const { partsMatrices } = this.props;
        const defaultMatrix = partsMatrices.find((matrix) => matrix.isDefault);
        // if it was deselected, reset fields
        if (!!!part) {
            const editableServiceItem = { ...this.state.editableServiceItem };

            editableServiceItem.type = ServiceItemType.Part;
            editableServiceItem.quantity = 1;
            editableServiceItem.price = 0.0;
            editableServiceItem.total = 0.0;
            editableServiceItem.subtotal = 0.0;
            editableServiceItem.inventoryItem = null;
            editableServiceItem.inventoryItemId = null;
            editableServiceItem.description = '';
            this.setState({ editableServiceItem });
            return;
        }

        const editableServiceItem = { ...this.state.editableServiceItem };
        editableServiceItem.inventoryItem = part;
        editableServiceItem.inventoryItemId = part?.id;
        editableServiceItem.priceCents = part?.retailPriceCents || 0;
        editableServiceItem.quantity = 1;

        const applicableMatrixRange = defaultMatrix?.ranges.find(
            (range) => range.fromCents <= part?.costCents && range.toCents >= part?.costCents,
        );
        if (applicableMatrixRange) {
            editableServiceItem.priceCents = part.costCents + (part.costCents * applicableMatrixRange.markup) / 100;
            editableServiceItem.partsMatrixRangeId = applicableMatrixRange.id;
            editableServiceItem.partsMatrixRange = applicableMatrixRange;
            editableServiceItem.partsMatrixId = defaultMatrix?.id!;
            editableServiceItem.partsMatrix = defaultMatrix!;
        } else {
            editableServiceItem.priceCents = part.retailPriceCents || part.costCents;
        }

        editableServiceItem.totalCents = editableServiceItem.priceCents * editableServiceItem.quantity;
        this.setState({ editableServiceItem });
    }

    handleQuantityChange(e: React.FormEvent<any>) {
        const { editableServiceItem } = this.state;
        const newQuantity = Number(e.currentTarget.value);
        const newTotalCents = Math.round(editableServiceItem.priceCents * newQuantity);

        this.setState({
            editableServiceItem: {
                ...editableServiceItem,
                quantity: newQuantity,
                totalCents: newTotalCents,
            },
        });
    }

    handlePriceChange(cents: number) {
        const { editableServiceItem } = this.state;
        const newPrice = cents;
        const newTotalCents = Math.round(newPrice * editableServiceItem.quantity);

        this.setState({
            editableServiceItem: {
                ...editableServiceItem,
                priceCents: newPrice,
                totalCents: newTotalCents,
            },
        });
    }

    handleLaborRateChange = (laborRate: LaborRate) => {
        const { editableServiceItem } = this.state;
        const newTotalCents = Math.round(laborRate.rateCents * editableServiceItem.quantity);
        this.setState({
            editableServiceItem: {
                ...editableServiceItem,
                laborRateId: laborRate.id,
                priceCents: laborRate.rateCents,
                totalCents: newTotalCents,
            },
        });
    };

    render() {
        const {
            item,
            index,
            fees,
            laborRates,
            updateAsignees,
            removeLabel,
            updateLabels,
            handleServiceItemDelete,
            state,
        } = this.props;

        const { editableServiceItem } = this.state;
        return (
            <>
                {item.isNew && (
                    <tr className="border bg-gray-100 border-gray-500" key={item.id}>
                        <td className="px-6 min-w-max align-middle border-l-0 border-r-0 text-xs p-4 whitespace-nowrap text-left items-center">
                            {editableServiceItem.type !== ServiceItemType.Fee && (
                                <ServiceItemInput
                                    serviceItem={editableServiceItem}
                                    serviceItemChanged={(e) => {
                                        this.setState({
                                            editableServiceItem: {
                                                ...editableServiceItem,
                                                [e.currentTarget.name]: e.currentTarget.value,
                                            },
                                        });
                                    }}
                                    handlePartSelected={(inventoryItem: InventoryItem) => {
                                        this.handleInventoryItemSelected(inventoryItem);
                                    }}
                                    handlePartDeselected={() => this.handleInventoryItemDeselectedSelected()}
                                    onBlur={() => {}}
                                />
                            )}
                        </td>
                        {editableServiceItem.type == ServiceItemType.Labor && (
                            <td className="px-6 align-middle border-l-0 border-r-0 text-xs p-4 whitespace-nowrap">
                                <div className="flex flex-row">
                                    <>
                                        {!!editableServiceItem.asignee && (
                                            <Avatar
                                                name={`${editableServiceItem.asignee?.firstName} ${editableServiceItem.asignee?.lastName}`}
                                                round
                                                size={'22'}
                                                className="clickable mr-2"
                                            />
                                        )}
                                        <span className="align-bottom mt-1">
                                            <UserPicker
                                                usersSelected={(users: User[]) => updateAsignees(users, index)}
                                                userIds={[editableServiceItem.asignee?.id!]}
                                                allowMultiple={false}
                                            />
                                        </span>
                                    </>
                                </div>
                            </td>
                        )}
                        <td className="px-6 align-middle border-l-0 border-r-0 text-xs p-4 whitespace-nowrap">
                            {editableServiceItem.type === ServiceItemType.Part && (
                                <div>
                                    <span>
                                        <PriceInputCents
                                            initialPriceCents={editableServiceItem.priceCents}
                                            handlePriceChange={this.handlePriceChange}
                                            className="outline-none w-24 placeholder-gray-400 font-medium px-3 py-2 focus:ring-1 focus:ring-blue-600 rounded-lg text-right"
                                        />
                                    </span>
                                    {editableServiceItem.partsMatrixRange && editableServiceItem.partsMatrix && (
                                        <span className="text-xs text-gray-500 bg-green-500 rounded-lg text-white p-1">
                                            <span className="font-semibold">
                                                {editableServiceItem.partsMatrix.name}
                                            </span>{' '}
                                            applied:
                                            <span className="font-semibold">
                                                {editableServiceItem.partsMatrixRange.markup}%
                                            </span>{' '}
                                            markup
                                        </span>
                                    )}
                                </div>
                            )}
                            {editableServiceItem.type === ServiceItemType.Labor && (
                                <NewDropdown
                                    isServiceCard={true}
                                    items={laborRates}
                                    bindName={'name'}
                                    onSelect={this.handleLaborRateChange}
                                    selected={laborRates.find((rate) => rate.id === editableServiceItem?.laborRateId)}
                                    id={editableServiceItem.id}
                                />
                            )}
                            {editableServiceItem.type === ServiceItemType.Fee && (
                                <Price
                                    clasName="font-semibold text-blue-500"
                                    price={fees.find((fee) => editableServiceItem.feeId == fee.id)?.amount!}
                                />
                            )}
                        </td>
                        <td className="px-6 align-middle border-l-0 border-r-0 text-xs p-4 whitespace-nowrap">
                            <div className="">
                                <input
                                    className="outline-none w-20 placeholder-gray-400 font-medium px-3 py-2 focus:ring-1 focus:ring-blue-600 rounded-lg mr-8"
                                    placeholder="1"
                                    type="number"
                                    min="0"
                                    name="quantity"
                                    value={editableServiceItem.quantity}
                                    onChange={this.handleQuantityChange}
                                />
                            </div>
                        </td>
                        <td className="px-6 align-middle border-l-0 border-r-0 text-xs  p-4">
                            <div className="flex flex-wrap flex-grow-0 items-baseline">
                                {editableServiceItem.labels?.map((label, indx) => (
                                    <div
                                        key={indx}
                                        style={{ backgroundColor: label.color }}
                                        className="px-2 py-1 mr-2 rounded-lg hover:opacity-80 text-xs"
                                    >
                                        <span className="font-semibold text-white">
                                            <FaTimesCircle
                                                onClick={() => removeLabel(editableServiceItem.id, indx)}
                                                className="inline mr-2 clickable"
                                            />
                                            {label.title}
                                        </span>
                                    </div>
                                ))}

                                <LabelPicker
                                    labelsSelected={(labels: any) => updateLabels(labels, index)}
                                    labelType={
                                        editableServiceItem.type === ServiceItemType.Labor
                                            ? 'laborStatus'
                                            : editableServiceItem.type === ServiceItemType.Part
                                            ? 'partStatus'
                                            : ''
                                    }
                                    labelIds={editableServiceItem.labels?.map((label) => label.id)}
                                />
                            </div>
                        </td>
                        <td className="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <Price
                                clasName="border-none outline-none w-24 placeholder-gray-400 font-medium px-3 py-2 group-hover:bg-blue-100 rounded-lg"
                                price={editableServiceItem.totalCents / 100}
                            />
                        </td>
                        <td className="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <button
                                className="bg-green-600 px-2 rounded-2xl font-medium text-base"
                                onClick={() => this.props.saveServiceItem(this.state.editableServiceItem!)}
                            >
                                Save
                            </button>
                        </td>
                    </tr>
                )}
                {state === FormStateType.Readonly && (
                    <tr className="border-t bg-blue-50 group hover:bg-blue-200" key={item.id}>
                        <td className="px-6 min-w-max align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left items-center">
                            {item.type !== ServiceItemType.Fee && (
                                <ServiceItemInput
                                    serviceItem={item}
                                    serviceItemChanged={(e) => console.log(e, item.id, true)}
                                    onBlur={() => {}}
                                />
                            )}
                            {item.type === ServiceItemType.Fee && (
                                <NewDropdown
                                    isServiceCard={true}
                                    items={fees}
                                    bindName={'name'}
                                    onSelect={(fee: Fee) => {
                                        console.log({
                                            currentTarget: {
                                                name: 'feeId',
                                                value: fee.id,
                                            },
                                        } as React.FormEvent<any>);
                                    }}
                                    selected={fees.find((fee) => fee.id === item?.feeId)}
                                    id={item.id}
                                    placeholder="Select fee"
                                />
                            )}
                        </td>
                        {item.type == ServiceItemType.Labor && (
                            <td className="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                <div className="flex flex-row">
                                    {item.asignee && (
                                        <Avatar
                                            name={`${item.asignee?.firstName} ${item.asignee?.lastName}`}
                                            round
                                            size={'22'}
                                            className="clickable mr-2"
                                        />
                                    )}
                                </div>
                            </td>
                        )}

                        <td className="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            {item.type === ServiceItemType.Part && (
                                <Price
                                    clasName="border-none outline-none w-24 placeholder-gray-400 font-medium px-3 py-2 group-hover:bg-blue-100 rounded-lg"
                                    price={item.priceCents / 100}
                                />
                            )}
                            {item.type === ServiceItemType.Labor && (
                                <div>{laborRates.find((rate) => rate.id === item?.laborRateId)?.name}</div>
                            )}
                            {item.type === ServiceItemType.Fee && (
                                <Price
                                    clasName="font-semibold text-blue-500"
                                    price={fees.find((fee) => item.feeId == fee.id)?.amount!}
                                />
                            )}
                        </td>
                        <td className="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <div className="">
                                <span className="border-none outline-none w-24 placeholder-gray-400 font-medium px-3 py-2 group-hover:bg-blue-100 rounded-lg">
                                    {item.quantity}
                                </span>
                            </div>
                        </td>
                        <td className="px-6 align-middle border-l-0 border-r-0 text-xs  p-4">
                            <div className="flex flex-wrap flex-grow-0 items-baseline">
                                {item.labels?.map((label, indx) => (
                                    <div
                                        key={indx}
                                        style={{ backgroundColor: label.color }}
                                        className="px-2 py-1 mr-2 rounded-lg hover:opacity-80 text-xs"
                                    >
                                        <span className="font-semibold text-white">
                                            <FaTimesCircle
                                                onClick={() => removeLabel(item.id, indx)}
                                                className="inline mr-2 clickable"
                                            />
                                            {label.title}
                                        </span>
                                    </div>
                                ))}

                                <LabelPicker
                                    labelsSelected={(labels: any) => updateLabels(labels, index)}
                                    labelType={
                                        item.type === ServiceItemType.Labor
                                            ? 'laborStatus'
                                            : item.type === ServiceItemType.Part
                                            ? 'partStatus'
                                            : ''
                                    }
                                    labelIds={item.labels?.map((label) => label.id)}
                                />
                            </div>
                        </td>
                        <td className="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <Price
                                clasName="border-none outline-none w-24 placeholder-gray-400 font-medium px-3 py-2 group-hover:bg-blue-100 rounded-lg"
                                price={item.totalCents / 100}
                            />
                        </td>
                        <td className="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                            <FaTrash
                                size={15}
                                className="opacity-0 group-hover:opacity-100 clickable "
                                onClick={() => handleServiceItemDelete(item.id)}
                            />
                        </td>
                    </tr>
                )}
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    partsMatrices: state.misc.partsPricingMatrices,
});

export default connect(mapStateToProps)(ServiceCardItemRow);
