import React, { useState } from 'react';
import ServiceAuthorizationStatus from 'models/types/ServiceAuthorizationStatus';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';

interface Props {
    currentStatus: ServiceAuthorizationStatus;
    className?: string;
    withPicker?: boolean;
    onChange?: Function;
    iconWithText?: boolean;
}

const AuthorizationStatus: React.FC<Props> = ({
    currentStatus,
    className = '',
    withPicker = true,
    onChange = () => {
        false;
    },
    iconWithText = false,
}: Props) => {
    const [isPickerOpen, setIsPickerOpen] = useState(false);

    const id = `serviceAuthoricationPicker${Math.random().toString(36).substr(2, 9)}`;

    const getStatusText = (status: ServiceAuthorizationStatus) => {
        switch (status) {
            case ServiceAuthorizationStatus.NotAuthorized: {
                return 'Not Authorized';
            }
            case ServiceAuthorizationStatus.Authorized: {
                return 'Authorized';
            }
            case ServiceAuthorizationStatus.Declined: {
                return 'Declined';
            }
        }
    };

    const onClickOutside = (e: any) => {
        const target = document.querySelector(`#${id}`);
        const withinBoundaries = e.composedPath().includes(target);

        if (withinBoundaries) {
        } else {
            togglePicker(false);
        }
    };

    const togglePicker = (open: boolean) => {
        if (open) {
            setIsPickerOpen(true);
            document.addEventListener('click', onClickOutside);
        } else {
            setIsPickerOpen(false);
            document.removeEventListener('click', onClickOutside);
        }
    };

    const handleStatusChange = (status: number) => {
        onChange({ currentTarget: { name: 'authorizationStatus', value: status } });
        setIsPickerOpen(false);
    };

    return (
        <div className={`relative ${className}`}>
            {currentStatus === ServiceAuthorizationStatus.Declined ? (
                <div className="inline-flex clickable" onClick={() => togglePicker(!isPickerOpen)}>
                    <FaTimesCircle className="inline clickable text-red-700 bg-none" title="declined" />

                    {!!iconWithText && <span className={`font-semibold text-sm ml-1 text-red-700`}>Declined</span>}
                </div>
            ) : (
                <div className="inline-flex clickable" onClick={() => togglePicker(!isPickerOpen)}>
                    <FaCheckCircle
                        className={`inline  ${
                            currentStatus === ServiceAuthorizationStatus.Authorized
                                ? 'text-green-500'
                                : 'text-gray-700 bg-none'
                        }`}
                        title={
                            currentStatus === ServiceAuthorizationStatus.Authorized ? 'Authorized' : 'Not Authorized'
                        }
                    />
                    {!!iconWithText && (
                        <span
                            className={`font-semibold text-sm ml-1 ${
                                currentStatus === ServiceAuthorizationStatus.Authorized
                                    ? 'text-green-500'
                                    : 'text-gray-500'
                            }`}
                        >
                            {currentStatus === ServiceAuthorizationStatus.Authorized ? 'Authorized' : 'Not Authorized'}
                        </span>
                    )}
                </div>
            )}
            {!!withPicker && isPickerOpen && (
                <div className="absolute bg-white border-2 border-gray-500 p-2 right-0 w-max" id={id}>
                    <div className="inline border-b-2 border-gray-400">{getStatusText(currentStatus)}</div>
                    {currentStatus !== ServiceAuthorizationStatus.Authorized && (
                        <div className="clickable hover:bg-gray-200" onClick={() => handleStatusChange(1)}>
                            Authorize
                        </div>
                    )}
                    {currentStatus !== ServiceAuthorizationStatus.Declined && (
                        <div className="clickable hover:bg-gray-200" onClick={() => handleStatusChange(2)}>
                            Decline
                        </div>
                    )}

                    {currentStatus !== ServiceAuthorizationStatus.NotAuthorized && (
                        <div className="clickable hover:bg-gray-200" onClick={() => handleStatusChange(0)}>
                            Reset authorization
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default AuthorizationStatus;
