import actionTypes from '../../helpers/actionTypes';
import Supplier from '../../models/SupplierModel';

class initialState {
    suppliersList!: Supplier[];
    suppliersLoading!: boolean;
    supplier!: Supplier;
    supplierLoading!: boolean;
    newSupplierLoading!: boolean;
    supplierUpdating!: boolean;
    newSupplier!: Supplier;
    suppliersCount!: number;
}

const supplierReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case actionTypes.GET_SUPPLIERS_REQUEST:
            return {
                ...state,
                suppliersLoading: true,
            };
        case actionTypes.GET_SUPPLIERS_SUCCESS:
            return {
                ...state,
                suppliersList: action.payload,
                suppliersLoading: false,
            };
        case actionTypes.GET_SUPPLIER_REQUEST:
            return {
                ...state,
                supplierLoading: true,
            };
        case actionTypes.GET_SUPPLIER_SUCCESS:
            return {
                ...state,
                supplier: action.payload,
                supplierLoading: false,
                newSupplier: action.payload,
            };
        case actionTypes.CREATE_SUPPLIER_REQUEST:
            return {
                ...state,
                newSupplierLoading: true,
            };
        case actionTypes.CREATE_SUPPLIER_SUCCESS:
            return {
                ...state,
                newSupplier: action.payload,
                newSupplierLoading: false,
            };
        case actionTypes.GET_SUPPLIERS_OVERVIEW_SUCCESS: {
            return {
                ...state,
                suppliersCount: action.payload.count,
            };
        }
        case actionTypes.UPDATE_SUPPLIER_REQUEST:
            return {
                ...state,
                supplierUpdating: true,
            };
        case actionTypes.UPDATE_SUPPLIER_SUCCESS:
            return {
                ...state,
                supplier: action.payload,
                supplierUpdating: false,
            };
        default: {
            return state;
        }
    }
};

export default supplierReducer;
