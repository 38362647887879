import React from 'react';

interface Props {
    percentage: number;
    className?: string;
}

const Percentage = ({ percentage, className = '' }: Props) => {
    return (
        <span className={className}>
            {new Intl.NumberFormat('en-US', {
                style: 'percent',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }).format(percentage)}
            &nbsp;{/* Add a non-breaking space */}
        </span>
    );
};

export default Percentage;
