import React from 'react';
import ModalStateType from 'models/generic/ModalState';
import axios from 'helpers/axios';
import config from 'helpers/config';
import Modal from 'components/common/Modal';
import { Divider, Form, Transition } from 'semantic-ui-react';
import User from 'models/UserModel';
import UsersAccessPointModel from 'models/UsersAccessPointModel';
import AccessPoint from 'models/AccessPointModel';
import AccessPointsTab from './AccessPointsTab';

interface Props {
    userId?: string;
    onClose: (userCreated?: boolean, userEdited?: boolean) => void;
}

interface ComponentState {
    modalState: ModalStateType;
    modalHeader: string;
    user: User;
    isLoading: boolean;
    isFormExpanded: boolean;
    isImagesModalOpen: boolean;
    formErrors: any;

    userAccessPoints: UsersAccessPointModel[];
    accessPoints: AccessPoint[];
    activeTab: number;
}

const requiredFields = ['firstName', 'lastName', 'email'];

class UserModal extends React.Component<Props, ComponentState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            modalState: this.props.userId ? ModalStateType.Edit : ModalStateType.Create,
            modalHeader: '',
            isLoading: true,
            isFormExpanded: true,
            user: {} as User,
            formErrors: {},
            isImagesModalOpen: false,

            userAccessPoints: [],
            accessPoints: [],
            activeTab: 0,
        };

        this.getUser = this.getUser.bind(this);
        this.setModalHeader = this.setModalHeader.bind(this);
        this.getValue = this.getValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
        this.toggleImagesModal = this.toggleImagesModal.bind(this);
    }

    componentDidMount() {
        if (this.state.modalState === ModalStateType.Edit) {
            this.setState({ isLoading: true });
            this.getUser();
        } else {
            this.getAllAccessPoints();
            this.setModalHeader();
        }
    }

    handleChange(event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) {
        const { name, value } = event.currentTarget;

        const updatedUser: User = { ...this.state.user, [name]: value };
        this.setState({ user: updatedUser });
    }

    getAllAccessPoints() {
        axios
            .get(`${config.apiUrl}/access/accessPoints`)
            .then((response) => {
                this.setState({ accessPoints: response.data, isLoading: false });
                this.setModalHeader();
            })
            .catch((err) => console.log(err));
    }

    getUser() {
        axios
            .get(`${config.apiUrl}/user/${this.props.userId}`)
            .then((response) => {
                this.setState({ user: response.data, isLoading: false });
                this.setModalHeader();
            })
            .catch((err) => console.log(err));

        axios
            .get(`${config.apiUrl}/access/${this.props.userId}`)
            .then((response) => {
                const accessPoints = response.data;
                this.setState({ accessPoints, isLoading: false });
                this.setModalHeader();
            })
            .catch((err) => console.log(err));
    }

    setModalHeader() {
        if (this.state.modalState === ModalStateType.Edit) {
            const { firstName, lastName } = this.state.user!;
            const modalHeader = `Edit ${firstName} ${lastName}`;
            this.setState({ modalHeader });
        } else {
            this.setState({ modalHeader: 'Register new user' });
        }
    }

    getValue(name: string) {
        // @ts-ignore
        return this.state.user[name] || '';
    }

    save() {
        if (!this.validateForm()) {
            return;
        }

        this.setState({ isLoading: true });

        if (this.state.modalState === ModalStateType.Create) {
            this.createUser(this.state.user);
        } else {
            this.updateUser(this.state.user);
        }
    }

    validateForm() {
        const errors: any = {};
        requiredFields.forEach((field) => {
            //@ts-ignore
            if (!this.state?.user?.[field] || this.state?.user?.[field]?.length === 0) {
                errors[field] = 'Cannot be empty';
            }
        });
        this.setState({ formErrors: errors });
        const isFormValid = Object.keys(errors).length === 0;
        return isFormValid;
    }

    updateUser(userData: any) {
        axios
            .put(`${config.apiUrl}/user`, userData)
            .then((_) => this.props.onClose(false, true))
            .catch((_) => {
                this.setState({ isLoading: false });
            });
    }

    createUser(userData: any) {
        axios
            .post(`${config.apiUrl}/user`, userData)
            .then((_) => {
                this.props.onClose(true, false);
            })
            .catch((_) => {
                this.setState({ isLoading: false });
            });
    }

    toggleImagesModal() {
        const { isImagesModalOpen } = this.state;
        this.setState({ isImagesModalOpen: !isImagesModalOpen });
        if (isImagesModalOpen) {
            axios
                .get(`${config.apiUrl}/file/images/`, {
                    params: { relatedEntityType: 'user', relatedEntityId: this.state.user.id },
                })
                .then((res) =>
                    this.setState((prevState) => ({
                        user: {
                            ...prevState.user,
                            files: res.data,
                        },
                    })),
                );
        }
    }

    getTabs() {
        return [
            { title: 'Access Points', component: <AccessPointsTab name="test" /> },
            { title: 'Notifications', component: <AccessPointsTab name="test" /> },
        ];
    }

    render() {
        const { activeTab, modalHeader, isLoading, isFormExpanded, formErrors } = this.state;
        const tabs = this.getTabs();
        return (
            <Modal
                headerText={modalHeader}
                isOpen={true}
                onClose={() => this.props.onClose(false, false)}
                onSave={this.save}
                isLoading={isLoading}
            >
                <div>
                    <Form size="large">
                        <Form.Group unstackable widths={2}>
                            <Form.Input
                                required
                                placeholder="First Name"
                                label="First Name"
                                name="firstName"
                                onChange={this.handleChange}
                                value={this.getValue('firstName')}
                                error={formErrors.firstName}
                            />
                            <Form.Input
                                required={true}
                                placeholder="Last Name"
                                label="Last Name"
                                name="lastName"
                                onChange={this.handleChange}
                                value={this.getValue('lastName')}
                                error={formErrors.lastName}
                            />
                        </Form.Group>
                        <Form.Group unstackable widths={2}>
                            <Form.Input
                                required={true}
                                placeholder="Email"
                                label="Email"
                                name="email"
                                onChange={this.handleChange}
                                error={formErrors.email}
                                value={this.getValue('email')}
                            />

                            <Form.Input
                                placeholder="Phone Number"
                                label="Phone Number"
                                name="phoneNumber"
                                onChange={this.handleChange}
                                value={this.getValue('phoneNumber')}
                            />
                        </Form.Group>

                        <Divider horizontal>
                            <span
                                className="clickable expand-link"
                                onClick={() => this.setState({ isFormExpanded: !isFormExpanded })}
                            >
                                {isFormExpanded ? 'Less' : 'More'}
                            </span>
                        </Divider>
                        <Transition.Group animation="fade up" duration="200">
                            {isFormExpanded && (
                                <>
                                    <Form.Group>
                                        <Form.TextArea
                                            width="16"
                                            placeholder="Notes"
                                            label="Notes"
                                            name="notes"
                                            onChange={this.handleChange}
                                            value={this.getValue('notes')}
                                        />
                                    </Form.Group>
                                </>
                            )}
                        </Transition.Group>
                    </Form>
                    {!isLoading &&
                        tabs.map((tab, index) => {
                            return (
                                <div
                                    className={`inline-flex px-5 border-b-4 ${
                                        activeTab === index
                                            ? ' border-blue-500 '
                                            : '  border-gray-500 hover:text-blue-400'
                                    }`}
                                    key={index}
                                >
                                    <span
                                        className={`clickable text-lg ${
                                            activeTab === index
                                                ? 'font-bold text-blue-500 border-blue-500 '
                                                : ' font-medium text-gray-400 border-gray-500 hover:text-blue-400'
                                        }`}
                                        onClick={() => this.setState({ activeTab: index })}
                                    >
                                        {tab.title}
                                    </span>
                                </div>
                            );
                        })}
                    {tabs[activeTab].component}
                </div>
            </Modal>
        );
    }
}

export default UserModal;
