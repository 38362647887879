import React from 'react';

import InventoryItem from 'models/InventoryItemModel';
import history from 'helpers/history';

interface Props {
    inventoryItems: InventoryItem[];
}

const SupplierInventoryItems: React.FC<Props> = ({ inventoryItems = [] }: Props) => {
    return (
        <div className="mt-4 overflow-x-auto">
            <table className="items-center bg-transparent border w-full">
                <thead className="border">
                    <tr className=" bg-blue-600 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                        <th className="pl-3 py-3 rounded-tl-xl">Description</th>
                        <th className="pl-6 py-3">Item Code</th>
                        <th className="px-6 py-3">Brand</th>
                        <th className="px-6 py-3">Price</th>
                        <th className="px-6 py-3">Cost</th>
                        <th className="px-6 py-3">Units</th>
                        <th className="px-6 py-3 rounded-tr-xl">Sales Revenue</th>
                    </tr>
                </thead>

                <tbody>
                    {inventoryItems.map((inventoryItem, index) => (
                        <tr
                            className={`border group font-medium hover:bg-blue-200 clickable ${
                                index % 2 === 0 ? 'bg-gray-100' : 'bg-blue-100'
                            }`}
                            key={index}
                            onClick={() => history.push(`/inventory/${inventoryItem.id}`)}
                        >
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2 text-left flex items-center">
                                <span className="font-semibold">{inventoryItem.description}</span>
                            </td>
                            <td className="px-6 min-w-max align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>{inventoryItem.itemCode}</span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>{inventoryItem.brand}</span>
                            </td>

                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span className="font-semibold">
                                    {new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }).format(inventoryItem.retailPrice)}{' '}
                                </span>
                            </td>

                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span className="font-semibold">
                                    {new Intl.NumberFormat('en-US', {
                                        style: 'currency',
                                        currency: 'USD',
                                    }).format(inventoryItem.cost)}{' '}
                                </span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>$</span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>$</span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {inventoryItems?.length! < 1 && (
                <div className="border py-4 px-4 wb-white text-gray-600 text-lg font-semibold text-center">
                    There are no items in inventory linked to this supplier yet
                </div>
            )}
        </div>
    );
};

export default SupplierInventoryItems;
