import axios from 'helpers/axios';
import { Dispatch } from 'redux';

import config from 'helpers/config';
import InternalCommentModel from 'models/InternalCommentModel';
import RepairOrder from 'models/RepairOrderModel';
import User from 'models/UserModel';
import React from 'react';
import { connect } from 'react-redux';
import InternalComment from './InternalComment';
import actionTypes from 'helpers/actionTypes';

interface Props {
    chatId: string;
    currentUser: User;

    repairOrder?: RepairOrder;
    dispatch: Dispatch;
}

interface State {
    chat: InternalCommentModel[];
}

class InternalChat extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            chat: [],
        };

        this.addNewComment = this.addNewComment.bind(this);
    }

    componentDidMount() {
        axios.get(`${config.apiUrl}/chat/${this.props.chatId}`).then((res) => this.setState({ chat: res.data }));
    }

    addNewComment(newComment: InternalCommentModel) {
        this.setState({ chat: [newComment, ...this.state.chat] });

        if (this.props.repairOrder) {
            this.props.dispatch({
                type: actionTypes.UPDATE_REPAIR_ORDER_SUCCESS,
                payload: {
                    ...this.props.repairOrder,
                    messagesCount: !!this.props.repairOrder.messagesCount ? ++this.props.repairOrder.messagesCount : 1,
                },
            });
        }
    }

    render() {
        const { chat } = this.state;
        const { currentUser, chatId } = this.props;
        return (
            <div>
                <InternalComment
                    comment={
                        {
                            createdBy: currentUser,
                        } as InternalCommentModel
                    }
                    isCreateMode={true}
                    chatId={chatId}
                    commentAdded={this.addNewComment}
                    repairOrder={this.props?.repairOrder}
                />
                {chat.map((comment) => (
                    <InternalComment comment={comment} key={comment.id} isCreateMode={false} />
                ))}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(InternalChat);
