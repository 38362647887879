import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { getSuppliers, getSuppliersOverview } from 'actions/supplierActions';
import Pagination from 'components/common/Pagination';
import history from 'helpers/history';
import placeholders from 'helpers/placeholders';
import TableColumn from 'models/TableColumnModel';
import Supplier from 'models/SupplierModel';
import axios from 'helpers/axios';
import config from 'helpers/config';
import SupplierModal from 'components/modals/SupplierModal';
import { FaEdit, FaRegTrashAlt, FaSort } from 'react-icons/fa';
import { displayConfirmationModal } from 'helpers/services/removeConfirmationService';

const { apiUrl } = config;

interface ComponentState {
    errorMessage: string | null;
    searchPhrase: string;
    page: number;
    itemsPerPage: number;
    suppliersCount: number;
    [x: string]: any;
}

interface Props {
    suppliers: Supplier[];
    suppliersLoading: boolean;
    suppliersCount: number;
    dispatch: Dispatch;
}

class SuppliersListPage extends React.Component<Props, ComponentState> {
    constructor(props: Props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.getSuppliers = this.getSuppliers.bind(this);
        this.pageChanged = this.pageChanged.bind(this);
        this.modalClosed = this.modalClosed.bind(this);

        this.state = {
            errorMessage: null,
            searchPhrase: '',
            page: 1,
            itemsPerPage: 30,
            suppliersCount: 0,
            isCreateSupplierModalOpen: false,
            isEditSupplierModalOpen: false,
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch<any>(getSuppliersOverview());
        this.getSuppliers();
    }

    getSuppliers() {
        const { dispatch } = this.props;
        const { page, searchPhrase } = this.state;
        let { itemsPerPage } = this.state;
        if (this.state.searchPhrase?.length > 0) {
            itemsPerPage = this.props.suppliersCount;
        }
        dispatch<any>(getSuppliers({ page, itemsPerPage, searchPhrase }));
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const { name, value } = event.currentTarget;
        this.setState(
            {
                [name]: value,
            },
            this.getSuppliers,
        );
    }

    openSupplier(supplierId: string) {
        history.push(`/supplier/${supplierId}`);
    }

    openSupplierWebsite(url: string, e: React.MouseEvent) {
        e.stopPropagation();
        window.open(url, '_blank');
    }

    editSupplier(supplierId: string) {
        this.setState({ isEditSupplierModalOpen: true, editableSupplierId: supplierId });
    }

    pageChanged(page?: number) {
        if (page) {
            this.setState({ page }, () => this.getSuppliers());
        }
    }

    deleteSupplier(supplier: Supplier) {
        displayConfirmationModal(`${supplier.companyName}`)
            .then(() => {
                axios
                    .delete(`${apiUrl}/supplier/${this.state.editableSupplierId}`)

                    .then(() => {
                        this.componentDidMount();
                    })
                    .catch((err) => console.log(err));
            })
            .catch((_) => _);
    }

    isColumnActive(columnName: string) {
        return tableColumns.find((column) => column.name === columnName)?.active;
    }

    modalClosed(supplierCreated?: boolean, supplierEdited?: boolean) {
        this.setState({ isCreateSupplierModalOpen: false, isEditSupplierModalOpen: false, editableSupplierId: null });
        if (supplierCreated || supplierEdited) {
            this.componentDidMount();
        }
    }

    getSupplierModal() {
        const { isCreateSupplierModalOpen, isEditSupplierModalOpen, editableSupplierId } = this.state;
        if (isEditSupplierModalOpen && !!editableSupplierId) {
            return (
                <SupplierModal
                    supplierId={editableSupplierId}
                    onClose={(supplierCreated?: boolean, supplierEdited?: boolean) =>
                        this.modalClosed(supplierCreated, supplierEdited)
                    }
                ></SupplierModal>
            );
        } else if (isCreateSupplierModalOpen) {
            return (
                <SupplierModal
                    onClose={(supplierCreated?: boolean, supplierEdited?: boolean) =>
                        this.modalClosed(supplierCreated, supplierEdited)
                    }
                ></SupplierModal>
            );
        }
    }

    render() {
        const { page, itemsPerPage, isCreateSupplierModalOpen, isEditSupplierModalOpen } = this.state;
        const { suppliersCount } = this.props;
        return (
            <div className="list-page">
                <h2 className="list-page__title">Suppliers List</h2>

                {isEditSupplierModalOpen || isCreateSupplierModalOpen ? this.getSupplierModal() : null}

                <div className="search-add-row items-center mt-3">
                    <input
                        onChange={this.handleChange}
                        name="searchPhrase"
                        placeholder={placeholders.CUSTOMER_SEARCH}
                        className="bg-white placeholder  placeholder-gray-400 placeholder-font-bold rounded-xl border border-green-500 px-2 py-1 font-medium w-1/3 focus:ring-1 focus:outline-none focus:border-blue-500"
                    ></input>
                    <button
                        className="bg-green-600 hover:bg-green-700 py-1 px-5 rounded-2xl font-medium text-base ml-auto"
                        onClick={() => this.setState({ isCreateSupplierModalOpen: !isCreateSupplierModalOpen })}
                    >
                        + Add new
                    </button>
                </div>
                <h4 className="font-semibold my-2">{suppliersCount} suppliers found</h4>
                {
                    <div className="overflow-x-auto">
                        <table className="items-center w-full">
                            <thead>
                                <tr className="bg-blue-500 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                                    {tableColumns.map(
                                        (column, index) =>
                                            column.active && (
                                                <th
                                                    key={index}
                                                    className={`pl-6 py-3 ${index === 0 && 'rounded-tl-md'}`}
                                                >
                                                    <span className="clickable">
                                                        <span>{column.text}</span>
                                                        {!!column.sortable && <FaSort className="inline ml-1" />}
                                                    </span>
                                                </th>
                                            ),
                                    )}
                                    <th className="px-6 py-3 rounded-tr-md">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.props.suppliers?.map((supplier) => (
                                    <tr
                                        className="border-b font-semibold hover:bg-blue-200 clickable bg-white border-r-2 border-l-2"
                                        key={supplier.id}
                                        onClick={() => this.openSupplier(supplier.id!)}
                                    >
                                        {this.isColumnActive('companyName') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3">
                                                {supplier.companyName}
                                            </td>
                                        )}

                                        {this.isColumnActive('phone') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3">
                                                {supplier.phone}
                                            </td>
                                        )}
                                        {this.isColumnActive('email') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3">
                                                {supplier.email}
                                            </td>
                                        )}
                                        {this.isColumnActive('website') && (
                                            <td
                                                className="px-6 align-middle whitespace-nowrap p-3"
                                                onClick={(e) => this.openSupplierWebsite(supplier.website, e)}
                                            >
                                                <a>{supplier.website}</a>
                                            </td>
                                        )}
                                        <td className="px-6 align-middle whitespace-nowrap p-3 non-clickable">
                                            <FaRegTrashAlt
                                                size={15}
                                                className="inline clickable mr-1"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.deleteSupplier(supplier);
                                                }}
                                            />
                                            <FaEdit
                                                size={15}
                                                className="inline clickable"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    this.editSupplier(String(supplier.id));
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {this.props.suppliers?.length < 1 && !this.props.suppliersLoading && (
                            <div className="py-8 flex justify-center border-b-2 border-l-2 border-r-2 rounded-b-lg bg-white">
                                <h2 className="text-lg font-semibold">No suppliers found</h2>
                            </div>
                        )}
                    </div>
                }
                <div className="mt-4">
                    <Pagination
                        currentPage={page}
                        pageSize={itemsPerPage}
                        totalItems={suppliersCount}
                        pageChanged={this.pageChanged}
                    ></Pagination>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    suppliers: state.supplier.suppliersList,
    suppliersLoading: state.supplier.suppliersLoading,
    suppliersCount: state.supplier.suppliersCount,
});

export default connect(mapStateToProps)(SuppliersListPage);

const tableColumns: TableColumn[] = [
    {
        name: 'companyName',
        active: true,
        sortable: true,
        text: 'Supplier Name',
    },
    {
        name: 'phone',
        active: true,
        sortable: true,
        text: 'Phone',
    },
    {
        name: 'email',
        active: true,
        sortable: true,
        text: 'Email',
    },
    {
        name: 'website',
        active: true,
        sortable: true,
        text: 'Website',
    },
];
