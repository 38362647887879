import axios from 'helpers/axios';
import config from 'helpers/config';
import User from 'models/UserModel';
import React from 'react';
import { useEffect, useState } from 'react';
import UserClockInCard from './UserClockInCard';
import ClockIn from 'models/ClockIn';
import moment from 'moment';
import dateFormats from 'helpers/dateFormats';
import TableColumn from 'models/TableColumnModel';
import { FaEdit, FaRegTrashAlt, FaSort } from 'react-icons/fa';
import RepairOrder from 'models/RepairOrderModel';
import TimeLogModal from './TimeLogModal';
import { displayConfirmationModal } from 'helpers/services/removeConfirmationService';

const TimeLogsPage = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [timeLogs, setTimeLogs] = useState<ClockIn[]>([]);
    const [activeRepairOrders, setActiveRepairOrders] = useState<RepairOrder[]>([]);
    const [isEditTimeLogModelOpen, setIsEditTimeLogModelOpen] = useState<boolean>(false);
    const [editableTimeLog, setEditableTimeLog] = useState<ClockIn | null>(null);

    useEffect(() => {
        fetchUsers();
        fetchTimeLogs();
        fetchActiveROS();
    }, []);

    const fetchUsers = async () => {
        axios
            .get(`${config.apiUrl}/user`, {
                params: {
                    withTimeLogs: true,
                },
            })
            .then((response) => {
                setUsers(response.data);
            })
            .catch((err) => console.log(err));
    };

    const fetchTimeLogs = async () => {
        axios
            .get(`${config.apiUrl}/timeLog/`)
            .then((response) => {
                setTimeLogs(response.data);
            })
            .catch((err) => console.log(err));
    };

    const fetchActiveROS = () => {
        axios
            .get(`${config.apiUrl}/repairOrder/board`)
            .then((res) => setActiveRepairOrders(res.data))
            .catch((_) => console.log(_));
    };

    const isColumnActive = (columnName: string) => {
        return timeLogColumns.find((column) => column.name === columnName)?.active;
    };

    const getDuration = (timeLog: ClockIn) => {
        const durationMilliseconds = moment
            .duration(moment(timeLog.clockOutTime).diff(moment(timeLog.clockInTime)))
            .as('milliseconds');
        const durationDays = Math.floor(durationMilliseconds / (1000 * 60 * 60 * 24));
        const remainingMilliseconds = durationMilliseconds % (1000 * 60 * 60 * 24);

        return `${durationDays > 0 ? `${durationDays} day${durationDays > 1 ? 's' : ''}, ` : ''}${moment
            .utc(remainingMilliseconds)
            .format('HH:mm:ss')}`;
    };

    const handleDelete = (timeLog: ClockIn) => {
        displayConfirmationModal()
            .then(() => {
                axios
                    .delete(`${config.apiUrl}/timeLog/${timeLog.id}`)
                    .then((_) => {
                        fetchTimeLogs();
                    })
                    .catch((err) => console.log(err));
            })
            .catch((_) => _);
    };

    const handleEdit = (timeLog: ClockIn) => {
        setIsEditTimeLogModelOpen(true);
        setEditableTimeLog(timeLog);
    };

    return (
        <div className="p-4">
            {isEditTimeLogModelOpen && editableTimeLog && (
                <TimeLogModal
                    timeLog={editableTimeLog}
                    handleClose={() => {
                        setEditableTimeLog(null);
                        setIsEditTimeLogModelOpen(false);
                        fetchTimeLogs();
                    }}
                ></TimeLogModal>
            )}
            <div className="grid grid-cols-3 gap-x-3 gap-y-3">
                {users.map((user) => (
                    <UserClockInCard
                        key={user.id}
                        user={user}
                        timeLogs={timeLogs.filter((log) => log.userId === user.id)}
                        onChange={fetchTimeLogs}
                        activeRepairOrders={activeRepairOrders}
                    />
                ))}
            </div>
            <div className="mt-12 mb-24">
                <div className="w-full mb-4 pb-2 border-b-2 border-blue-600 text-lg text-blue-500 font-semibold">
                    Time Logs
                </div>
                <div className="overflow-x-auto">
                    <table className="items-center w-full ">
                        <thead>
                            <tr className=" text-white bg-gray-200 font-extrabold  text-left text-xs uppercase align-middle">
                                {timeLogColumns.map(
                                    (column, index) =>
                                        column.active && (
                                            <th key={index} className={`pl-6 py-3 ${index === 0 && 'rounded-tl-md'}`}>
                                                <span className="clickable text-blue-500 font-bold ">
                                                    <span>{column.text}</span>
                                                    {!!column.sortable && <FaSort className="inline ml-1" />}
                                                </span>
                                            </th>
                                        ),
                                )}
                                <th className="px-6 py-3 rounded-tr-md  text-blue-500 font-bold">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {!!timeLogs &&
                                timeLogs.map((timeLog) => (
                                    <tr
                                        className={`border-b font-semibold hover:bg-blue-200 clickable bg-white border-r-2 border-l-2`}
                                        key={timeLog.id}
                                    >
                                        {isColumnActive('user') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3">
                                                <div>
                                                    <span className="font-semibold text-blue-400">
                                                        {timeLog.user?.firstName} {timeLog.user?.lastName}
                                                    </span>
                                                </div>
                                            </td>
                                        )}
                                        {isColumnActive('RO') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3">
                                                <div>
                                                    {timeLog.repairorder && (
                                                        <span className="font-semibold text-gray-500">
                                                            #{timeLog.repairorder?.repairOrderNumber}{' '}
                                                            {timeLog.repairorder?.title}
                                                        </span>
                                                    )}
                                                </div>
                                            </td>
                                        )}
                                        {isColumnActive('clockInTime') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400 ">
                                                <span className="align-baseline">
                                                    <span>
                                                        {moment(timeLog.clockInTime).format(dateFormats.USA_DATETIME)}
                                                    </span>
                                                </span>
                                            </td>
                                        )}
                                        {isColumnActive('clockOutTime') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400">
                                                {timeLog.clockOutTime ? (
                                                    <span>
                                                        <span>
                                                            {moment(timeLog.clockOutTime).format(
                                                                dateFormats.USA_DATETIME,
                                                            )}
                                                        </span>
                                                    </span>
                                                ) : (
                                                    <span className="bg-green-500 rounded-lg p-1.5 text-white font-semibold">
                                                        Ongoing
                                                    </span>
                                                )}
                                            </td>
                                        )}

                                        {isColumnActive('duration') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3">
                                                {timeLog.clockOutTime && timeLog.clockInTime && (
                                                    <div>
                                                        <span className="font-semibold">{getDuration(timeLog)}</span>
                                                    </div>
                                                )}
                                            </td>
                                        )}
                                        {isColumnActive('description') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400">
                                                <span>{timeLog?.description}</span>
                                            </td>
                                        )}

                                        <td className="px-6 align-middle whitespace-nowrap p-3 non-clickable">
                                            <FaRegTrashAlt
                                                size={15}
                                                className="inline clickable mr-1"
                                                onClick={(_) => {
                                                    handleDelete(timeLog);
                                                }}
                                            />
                                            <FaEdit
                                                size={15}
                                                className="inline clickable"
                                                onClick={(_) => {
                                                    handleEdit(timeLog);
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default TimeLogsPage;
const timeLogColumns: TableColumn[] = [
    {
        name: 'user',
        active: true,
        text: 'User',
        sortable: false,
    },
    {
        name: 'RO',
        active: true,
        text: 'Repair Order',
        sortable: false,
    },
    {
        name: 'clockInTime',
        active: true,
        text: 'Start Date Time',
        sortable: false,
    },
    {
        name: 'clockOutTime',
        active: true,
        text: 'End Date Time',
        sortable: false,
    },

    {
        name: 'duration',
        active: true,
        text: 'Duration',
        sortable: false,
    },
    {
        name: 'description',
        active: true,
        text: 'Note',
        sortable: false,
    },
];
