import MethodOfContact from 'components/common/MethodOfContact';
import Customer from 'models/CustomerModel';
import React from 'react';

interface Props {
    customer: Customer;
    handleChange: (e: any) => void;
    isEditMode: boolean;
}

const CustomerDetailsForm: React.FC<Props> = ({ customer, handleChange, isEditMode = false }: Props) => {
    const getValue = (propertyName: string) => {
        // @ts-ignore
        return customer[propertyName];
    };

    return (
        <div className="grid grid-cols-2 mb-4">
            <div className="w-full my-2 pl-4">
                <span className="inline-block w-1/4">First Name</span>
                <input
                    className={`rounded-md border-none font-bold w-3/4 pl-4 py-2`}
                    type="text"
                    value={getValue('firstName')}
                    name="firstName"
                    disabled={!isEditMode}
                    onChange={handleChange}
                />
            </div>
            <div className="w-full my-2 pl-4">
                <span className="inline-block w-1/4">Last Name</span>
                <input
                    className={`rounded-md border-none font-bold w-3/4 pl-4 py-2`}
                    type="text"
                    value={getValue('lastName')}
                    name="lastName"
                    disabled={!isEditMode}
                    onChange={handleChange}
                />
            </div>
            <div className="w-full my-2 pl-4">
                <span className="inline-block w-1/4">Email</span>
                <input
                    className={`rounded-md border-none font-bold w-3/4 pl-4 py-2`}
                    type="text"
                    value={getValue('primaryEmail')}
                    name="primaryEmail"
                    disabled={!isEditMode}
                    onChange={handleChange}
                />
            </div>
            <div className="w-full my-2 pl-4 flex">
                <span className="inline-block w-1/4">Preferred method of contact</span>
                <div className="w-1/2">
                    <MethodOfContact
                        selected={getValue('preferredMethodOfContact')}
                        updateMethodOfContact={(MOC) =>
                            handleChange({ currentTarget: { name: 'preferredMethodOfContact', value: MOC } })
                        }
                        isDisabled={!isEditMode}
                    />
                </div>
            </div>
            <div className="w-full my-2 pl-4">
                <span className="inline-block w-1/4">Notes</span>
                <input
                    className={`rounded-md border-none font-bold w-3/4 pl-4 py-2`}
                    type="text"
                    value={getValue('notes')}
                    name="notes"
                    disabled={!isEditMode}
                    onChange={handleChange}
                />
            </div>
        </div>
    );
};

export default CustomerDetailsForm;
