import FullPhotoView from 'components/common/FullPhotoView';
import RelativeTime from 'components/common/RelativeTime';
import config from 'helpers/config';
import FileModel from 'models/FileModel';
import moment from 'moment';
import React, { useState } from 'react';
import UserFullName from './UserFullName';

interface Props {
    files: FileModel[];
    entityName: string;
}

const FilesList: React.FC<Props> = ({ entityName, files = [] }: Props) => {
    const [isPhotoExpanded, setIsPhotoExpanded] = useState(false);
    const [expandedPhoto, setExpandedPhoto] = useState<FileModel>();

    return (
        <div>
            {isPhotoExpanded && expandedPhoto && (
                <FullPhotoView
                    photoSourceURL={`${config.fileRootURL}/images/${expandedPhoto.fileName}`}
                    fileName={expandedPhoto.fileName}
                    onClose={() => {
                        setIsPhotoExpanded(false);
                        setExpandedPhoto(undefined);
                    }}
                />
            )}
            <div className="w-10/12 mt-4 overflow-x-auto">
                <table className="items-center bg-transparent border w-full">
                    <thead className="border">
                        <tr className=" bg-blue-600 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                            <th className="pl-6 py-3 rounded-tl-xl">Title</th>
                            <th className="pl-6 py-3 ">Date Added</th>
                            <th className="pl-6 py-3 ">Added By</th>
                            <th className="px-6 py-3 rounded-tr-xl"></th>
                        </tr>
                    </thead>

                    <tbody>
                        {files?.map((file, index) => (
                            <tr
                                className={`border group font-medium hover:bg-blue-200 clickable ${
                                    index % 2 === 0 ? 'bg-gray-100' : 'bg-blue-100'
                                }`}
                                key={index}
                            >
                                <td className="px-6 min-w-max align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2 text-left flex items-center">
                                    <span className="font-semibold text-gray-500">{file.fileName}</span>
                                </td>
                                <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                    <span className="float-left">
                                        <RelativeTime dateTime={file.createdAt} />
                                    </span>
                                    <br />
                                    <span>
                                        <span className="text-sm">{moment(file.createdAt).format('MM/DD/YYYY')}</span>
                                    </span>
                                </td>
                                <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                    {!!file?.createdById && <UserFullName userId={file.createdById} />}
                                </td>
                                <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                    {file.type === 'image' && (
                                        <div>
                                            <div className="ml-2 border-2 mt-4">
                                                <img
                                                    src={`${config.fileRootURL}/images/${file.fileName}`}
                                                    alt={file.fileName}
                                                    title={file.fileName}
                                                    className="clickable inspection-item-photo-tooltip inline "
                                                    onClick={() => {
                                                        setIsPhotoExpanded(true);
                                                        setExpandedPhoto(file);
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {files.length! < 1 && (
                    <div className="border py-4 px-4 wb-white text-gray-600 text-lg font-semibold text-center">
                        This {entityName} has no attached files
                    </div>
                )}
            </div>
        </div>
    );
};

export default FilesList;
