import React from 'react';
import User from '../../../models/UserModel';
import history from '../../../helpers/history';
import { Link } from 'react-router-dom';

interface Props {
    user: User;
}

const DemoWarning: React.FC<Props> = (props: Props) => {
    const { tenant } = props.user;
    let daysOfTrialLeft = null;
    if (tenant?.isInDemo && tenant?.trialEnding) {
        const today = new Date();
        const trialEndingDate = new Date(tenant.trialEnding);
        const diff = trialEndingDate.getTime() - today.getTime();
        daysOfTrialLeft = Math.floor(diff / (1000 * 60 * 60 * 24)) + 1;
    }
    if (typeof daysOfTrialLeft === 'number' && daysOfTrialLeft <= 0) {
        setTimeout(() => history.push('/subscribe'), 500);
    }

    return (
        <div className={daysOfTrialLeft ? 'header__demo-warning' : ''}>
            {!!daysOfTrialLeft && (
                <small>
                    <b>{daysOfTrialLeft}</b> days of trial left!{' '}
                    <Link className="clickable" to="/subscribe">
                        <b>Subscribe now</b>
                    </Link>
                </small>
            )}
        </div>
    );
};

export default DemoWarning;
