import CustomerFullName from 'components/common/CustomerFullName';
import NewDropdown from 'components/common/NewDropdown';
import Price from 'components/common/Price';
import RelativeTime from 'components/common/RelativeTime';
import Toggle from 'components/common/Toggle';
import UserFullName from 'components/common/UserFullName';
import axios from 'helpers/axios';
import config from 'helpers/config';
import dateFormats from 'helpers/dateFormats';
import Payment from 'models/PaymentModel';
import OrderByQueryModel from 'models/common/OrderByQueryModel';
import SearchQueryParamsModel from 'models/common/SearchQueryParamsModel';
import DateRangeType from 'models/types/DateRangeTypes';
import OrderByType from 'models/types/OrderByTypes';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {}

interface State {
    payments: Payment[];
    orderBy: OrderByType;
    dateRange: DateRangeType;

    dateFrom: string;
    dateTo: string;
}

class AllPaymentsReports extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            payments: [],
            orderBy: OrderByType.Newest,
            dateRange: DateRangeType.AllTime,
            dateFrom: new Date(new Date().setMonth(new Date().getMonth() - 1)).toISOString().slice(0, 10),
            dateTo: new Date().toISOString().slice(0, 10),
        };
    }

    componentDidMount() {
        const queryParams: SearchQueryParamsModel & OrderByQueryModel = {
            orderBy: this.state.orderBy,
            dateRange: this.state.dateRange,
            dateFrom: new Date(this.state.dateFrom),
            dateTo: new Date(this.state.dateTo),
        };

        axios
            .get(`${config.apiUrl}/report/allPayments`, { params: queryParams })
            .then((res) => this.setState({ payments: [...res.data] }));
    }

    render() {
        const { payments } = this.state;
        return (
            <div>
                <h2 className="font-bold text-gray-600 text-xl font-mono">All Payments Report</h2>
                <div className="my-4 p-3 border border-gray-600 rounded-lg bg-white">
                    <div className="flex">
                        <div className="flex mr-6">
                            <span className="text-gray-500 font-semibold mr-4">Include Sales tax: </span>
                            <Toggle
                                value={false}
                                name="collectPayments"
                                label="Collect Payments"
                                onChange={function (newValue: boolean): void {
                                    console.log(newValue);
                                }}
                            />
                        </div>
                        <div className="flex mr-6">
                            <span className="text-gray-500 font-semibold mr-4">Order by: </span>
                            <NewDropdown
                                items={orderByOptions}
                                bindName={'name'}
                                onSelect={(orderBy) => {
                                    this.setState(
                                        {
                                            orderBy: orderBy.value,
                                        },
                                        this.componentDidMount,
                                    );
                                }}
                                isServiceCard={true}
                                selected={orderByOptions.find((option) => option.value === this.state.orderBy)}
                                verticalPadding="py-0"
                            />
                        </div>
                        <div className="flex mr-1.5">
                            <span className="text-gray-500 font-semibold mr-4">From: </span>
                            <NewDropdown
                                items={dateRangeOptions}
                                isServiceCard={true}
                                bindName={'name'}
                                onSelect={(dateRange) => {
                                    this.setState(
                                        {
                                            dateRange: dateRange.value,
                                        },
                                        this.componentDidMount,
                                    );
                                }}
                                selected={dateRangeOptions.find((option) => option.value === this.state.dateRange)}
                                verticalPadding="py-0"
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-4 overflow-x-auto ">
                    <table className="items-center bg-transparent border w-full">
                        <thead className="border">
                            <tr className=" bg-blue-600 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                                <th className="pl-6 py-3 rounded-tl-xl">Amount</th>
                                <th className="px-6 py-3">RO #</th>
                                <th className="px-6 py-3">Date Posted</th>
                                <th className="px-6 py-3">Payment Date</th>
                                <th className="px-6 py-3">Reference Number</th>
                                <th className="px-6 py-3">Customer</th>
                                <th className="px-6 py-3 rounded-tr-xl">Recorded By</th>
                            </tr>
                        </thead>

                        <tbody>
                            {payments?.map((payment, index) => (
                                <tr
                                    className={`border group font-medium hover:bg-blue-200 clickable ${
                                        index % 2 === 0 ? 'bg-gray-100' : 'bg-blue-100'
                                    }`}
                                    key={payment.id}
                                >
                                    <td className="px-6 min-w-max align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2 text-left flex items-center">
                                        <span className="font-semibold text-green-400">
                                            <Price price={payment.amountCents / 100} />
                                        </span>
                                    </td>
                                    <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                        <span className="font-semibold text-gray-500">
                                            <Link to={`/repairOrder/${payment.repairorderId}`}>
                                                RO#: {payment.repairorder?.repairOrderNumber}
                                            </Link>
                                        </span>
                                    </td>
                                    <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                        <span className="float-left">
                                            <RelativeTime dateTime={payment.createdAt} />
                                        </span>
                                        <br />
                                        <span>
                                            <span className="text-sm">
                                                {moment(payment.createdAt).format(dateFormats.USA_DATETIME)}
                                            </span>
                                        </span>
                                    </td>
                                    <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                        <span className="float-left">
                                            <RelativeTime dateTime={payment.paymentDate} />
                                        </span>
                                        <br />
                                        <span>
                                            <span className="text-sm">
                                                {moment(payment.paymentDate).format('MM/DD/YYYY')}
                                            </span>
                                        </span>
                                    </td>
                                    <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                        <span className="font-semibold text-gray-500">{payment?.referenceNumber}</span>
                                    </td>
                                    <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                        <span className="font-semibold text-gray-500">
                                            <Link to={`/customer/${payment.repairorder?.customerId}`}>
                                                <CustomerFullName customerId={payment.repairorder?.customerId!} />
                                            </Link>
                                        </span>
                                    </td>
                                    <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                        {!!payment?.createdById && <UserFullName userId={payment?.createdById} />}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {payments?.length! < 1 && (
                        <div className="border py-4 px-4 wb-white text-gray-600 text-lg font-semibold text-center">
                            Your repair shop has not recorded any payments yet
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default AllPaymentsReports;

const orderByOptions = [
    {
        id: 777,
        name: 'Newest',
        value: OrderByType.Newest,
    },
    {
        id: 0,
        name: 'Oldest',
        value: OrderByType.Oldest,
    },
    {
        id: 2,
        name: 'Amount paid: highest first',
        value: 'amount.DESC',
    },
    {
        id: 3,
        name: 'Amount paid: lowest first',
        value: 'amount.ASC',
    },
];

const dateRangeOptions = [
    {
        id: 777,
        name: 'All Time',
        value: DateRangeType.AllTime,
    },
    {
        id: 0,
        name: 'Last 24h',
        value: DateRangeType.Last24Hours,
    },
    {
        id: 2,
        name: 'Past Week',
        value: DateRangeType.PastWeek,
    },
    {
        id: 3,
        name: 'Past Month',
        value: DateRangeType.PastMonth,
    },
    {
        id: 4,
        name: 'Past Year',
        value: DateRangeType.PastYear,
    },
    {
        id: 5,
        name: 'Custom',
        value: DateRangeType.Custom,
    },
];
