import axios from 'helpers/axios';
import config from 'helpers/config';
import LoanerVehicle from 'models/LoanerVehicleModel';
import React, { useState } from 'react';
import { FaTimesCircle } from 'react-icons/fa';

interface Props {
    loanerSelected: (loanerId?: LoanerVehicle) => void;
    displayDetailsWhenSelected?: boolean;
}

const LoanerSearch: React.FC<Props> = ({ displayDetailsWhenSelected = false, loanerSelected }: Props) => {
    const [loaners, setLoaners] = useState<LoanerVehicle[]>([]);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [isSearchLocked, setIsSearchLocked] = useState(false);
    const [selectedLoaner, setSelectedLoaner] = useState<LoanerVehicle | undefined>();

    const fetchLoaners = (searchPhrase: string) => {
        axios
            .get(`${config.apiUrl}/loaner/search`, { params: { searchPhrase } })
            .then((res) => {
                setLoaners(res.data);
            })
            .catch((_) => {
                setLoaners([]);
            });
    };

    const handleCustomerSelect = (loaner: LoanerVehicle) => {
        setSelectedLoaner(loaner);
        loanerSelected(loaner);
        setLoaners([]);
        setIsSearchActive(false);
        setIsSearchLocked(false);
    };

    return (
        <div>
            {!!selectedLoaner ? (
                <div className="rounded-lg px-2 py-1 border border-black font-semibold w-full items-baselin bg-white">
                    <div className="flex w-full">
                        <div>
                            <div>
                                <span className="mr-2">
                                    {selectedLoaner.year} {selectedLoaner.make} {selectedLoaner.model}
                                </span>
                            </div>
                            {selectedLoaner.VIN && (
                                <div>
                                    <small>VIN: {selectedLoaner.VIN}</small>
                                </div>
                            )}
                        </div>
                        <div className="ml-auto">
                            <FaTimesCircle
                                onClick={() => {
                                    setSelectedLoaner(undefined);
                                    loanerSelected(undefined);
                                }}
                                className="inline mr-1 clickable  ml-auto "
                            />
                        </div>
                    </div>
                    {displayDetailsWhenSelected && (
                        <div>
                            <div className="text-sm">mileageee:</div>
                        </div>
                    )}
                </div>
            ) : (
                <input
                    type="text"
                    className="rounded-lg border-blue-600 border-2 w-full px-2 py-1 "
                    onChange={(e) => fetchLoaners(e.target.value)}
                    onFocus={() => {
                        setIsSearchActive(true);
                        fetchLoaners('');
                    }}
                    onBlur={() => {
                        setTimeout(() => {
                            if (isSearchLocked) {
                                return;
                            }
                            setIsSearchActive(false);
                        }, 100);
                    }}
                    placeholder="Search by make, model, year or VIN"
                />
            )}
            {!selectedLoaner && isSearchActive && (
                <div
                    className="border absolute z-50 w-full border-blue-600 rounded-sm divide-y-2 border-t-0"
                    onMouseOver={() => setIsSearchLocked(true)}
                    onMouseLeave={() => setIsSearchLocked(false)}
                >
                    {loaners &&
                        loaners.map((loaner, index) => (
                            <div
                                key={index}
                                className="clickable hover:bg-blue-200 px-4 py-1.5 bg-white"
                                onClickCapture={() => handleCustomerSelect(loaner)}
                            >
                                <div className="font-semibold">
                                    {loaner.year} {loaner.make} {loaner.model}
                                </div>
                                {loaner.VIN && <div className="text-sm">{loaner.VIN}</div>}
                            </div>
                        ))}
                    {loaners?.length < 1 && (
                        <div className="flex flex-col text-center py-4 font-semibold text-gray-600 bg-white">
                            There are 0 results
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default LoanerSearch;
