import Modal from 'components/common/Modal';
import ModalStateType from 'models/generic/ModalState';
import Service from 'models/ServiceModel';
import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import Toggle from 'components/common/Toggle';
import ServiceAuthorizationStatus from 'models/types/ServiceAuthorizationStatus';
import Authorization from 'models/AuthorizationModel';
import AuthorizationType from 'models/types/AuthorizationType';
import { FaCheck } from 'react-icons/fa';
import { IoMdCheckmark } from 'react-icons/io';

interface Props {
    services: Service[];
    authorization?: Authorization;

    onClose: (authorizationUpdated: boolean) => void;
    dispatch: Dispatch;
}

interface State {
    isLoading: boolean;
    modalState: ModalStateType;

    authorization: Partial<Authorization>;
}

class ManualAUthorizationModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            modalState: ModalStateType.Create,
            authorization: {},
        };
    }

    componentDidMount() {
        if (this.props.authorization) {
            this.setState({ modalState: ModalStateType.Overview });
        } else {
            this.setState({
                modalState: ModalStateType.Create,
                authorization: { type: AuthorizationType.ManualInPerson },
            });
        }
    }

    isSelectedMethodOfAuthorization(authType: AuthorizationType) {
        return this.state.authorization.type === authType;
    }

    render() {
        const { isLoading, modalState } = this.state;
        const { services } = this.props;
        return (
            <Modal
                headerText={'Manual Authorization'}
                isOpen={true}
                onClose={() => this.props.onClose(false)}
                isLoading={isLoading}
                isBackgroundWhite={true}
                isDeleteHidden={modalState !== ModalStateType.Edit}
                saveText={'Update'}
                isSaveHidden={modalState !== ModalStateType.Edit}
            >
                {services.map((service) => (
                    <div key={service.id} className="mb-2">
                        <div className="flex">
                            <div className="">
                                <div className="font-bold text-lg">{service.title}</div>
                                <div className="font-semibold">
                                    $
                                    {service.items
                                        ?.map((item) => item.price || 0)
                                        .reduce((a, b) => +a + +b, 0)
                                        .toFixed(2)}
                                </div>
                            </div>
                            <div className="ml-auto">
                                <Toggle
                                    value={service.authorizationStatus === ServiceAuthorizationStatus.Authorized}
                                    name="isAuthorized"
                                    onChange={(newValue) => {
                                        console.log(newValue);
                                        // if (newValue) {
                                        //     this.setState({
                                        //         approvedServices: [...approvedServices, service],
                                        //     });
                                        // } else {
                                        //     this.setState({
                                        //         approvedServices: approvedServices.filter(
                                        //             (s) => s.id != service.id,
                                        //         ),
                                        //     });
                                        // }
                                    }}
                                />
                                {
                                    <div>
                                        <IoMdCheckmark
                                            className="text-xl bg-green-500  text-white border-1"
                                            size={25}
                                        />
                                        <FaCheck />
                                        {/* <Fa */}
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                ))}

                <div className="mt-6">
                    <div className="flex">
                        {authMethods.map((method) => {
                            return (
                                <button
                                    onClick={() => {
                                        this.setState({
                                            authorization: {
                                                ...this.state.authorization,
                                                type: method.value,
                                            },
                                        });
                                    }}
                                    key={method.value}
                                    className={`p-4 ${
                                        this.isSelectedMethodOfAuthorization(method.value) &&
                                        'bg-blue-500 text-white font-semibold'
                                    } text-black rounded-lg ${''} bg-white hover:bg-blue-100 w-1/4 border border-blue-600 clickable mr-2`}
                                >
                                    {method.text}
                                </button>
                            );
                        })}
                    </div>
                    <label htmlFor="note" className="font-bold">
                        Note
                    </label>
                    <textarea
                        name="note"
                        className="bg-gray-50 rounded-md border w-full px-2 font-medium py-2 focus:ring-1 focus:outline-none focus:border-blue-500"
                        // value={task.note}
                        // onChange={(e) => this.props.handleChange(e, index)}
                        // onBlur={() => this.props.updateTask(index)}
                    ></textarea>
                </div>
            </Modal>
        );
    }
}

const authMethods = [
    {
        value: AuthorizationType.ManualInPerson,
        text: 'In person',
    },
    {
        value: AuthorizationType.ManualByPhone,
        text: 'By Phone',
    },
    {
        value: AuthorizationType.ManualByText,
        text: 'By SMS',
    },
    {
        value: AuthorizationType.ManualyByEmail,
        text: 'By Email',
    },
];
export default connect()(ManualAUthorizationModal);
