import React, { useState } from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { GiHamburgerMenu } from 'react-icons/gi';

import DemoWarning from 'components/common/demoWarning/DemoWarning';
import DeleteConfirmation from 'components/common/DeleteConfirmation';
import AddNew from './AddNew';

import { getCurrentUser, getUsersAccessPoints } from 'actions/userActions';
import { deleteDemoData, getPricingMatrices, getTenantSettings } from 'actions/tenantActions';

import User from 'models/UserModel';

import modalResources from 'helpers/resources/modals';
import axios from 'helpers/axios';
import config from 'helpers/config';
import actionTypes from 'helpers/actionTypes';
import UserAvatar from './UserAvatar';
import Notifications from './Notifications';
import RecentRos from './RecentRos';
import SuperSearchResults from 'models/SuperSearchResultModel';
import getFullVehicleName from 'helpers/vehicleFullNameHelper';
import { FaClipboardList } from 'react-icons/fa';
import history from 'helpers/history';
import { CiCircleRemove } from 'react-icons/ci';

interface Props {
    companyName?: string;
    currentUser?: User;
    dispatch: Dispatch;
}

const NavigationBar: React.FC<Props> = (props: Props) => {
    const { currentUser, dispatch } = props;

    const [isDeleteDemoDataOpen, setIsDeleteDemoDataOpen] = useState(false);
    const [searchResults, setSearchResults] = useState<SuperSearchResults>();
    const [isSearchResultsOpen, setIsSearchResultsOpen] = useState(false);
    const [isNewRepairOrderLoading, setIsNewRepairOrderLoading] = useState(false);
    const [searchPhrase, setSearchPhrase] = useState('');

    const toggleSearchResults = (open: boolean) => {
        if (open) {
            setIsSearchResultsOpen(true);
            document.addEventListener('click', onClickOutside);
        } else {
            setIsSearchResultsOpen(false);
            // @ts-ignore
            document.getElementById('seach-bar')!.value = '';
            document.removeEventListener('click', onClickOutside);
        }
    };

    const onClickOutside = (e: any) => {
        const target = document.querySelector(`#search-results`);
        const withinBoundaries = e.composedPath().includes(target);

        if (withinBoundaries) {
        } else {
            toggleSearchResults(false);
        }
    };

    if (!props.currentUser) {
        dispatch<any>(getCurrentUser());
        dispatch<any>(getUsersAccessPoints());
        dispatch<any>(getTenantSettings());
        dispatch<any>(getPricingMatrices());
    }

    const handleDeleteDemoData = (deleteData: boolean) => {
        if (deleteData) {
            const { dispatch } = props;
            dispatch<any>(deleteDemoData());
        }
        setIsDeleteDemoDataOpen(false);
    };

    const toggleSidebar = () => {
        const { userSetting } = currentUser!;
        userSetting.isSideBarCollapsed = !userSetting.isSideBarCollapsed;
        dispatch({ type: actionTypes.UPDATE_USER_SETTINGS_SUCCESS, payload: userSetting });
        axios.put(`${config.apiUrl}/user/settings`, userSetting);
    };

    const handleSearch = (searchPhrase: string) => {
        if (searchPhrase.length === 0) {
            toggleSearchResults(false);
            setSearchPhrase(searchPhrase);
            return;
        }
        setSearchPhrase(searchPhrase);
        axios.get(`${config.apiUrl}/misc/search?sq=${searchPhrase}`).then((response) => {
            setSearchResults(response.data);
            toggleSearchResults(true);
        });
    };

    const createNewRoForCustomer = (customerId: string) => {
        setIsNewRepairOrderLoading(true);

        axios
            .post(`${config.apiUrl}/repairOrder`, { customerId: customerId })
            .then((res) => {
                setIsNewRepairOrderLoading(false);
                // setSearchResults(undefined);
                // setIsSearchResultsOpen(false);
                toggleSearchResults(false);
                if (res.data?.id) {
                    history.push(`/repairOrder/${res.data.id}`);
                }
            })
            .catch((_) => {
                setIsNewRepairOrderLoading(false);
            });
    };

    const createNewRoForVehicle = (customerId: string, vehicleId: string) => {
        setIsNewRepairOrderLoading(true);

        axios
            .post(`${config.apiUrl}/repairOrder`, {
                customerId,
                vehicleId,
            })
            .then((res) => {
                setIsNewRepairOrderLoading(false);
                toggleSearchResults(false);
                if (res.data?.id) {
                    history.push(`/repairOrder/${res.data.id}`);
                }
            })
            .catch((_) => {
                setIsNewRepairOrderLoading(false);
            });
    };

    return (
        <div className="header-container">
            {isNewRepairOrderLoading && (
                <div className="page-loading">
                    <div className="spinner"></div>
                </div>
            )}
            <div
                style={{ backgroundColor: '#e25319' }}
                className="header bg-gray-700 border-b border-blue-600 shadow-lg "
            >
                {!!currentUser && <DemoWarning user={currentUser} />}
                <div className="header__header-bar">
                    <div className="p-2 rounded-full hover:bg-gray-400 ml-4">
                        <GiHamburgerMenu className="text-white clickable text-2xl" onClick={() => toggleSidebar()} />
                    </div>
                    <div className="app-name ">
                        <img className="inline" src={`../assets/favicon.png`}></img>
                        <span className="inline font-semibold text-white ">AutoFlow Manager</span>
                    </div>
                    {!!currentUser?.tenant?.hasDemoData && (
                        <button
                            className="ml-auto font-semibold text-lg font-mono bg-gray-100 text-gray-500 border rounded-3xl clickable px-3 py-1 hover:bg-red-600"
                            onClick={() => setIsDeleteDemoDataOpen(true)}
                        >
                            {modalResources.DEMO_DATA_REMINDER}
                        </button>
                    )}
                    <DeleteConfirmation
                        isOpen={isDeleteDemoDataOpen}
                        onCancel={() => handleDeleteDemoData(false)}
                        onConfirm={() => handleDeleteDemoData(true)}
                        deletedItem={modalResources.DELETE_DEMO_DATA_CONFIRMATION}
                    ></DeleteConfirmation>
                    <div className="ml-auto mr-6 flex ">
                        <div className="mr-24  relative">
                            <div className="relative ">
                                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                                    <svg
                                        className="w-4 h-4 text-gray-500 dark:text-gray-400 ml-2"
                                        aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 20 20"
                                    >
                                        <path
                                            stroke="currentColor"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                        />
                                    </svg>
                                </div>

                                <input
                                    type="seach"
                                    placeholder="Quick find"
                                    autoComplete="off"
                                    name="shhhhh"
                                    onChange={(e) => {
                                        handleSearch(e.target.value);
                                    }}
                                    id="seach-bar"
                                    // block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500

                                    className="inline  rounded-3xl w-96 pl-8 py-4 focus:outline-none
                                     focus:border-blue-500 text-black bg-gray-100 font-semibold"
                                ></input>
                                {!!searchPhrase?.length && (
                                    <button
                                        onClick={() => {
                                            setSearchPhrase('');
                                        }}
                                        type="submit"
                                        className="text-gray-700 absolute end-2.5 bottom-2.5 -ml-12 hover:text-gray-800 font-medium rounded-lg text-sm px-4 py-2"
                                    >
                                        <CiCircleRemove className="text-2xl" />
                                    </button>
                                )}
                            </div>
                            {searchResults && isSearchResultsOpen && (
                                <div
                                    id="search-results"
                                    className="max-h-96 -mr-4 overflow-y-scroll absolute bg-white border border-gray-500  right-0 w-96 z-50 rounded mt-2 shadow-lg"
                                >
                                    {!!searchResults.ROs && (
                                        <div className="p-2 border-b border-gray-500">
                                            <span className="text-black font-semibold">
                                                Repair Orders ({searchResults.ROs.count}):{' '}
                                            </span>
                                            <div className="ml-2">
                                                {searchResults.ROs.rows.map((ro) => (
                                                    <div key={ro.id} className="clickable hover:bg-blue-200 p-1.5">
                                                        #{ro.repairOrderNumber} {ro.title}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {!!searchResults.customers && (
                                        <div className="p-2 border-b border-gray-500">
                                            <span className="text-black font-semibold">
                                                Customers ({searchResults.customers?.length}):{' '}
                                            </span>
                                            <div className="ml-2">
                                                {searchResults.customers.map((customer) => (
                                                    <div
                                                        key={customer.id}
                                                        className="flex clickable hover:bg-blue-200 p-1.5"
                                                    >
                                                        <div>
                                                            {customer.firstName} {customer.lastName}
                                                        </div>
                                                        <div className="ml-auto">
                                                            <button
                                                                className="font-semibold p-1.5 text-sm rounded-xl hover:bg-green-500 border border-green-500 text-green-500 hover:text-white mr-4"
                                                                onClick={() =>
                                                                    createNewRoForCustomer(customer.id as string)
                                                                }
                                                            >
                                                                <span>+</span>
                                                                <FaClipboardList className="inline mr-2 align-baseline" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                    {!!searchResults.vehicles && (
                                        <div className="p-2 border-b border-gray-500">
                                            <span className="text-black font-semibold">
                                                Vehicles ({searchResults.vehicles?.length}):{' '}
                                            </span>
                                            <div className="ml-2">
                                                {searchResults.vehicles.map((vehicle) => (
                                                    <div key={vehicle.id} className="clickable hover:bg-blue-200 p-1.5">
                                                        <span>{getFullVehicleName(vehicle)}</span>
                                                        <div className="ml-auto">
                                                            <button
                                                                className="font-semibold p-1.5 text-sm rounded-xl hover:bg-green-500 border border-green-500 text-green-500 hover:text-white mr-4"
                                                                onClick={() =>
                                                                    createNewRoForVehicle(
                                                                        vehicle.customerId as string,
                                                                        vehicle.id as string,
                                                                    )
                                                                }
                                                            >
                                                                <span>+</span>
                                                                <FaClipboardList className="inline mr-2 align-baseline" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>

                        <RecentRos />
                        <AddNew />
                        <Notifications />
                        {props.currentUser && <UserAvatar user={props.currentUser!} />}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(NavigationBar);
