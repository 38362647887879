import axios from '../helpers/axios';
import config from '../helpers/config';
import { Dispatch } from 'redux';
import actionTypes from '../helpers/actionTypes';
import Booking from '../models/BookingModel';

const { apiUrl } = config;

export const createBooking = (newBooking: Booking) => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.CREATE_BOOKING_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.CREATE_BOOKING_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.CREATE_BOOKING_FAILURE, payload });

    dispatch(request());

    axios
        .post(`${apiUrl}/repairOrder`, newBooking)
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};
