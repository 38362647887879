import actionTypes from '../../helpers/actionTypes';
import Booking from '../../models/BookingModel';

class initialState {
    currentBooking!: Booking;
}

const bookingReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case actionTypes.CURRENT_USER_SUCCESS:
            return {
                ...state,
                currenBooking: action.payload,
            };
        default: {
            return state;
        }
    }
};

export default bookingReducer;
