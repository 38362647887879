import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { FaClipboardList, FaColumns, FaList } from 'react-icons/fa';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';

import axios from 'helpers/axios';
import config from 'helpers/config';
import history from 'helpers/history';
import actionTypes from 'helpers/actionTypes';

import RepairOrder from 'models/RepairOrderModel';
import User from 'models/UserModel';
import WorkflowStatus from 'models/WorkflowStatusModel';

import Overlay from 'components/common/Overlay';
import BoardColumn from './BoardColumn';
import JobListView from '../jobListView/JobListView';
import LaborRate from 'models/LaborRateModel';
import TagsPicker from 'components/common/TagsPicker';
import Label from 'models/LabelModel';

interface ComponentState {
    isNewRepairOrderLoading: boolean;
    repairOrders: RepairOrder[];
    filteredRepairOrders: RepairOrder[];
    isBoardViewActive: boolean;
    workflowStatuses: WorkflowStatus[];
    laborRates: LaborRate[];
    isLoading: boolean;

    employees: User[];
}

interface Props {
    dispatch: Dispatch;
    currentUser: User;
}

class JobListPage extends React.Component<Props, ComponentState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isNewRepairOrderLoading: false,
            isBoardViewActive: true,
            repairOrders: [],
            filteredRepairOrders: [],
            workflowStatuses: [],
            laborRates: [],
            isLoading: true,
            employees: [],
        };

        this.reorder = this.reorder.bind(this);
        this.handleJobArchivedOrDeleted = this.handleJobArchivedOrDeleted.bind(this);
    }

    componentDidMount() {
        this.getActiveRepairOrders();
        this.getWorkflowStatuses();
        this.getLaborRates();
        this.getEmployees();
    }

    getEmployees() {
        axios.get(`${config.apiUrl}/user`).then((res) => {
            this.setState({ employees: res.data });
        });
    }

    getLaborRates() {
        axios.get(`${config.apiUrl}/laborRate`).then((res) => {
            this.setState({ laborRates: res.data });
        });
    }

    getActiveRepairOrders() {
        axios
            .get(`${config.apiUrl}/repairOrder/board`)
            .then((res) => this.setState({ repairOrders: res.data }))
            .catch((_) => console.log(_))
            .finally(() => this.setState({ isLoading: false }));
    }

    getWorkflowStatuses() {
        axios
            .get(`${config.apiUrl}/tenant/workflowStatuses`)
            .then((res) => this.setState({ workflowStatuses: res.data }))
            .catch((err) => console.log(err));
    }

    goToRepairOrder() {
        this.setState({ isNewRepairOrderLoading: true }, () => {
            axios
                .post(`${config.apiUrl}/repairOrder`, {})
                .then((res) => {
                    this.setState({ isNewRepairOrderLoading: false });
                    if (res.data?.id) {
                        history.push(`/repairOrder/${res.data.id}`);
                    }
                })
                .catch((_) => {
                    this.setState({ isNewRepairOrderLoading: false });
                });
        });
    }

    reorder(dropEvent: DropResult) {
        if (!!!dropEvent.destination) {
            return;
        }

        const { destination, source, draggableId } = dropEvent;
        // Not actually moved anywhere
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }

        const destinationTasks = this.state.repairOrders
            .filter((RO) => RO.workflowStatusId === destination.droppableId)
            .sort((RO1: RepairOrder, RO2: RepairOrder) => {
                return RO1.columnOrderIndex - RO2.columnOrderIndex;
            });

        let newColumnOrderIndex;

        // dropped into empty column
        if (destinationTasks.length === 0) {
            newColumnOrderIndex = 1000;
        } else if (destinationTasks.length === 1) {
            // dropped into column with one task only
            newColumnOrderIndex =
                destination.index === 0
                    ? destinationTasks[0].columnOrderIndex / 2
                    : destinationTasks[0].columnOrderIndex + 1000;
        } else {
            // within the same column
            if (destination.droppableId === source.droppableId) {
                newColumnOrderIndex =
                    destination.index < source.index
                        ? destination.index !== 0
                            ? (destinationTasks[destination.index - 1].columnOrderIndex +
                                  destinationTasks[destination.index].columnOrderIndex) /
                              2
                            : destinationTasks[0].columnOrderIndex / 2
                        : destination.index !== destinationTasks.length - 1
                        ? (destinationTasks[destination.index + 1].columnOrderIndex +
                              destinationTasks[destination.index].columnOrderIndex) /
                          2
                        : destinationTasks[destinationTasks.length - 1].columnOrderIndex + 100;
            } else {
                // dropped into another column
                if (destination.index === 0) {
                    newColumnOrderIndex = destinationTasks[0].columnOrderIndex / 2;
                } else if (destination.index === destinationTasks.length) {
                    newColumnOrderIndex = destinationTasks[destination.index - 1].columnOrderIndex + 1000;
                } else {
                    newColumnOrderIndex =
                        (destinationTasks[destination.index - 1].columnOrderIndex +
                            destinationTasks[destination.index].columnOrderIndex) /
                        2;
                }
            }
        }

        const { repairOrders } = this.state;
        const ticketIndex = this.state.repairOrders.findIndex((RO) => RO.id === draggableId);
        repairOrders[ticketIndex].workflowStatusId = dropEvent.destination.droppableId;
        repairOrders[ticketIndex].columnOrderIndex = newColumnOrderIndex;

        this.setState(
            {
                repairOrders,
            },
            () => {
                this.updateWorkflowStatus(this.state.repairOrders[ticketIndex]);
            },
        );
    }

    updateWorkflowStatus(repairOrder: RepairOrder) {
        axios
            .put(`${config.apiUrl}/repairOrder?withoutSuccessMessage=true`, repairOrder)
            .then((_) => _)
            .catch((err) => console.log(err));
    }

    handleJobArchivedOrDeleted(jobId: string) {
        const activeRepairOrders = this.state.repairOrders.filter((RO) => RO.id !== jobId);
        this.setState({ repairOrders: activeRepairOrders });
    }

    isBoardViewActive() {
        if (!!this.props?.currentUser?.userSetting) {
            return this.props.currentUser.userSetting.isBoardViewActive;
        } else {
            return true;
        }
    }

    updateListViewSettings() {
        const { userSetting: userSettings } = this.props.currentUser;
        userSettings.isBoardViewActive = !userSettings.isBoardViewActive;
        axios
            .put(`${config.apiUrl}/user/settings`, userSettings)
            .then(() => this.props.dispatch({ type: actionTypes.UPDATE_USER_SETTINGS_SUCCESS, payload: userSettings }))
            .catch((_) => console.log(_));
    }

    filterByTags(tags: Label[]) {
        const { repairOrders } = this.state;
        console.table(tags);
        if (tags.length > 0) {
            const filtered = repairOrders.filter((RO) => {
                return RO.labels.some((label) => tags.map((tag) => tag.id).includes(label.id));
            });
            this.setState({ filteredRepairOrders: filtered });
        } else {
            this.setState({ filteredRepairOrders: repairOrders });
        }
    }

    render() {
        const { isNewRepairOrderLoading, repairOrders, workflowStatuses, laborRates, isLoading, filteredRepairOrders } =
            this.state;
        return (
            <div className="list-page bg-white min-h-screen">
                {isLoading && <Overlay />}
                <div className="flex">
                    <h2 className="list-page__title">Job Centre</h2>
                    <div className="ml-auto">
                        <button
                            disabled={isNewRepairOrderLoading}
                            className="font-semibold py-2 px-3 rounded-xl hover:bg-green-500 border border-green-500 text-green-500 hover:text-white"
                            onClick={() => this.goToRepairOrder()}
                        >
                            <FaClipboardList className="inline mr-2 align-baseline" />
                            <span>New Repair Order</span>
                        </button>
                    </div>
                </div>

                <div className="text-gray-500 text-xl inline-flex mt-6 mb-3">
                    <div
                        className={`border border-gray-400 border-r-0 rounded-l-md flex px-3 py-1 ${
                            !!this.isBoardViewActive() && 'bg-gray-200 text-blue-500'
                        } ${!this.isBoardViewActive() && 'hover:bg-gray-300 clickable '}`}
                        onClick={() => {
                            if (!this.isBoardViewActive()) {
                                this.updateListViewSettings();
                            }
                        }}
                        title="Kanban View"
                    >
                        <span className="mr-2">Board</span>
                        <FaColumns className="mt-1" />
                    </div>
                    <div
                        className={`border border-gray-400 rounded-r-md flex px-3 py-1 ${
                            !!!this.isBoardViewActive() && 'bg-gray-200 text-blue-500'
                        } ${!!this.isBoardViewActive() && 'hover:bg-gray-300 clickable'}`}
                        onClick={() => {
                            if (this.isBoardViewActive()) {
                                this.updateListViewSettings();
                            }
                        }}
                        title="List View"
                    >
                        <span className="mr-2">List</span>

                        <FaList className="mt-1" />
                    </div>

                    <div className="text-md text-gray-500  pt-1.5 ml-6">Filters:</div>

                    <div className="ml-4">
                        <TagsPicker
                            onchange={(tags: Label[]) => {
                                this.filterByTags(tags);
                            }}
                        ></TagsPicker>
                    </div>
                </div>

                {this.isBoardViewActive() ? (
                    <DragDropContext onDragEnd={this.reorder}>
                        <div className="inline-flex w-full h-full ">
                            {workflowStatuses.map((workflowStatus: WorkflowStatus) => {
                                return (
                                    <div
                                        key={workflowStatus.id}
                                        className={`w-1/${workflowStatuses?.length} min-h-screen`}
                                    >
                                        <BoardColumn
                                            column={workflowStatus}
                                            tasks={
                                                filteredRepairOrders.length
                                                    ? filteredRepairOrders.filter(
                                                          (RO) => RO.workflowStatusId === workflowStatus.id,
                                                      )
                                                    : repairOrders.filter(
                                                          (RO) => RO.workflowStatusId === workflowStatus.id,
                                                      )
                                            }
                                            jobArchivedOrDeleted={this.handleJobArchivedOrDeleted}
                                            laborRates={laborRates}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </DragDropContext>
                ) : (
                    <div>
                        {this.state.workflowStatuses?.length > 0 && !!repairOrders && (
                            <JobListView repairOrders={repairOrders} workflowStatuses={this.state.workflowStatuses} />
                        )}
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(JobListPage);
