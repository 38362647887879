import React from 'react';
import history from '../../../helpers/history';
import { connect } from 'react-redux';
import { Grid, Form, Button } from 'semantic-ui-react';
import axios from '../../../helpers/axios';
import config from '../../../helpers/config';
const { apiUrl } = config;

interface ComponentState {
    email: string;
    password: string;
    password2: string;
    isRegisterButtonEnabled: boolean;
    isSubmitted: boolean;
    confirmationPassword: string;
    isLoading: boolean;
    verificationId: string;
    [x: string]: any;
}

class EmployeeRegistrationPage extends React.Component<any, ComponentState> {
    constructor(props: any) {
        super(props);

        //@ts-ignore
        this.state = {
            isSubmitted: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    componentDidMount() {
        // @ts-ignore
        const { verificationId } = this.props.match.params;
        this.setState({ verificationId });

        const name = new URLSearchParams(this.props.location.search).get('name');
        const email = new URLSearchParams(this.props.location.search).get('email');
        this.setState({
            name,
            email: email!,
        });
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const { name, value } = event.currentTarget;
        this.setState({ [name]: value });
    }

    submit() {
        const { password, password2 } = this.state;
        if (password !== password2) {
            this.setState({
                isSubmitted: true,
            });
            return;
        }
        const userData = {
            email: this.state.email,
            password: this.state.password,
            verificationId: this.state.verificationId,
        };
        this.setState({ isLoading: true });

        axios({
            method: 'post',
            url: `${apiUrl}/user/setPasswordEmail`,
            data: userData,
        })
            .then((_) => {
                setTimeout(() => {
                    this.setState({ isSubmitted: true, isLoading: false }, () => {
                        history.push('/login');
                    }),
                        1000;
                });
            })
            .catch((_) => this.setState({ isLoading: false }));
    }

    render() {
        const { name, email, password, password2, isSubmitted } = this.state;
        return (
            <Grid textAlign="center" style={{ height: '100vh', padding: '10%' }} verticalAlign="middle">
                <Grid.Column style={{ maxWidth: 450 }}>
                    <div>
                        <div className="text-center text-xl text-blue-400 font-semibold mb-6">
                            <div>
                                Welcome, <span className="text-blue-600">{name}</span>!
                            </div>
                            <div>Please enter your email and password before using Repair Software</div>
                        </div>
                        <Form size="large" error={this.props.errorMessage?.length > 0} loading={this.state.isLoading}>
                            <Form.Input
                                fluid
                                icon="mail"
                                iconPosition="left"
                                placeholder="E-mail address"
                                onChange={this.handleChange}
                                name="email"
                                value={email}
                                required={true}
                            />
                            <Form.Input
                                fluid
                                icon="lock"
                                iconPosition="left"
                                placeholder="Password"
                                type="password"
                                name="password"
                                onChange={this.handleChange}
                            />
                            <Form.Input
                                fluid
                                icon="lock"
                                iconPosition="left"
                                placeholder="Confirm Password"
                                type="password"
                                name="password2"
                                onChange={this.handleChange}
                            />
                            {password !== password2 && isSubmitted && (
                                <div className="text-red-600 font-bold">Passwords do not match</div>
                            )}
                            <Button color="blue" fluid size="large" onClick={this.submit}>
                                Submit
                            </Button>
                        </Form>
                    </div>
                </Grid.Column>
            </Grid>
        );
    }
}

export default connect()(EmployeeRegistrationPage);
