import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FaCar, FaClipboardList, FaEdit, FaEnvelope, FaPhoneSquare, FaSave, FaTimesCircle } from 'react-icons/fa';

import { getCustomer, updateCustomer } from 'actions/customerActions';
import Customer from 'models/CustomerModel';
import CustomerRepairOrders from './tabs/CustomerRepairOrders';
import CustomerVehicles from './tabs/CustomerVehicles';
import CustomerStats from './CustomerStats';
import CustomerDetailsForm from './CustomerDetailsForm';

import axios from 'helpers/axios';
import history from 'helpers/history';
import config from 'helpers/config';
import VehicleModal from 'components/modals/vehicle/VehicleModal';
import CustomerOrVehiclePayments from './tabs/CustomerOrVehiclePayments';
import LabelPicker from 'components/common/LabelPicker';
import Label from 'models/LabelModel';

interface Props {
    dispatch: Dispatch;
    customer: Customer;
    customerLoading: boolean;
}

interface State {
    activeTab: number;
    isEditMode: boolean;
    customer?: Customer;
    isNewRepairOrderLoading: boolean;
    isCreateVehicleModalOpen: boolean;
}

class CustomerDetailsPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isEditMode: false,
            activeTab: 0,
            isNewRepairOrderLoading: false,
            isCreateVehicleModalOpen: false,
        };

        this.getCustomerDetails = this.getCustomerDetails.bind(this);
        this.handleNewVehicleModalClosed = this.handleNewVehicleModalClosed.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getCustomerDetails();
    }

    getCustomerDetails() {
        // @ts-ignore
        const { customerId } = this.props.match.params;
        const { dispatch } = this.props;
        dispatch<any>(getCustomer(customerId, true));
    }

    getTabs() {
        return [
            {
                title: 'Repair Orders',
                component: (
                    <CustomerRepairOrders
                        repairOrders={this.props.customer?.repairOrders!}
                        vehicles={this.props.customer?.vehicles!}
                    />
                ),
            },
            {
                title: 'Vehicles',
                component: <CustomerVehicles vehicles={this.props.customer?.vehicles!} />,
            },
            {
                title: 'Payments',
                component: (
                    <CustomerOrVehiclePayments repairOrders={this.props.customer?.repairOrders!} isForCustomer={true} />
                ),
            },
            {
                title: 'Invoices',
                component: (
                    <CustomerRepairOrders
                        repairOrders={this.props.customer?.repairOrders!}
                        vehicles={this.props.customer?.vehicles!}
                    />
                ),
            },
        ];
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const { name, value } = event.currentTarget;

        // @ts-ignore
        const updatedCustomer: CustomerModel = { ...this.state.customer, [name]: value };
        this.setState({ customer: updatedCustomer });
    }

    toggleEditMode() {
        const isEditMode = this.state.isEditMode;

        if (isEditMode) {
            const { dispatch } = this.props;
            dispatch<any>(updateCustomer(this.state.customer!));
        }

        this.setState({
            isEditMode: !isEditMode,
            customer: this.props.customer,
        });
    }

    goToRepairOrder() {
        this.setState({ isNewRepairOrderLoading: true }, () => {
            axios
                .post(`${config.apiUrl}/repairOrder`, { customerId: this.props.customer.id })
                .then((res) => {
                    this.setState({ isNewRepairOrderLoading: false });
                    if (res.data?.id) {
                        history.push(`/repairOrder/${res.data.id}`);
                    }
                })
                .catch((_) => {
                    this.setState({ isNewRepairOrderLoading: false });
                });
        });
    }

    handleNewVehicleModalClosed(vehicleCreated?: boolean) {
        if (vehicleCreated) {
            this.getCustomerDetails();
        }
        this.setState({
            isCreateVehicleModalOpen: false,
        });
    }

    removeLabel(labelIndex: number) {
        const { labels } = this.props.customer;
        if (labels?.length) {
            labels.splice(labelIndex, 1);
            const { customer } = this.props;
            customer.labels = labels;
            const { dispatch } = this.props;
            dispatch<any>(updateCustomer(customer!));
        }
    }

    render() {
        const { customerLoading, customer } = this.props;
        const { activeTab, isEditMode, isNewRepairOrderLoading, isCreateVehicleModalOpen } = this.state;
        const tabs = this.getTabs();
        return (
            <div className="bg-white mb-40 relative">
                {customerLoading && (
                    <div className="page-loading">
                        <div className="spinner"></div>
                    </div>
                )}
                {!!customerLoading && <b>Loading...</b>}
                {isCreateVehicleModalOpen && (
                    <VehicleModal onClose={this.handleNewVehicleModalClosed} customer={this.props.customer} />
                )}
                <div className="w-full bg-blue-500 p-8 pb-2">
                    <h3 className="text-blue-100">Customer</h3>
                    <h2 className="text-blue-50 text-2xl font-semibold">
                        {customer?.firstName} {customer?.lastName}
                    </h2>
                </div>
                <div className="w-full text-white from-blue-700 to-blue-500 px-8 py-2 bg-gradient-to-r">
                    <div className="pb-2">
                        {!!customer?.primaryEmail && (
                            <span>
                                <FaEnvelope className="inline align-baseline mr-2" />
                                <a className="mr-2" href={`mailto:${customer.primaryEmail}`}>
                                    {customer.primaryEmail}
                                </a>
                            </span>
                        )}
                    </div>
                    <div>
                        {customer?.phoneNumbers?.map((number) => (
                            <span key={number.phoneNumber}>
                                <FaPhoneSquare className="inline align-baseline mr-2" />
                                <a className="mr-2" href={`tel:${number.phoneNumber}`}>
                                    {number.phoneNumber}
                                </a>
                            </span>
                        ))}
                    </div>
                    <div className="flex">
                        <div className="font-semibold mr-1.5">Labels: </div>
                        {customer?.labels?.map((label, indx) => (
                            <span
                                key={indx}
                                style={{ backgroundColor: label.color }}
                                className="mr-2 px-1 py-1 rounded-lg hover:opacity-80 text-xs"
                            >
                                <span className="font-semibold text-white inline-flex whitespace-nowrap">
                                    <FaTimesCircle
                                        onClick={() => this.removeLabel(indx)}
                                        className="inline mr-1 clickable align-baseline text-xs"
                                    />
                                    {label.title}
                                </span>
                            </span>
                        ))}
                        <LabelPicker
                            labelType={'customer'}
                            labelsSelected={(labels: Label[]) => {
                                const { customer } = this.props;
                                // @ts-ignore
                                customer!.labels = labels;
                                const { dispatch } = this.props;
                                dispatch<any>(updateCustomer(customer!));
                            }}
                            labelIds={customer?.labels?.map((label) => label.id)}
                        />
                    </div>
                </div>
                <div className="px-8 flex flex-col">
                    <div className="mt-8">
                        <button
                            disabled={isNewRepairOrderLoading}
                            className="font-semibold py-2 px-3 rounded-xl hover:bg-green-500 border border-green-500 text-green-500 hover:text-white mr-4"
                            onClick={() => this.goToRepairOrder()}
                        >
                            <FaClipboardList className="inline mr-2 align-baseline" />
                            <span>New Invoice / Repair Order</span>
                        </button>
                        <button
                            disabled={isNewRepairOrderLoading}
                            className="font-semibold py-2 px-3 rounded-xl hover:bg-green-500 border border-green-500 text-green-500 hover:text-white mr-4"
                            onClick={() => this.setState({ isCreateVehicleModalOpen: true })}
                        >
                            <FaCar className="inline mr-2 align-baseline" />
                            <span>New Vehicle</span>
                        </button>
                    </div>
                    <div className="w-1/2 mt-4">{!!customer && <CustomerStats customer={customer} />}</div>
                    <div className="mt-8 border-2 rounded-xl bg-gray-100 p-6 w-1/2 relative flex flex-col">
                        {!!customerLoading && (
                            <div className="absolute left-1/2 top-1/3">
                                <h2 className="font-bold">Loading...</h2>
                            </div>
                        )}
                        {!customerLoading && !!customer && (
                            <CustomerDetailsForm
                                customer={isEditMode ? this.state.customer! : customer}
                                isEditMode={isEditMode}
                                handleChange={(e) => this.handleChange(e)}
                            />
                        )}
                        <button
                            className={`${
                                isEditMode ? 'bg-green-400' : 'bg-gray-400'
                            } rounded-2xl px-4 py-1 font-bold ml-auto`}
                            onClick={() => this.toggleEditMode()}
                        >
                            {isEditMode ? <FaSave className="inline mr-2" /> : <FaEdit className="inline mr-2" />}
                            {isEditMode ? 'Save' : 'Edit'}
                        </button>
                    </div>
                    <div className="mt-8">
                        {tabs.map((tab, index) => {
                            return (
                                <div
                                    className={`inline-flex px-5 border-b-4 ${
                                        activeTab === index
                                            ? ' border-blue-500 '
                                            : '  border-gray-500 hover:text-blue-400'
                                    }`}
                                    key={index}
                                >
                                    <span
                                        className={`clickable text-lg ${
                                            activeTab === index
                                                ? 'font-bold text-blue-500 border-blue-500 '
                                                : ' font-medium text-gray-400 border-gray-500 hover:text-blue-400'
                                        }`}
                                        onClick={() => this.setState({ activeTab: index })}
                                    >
                                        {tab.title}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                    {tabs[activeTab].component}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    customer: state.customer.customer,
    customerLoading: state.customer.customerLoading,
});

export default connect(mapStateToProps)(CustomerDetailsPage);
