import React from 'react';
import CustomerSection from '../booking/CustomerSection';
import VehicleSection from '../booking/VehicleSection';
import axios from 'helpers/axios';
import config from 'helpers/config';
import Customer from 'models/CustomerModel';
import VehicleModel from 'models/VehicleModel';

interface Props {
    onChange: (amount: any) => void;
    customer?: Customer;
    vehicle?: VehicleModel;
}

interface State {
    customer?: Customer;
    vehicle?: VehicleModel;
}

class CustomerVehicleSelection extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
        this.customerSelected = this.customerSelected.bind(this);
        this.vehicleSelected = this.vehicleSelected.bind(this);
    }

    componentDidMount() {
        const { customer, vehicle } = this.props;
        if (!!customer || !!vehicle) {
            this.setState({ customer, vehicle });
            if (!!customer && !vehicle) {
                this.getCustomerWithVehicles(customer.id!);
            }
        }
    }

    getCustomerWithVehicles(customerId: string) {
        axios
            .get(`${config.apiUrl}/customer/${customerId}`)
            .then((res) => this.setState({ customer: res.data }))
            .catch((err) => console.log(err));
    }

    customerSelected(customerId: string) {
        this.props.onChange({ currentTarget: { name: 'customerId', value: customerId } });
        if (!!customerId) {
            this.getCustomerWithVehicles(customerId);
        } else {
            this.props.onChange({ currentTarget: { name: 'vehicleId', value: null } });
            this.setState({ customer: undefined, vehicle: undefined });
        }
    }

    vehicleSelected(vehicleId: string, isNewlyCreated: boolean) {
        this.props.onChange({ currentTarget: { name: 'vehicleId', value: vehicleId } });
        const vehicle = isNewlyCreated
            ? undefined
            : this.state.customer?.vehicles?.find((vehicle) => vehicle.id === vehicleId);
        this.setState({ vehicle });
        if (isNewlyCreated || !!!vehicleId) {
            if (!!this.state.customer) {
                this.getCustomerWithVehicles(this.state.customer!.id!);
            }
        }
    }

    render() {
        const { customer, vehicle } = this.state;
        return (
            <>
                <div className="flex w-full divide-x-2 divide-gray-200 bg-white rounded-lg border-2">
                    <div className="w-full p-3">
                        <CustomerSection customerSelected={this.customerSelected} customer={this.state.customer} />
                    </div>
                    <div className="w-full p-3">
                        <VehicleSection
                            vehicleSelected={this.vehicleSelected}
                            customer={customer}
                            selectedVehicle={vehicle}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default CustomerVehicleSelection;
