import React from 'react';
import Modal from 'react-modal';
import { Button } from 'semantic-ui-react';

interface Props {
    isOpen: boolean;
    deletedItem?: string;
    onConfirm: Function;
    onCancel: Function;
}

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const DeleteConfirmation: React.FC<Props> = (props: Props) => (
    <Modal isOpen={props.isOpen} style={customStyles} appElement={document.getElementById('root') as HTMLElement}>
        <div className="delete-confirmation-modal">
            <h2>Delete Confirmation</h2>
            <div>Are you sure you want to delete {props.deletedItem ? <b>{props.deletedItem}</b> : 'this item'}?</div>
            <div className="modal-buttons">
                <div className="button-cancel">
                    <Button color="grey" onClick={() => props.onCancel()}>
                        Cancel
                    </Button>
                </div>
                <div className="button-delete">
                    <Button color="red" onClick={() => props.onConfirm()}>
                        Delete
                    </Button>
                </div>
            </div>
        </div>
    </Modal>
);

export default DeleteConfirmation;
