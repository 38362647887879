import MethodOfContactTypes from 'models/types/MethodOfContactTypes';
import React, { useState } from 'react';

interface Props {
    selected: MethodOfContactTypes;
    updateMethodOfContact: (type: MethodOfContactTypes) => void;
    isDisabled: boolean;
}

const MethodOfContact: React.FC<Props> = ({ selected, updateMethodOfContact, isDisabled }: Props) => {
    const [isExpanded, setIsExpanded] = useState(false);
    return (
        <div className="w-full relative">
            <button
                id="methodOfContact"
                data-dropdown-toggle="dropdown"
                className={`w-full bg-white  ${
                    isDisabled
                        ? 'text-black bg-gray-100'
                        : 'text-black focus:ring-1 focus:outline-none focus:ring-blue-300'
                } font-bold rounded-md  px-4 py-2 text-center inline-flex items-center`}
                type="button"
                onClick={() => setIsExpanded(!isExpanded)}
            >
                {methodsOfContact.find((MOC) => MOC.value === selected)?.name}
                {!isDisabled && (
                    <svg
                        className="ml-auto w-4 h-4"
                        aria-hidden="true"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                    >
                        <path strokeLinecap="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                    </svg>
                )}
            </button>
            <div
                id="dropdown"
                className={` ${
                    (!isExpanded || isDisabled) && 'hidden'
                } w-full z-10 absolute  bg-white rounded divide-y divide-gray-100 shadow  border border-blue-500`}
            >
                <ul className="py-1 text-sm text-gray-700" aria-labelledby="methodOfContact">
                    {methodsOfContact.map((methodOfContact) => (
                        <li
                            className={`block clickable ${
                                methodOfContact.value === selected ? 'bg-blue-400 text-white' : 'hover:bg-gray-100'
                            } py-2 px-4 font-semibold`}
                            key={methodOfContact.value}
                            onClick={() => {
                                setIsExpanded(false);
                                updateMethodOfContact(methodOfContact.value);
                            }}
                        >
                            {methodOfContact.name}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

const methodsOfContact = [
    {
        name: 'Phone',
        value: MethodOfContactTypes.Phone,
    },
    {
        name: 'SMS',
        value: MethodOfContactTypes.SMS,
    },
    {
        name: 'Email',
        value: MethodOfContactTypes.Email,
    },
];

export default MethodOfContact;
