import { combineReducers } from 'redux';
import authReducer from './authReducer';
import userReducer from './userReducer';
import customerReducer from './customerReducer';
import vehicleReducer from './vehicleReducer';
import bookingReducer from './bookingReducer';
import inventoryReducer from './inventoryReducer';
import supplierReducer from './supplierReducer';
import miscReducer from './miscReducer';
import tenantReducer from './tenantReducer';
import repairOrderReducer from './repairOrderReducer';

export default combineReducers({
    auth: authReducer,
    user: userReducer,
    customer: customerReducer,
    vehicle: vehicleReducer,
    booking: bookingReducer,
    inventory: inventoryReducer,
    supplier: supplierReducer,
    misc: miscReducer,
    tenant: tenantReducer,
    repairOrder: repairOrderReducer,
});
