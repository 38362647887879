import React from 'react';
import { Form } from 'semantic-ui-react';

import axios from 'helpers/axios';
import config from 'helpers/config';

import Tenant from 'models/TenantModel';
import FormModes from 'helpers/FormModes';
import Toggle from 'components/common/Toggle';

interface Props {}

interface State {
    companySettings: any;
    tenant: Tenant;
    formState: FormModes;
    isLoading: boolean;
}

class CompanySettings extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            companySettings: {},
            tenant: {} as Tenant,
            formState: FormModes.Preview,
            isLoading: false,
        };

        this.getValue = this.getValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getCompanySettings();
    }

    getCompanySettings() {
        axios
            .get(`${config.apiUrl}/tenant/settings`)
            .then((res) => this.setState({ tenant: res.data, companySettings: res.data.tenantSettings }));
    }

    getValue(name: string) {
        // @ts-ignore
        return this.state?.companySettings[name] || '';
    }

    handleChange(event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) {
        const { name, value } = event.currentTarget;

        const updateCompanySettings: any = { ...this.state.companySettings, [name]: value };
        this.setState({ companySettings: updateCompanySettings });
    }

    handleUpdate() {
        const { companySettings } = this.state;
        this.setState({ isLoading: true });
        axios
            .put(`${config.apiUrl}/tenant`, companySettings)
            .finally(() => this.setState({ isLoading: false, formState: FormModes.Preview }));
    }

    render() {
        const { formState } = this.state;
        const FORM_INPUT_CLASS = 'rounded-md border-none font-bold w-1/4 pl-4 py-2 focus:ring-1 focus:outline-none';

        return (
            <div className="flex flex-col items-center">
                <div className="border rounded-xl bg-white w-1/3">
                    <h3 className="font-semibold text-lg rounded-t-xl bg-blue-400 border-b px-6 py-3">Tax settings</h3>
                    <div className="p-6">
                        <Form size="large">
                            <Form.Group unstackable widths={2}>
                                <div className="flex">
                                    <span className="inline-block w-1/4 ml-2 font-semibold py-2 mr-6">Sales Tax</span>
                                    <div className="">
                                        <input
                                            className={`${FORM_INPUT_CLASS} pl-6`}
                                            type="number"
                                            value={this.getValue('salesTaxRate')}
                                            name="salesTaxRate"
                                            disabled={formState === FormModes.Preview}
                                            onChange={this.handleChange}
                                        />
                                    </div>
                                    <div className="ml-2 py-2">
                                        <span className="font-bold"> % </span>
                                    </div>
                                </div>
                            </Form.Group>
                        </Form>
                        <div className="space-y-6">
                            <Toggle
                                onChange={(newValue) =>
                                    this.setState({
                                        companySettings: {
                                            ...this.state.companySettings,
                                            isSalesTaxApplicableToLabor: newValue,
                                        },
                                    })
                                }
                                value={this.state.companySettings?.isSalesTaxApplicableToLabor}
                                name="isSalesTaxApplicableToLabor"
                                label="Apply to Labor"
                                disabled={formState === FormModes.Preview}
                            />
                            <Toggle
                                onChange={(newValue) =>
                                    this.setState({
                                        companySettings: {
                                            ...this.state.companySettings,
                                            isSalesTaxApplicableToParts: newValue,
                                        },
                                    })
                                }
                                value={this.state.companySettings?.isSalesTaxApplicableToParts}
                                name="isSalesTaxApplicableToParts"
                                label="Apply to Parts"
                                disabled={formState === FormModes.Preview}
                            />
                            <Toggle
                                onChange={(newValue) =>
                                    this.setState({
                                        companySettings: {
                                            ...this.state.companySettings,
                                            isSalesTaxApplicableToFees: newValue,
                                        },
                                    })
                                }
                                value={this.state.companySettings?.isSalesTaxApplicableToFees}
                                name="isSalesTaxApplicableToFees"
                                label="Apply to Fees"
                                disabled={formState === FormModes.Preview}
                            />
                        </div>
                        <div className="mb-6">
                            {formState === FormModes.Preview && (
                                <button
                                    onClick={() => {
                                        this.setState({ formState: FormModes.Edit });
                                    }}
                                    className="block float-right rounded-xl px-2 font-semibold border border-gray-500 bg-green-500"
                                >
                                    Edit
                                </button>
                            )}

                            {formState === FormModes.Edit && (
                                <button
                                    onClick={() => {
                                        this.handleUpdate();
                                    }}
                                    className="block float-right rounded-xl px-2 font-semibold border border-gray-500 bg-green-500"
                                >
                                    Update
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanySettings;
