import React, { useEffect, useState } from 'react';

interface Props {
    initialPriceCents: number;
    handlePriceChange: (priceCents: number) => void;
    className?: string;
    hasError?: boolean;
}

const PriceInputCents = ({ initialPriceCents, handlePriceChange, className, hasError }: Props) => {
    // Convert cents to a display string in American formatting (e.g., 1,999 cents to "19.99").
    const centsToDisplayFormat = (cents: number) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(cents / 100);
        // .replace(/\D00(?=\D*$)/, '');
    };
    const [priceInCents, setPriceInCents] = useState(initialPriceCents);
    const [displayValue, setDisplayValue] = useState(() => centsToDisplayFormat(initialPriceCents));

    useEffect(() => {
        setPriceInCents(initialPriceCents);
        setDisplayValue(centsToDisplayFormat(initialPriceCents));
    }, [initialPriceCents]);

    const displayFormatToCents = (displayValue: string) => {
        const numericValue = parseFloat(displayValue.replace(/[^0-9.-]+/g, ''));
        return !isNaN(numericValue) ? Math.round(numericValue * 100) : 0;
    };

    const handleBlur = () => {
        setDisplayValue(centsToDisplayFormat(priceInCents));
    };

    const handleChange = (event: any) => {
        // Directly update displayValue for input but remove currency formatting for internal conversion.
        const input = event.target.value.replace(/[^0-9.-]+/g, '');
        setDisplayValue(event.target.value);
        const convertedCents = displayFormatToCents(input);
        // Update the internal state if the input is a valid number.
        if (!isNaN(convertedCents)) {
            setPriceInCents(convertedCents);
            handlePriceChange(convertedCents);
        }
    };

    return (
        <input
            type="text"
            value={displayValue}
            onChange={handleChange}
            onBlur={handleBlur}
            className={`${className} ${hasError && 'outline-red-600'}`}
        />
    );
};

export default PriceInputCents;
