import Service from 'models/ServiceModel';
import User from 'models/UserModel';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import axios from 'helpers/axios';
import config from 'helpers/config';
import actionTypes from 'helpers/actionTypes';
import ServiceCard from './ServiceCard';
import LaborRate from 'models/LaborRateModel';
import Fee from 'models/FeeModel';
import { getActivityLog } from 'actions/repairOrderActions';
import { BsInfoCircle } from 'react-icons/bs';
import Overlay from 'components/common/Overlay';
import CannedJob from 'models/CannedJobModel';

interface Props {
    currentUser: User;
    repairOrderId?: string;
    dispatch: Dispatch;

    cannedJobs: CannedJob[];
}

interface State {
    services: Service[];
    laborRates: LaborRate[];
    fees: Fee[];
    isLoading: boolean;
    isCannedJobPickerModalOpen: boolean;
}

class ServicesTab extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            services: [],
            laborRates: [],
            fees: [],
            isLoading: true,
            isCannedJobPickerModalOpen: false,
        };
    }

    componentDidMount() {
        this.getLaborRates();
        this.getFees();
        axios
            .get(`${config.apiUrl}/repairOrder/${this.props.repairOrderId}/services`)
            .then((res) => {
                this.setState({ services: res.data });
                this.props.dispatch({
                    type: actionTypes.GET_REPAIR_ORDER_SERVICES_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((_) => console.log(_))
            .finally(() => this.setState({ isLoading: false }));
    }

    getLaborRates() {
        axios.get(`${config.apiUrl}/laborRate`).then((res) => {
            const rates = res.data?.map((laborRate: LaborRate) => {
                return { ...laborRate, name: `${laborRate.name} - $${laborRate.rate.toFixed(2)}` };
            });
            this.setState({ laborRates: rates });
        });
    }

    getFees() {
        axios.get(`${config.apiUrl}/fee`).then((res) => {
            this.setState({ fees: res.data });
        });
    }

    removeService(index: number) {
        const services = [...this.state.services];
        this.setState({ services: services.filter((_, idx) => idx !== index) });
    }

    openCannedServicePicker() {
        this.setState({ isCannedJobPickerModalOpen: true });
    }

    createService() {
        const { repairOrderId } = this.props;
        const { services } = this.state;
        // prettier-ignore
        const newService = {
            items: [],
            repairOrderId,
        } as unknown as Service;
        axios
            .post(`${config.apiUrl}/repairOrder/service/${this.props.repairOrderId}`, newService)
            .then((res) => {
                this.props.dispatch({
                    type: actionTypes.ADD_REPAIR_ORDER_SERVICE_SUCCESS,
                    payload: res.data,
                });

                const { dispatch } = this.props;
                dispatch<any>(getActivityLog(repairOrderId!));
                this.setState({ services: [...services, { ...res.data }] });
            })
            .catch((_) => _);
    }

    createServiceFromCannedJob(cannedJob: CannedJob) {
        const { repairOrderId } = this.props;
        const { services } = this.state;
        const newService = {
            items: cannedJob.items,
            repairOrderId,
            isCreatedOffCannedJob: true,
            cannedJobId: cannedJob.id,
        } as unknown as Service;
        axios
            .post(`${config.apiUrl}/repairOrder/service/${this.props.repairOrderId}`, newService)
            .then((res) => {
                this.props.dispatch({
                    type: actionTypes.ADD_REPAIR_ORDER_SERVICE_SUCCESS,
                    payload: res.data,
                });

                const { dispatch } = this.props;
                dispatch<any>(getActivityLog(repairOrderId!));
                this.setState({ services: [...services, { ...res.data }] });
            })
            .catch((_) => _)
            .finally(() => this.setState({ isCannedJobPickerModalOpen: false }));
    }

    render() {
        const { services, laborRates, fees, isLoading, isCannedJobPickerModalOpen } = this.state;
        const { repairOrderId } = this.props;
        return (
            <div className="rounded-xl mb-96">
                <div className="flex py-3 justify-between">
                    {!services.length && !isLoading && (
                        <div className="py-2 px-6 rounded bg-blue-100 border-blue-400 border flex w-auto">
                            <span className="mr-4">
                                <BsInfoCircle size={20}></BsInfoCircle>
                            </span>
                            <span className="font-semibold text-gray-600">
                                No services have been created for this RO yet
                            </span>
                        </div>
                    )}
                    {isLoading && <Overlay />}
                    {isCannedJobPickerModalOpen && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex justify-center items-center">
                            <div className="bg-white p-4 rounded-xl">
                                <h2 className="text-2xl font-semibold mb-4">Select a Canned Service</h2>
                                <div className="flex flex-wrap">
                                    {this.props.cannedJobs.map((cannedJob) => (
                                        <button
                                            key={cannedJob.id}
                                            className="bg-green-600 p-2 rounded-2xl font-medium text-base mr-2 mb-2"
                                            onClick={() => this.createServiceFromCannedJob(cannedJob)}
                                        >
                                            {cannedJob.title}
                                        </button>
                                    ))}
                                </div>
                                <button
                                    className="bg-red-600 p-2 rounded-2xl font-medium text-base mt-4"
                                    onClick={() => this.setState({ isCannedJobPickerModalOpen: false })}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    )}
                    <button
                        className="ml-auto bg-green-600 p-1 rounded-2xl font-medium text-base transform hover:scale-105 transition opacity-90 duration-500 hover:opacity-100"
                        onClick={() => this.createService()}
                    >
                        + Add Service
                    </button>
                    <button
                        className="bg-green-600 p-1 rounded-2xl font-medium text-base transform hover:scale-105 transition opacity-90 duration-500 hover:opacity-100"
                        onClick={() => this.openCannedServicePicker()}
                    >
                        + Add Canned Service
                    </button>
                </div>
                {services.map((service, index) => (
                    <ServiceCard
                        service={service}
                        laborRates={laborRates}
                        key={service.id}
                        repairOrderId={repairOrderId}
                        serviceDeleted={() => this.removeService(index)}
                        fees={fees}
                    />
                ))}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(ServicesTab);
