import React from 'react';
import { FaCar, FaPhoneSquare, FaUser } from 'react-icons/fa';

import axios from 'helpers/axios';
import config from 'helpers/config';
import RepairOrder from 'models/RepairOrderModel';
import Service from 'models/ServiceModel';
import Toggle from 'components/common/Toggle';
import LaborRate from 'models/LaborRateModel';
import { getServiceItemTotal, getServiceTotal } from 'helpers/services/priceCalculationService';
import Price from 'components/common/Price';
import SignatureAuthorizationModal from './SignatureAuthorizationModal';
import TenantSettings from 'models/TenantSettingsModel';

interface Props {}

interface State {
    repairOrderId: string;
    repairOrder?: RepairOrder;
    services: Service[];
    approvedServices: Service[];
    laborRates: LaborRate[];

    grandTotal: number;

    isLoading: boolean;
    isAuthorizationModalOpen: boolean;
    tenantSettings?: TenantSettings;
}

class EstimatePage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        // @ts-ignore
        const { repairOrderId } = this.props.match.params;

        this.state = {
            repairOrderId,
            repairOrder: undefined,
            isLoading: true,
            services: [],
            approvedServices: [],
            laborRates: [],
            grandTotal: 0.0,
            isAuthorizationModalOpen: false,
            tenantSettings: undefined,
        };
    }

    componentDidMount() {
        const RO_Request = axios.get(`${config.apiUrl}/repairOrder/public/${this.state.repairOrderId}`);
        const servicesRequest = axios.get(`${config.apiUrl}/repairOrder/public/${this.state.repairOrderId}/services`);
        Promise.all([RO_Request, servicesRequest]).then((res) => {
            this.getLaborRates(res[0].data.tenantId);
            this.getTaxSettings(res[0].data.tenantId);
            this.setState({
                repairOrder: res[0].data,
                services: res[1].data.map((service: Service) => {
                    service.items.sort((a, b) => {
                        const c = new Date(a.createdAt);
                        const d: any = new Date(b.createdAt);
                        return c > d ? 1 : -1;
                    });
                    return { ...service, isAuthorized: true, key: Math.random().toString(36).substr(2, 9) };
                }),
                approvedServices: res[1].data,
                isLoading: false,
            });
        });
    }

    getLaborRates(tenantId: string) {
        axios.get(`${config.apiUrl}/laborRate/public/${tenantId}`).then((res) => {
            this.setState({ laborRates: res.data });
        });
    }

    getTaxSettings(tenantId: string) {
        axios.get(`${config.apiUrl}/tenant/public/taxSettings/${tenantId}`).then((res) => {
            this.setState({ tenantSettings: res.data });
        });
    }

    getGrandTotal(approvedOnly = true) {
        const { approvedServices, services, laborRates } = this.state;
        if (approvedOnly) {
            return Number(
                approvedServices
                    ?.map((service) => getServiceTotal(service, laborRates, true, this.state.tenantSettings))
                    .reduce((a, b) => +a + +b, 0)
                    .toFixed(2),
            );
        } else {
            return Number(
                services
                    ?.map((service) => getServiceTotal(service, laborRates, true, this.state.tenantSettings))
                    .reduce((a, b) => +a + +b, 0)
                    .toFixed(2),
            );
        }
    }

    authorize() {
        if (this.state.approvedServices.length === 0) return;
        this.setState({ isAuthorizationModalOpen: true });
    }

    render() {
        const { repairOrder, services, approvedServices, laborRates, isAuthorizationModalOpen } = this.state;
        return (
            <div>
                {isAuthorizationModalOpen && (
                    <SignatureAuthorizationModal
                        declinedServices={services.filter((s) => !approvedServices.some((as) => as.id === s.id))}
                        repairOrderId={repairOrder!.id}
                        approvedServices={approvedServices}
                        onClose={() => this.setState({ isAuthorizationModalOpen: false })}
                    />
                )}
                {repairOrder && (
                    <div className="flex flex-col items-center">
                        <div className="mb-7 border-b-2 bg-gradient-to-r from-blue-700 to-blue-500 px-10 py-3 w-full">
                            <h2 className="text-lg font-semibold text-gray-100">{repairOrder.tenant?.businessName}</h2>

                            {repairOrder.tenant?.tenantSettings?.phoneNumber && (
                                <h2 className=" font-semibold text-gray-100">
                                    <FaPhoneSquare className="inline align-baseline mr-2" />
                                    <a className="mr-2" href={`tel:${repairOrder.tenant?.tenantSettings?.phoneNumber}`}>
                                        {repairOrder.tenant?.tenantSettings?.phoneNumber}
                                    </a>
                                </h2>
                            )}
                        </div>

                        <div className="pb-5 border-b-4 w-11/12 sm:w-4/5 md:w-3/5 lg:w-2/5">
                            <div className="mb-7 border-b-2">
                                <h2 className="text-2xl font-semibold">Estimate #{repairOrder?.repairOrderNumber}</h2>
                            </div>

                            <div className="mb-5">
                                <div>
                                    <FaCar className="inline mr-2 align-baseline text-gray-500" />
                                    <span>
                                        {repairOrder.vehicle?.year} {repairOrder.vehicle?.make}{' '}
                                        {repairOrder.vehicle?.model}
                                    </span>
                                </div>
                                <div>
                                    <FaUser className="inline mr-2 align-baseline text-gray-500" />
                                    <span>
                                        {repairOrder.customer?.firstName} {repairOrder.customer?.lastName}
                                    </span>
                                </div>
                            </div>

                            <div>
                                <div className="flex">
                                    <div className="w-1/3 sm:w-2/3 md:w-2/3 font-semibold border-b">
                                        Customer Concern
                                    </div>
                                    <div className="w-1/3 sm:w-2/3 md:w-2/3 font-semibold border-b">Tech Comment</div>
                                </div>

                                {repairOrder.vehicleIssues.map((problem) => (
                                    <div key={'concern' + problem.id} className="flex border-t py-1.5">
                                        <div
                                            key={'csoncern' + problem.id}
                                            className="leading-5 w-1/3 sm:w-2/3 md:w-2/3"
                                        >
                                            {problem.description}
                                        </div>
                                        <div key={'finding' + problem.id} className="leading-5 w-1/3 sm:w-2/3 md:w-2/3">
                                            {problem.finding}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="pt-5 w-11/12 pb-5 sm:w-4/5 md:w-3/5 lg:w-2/5">
                            <div className="mb-7">
                                <h2 className="text-2xl font-semibold">Services</h2>
                            </div>

                            {services.map((service) => (
                                <div
                                    key={'service' + service.id}
                                    className="border-2 p-2 py-4 mb-4 bg-white rounded-lg border-blue-300"
                                >
                                    <div className="flex text-xl">
                                        <h2 className="font-mono font-semibold">{service.title}</h2>
                                        <div className="ml-auto flex">
                                            <div className="font-semibold text-lg mr-2 inline whitespace-nowrap">
                                                {approvedServices.some((s) => s.id === service.id)
                                                    ? 'Authorized'
                                                    : 'Declined'}
                                            </div>
                                            <Toggle
                                                value={approvedServices.some((s) => s.id === service.id)}
                                                name="isAuthorized"
                                                onChange={(newValue) => {
                                                    if (newValue) {
                                                        this.setState({
                                                            approvedServices: [...approvedServices, service],
                                                        });
                                                    } else {
                                                        this.setState({
                                                            approvedServices: approvedServices.filter(
                                                                (s) => s.id != service.id,
                                                            ),
                                                        });
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="flex my-2 border-b-2 border-gray-500">
                                        {/* <div className="ml-auto mt-2">
                                            <span className="ml-2 font-semibold text-lg">
                                                <Price
                                                    price={Number(
                                                        getServiceTotal(
                                                            service,
                                                            laborRates,
                                                            true,
                                                            this.state.tenantSettings,
                                                        ),
                                                    )}
                                                />
                                            </span>
                                        </div> */}
                                    </div>

                                    {service.items?.map((item) => (
                                        <div key={'serviceItem' + item.id} className="flex">
                                            <div className="font-semibold">
                                                {item.inventoryItemId
                                                    ? item.inventoryItem?.description
                                                    : item.description}
                                            </div>
                                            <div className="ml-auto">
                                                <Price price={getServiceItemTotal(item, laborRates)} />
                                            </div>
                                        </div>
                                    ))}

                                    <div className="border-t-2 border-gray-500 flex mt-2 pt-1.5">
                                        <div className="font-semibold">
                                            Subtotal:{' '}
                                            <span>
                                                <Price price={Number(getServiceTotal(service, laborRates, false))} />
                                            </span>
                                        </div>

                                        <div className="">
                                            <span className="ml-6 font-semibold">
                                                Tax:{' '}
                                                <Price
                                                    price={
                                                        Number(
                                                            getServiceTotal(
                                                                service,
                                                                laborRates,
                                                                true,
                                                                this.state.tenantSettings,
                                                            ),
                                                        ) - Number(getServiceTotal(service, laborRates, false))
                                                    }
                                                />
                                            </span>
                                        </div>

                                        <div className="ml-auto ">
                                            <span className="ml-2 font-semibold text-lg">
                                                Service Total:{' '}
                                                <Price
                                                    price={Number(
                                                        getServiceTotal(
                                                            service,
                                                            laborRates,
                                                            true,
                                                            this.state.tenantSettings,
                                                        ),
                                                    )}
                                                />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="pt-5 w-11/12 pb-5 sm:w-4/5 md:w-3/5 lg:w-2/5">
                            <div className="w-full float-left">
                                <div className="font-semibold">Grand Total:</div>
                                <div className="flex">
                                    <div>Estimated</div>
                                    <div className="ml-auto font-semibold">
                                        <Price price={this.getGrandTotal(false)} />
                                    </div>
                                </div>
                                <div className="flex">
                                    <div>Selected for approval</div>
                                    <div className="ml-auto font-semibold">
                                        <Price price={this.getGrandTotal()} />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="pt-5 w-11/12 pb-5 sm:w-4/5 md:w-3/5 lg:w-2/5">
                            <div
                                onClick={() => this.authorize()}
                                className={`flex flex-col text-center ${
                                    approvedServices.length > 0 ? 'clickable bg-blue-700' : 'bg-gray-400'
                                }  text-2xl text-white font-semibold rounded-lg border-2 border-white py-4 w-full`}
                            >
                                <span>Authorize ({approvedServices.length} services)</span>
                                <span>
                                    <Price price={this.getGrandTotal()} />
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default EstimatePage;
