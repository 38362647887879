import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import CompanySettings from './menus/CompanySettings';
import UsersSettings from './menus/users/UsersSettings';
import history from 'helpers/history';
import BaseRegistrySettings from './menus/BaseRegistrySettings';
import LabelSettings from './menus/labels/LabelSettings';
import SubscriptionSettings from './menus/SubscriptionSettings';
import WorkflowSettings from './menus/WorkflowSettings';
import InspectionSettings from './menus/InspectionSettings';
import DeletedItemsPage from './menus/deletedItems.tsx/DeletedItemsPage';
import TaxSettings from './menus/TaxSettings';
import LaborRateSettings from './menus/LaborRatesSettings';
import FeesSettings from './menus/FeesSettings';
import PartsMatricesSettings from './menus/matrices/PartsMatricesSettings';
import CannedJobSettingsPage from './cannedjobs/CannedJobsSettingsPage';

interface ComponentState {
    activeMenuIndex?: number;
}

interface Props {
    dispatch: Dispatch;
}

class SettingsPage extends React.Component<Props, ComponentState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            activeMenuIndex: undefined,
        };
    }

    componentDidMount() {
        //@ts-ignore
        const { subpageName } = this.props.match.params;
        this.setActiveMenu(subpageName);
    }

    setActiveMenu(subpageName: string) {
        let activeMenuIndex = navigationItems.findIndex((menuItem) => menuItem.location === subpageName);
        if (activeMenuIndex === -1) activeMenuIndex = 0;
        this.setState({ activeMenuIndex });
    }

    render() {
        const { activeMenuIndex } = this.state;
        return (
            <div className="settings-page">
                <div className="settings-page__sidebar">
                    <div className="min-w-max">
                        {navigationItems.map((item, index) => {
                            return (
                                <div key={item.name} className={`ml-3`}>
                                    <span
                                        key={item.name}
                                        className={`settings-page__item  ${activeMenuIndex === index && ' active'}`}
                                        onClick={() => {
                                            history.push(`/settings/${item.location}`);
                                            this.setState({ activeMenuIndex: index });
                                        }}
                                    >
                                        {item.name}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                </div>
                {!(activeMenuIndex === undefined) && (
                    <div className="settings-page__main-area page ">{navigationItems[activeMenuIndex].component}</div>
                )}
            </div>
        );
    }
}

const navigationItems: { name: string; location: string; component?: React.ReactElement }[] = [
    {
        name: 'Company settings ',
        location: 'companySettings',
        component: <CompanySettings />,
    },
    {
        name: 'Tax settings ',
        location: 'taxSettings',
        component: <TaxSettings />,
    },
    {
        name: 'Canned Jobs ',
        location: 'cannedJobs',
        component: <CannedJobSettingsPage />,
    },
    {
        name: 'Labor Rates',
        location: 'laborRates',
        component: <LaborRateSettings />,
    },
    {
        name: 'Pricing Matrices',
        location: 'pricingMatrices',
        component: <PartsMatricesSettings />,
    },
    {
        name: 'Labor Matrices',
        location: 'laborMatrices',
        component: <LaborRateSettings />,
    },
    {
        name: 'Fees',
        location: 'fees',
        component: <FeesSettings />,
    },
    {
        name: 'Users',
        location: 'users',
        component: <UsersSettings />,
    },
    {
        name: 'Deleted items',
        location: 'deletedItems',
        component: <DeletedItemsPage />,
    },
    {
        name: 'Workflow',
        location: 'workflow',
        component: <WorkflowSettings />,
    },
    {
        name: 'Inspections',
        location: 'inspection',
        component: <InspectionSettings />,
    },
    {
        name: 'Labels',
        location: 'labels',
        component: <LabelSettings />,
    },

    {
        name: 'Base Registry',
        location: 'baseRegistry',
        component: <BaseRegistrySettings />,
    },
    {
        name: 'Subscription settings',
        location: 'subscriptionSettings',
        component: <SubscriptionSettings />,
    },
];

export default connect()(SettingsPage);
