import LoanerSearch from 'components/common/LoanerSearch';
import Modal from 'components/common/Modal';
import axios from 'helpers/axios';
import config from 'helpers/config';
import Customer from 'models/CustomerModel';
import LoanerBookingModel from 'models/LoanerBookingModel';
import LoanerVehicle from 'models/LoanerVehicleModel';
import RepairOrder from 'models/RepairOrderModel';
import React from 'react';

interface Props {
    onClose: () => void;
    customerId?: string;
    RO_ID?: string;
}

interface State {
    customer?: Customer;
    repairOrder?: RepairOrder;
    isLoading: boolean;
    modalTitle: string;

    selectedLoanerVehicle?: LoanerVehicle;
    bookingNotes?: string;
}

class LoanerBookingModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: true,
            modalTitle: 'New Loaner Booking',
            bookingNotes: '',
        };
    }

    componentDidMount() {
        this.getCustomerAndRO();
    }

    getCustomerAndRO() {
        if (this.props.customerId) {
            axios
                .get(`${config.apiUrl}/customer/${this.props.customerId}`)
                .then((response) => {
                    this.setState({ customer: response.data, isLoading: false });
                })
                .catch((err) => console.log(err));
        }

        if (this.props.RO_ID) {
            axios
                .get(`${config.apiUrl}/repairOrder/${this.props.RO_ID}`)
                .then((response) => {
                    this.setState({ repairOrder: response.data, isLoading: false });
                })
                .catch((err) => console.log(err));
        }
    }

    handleCreate() {
        console.log('create');

        const booking: Partial<LoanerBookingModel> = {
            customerId: this.state.customer?.id,
            repairorderId: this.state.repairOrder?.id,
            loanerVehicleId: this.state.selectedLoanerVehicle?.id,
            notes: this.state.bookingNotes,
        };

        // if valid
        axios
            .post(`${config.apiUrl}/loaner/booking`, { ...booking })
            .then((_) => {
                this.props.onClose();
            })
            .catch((err) => console.log(err));
    }

    render() {
        const { customer, repairOrder, isLoading, modalTitle } = this.state;
        return (
            <Modal
                isOpen={true}
                onClose={this.props.onClose}
                headerText={modalTitle}
                isLoading={isLoading}
                saveText="Create"
                onSave={() => this.handleCreate()}
            >
                <div>
                    <div className="font-semibold">
                        RO#: <span className="font-semibold text-blue-600">{repairOrder?.repairOrderNumber}</span> -{' '}
                        {repairOrder?.title}
                    </div>
                    {!!customer && (
                        <div>
                            {customer.firstName} {customer.lastName}
                        </div>
                    )}
                </div>
                <div className="mb-6">
                    <div className="font-semibold text-lg text-gray-600">Car: </div>
                    <LoanerSearch
                        loanerSelected={(loaner) => this.setState({ selectedLoanerVehicle: loaner })}
                        displayDetailsWhenSelected={true}
                    ></LoanerSearch>
                </div>
                <div className="mb-96">
                    <textarea
                        className="rounded-md w-full border border-gray-300 focus:outline-none focus:border-blue-500 bg-gray-100 px-4 py-2"
                        placeholder="Notes"
                        autoFocus={false}
                        onChange={(e) => this.setState({ bookingNotes: e.target.value })}
                        value={this.state.bookingNotes}
                        rows={4}
                    />
                </div>
            </Modal>
        );
    }
}

export default LoanerBookingModal;
