import React, { SyntheticEvent } from 'react';
import { FaEdit, FaPlusCircle, FaTrash } from 'react-icons/fa';

import Checkbox from 'components/common/Checkbox';
import EnumDropdown from 'components/common/EnumDropdown';
import Price from 'components/common/Price';
import PriceInput from 'components/common/PriceInput';
import Fee from 'models/FeeModel';
import FeeType from 'models/types/FeeType';

import axios from 'helpers/axios';
import config from 'helpers/config';
import { displayConfirmationModal } from 'helpers/services/removeConfirmationService';
import Percentage from 'components/common/Percentage';

interface Props {}

interface State {
    fees: Fee[];
    isCreateNewFeeActive: boolean;
    editableFeeId: string | null;
    name: string | null;
    amount: number | null;
    type: FeeType | null;
    isTaxable: boolean;

    calculateOnLabor: boolean;
    calculateOnParts: boolean;
    calculateOnSublet: boolean;
}

class FeesSettings extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            fees: [],
            isCreateNewFeeActive: false,
            editableFeeId: null,
            amount: null,
            name: null,
            type: null,
            isTaxable: false,

            calculateOnLabor: false,
            calculateOnParts: true,
            calculateOnSublet: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.createNewFee = this.createNewFee.bind(this);
        this.updateFee = this.updateFee.bind(this);
    }

    componentDidMount() {
        this.getFees();
    }

    getFees() {
        axios.get(`${config.apiUrl}/fee`).then((res) => this.setState({ fees: res.data }));
    }

    createNewFee() {
        const { name, amount, type, isTaxable } = this.state;
        const fee = {
            name,
            amount: Number(amount),
            type,
            isTaxable,
        };

        axios.post(`${config.apiUrl}/fee`, fee).then((_) => {
            this.getFees();
            this.setState({ name: null, amount: null, type: null, isTaxable: true, isCreateNewFeeActive: false });
        });
    }

    updateFee() {
        const { name, amount, type, isTaxable, editableFeeId, calculateOnLabor, calculateOnParts, calculateOnSublet } =
            this.state;
        const fee = {
            name,
            type,
            amount: Number(amount?.toString().replace(/,/g, '')),
            isTaxable,
            id: editableFeeId,
            calculateOnLabor,
            calculateOnParts,
            calculateOnSublet,
        };

        axios.put(`${config.apiUrl}/fee`, fee).then((_) => {
            this.setState(
                {
                    name: null,
                    amount: null,
                    type: null,
                    isTaxable: true,
                    isCreateNewFeeActive: false,
                    editableFeeId: null,
                    calculateOnLabor: false,
                    calculateOnParts: true,
                    calculateOnSublet: false,
                },
                this.getFees,
            );
        });
    }

    handleDelete(fee: Fee) {
        displayConfirmationModal(`fee: "${fee.name}"`)
            .then(() => {
                axios.delete(`${config.apiUrl}/fee/${fee.id}`).then(() => {
                    this.getFees();
                    this.setState({
                        name: null,
                        amount: null,
                        type: null,
                        isTaxable: true,
                        isCreateNewFeeActive: false,
                        calculateOnLabor: false,
                        calculateOnParts: true,
                        calculateOnSublet: false,
                    });
                });
            })
            .catch((_) => _);
    }

    editFee(fee: Fee) {
        this.setState({
            editableFeeId: fee.id,
            name: fee.name,
            amount: fee.amount,
            type: fee.type,
            isCreateNewFeeActive: false,
            isTaxable: fee.isTaxable,
            calculateOnLabor: fee.calculateOnLabor,
            calculateOnParts: fee.calculateOnParts,
            calculateOnSublet: fee.calculateOnSublet,
        });
    }

    handleChange(e: SyntheticEvent) {
        // @ts-ignore
        const { name, value } = e.currentTarget;
        // @ts-ignore
        this.setState({ [name]: value });
    }

    getEditableRow(isEdit: boolean) {
        return (
            <>
                <td className="table-cell">
                    <input
                        name="name"
                        className="bg-gray-50 rounded-md border w-full px-2 font-medium py-2 focus:ring-1 focus:outline-none focus:border-blue-500"
                        onChange={this.handleChange}
                        value={this.state.name!}
                        autoFocus
                    ></input>
                </td>
                <td className="table-cell">
                    <EnumDropdown
                        items={FEE_TYPES}
                        bindName={'name'}
                        onSelect={(feeType: any) => {
                            this.handleChange({
                                currentTarget: {
                                    name: 'type',
                                    value: feeType.type,
                                },
                            } as React.FormEvent<any>);
                        }}
                        selected={this.state.type}
                        id={this.state.editableFeeId || 'newFee'}
                    />
                </td>

                <td className="table-cell">
                    <div className="flex">
                        <span className="mr-2 text-sm font-semibold mt-2">Labor</span>
                        <Checkbox
                            name="calculateOnLabor"
                            value={this.state.calculateOnLabor}
                            onChange={this.handleChange}
                            size={4}
                        />
                    </div>
                    <div className="flex">
                        <span className="mr-2 text-sm font-semibold mt-2">Parts</span>
                        <Checkbox
                            name="calculateOnParts"
                            value={this.state.calculateOnParts}
                            onChange={this.handleChange}
                            size={4}
                        />
                    </div>
                    <div className="flex">
                        <span className="mr-2 text-sm font-semibold mt-2">Sublets</span>
                        <Checkbox
                            name="calculateOnSublet"
                            value={this.state.calculateOnSublet}
                            onChange={this.handleChange}
                            size={4}
                        />
                    </div>
                </td>
                <td className="table-cell">
                    <span className="font-semibold inline">{this.state.type === FeeType.Fixed ? '$' : '%'}</span>
                    <PriceInput
                        name="amount"
                        className="inline bg-gray-50 rounded-md border px-2 font-medium py-2 focus:ring-1 focus:outline-none focus:border-blue-500"
                        onChange={this.handleChange}
                        amount={this.state.amount!}
                        placeholder="0.00"
                    ></PriceInput>
                </td>

                <td className="table-cell">
                    <Checkbox name="isTaxable" value={this.state.isTaxable} onChange={this.handleChange} />
                </td>

                <td className="table-cell">
                    <div>
                        <button
                            onClick={isEdit ? this.updateFee : this.createNewFee}
                            className="bg-green-500 rounded-lg font-semibold text-lg text-white px-2 border w-full"
                        >
                            Save
                        </button>
                    </div>
                    <div>
                        <button
                            onClick={() =>
                                this.setState({
                                    editableFeeId: null,
                                    isCreateNewFeeActive: false,
                                    name: null,
                                    amount: null,
                                })
                            }
                            className="bg-gray-100 rounded-lg font-semibold text-lg text-gray-700 px-2 border w-full"
                        >
                            Cancel
                        </button>
                    </div>
                </td>
            </>
        );
    }

    render() {
        const { fees, isCreateNewFeeActive, editableFeeId } = this.state;
        // TODO add auto appply setting
        return (
            <div className="flex flex-col items-center">
                <div className="w-full">
                    <div className="flex">
                        <div className="font-semibold text-gray-500 text-lg">Shop Fees</div>
                        <div className="flex flex-row float-right ml-auto mb-2">
                            <div className="create-new-button ml-auto">
                                {/* className={`${
                            isCreateNewFeeActive || editableFeeId ? 'bg-gray-500' : 'bg-green-500'
                        } rounded-lg px-2 py-1 font-semibold text-white mr-auto ml-3`} */}
                                <button
                                    className="font-semibold py-1 px-3 rounded-xl hover:bg-green-500 border border-green-500 text-green-500 hover:text-white"
                                    onClick={() =>
                                        this.setState({
                                            isCreateNewFeeActive: !isCreateNewFeeActive,
                                            editableFeeId: null,
                                            name: '',
                                            amount: 0.0,
                                            type: FeeType.Fixed,
                                            isTaxable: true,
                                            calculateOnLabor: false,
                                            calculateOnParts: true,
                                            calculateOnSublet: false,
                                        })
                                    }
                                >
                                    {isCreateNewFeeActive || editableFeeId ? (
                                        'Cancel'
                                    ) : (
                                        <>
                                            <FaPlusCircle className="inline align-text-top mr-1" />
                                            <span>Add New Fee</span>
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div>
                        <table className="bg-transparent w-full">
                            <thead>
                                <tr className="bg-blue-600  text-white border-none font-semibold text-left text-xs uppercase align-middle ">
                                    <th className="pl-6 py-3 rounded-tl-xl">Fee Name</th>
                                    <th className="pl-6 py-3">Method</th>
                                    <th className="pl-6 py-3">Calculate on</th>
                                    <th className="pl-6">Amount</th>
                                    <th className="pl-6">Is Taxable</th>
                                    <th className="pl-6 rounded-tr-xl"></th>
                                </tr>
                            </thead>
                            <tbody className="border">
                                {fees?.map((fee, index) => {
                                    return fee.id === editableFeeId ? (
                                        <tr className="text-black font-medium bg-white border-b " key={fee.id}>
                                            {this.getEditableRow(true)}
                                        </tr>
                                    ) : (
                                        <tr
                                            key={index}
                                            className={`text-black font-medium bg-white  hover:bg-blue-100 py-52`}
                                        >
                                            <td className="px-6 align-middle h-5 text-sm py-4 border-b text-left items-center">
                                                <div className="whitespace-pre-wrap">{fee.name}</div>
                                            </td>
                                            <td className="px-6 align-middle h-5 text-sm py-4 border-b text-left items-center">
                                                <EnumDropdown
                                                    items={FEE_TYPES}
                                                    bindName={'name'}
                                                    onSelect={(_) => {
                                                        return;
                                                    }}
                                                    disabled={true}
                                                    selected={
                                                        FEE_TYPES.find((feeType) => feeType.type === fee.type)?.type
                                                    }
                                                    id={fee.id}
                                                />
                                            </td>
                                            <td className="px-6 align-middle h-5 text-sm py-4 border-b">
                                                <div className="flex">
                                                    <span className="mr-2 text-sm font-semibold mt-2">Labor</span>
                                                    <Checkbox
                                                        name="calculateOnLabor"
                                                        value={fee.calculateOnLabor}
                                                        disabled
                                                        size={4}
                                                    />
                                                </div>
                                                <div className="flex">
                                                    <span className="mr-2 text-sm font-semibold mt-2">Parts</span>
                                                    <Checkbox
                                                        name="calculateOnParts"
                                                        value={fee.calculateOnParts}
                                                        disabled
                                                        size={4}
                                                    />
                                                </div>
                                                <div className="flex">
                                                    <span className="mr-2 text-sm font-semibold mt-2">Sublets</span>
                                                    <Checkbox
                                                        name="calculateOnSublet"
                                                        value={fee.calculateOnSublet}
                                                        disabled
                                                        size={4}
                                                    />
                                                </div>
                                            </td>
                                            <td className="px-6 align-middle h-5 text-sm py-4 border-b">
                                                <div className="whitespace-pre-wrap">
                                                    {fee.type === FeeType.Fixed ? (
                                                        <Price price={fee.amount} />
                                                    ) : (
                                                        <Percentage percentage={fee.amount / 100} />
                                                    )}
                                                </div>
                                            </td>

                                            <td className="px-6 align-middle h-5 text-sm py-4 border-b">
                                                <Checkbox name="isTaxable" value={fee.isTaxable} disabled />
                                            </td>

                                            <td className="px-6 align-middle h-5 text-sm py-4 border-b non-clickable">
                                                <div className="flex text-xl">
                                                    <FaEdit
                                                        className="clickable mr-2"
                                                        onClick={() => this.editFee(fee)}
                                                    />
                                                    <FaTrash
                                                        className="clickable "
                                                        onClick={() => this.handleDelete(fee)}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                                {isCreateNewFeeActive && (
                                    <tr className="text-black font-medium bg-white border-b">
                                        {this.getEditableRow(false)}
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        {fees?.length < 1 && !isCreateNewFeeActive && (
                            <div className="py-8 flex justify-center border-b-2 border-l-2 border-r-2 rounded-b-lg bg-white w-full">
                                <h2 className="text-lg font-semibold">You do not have any fees setup yet</h2>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default FeesSettings;

const FEE_TYPES = [
    {
        name: 'Percentage %',
        type: FeeType.Percentage,
    },
    {
        name: 'Fixed $',
        type: FeeType.Fixed,
    },
];
