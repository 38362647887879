import { Dispatch } from 'redux';

import axios from 'helpers/axios';
import config from 'helpers/config';
import actionTypes from 'helpers/actionTypes';
import VehicleModel from 'models/VehicleModel';
import { GetOrderedListRequest } from 'models/common/GetOrderedListRequest';

const { apiUrl } = config;

export interface GetVehiclesRequest extends GetOrderedListRequest {
    searchPhrase: string;
    page: number;
    itemsPerPage: number;
}

export interface NewVehicleRequest {
    licensePlate: string;
    VIN?: string;
    make: string;
    model: string;
    color?: string;
    odometer?: string;
    customerId?: string;
    year?: number | string;
}

export const getVehicles = (requestParams: GetVehiclesRequest) => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.GET_VEHICLES_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.GET_VEHICLES_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.GET_VEHICLES_FAILURE, payload });

    dispatch(request());

    axios
        .get(`${apiUrl}/vehicle`, { params: requestParams })
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};

// prettier-ignore
export const getVehicle =
    (vehicleId: number, withRepairOrders = false, withLifetimeStats = false) =>
    (dispatch: Dispatch) => {
        const request = () => ({ type: actionTypes.GET_VEHICLE_REQUEST });
        const success = (payload: any) => ({ type: actionTypes.GET_VEHICLE_SUCCESS, payload });
        const failure = (payload: any) => ({ type: actionTypes.GET_VEHICLE_FAILURE, payload });

        dispatch(request());

        axios
            .get(`${apiUrl}/vehicle/${vehicleId}`, {
                params: {
                    withLifetimeStats: Number(withLifetimeStats),
                    withRepairOrders: Number(withRepairOrders),
                },
            })
            .then((res) => {
                dispatch(success(res.data));
            })
            .catch((err) => {
                dispatch(failure(err.message));
            });
    };

export const updateVehicle = (vehicle: VehicleModel) => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.UPDATE_VEHICLE_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.UPDATE_VEHICLE_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.UPDATE_VEHICLE_FAILURE, payload });

    dispatch(request());

    axios
        .put(`${apiUrl}/vehicle`, vehicle)
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};

export const createVehicle = (newVehicle: NewVehicleRequest) => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.CREATE_VEHICLE_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.CREATE_VEHICLE_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.CREATE_VEHICLE_FAILURE, payload });

    dispatch(request());

    axios
        .post(`${apiUrl}/vehicle`, newVehicle)
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};

export const getVehiclesOverview = () => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.GET_VEHICLES_OVERVIEW_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.GET_VEHICLES_OVERVIEW_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.GET_VEHICLES_OVERVIEW_FAILURE, payload });

    dispatch(request());
    axios
        .get(`${config.apiUrl}/vehicle/overview`)
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};

export const deleteVehicle = (vehicleId: string) => (dispatch: Dispatch) => {
    const success = (payload: any) => ({ type: actionTypes.DELETE_VEHICLE_SUCCESS, payload });

    axios
        .delete(`${apiUrl}/vehicle/${vehicleId}`)
        .then(() => {
            getVehiclesOverview()(dispatch);
            dispatch(success(vehicleId));
        })
        .catch((err) => {
            console.log(err);
        });
};
