import React, { useState } from 'react';

import axios from 'helpers/axios';
import config from 'helpers/config';
import history from 'helpers/history';
import { FaCalendar, FaPlus } from 'react-icons/fa';

const ADD_NEW_BUTTON_CSS =
    'clickable border px-2 py-12 text-center text-gray-600 hover:text-blue-600 hover:bg-gray-100';

interface Props {
    className?: string;
    withPicker?: boolean;
    onChange?: Function;
    iconWithText?: boolean;
}

const AddNew: React.FC<Props> = ({ className = '', withPicker = true }: Props) => {
    const [isPickerOpen, setIsPickerOpen] = useState(false);

    const id = 'addNewPicker';

    const onClickOutside = (e: any) => {
        const target = document.querySelector(`#${id}`);
        const withinBoundaries = e.composedPath().includes(target);

        if (withinBoundaries) {
        } else {
            togglePicker(false);
        }
    };

    const togglePicker = (open: boolean) => {
        if (open) {
            setIsPickerOpen(true);
            document.addEventListener('click', onClickOutside);
        } else {
            setIsPickerOpen(false);
            document.removeEventListener('click', onClickOutside);
        }
    };

    const addRepairOrder = () => {
        togglePicker(false);
        axios.post(`${config.apiUrl}/repairOrder`, {}).then((res) => {
            if (res.data?.id) {
                history.push(`/repairOrder/${res.data.id}`);
            }
        });
    };

    const goToCalendarNewAppointment = () => {
        togglePicker(false);
        history.push('/calendar', { openNewAppointmentModal: true });
    };

    return (
        <div className={`relative ${className} mr-4`} title="Add new">
            <div
                className={`p-2.5 rounded-full  clickable  hover:bg-gray-400 ml-4 mt-1.5
                ${isPickerOpen ? 'bg-gray-400' : 'bg-gray-500'}
                `}
                onClick={() => togglePicker(!isPickerOpen)}
            >
                <FaPlus className="text-white clickable text-xl" />
            </div>

            {!!withPicker && isPickerOpen && (
                <div
                    className="absolute top-16 left-1/2 transform -translate-x-1/2 bg-white border-2 border-gray-700 rounded-lg shadow-2xl w-max"
                    id={id}
                >
                    <div className="text-center text-black font-semibold border-b-2 py-3 border-gray-300">Add new</div>
                    <div className="grid grid-cols-3 font-semibold">
                        <div className={ADD_NEW_BUTTON_CSS}>Customer and vehicle</div>
                        <div className={ADD_NEW_BUTTON_CSS} onClick={() => addRepairOrder()}>
                            Estimate
                        </div>
                        <div className={ADD_NEW_BUTTON_CSS} onClick={() => goToCalendarNewAppointment()}>
                            <div className="flex">
                                <FaCalendar />
                                <span>Appointment</span>
                            </div>
                        </div>
                        <div className={ADD_NEW_BUTTON_CSS}>Inventory Part</div>
                        <div className={ADD_NEW_BUTTON_CSS}>Fleet</div>
                        <div className={ADD_NEW_BUTTON_CSS}>Purchase Order</div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddNew;
