import actionTypes from 'helpers/actionTypes';
import RepairOrderActivity from 'models/RepairOrderActivity';
import RepairOrder from 'models/RepairOrderModel';
import Service from 'models/ServiceModel';
import VehicleIssue from 'models/VehicleIssue';

class initialState {
    repairOrder!: RepairOrder;
    services!: Service[];
    activityLogs!: RepairOrderActivity[];
    vehicleIssues!: VehicleIssue[];
}

const repairOrderReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case actionTypes.GET_REPAIR_ORDER_SUCCESS:
            return {
                ...state,
                repairOrder: action.payload,
                activityLogs: action.payload.activityLogs,
                vehicleIssues: action.payload.vehicleIssues,
            };

        case actionTypes.UPDATE_REPAIR_ORDER_SUCCESS: {
            return {
                ...state,
                repairOrder: action.payload,
                activityLogs: action.payload.activityLogs,
                vehicleIssues: action.payload.vehicleIssues,
            };
        }
        case actionTypes.GET_REPAIR_ORDER_SERVICES_SUCCESS:
            return {
                ...state,
                services: action.payload,
            };

        case actionTypes.ADD_REPAIR_ORDER_SERVICE_SUCCESS:
            return {
                ...state,
                // @ts-ignore
                services: [...state.services, action.payload],
            };

        case actionTypes.UPDATE_REPAIR_ORDER_SERVICE_SUCCESS: {
            return {
                ...state,
                // @ts-ignore
                services: state.services.map((service: Service) => {
                    if (service.id !== action.payload.id) {
                        return service;
                    } else {
                        return action.payload;
                    }
                }),
            };
        }
        case actionTypes.UPDATE_REPAIR_ORDER_ACTIVITY_LOG_SUCCESS: {
            return {
                ...state,
                activityLogs: action.payload,
            };
        }
        case actionTypes.UPDATE_REPAIR_ORDER_VEHICLE_ISSUES_SUCCESS: {
            return {
                ...state,
                // @ts-ignore
                vehicleIssues: state.vehicleIssues.map((vehicleIssue: VehicleIssue) => {
                    if (vehicleIssue.id !== action.payload.id) {
                        return vehicleIssue;
                    } else {
                        return action.payload;
                    }
                }),
            };
        }

        case actionTypes.ADD_REPAIR_ORDER_VEHICLE_ISSUE_SUCCESS: {
            return {
                ...state,
                // @ts-ignore
                vehicleIssues: [...state.vehicleIssues, action.payload],
            };
        }
        case actionTypes.DELETE_REPAIR_ORDER_VEHICLE_ISSUE_REQUEST: {
            return {
                ...state,
                // @ts-ignore
                vehicleIssues: state.vehicleIssues.filter((vehicleIssue) => vehicleIssue.id !== action.payload),
            };
        }

        case actionTypes.DELETE_REPAIR_ORDER_SERVICE_SUCCESS: {
            return {
                ...state,
                // @ts-ignore
                services: state.services.filter((service) => service.id !== action.payload),
            };
        }

        case actionTypes.REPAIR_ORDER_PAGE_CLOSED: {
            return {
                ...state,
                repairOrder: null,
                services: [],
                activityLogs: [],
                vehicleIssues: [],
            };
        }

        default: {
            return state;
        }
    }
};

export default repairOrderReducer;
