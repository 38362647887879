import React from 'react';
import NavigationBar from './NavigationBar';
import SideBar from './SideBar';
import { connect } from 'react-redux';
import User from '../../models/UserModel';

interface Props {
    children: any;
    currentUser: User;
}

const Layout: React.FC<Props> = (props) => {
    return (
        <div className="layout" id="main-layout-1">
            <NavigationBar />
            <div className="flex">
                <SideBar {...props.children.props} />
                <div className={`main-area ${props.currentUser?.tenant?.isInDemo ? 'header-expanded' : ''}`}>
                    {props.children}
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(Layout);
