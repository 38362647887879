import Service from 'models/ServiceModel';
import ServiceItemType from 'models/types/ServiceItemType';
import ServiceItem from 'models/ServiceItemModel';
import LaborRate from 'models/LaborRateModel';
import ServiceAuthorizationStatus from 'models/types/ServiceAuthorizationStatus';
import Fee from 'models/FeeModel';
import TenantSettings from 'models/TenantSettingsModel';
import FeeType from 'models/types/FeeType';

export const getServiceTotal = (
    service: Service,
    laborRates: LaborRate[],
    withTaxes = false,
    tenantSettings?: TenantSettings,
) => {
    const { items } = service;
    if (!!!items?.length) {
        return '0.00';
    }
    const laborTotal = items
        ?.filter((item) => item.type === ServiceItemType.Labor)
        .map((laborItem) => getServiceItemTotal(laborItem, laborRates))
        .reduce((partialSum, a) => partialSum + a, 0);
    const partTotal = items
        ?.filter((item) => item.type === ServiceItemType.Part)
        .map((partItem) => partItem.total)
        .reduce((partialSum, a) => partialSum + a, 0);
    const feeTotal = items
        ?.filter((item) => item.type === ServiceItemType.Fee)
        .map((feeItem) => feeItem.price * feeItem.quantity)
        .reduce((partialSum, a) => partialSum + a, 0);

    let grandTotal;
    if (withTaxes && !!tenantSettings) {
        grandTotal =
            applyTax(laborTotal, !!tenantSettings?.isSalesTaxApplicableToLabor, tenantSettings?.salesTaxRate!) +
            applyTax(partTotal, !!tenantSettings?.isSalesTaxApplicableToParts, tenantSettings?.salesTaxRate!) +
            applyTax(feeTotal, !!tenantSettings?.isSalesTaxApplicableToFees, tenantSettings?.salesTaxRate!);
    } else {
        grandTotal = laborTotal + partTotal + feeTotal;
    }
    return grandTotal.toFixed(2).toString();
};

const applyTax = (amount: number, isApplicable: boolean, taxRate: number) => {
    if (isApplicable) {
        return amount + (amount / 100) * taxRate;
    } else {
        return amount;
    }
};

export const getServiceItemTotal = (item: ServiceItem, laborRates: LaborRate[], fees?: Fee[]) => {
    let total = 0.0;
    if (item.type === ServiceItemType.Labor) {
        const selectedRate = laborRates?.find((rate) => rate.id === item?.laborRateId)?.rate;
        total = Number((selectedRate || 0) * item.quantity);
    }

    if (item.type === ServiceItemType.Part) {
        total = item.price * item.quantity;
    }

    if (item.type === ServiceItemType.Fee && fees?.length) {
        total = fees!.find((fee) => fee.id === item?.feeId)?.amount!;
    }

    return total;
};

export const getServiceTotalForServiceItemType = (
    service: Service,
    serviceItemType: ServiceItemType,
    laborRates: LaborRate[],
) => {
    const items = service.items.filter((lineItem) => lineItem.type === serviceItemType);

    const total = items
        .map((lineItem) => getServiceItemTotal(lineItem, laborRates))
        ?.reduce((a, b) => +a + +b, 0)
        .toFixed(2);
    return total;
};

export const getGrandTotal = (
    services: Service[],
    laborRates: LaborRate[],
    includeRecommendedNotAuthorized = false,
) => {
    let servicesToInclude = services;
    if (!includeRecommendedNotAuthorized) {
        servicesToInclude = services?.filter(
            (service) =>
                !(service.isRecommended && service.authorizationStatus !== ServiceAuthorizationStatus.Authorized),
        );
    }

    const grandTotal = Number(
        servicesToInclude
            ?.map((service) => getServiceTotal(service, laborRates))
            .reduce((a, b) => +a + +b, 0)
            .toFixed(2),
    );

    return grandTotal;
};

export const calculateFeeAmount = (fee: Fee, service: Service, laborRates: LaborRate[]) => {
    const { items } = service;
    if (!!!items?.length) {
        return '0.00';
    }

    const applicableItems = items.filter((item) => {
        return (
            (item.type === ServiceItemType.Labor && fee.calculateOnLabor) ||
            (item.type === ServiceItemType.Part && fee.calculateOnParts) ||
            (item.type === ServiceItemType.Sublet && fee.calculateOnSublet)
        );
    });
    if (fee.type === FeeType.Fixed) {
        return fee.amount;
    } else {
        const amount =
            (fee.amount / 100) *
            applicableItems
                .map((item) => getServiceItemTotal(item, laborRates))
                .reduce((partialSum, a) => partialSum + a, 0);
        return amount;
    }
};
