import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FaClipboardList } from 'react-icons/fa';

import { getVehicle, updateVehicle } from 'actions/vehicleActions';
import VehicleModel from 'models/VehicleModel';
import VehicleStats from './VehicleStats';
import VehicleDetailsForm from './VehicleDetailsForm';
import VehicleRepairOrders from './tabs/VehicleRepairOrders';
import VehicleFiles from './tabs/VehicleFiles';
import axios from 'helpers/axios';
import history from 'helpers/history';
import config from 'helpers/config';
import LaborRate from 'models/LaborRateModel';
import CustomerOrVehiclePayments from 'components/pages/customers/customerDetails/tabs/CustomerOrVehiclePayments';

interface Props {
    dispatch: Dispatch;
    vehicle: VehicleModel;
    vehicleLoading: boolean;
    vehicleUpdating: boolean;
}

interface State {
    isEditMode: boolean;
    activeTab: number;
    vehicle?: VehicleModel;
    laborRates: LaborRate[];
    isNewRepairOrderLoading: boolean;
}

class VehicleDetailsPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            activeTab: 0,
            isEditMode: false,
            laborRates: [],
            isNewRepairOrderLoading: false,
        };

        this.getVehicleDetails();
        this.getLaborRates();
        this.getVehicleDetails = this.getVehicleDetails.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleEditMode = this.toggleEditMode.bind(this);
    }

    getLaborRates() {
        axios.get(`${config.apiUrl}/laborRate`).then((res) => {
            this.setState({ laborRates: res.data });
        });
    }

    getVehicleDetails() {
        // @ts-ignore
        const { vehicleId } = this.props.match.params;
        const { dispatch } = this.props;
        dispatch<any>(getVehicle(vehicleId, true, true));
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const { name, value } = event.currentTarget;

        // @ts-ignore
        const updatedVehicle: VehicleModel = { ...this.state.vehicle, [name]: value };
        this.setState({ vehicle: updatedVehicle });
    }

    toggleEditMode() {
        const isEditMode = this.state.isEditMode;

        if (isEditMode) {
            const { dispatch } = this.props;
            dispatch<any>(updateVehicle(this.state.vehicle!));
        }

        this.setState({
            isEditMode: !isEditMode,
            vehicle: this.props.vehicle,
        });
    }

    goToRepairOrder() {
        this.setState({ isNewRepairOrderLoading: true }, () => {
            axios
                .post(`${config.apiUrl}/repairOrder`, {
                    customerId: this.props.vehicle.customerId,
                    vehicleId: this.props.vehicle.id,
                })
                .then((res) => {
                    this.setState({ isNewRepairOrderLoading: false });
                    if (res.data?.id) {
                        history.push(`/repairOrder/${res.data.id}`);
                    }
                })
                .catch((_) => {
                    this.setState({ isNewRepairOrderLoading: false });
                });
        });
    }

    getTabs() {
        return [
            {
                title: 'Repair Orders',
                component: (
                    <VehicleRepairOrders
                        repairOrders={this.props.vehicle?.repairOrders!}
                        laborRates={this.state.laborRates}
                    />
                ),
            },
            {
                title: 'Payments',
                component: (
                    <CustomerOrVehiclePayments repairOrders={this.props.vehicle?.repairOrders!} isForCustomer={false} />
                ),
            },
            {
                title: 'Files',
                component: <VehicleFiles files={this.props.vehicle?.files} />,
            },
        ];
    }

    render() {
        const { vehicleLoading, vehicle } = this.props;
        const { isEditMode, activeTab, isNewRepairOrderLoading } = this.state;
        const tabs = this.getTabs();

        return (
            <div className="bg-white mb-40">
                {!!vehicleLoading && <b>Loading...</b>}
                <div className="w-full bg-blue-500 p-8">
                    <h3 className="text-blue-100">Vehicle</h3>
                    <h2 className="text-blue-50 text-2xl font-semibold">
                        {vehicle?.year} {vehicle?.make} {vehicle?.model}
                    </h2>
                </div>
                <div className="mt-8 px-8">
                    <button
                        disabled={isNewRepairOrderLoading}
                        className="font-semibold py-2 px-3 rounded-xl hover:bg-green-500 border border-green-500 text-green-500 hover:text-white mr-4"
                        onClick={() => this.goToRepairOrder()}
                    >
                        <FaClipboardList className="inline mr-2 align-baseline" />
                        <span>New Invoice / Repair Order</span>
                    </button>
                </div>
                {!vehicleLoading && vehicle && (
                    <div className="pl-8 flex flex-col">
                        <VehicleStats vehicle={vehicle} />
                        <VehicleDetailsForm
                            vehicle={isEditMode ? this.state.vehicle! : vehicle}
                            handleChange={this.handleChange}
                            toggleEditMode={this.toggleEditMode}
                            isEditMode={isEditMode}
                        />
                        <div className="mt-8">
                            {tabs.map((tab, index) => {
                                return (
                                    <div
                                        className={`inline-flex px-5 border-b-4 ${
                                            activeTab === index
                                                ? ' border-blue-500 '
                                                : '  border-gray-500 hover:text-blue-400'
                                        }`}
                                        key={index}
                                    >
                                        <span
                                            className={`clickable text-lg ${
                                                activeTab === index
                                                    ? 'font-bold text-blue-500 border-blue-500 '
                                                    : ' font-medium text-gray-400 border-gray-500 hover:text-blue-400'
                                            }`}
                                            onClick={() => this.setState({ activeTab: index })}
                                        >
                                            {tab.title}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                        {tabs[activeTab].component}
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    vehicle: state.vehicle.vehicle,
    vehicleLoading: state.vehicle.vehicleLoading,
    vehicleUpdating: state.vehicle.vehicleUpdating,
});

export default connect(mapStateToProps)(VehicleDetailsPage);
