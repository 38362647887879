import { Dispatch } from 'redux';
import axios from '../helpers/axios';
import actionTypes from '../helpers/actionTypes';
import history from '../helpers/history';
import config from '../helpers/config';
import CreateUserRequest from '../models/create/CreateUserRequest';
import CreateTenantRequest from '../models/create/CreateTenantRequest';

const { apiUrl } = config;

export const login = (email: string, password: string) => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.LOGIN_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.LOGIN_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.LOGIN_FAILURE, payload });

    dispatch(request());
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

    axios
        .post(`${apiUrl}/auth/login`, { email, password, deviceType: width < 768 ? 'Mobile' : 'Desktop' })
        .then((res) => {
            if (res.status === 255) {
                localStorage.setItem('token', res.data.token);
                history.push('/admin_portal');
            } else {
                setTimeout(() => {
                    dispatch(success(res.data));
                    localStorage.setItem('token', res.data.token);
                    history.push('/');
                }, 500);
            }
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};

export const registerNewUser = (userData: CreateUserRequest) => (dispatch: Dispatch) => {
    const success = (payload: any) => ({ type: actionTypes.REGISTER_SUCCESS, payload });

    axios
        .post(`${apiUrl}/auth/register`, userData)
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => console.log(err.message));
};

export const registerNewTenant = (tenantData: CreateTenantRequest) => (dispatch: Dispatch) => {
    const success = (payload: any) => ({ type: actionTypes.REGISTER_SUCCESS, payload });

    axios
        .post(`${apiUrl}/auth/registerTenant`, tenantData)
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => console.log(err.message));
};
