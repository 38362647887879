import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import Layout from '../components/layout/Layout';

const PrivateRoute: React.SFC<any> = ({ component: Component, ...rest }: { component: React.ComponentType<any> }) => (
    <Route
        {...rest}
        render={(props) =>
            localStorage.getItem('token') ? (
                <Layout>
                    <Component {...props} />
                </Layout>
            ) : (
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )
        }
    />
);

export default PrivateRoute;
