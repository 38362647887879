import Vehicle from 'models/VehicleModel';
import React from 'react';
import { FaEdit, FaSave } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

interface Props {
    vehicle: Vehicle;
    handleChange: (e: any) => void;
    toggleEditMode: () => void;

    isEditMode: boolean;

    vehicleLoading: boolean;
    vehicleUpdating: boolean;
}

const FORM_INPUT_CLASS = 'rounded-md border-none font-bold w-3/4 pl-4 py-2 focus:ring-1 focus:outline-none';

const VehicleDetailsForm: React.FC<Props> = ({
    vehicle,
    handleChange,
    toggleEditMode,
    vehicleLoading,
    vehicleUpdating,
    isEditMode = false,
}: Props) => {
    const getValue = (propertyName: string) => {
        // @ts-ignore
        return vehicle[propertyName];
    };

    return (
        <div className="mt-8 border-2 rounded-xl bg-gray-100 p-6 w-1/2 relative flex flex-col">
            {!!vehicleLoading ||
                (!!vehicleUpdating && (
                    <div className="absolute left-1/2 top-1/3">
                        <h2 className="font-bold">Loading...</h2>
                    </div>
                ))}
            <div className="grid grid-cols-2 mb-4">
                <div className="w-full my-2 pl-4">
                    <span className="inline-block w-1/4">Make</span>
                    <input
                        className={FORM_INPUT_CLASS}
                        type="text"
                        value={getValue('make')}
                        name="make"
                        disabled={!isEditMode}
                        onChange={handleChange}
                    />
                </div>
                <div className="w-full my-2 pl-4">
                    <span className="inline-block w-1/4">Model</span>
                    <input
                        className={FORM_INPUT_CLASS}
                        type="text"
                        value={getValue('model')}
                        name="model"
                        disabled={!isEditMode}
                        onChange={handleChange}
                    />
                </div>
                <div className="w-full my-2 pl-4">
                    <span className="inline-block w-1/4">Year</span>
                    <input
                        className={FORM_INPUT_CLASS}
                        type="text"
                        value={getValue('year')}
                        name="year"
                        disabled={!isEditMode}
                        onChange={handleChange}
                    />
                </div>
                <div className="w-full my-2 pl-4">
                    <span className="inline-block w-1/4">License Plate</span>
                    <input
                        className={FORM_INPUT_CLASS}
                        type="text"
                        value={getValue('licensePlate')}
                        name="licensePlate"
                        disabled={!isEditMode}
                        onChange={handleChange}
                    />
                </div>
                <div className="w-full my-2 pl-4">
                    <span className="inline-block w-1/4">VIN</span>
                    <input
                        className={FORM_INPUT_CLASS}
                        type="text"
                        value={getValue('VIN')}
                        name="VIN"
                        disabled={!isEditMode}
                        onChange={handleChange}
                    />
                </div>
                <div className="w-full my-2 pl-4">
                    <span className="inline-block w-1/4">Owner</span>
                    <Link to={`/customer/${vehicle.customer?.id}`} className="font-bold w-3/4 pl-4 py-2 link">
                        {vehicle.customer?.firstName} {vehicle.customer?.lastName}
                    </Link>
                </div>
            </div>
            <button
                className={`${isEditMode ? 'bg-green-400' : 'bg-gray-400'} rounded-2xl px-4 py-1 font-bold ml-auto`}
                onClick={() => toggleEditMode()}
            >
                {isEditMode ? <FaSave className="inline mr-2" /> : <FaEdit className="inline mr-2" />}
                {isEditMode ? 'Save' : 'Edit'}
            </button>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    vehicleLoading: state.vehicle.vehicleLoading,
    vehicleUpdating: state.vehicle.vehicleUpdating,
});

export default connect(mapStateToProps)(VehicleDetailsForm);
