import React from 'react';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import ModalStateType from 'models/generic/ModalState';
import InspectionTemplate from 'models/InspectionTemplateModel';
import Modal from 'components/common/Modal';
import axios from 'helpers/axios';
import config from 'helpers/config';
import { FaPlusCircle, FaTrash } from 'react-icons/fa';
import InspectionTemplateTask from 'models/InspectionTemplateTaskModel';

interface Props {
    inspection?: InspectionTemplate;
    onClose: (paymentCreated?: boolean, paymentUpdated?: boolean) => void;

    dispatch: Dispatch;
}

interface State {
    modalState: ModalStateType;
    modalHeader: string;

    inspection: InspectionTemplate;
    isLoading: boolean;
}

class InspectionModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            modalState: !this.props.inspection ? ModalStateType.Create : ModalStateType.Edit,
            modalHeader: '',
            isLoading: false,
            inspection: {} as InspectionTemplate,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    componentDidMount() {
        if (this.state.modalState === ModalStateType.Create) {
            this.setState({
                inspection: {
                    inspectionTemplateItems: [{ description: '' }] as InspectionTemplateTask[],
                } as InspectionTemplate,
            });
        } else {
            const inspectionCopy = this.props.inspection;
            // @ts-ignore
            inspectionCopy.inspectionTemplateItems = inspectionCopy.tasks;
            this.setState({ inspection: inspectionCopy! });
        }
        this.setModalHeader();
    }

    setModalHeader() {
        if (this.state.modalState === ModalStateType.Edit) {
            this.setState({ modalHeader: 'Edit Inspection' });
        } else {
            this.setState({ modalHeader: 'New Inspection' });
        }
    }

    updateInspectionTemplate(inspection: any) {
        inspection.tasks = inspection.inspectionTemplateItems;
        axios
            .put(`${config.apiUrl}/inspection/template`, inspection)
            .then((_) => this.props.onClose(false, true))
            .catch((_) => this.setState({ isLoading: false }));
    }

    createInspectionTemplate() {
        const { inspection } = this.state;
        axios
            .post(`${config.apiUrl}/inspection/template`, inspection)
            .then((_) => this.props.onClose(true, false))
            .catch((_) => this.setState({ isLoading: false }));
    }

    getValue(name: string) {
        // @ts-ignore
        return this.state.inspection[name];
    }

    handleChange(event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) {
        const { name, value } = event.currentTarget;

        // @ts-ignore
        const updatedInspection = { ...this.state.inspection, [name]: value };
        this.setState({ inspection: updatedInspection });
    }

    handleUpdate() {
        this.setState({ isLoading: true });
        if (this.state.modalState === ModalStateType.Create) {
            this.createInspectionTemplate();
        } else {
            this.updateInspectionTemplate(this.state.inspection);
        }
    }

    addTemplateTask() {
        const inspectionCopy = this.state.inspection;
        inspectionCopy.inspectionTemplateItems = [
            ...inspectionCopy.inspectionTemplateItems,
            { description: '' } as InspectionTemplateTask,
        ];
        this.setState({
            inspection: { ...inspectionCopy },
        });
    }

    removeTask(taskIndex: number) {
        const { inspection } = this.state;
        inspection.inspectionTemplateItems.splice(taskIndex, 1);
        this.setState({ inspection });
    }

    updateDescription(taskIndex: number, description: string) {
        const { inspection } = this.state;
        inspection.inspectionTemplateItems[taskIndex].description = description;
        this.setState({ inspection });
    }

    render() {
        const { modalHeader, isLoading, modalState, inspection } = this.state;
        return (
            <Modal
                headerText={modalHeader}
                isOpen={true}
                onClose={() => this.props.onClose(false, false)}
                isLoading={isLoading}
                isBackgroundWhite={true}
                isDeleteHidden={modalState !== ModalStateType.Edit}
                onSave={this.handleUpdate}
                saveText={modalState === ModalStateType.Edit ? 'Update' : 'Create'}
            >
                <div className="w-full">
                    <div className="pb-2">
                        <label className="font-semibold">Inspection Title</label>
                        <input
                            type="text"
                            name="name"
                            className="bg-gray-50 rounded-md border w-full px-2 text-lg font-semibold py-2 focus:ring-1 focus:outline-none focus:border-blue-500"
                            value={this.getValue('name')}
                            autoFocus
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="pb-4">
                        <label className="font-semibold">Description</label>
                        <textarea
                            name="description"
                            className="bg-gray-50 rounded-md border w-full px-2 text-base font-semibold py-2 focus:ring-1 focus:outline-none focus:border-blue-500"
                            value={this.getValue('description')}
                            autoFocus
                            onChange={this.handleChange}
                        />
                    </div>
                    <div>
                        <label className="font-semibold">Inspection Tasks</label>
                        {inspection?.inspectionTemplateItems?.map((task, index) => {
                            return (
                                <div key={index} className="pb-2 flex">
                                    <input
                                        type="text"
                                        name="name"
                                        className="w-full bg-gray-50 rounded-md border px-2 text-lg font-semibold py-2 focus:ring-1 focus:outline-none focus:border-blue-500"
                                        value={task.description}
                                        onChange={(e: any) => this.updateDescription(index, e.target.value)}
                                        autoFocus
                                    />
                                    <FaTrash
                                        className="inline self-center mx-3 clickable"
                                        onClick={() => this.removeTask(index)}
                                    />
                                </div>
                            );
                        })}
                        <div className="uppercase font-semibold">
                            <div onClick={() => this.addTemplateTask()} className="clickable inline">
                                <FaPlusCircle className="mr-1 inline align-middle text-lg" />
                                <span>Add Inspection Task</span>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default connect()(InspectionModal);
