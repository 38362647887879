import { getRecentRepairOrders, setRecentRepairOrders } from 'helpers/services/localStorageService';
import actionTypes from '../../helpers/actionTypes';
import PartsMatrix from 'models/PartsMatrixModel';

interface InitialState {
    isDeleteConfirmationModalOpen: boolean;
    partsPricingMatrices: PartsMatrix[];
    isWarningModalOpen: boolean;
    customerNames: { id: string; fullName: string }[];
    recentlyViewedROs: any[];
}

const initialState: InitialState = {
    isDeleteConfirmationModalOpen: false,
    partsPricingMatrices: [],
    isWarningModalOpen: false,
    customerNames: [],
    recentlyViewedROs: getRecentRepairOrders(),
};

const miscReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case actionTypes.OPEN_DELETE_CONFIRMATION:
            return {
                ...state,
                isDeleteConfirmationModalOpen: true,
            };

        case actionTypes.GET_PRICING_MATRICES_SUCCESS:
            return {
                ...state,
                partsPricingMatrices: action.payload,
            };
        case actionTypes.CLOSE_DELETE_CONFIRMATION:
            return {
                ...state,
                isDeleteConfirmationModalOpen: false,
            };
        case actionTypes.OPEN_WARNING_MODAL:
            return {
                ...state,
                isWarningModalOpen: true,
            };
        case actionTypes.CLOSE_WARNING_MODAL:
            return {
                ...state,
                isWarningModalOpen: false,
            };
        case actionTypes.ADD_CUSTOMER_NAME:
            console.log(action.payload);
            return {
                ...state,
                customerNames:
                    state.customerNames?.length > 0
                        ? [...state.customerNames, { ...action.payload }]
                        : [{ ...action.payload }],
            };
        case actionTypes.UPDATE_RECENTLY_VIEWED_ROS:
            const currentHistory = state.recentlyViewedROs ? state.recentlyViewedROs : getRecentRepairOrders();

            // Check if the current repair order ID already exists in the history
            const alreadyExists = currentHistory.some((ro) => ro.id === action.payload.id);

            // If it exists, filter it out from the history
            const filteredHistory = alreadyExists
                ? currentHistory.filter((ro) => ro.id !== action.payload.id)
                : currentHistory;

            // Add the new repair order to the beginning of the history
            const updatedRecentlyViewedROs = [action.payload, ...filteredHistory.slice(0, 6)];

            setRecentRepairOrders(JSON.stringify(updatedRecentlyViewedROs));

            return {
                ...state,
                recentlyViewedROs: updatedRecentlyViewedROs,
            };
        case actionTypes.DELETE_RECENTLY_VIEWED_ROS:
            setRecentRepairOrders(JSON.stringify([]));

            return {
                ...state,
                recentlyViewedROs: [],
            };
        default: {
            return state;
        }
    }
};

export default miscReducer;
