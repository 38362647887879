import history from 'helpers/history';
import React, { useState } from 'react';
import { FaCar, FaHistory, FaUser } from 'react-icons/fa';
import { connect } from 'react-redux';

interface Props {
    recentRos: any[];
}

const RecentRos: React.FC<Props> = ({ recentRos = [] }) => {
    const [isHistoryOpen, setIsHistoryOpen] = useState(false);
    const id = 'ROsHistory';

    const toggleHistory = (open: boolean) => {
        if (open) {
            setIsHistoryOpen(true);
            document.addEventListener('click', onClickOutside);
        } else {
            setIsHistoryOpen(false);
            document.removeEventListener('click', onClickOutside);
        }
    };

    const onClickOutside = (e: any) => {
        const target = document.querySelector(`#${id}`);
        const withinBoundaries = e.composedPath().includes(target);

        if (withinBoundaries) {
        } else {
            toggleHistory(false);
        }
    };

    return (
        <div className={`relative `} title="History">
            <div
                className={`clickable p-2 rounded-full  hover:bg-gray-400 ml-4 mt-1.5 ${
                    isHistoryOpen ? 'bg-gray-400' : 'bg-gray-500'
                }`}
                onClick={() => toggleHistory(!isHistoryOpen)}
            >
                <FaHistory className="text-white clickable text-2xl" />
            </div>

            {isHistoryOpen && (
                <div
                    className="absolute top-16 left-1/2 transform -translate-x-1/2 bg-white border-2 shadow-2xl w-max rounded-lg px-4 py-4"
                    id={id}
                >
                    <div>
                        <div className="font-semibold text-gray-600 text-lg">Recently visited ROs</div>
                    </div>
                    {recentRos.map((RO, index) => {
                        return (
                            <div
                                key={index}
                                className="clickable hover:text-blue-600 border rounded-lg my-1.5 border-blue-500 p-1.5 hover:bg-blue-200"
                                onClick={() => {
                                    history.push(`/repairOrder/${RO.id}`);
                                    toggleHistory(false);
                                }}
                            >
                                <div>
                                    <span className="font-bold">#{RO.number}</span>{' '}
                                    <span className="font-semibold">{RO.name}</span>
                                </div>
                                {!!RO.vehicle && (
                                    <div className="flex">
                                        <FaCar className="inline mr-2 align-baseline text-gray-500" />
                                        <div className="text-sm font-semibolt">
                                            {RO.vehicle?.year} {RO.vehicle?.make} {RO.vehicle?.model}
                                        </div>
                                    </div>
                                )}

                                {!!RO.customer && (
                                    <div>
                                        <FaUser className="inline mr-2 align-baseline text-gray-500" />
                                        <span className="text-sm font-semibolt">
                                            {RO.customer?.firstName} {RO.customer?.lastName}
                                        </span>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    recentRos: state.misc.recentlyViewedROs,
});

export default connect(mapStateToProps)(RecentRos);
