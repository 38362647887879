import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { AiFillFileAdd } from 'react-icons/ai';
import { MdAddAPhoto } from 'react-icons/md';
import { CgFileDocument } from 'react-icons/cg';

import RepairOrder from 'models/RepairOrderModel';
import axios from 'helpers/axios';
import config from 'helpers/config';
import actionTypes from 'helpers/actionTypes';
import FullPhotoView from 'components/common/FullPhotoView';
import UserFullName from 'components/common/UserFullName';
import RelativeTime from 'components/common/RelativeTime';

interface Props {
    repairOrder: RepairOrder;
    dispatch: Dispatch;
}

interface State {
    isPhotoExpanded: boolean;
    expandedPhoto?: any | null;
}

class AttachmentsTab extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isPhotoExpanded: false,
        };
    }

    reloadAttachments() {
        axios
            .get(`${config.apiUrl}/file/images/`, {
                params: { relatedEntityType: 'repairOrder', relatedEntityId: this.props.repairOrder.id },
            })
            .then((res) => {
                this.props.dispatch({
                    type: actionTypes.GET_REPAIR_ORDER_SUCCESS,
                    payload: {
                        ...this.props.repairOrder,
                        files: res.data,
                        attachmentsCount: !!this.props.repairOrder.attachmentsCount
                            ? ++this.props.repairOrder.attachmentsCount
                            : 1,
                    },
                });
            });
    }

    viewFullPhoto(photo: any) {
        this.setState({
            isPhotoExpanded: true,
            expandedPhoto: photo,
        });
    }

    addAttachment(isPhotoInput = true) {
        const imageInput = document.createElement('input');
        imageInput.type = 'file';
        imageInput.style.display = 'none';

        if (isPhotoInput) {
            imageInput.accept = '.gif,.jpg,.jpeg,.png';
        } else {
            imageInput.accept = '.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf';
        }

        document.body.appendChild(imageInput);

        imageInput.onchange = (e) => {
            // @ts-ignore
            const file = e?.target?.files[0];

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = (_) => {
                const fileUploadRequest = [
                    {
                        dataURL: reader.result,
                        file: {
                            lastModified: file?.lastModified,
                            name: file?.name,
                            size: file?.size,
                        },
                        repairOrderId: this.props.repairOrder.id,
                    },
                ];

                document.body.removeChild(imageInput);

                axios
                    .post(`${config.apiUrl}/file/${isPhotoInput ? 'images' : 'documents'}`, fileUploadRequest)
                    .then((_) => this.reloadAttachments())
                    .catch((_) => _);
            };
        };

        imageInput.click();
    }

    render() {
        const { isPhotoExpanded, expandedPhoto } = this.state;

        return (
            <div className="mt-4 bg-white p-4 rounded-xl border">
                {isPhotoExpanded && (
                    <FullPhotoView
                        photoSourceURL={`${config.fileRootURL}/images/${expandedPhoto.fileName}`}
                        fileName={expandedPhoto.fileName}
                        onClose={() => this.setState({ isPhotoExpanded: false, expandedPhoto: null })}
                    />
                )}

                <div className="flex mb-4">
                    <div className="mr-4">
                        <button
                            className="bg-green-600 p-1 px-3 rounded-2xl font-medium text-base transform hover:scale-105 transition opacity-90 duration-500 hover:opacity-100 flex"
                            onClick={() => this.addAttachment(false)}
                        >
                            <span className="mr-2">+ Add Document</span>
                            <AiFillFileAdd className="self-baseline mt-1">
                                <input type="file" alt="Submit" width="48" height="48"></input>
                            </AiFillFileAdd>
                        </button>
                    </div>

                    <div>
                        <button
                            className="bg-green-600 p-1 px-3 rounded-2xl font-medium text-base transform hover:scale-105 transition opacity-90 duration-500 hover:opacity-100 flex"
                            onClick={() => this.addAttachment(true)}
                        >
                            <span className="mr-2">+ Add Photo</span>

                            <MdAddAPhoto className="self-baseline mt-1">
                                <input type="image" alt="Submit" width="48" height="48"></input>
                            </MdAddAPhoto>
                        </button>
                    </div>
                </div>
                <div className="flex flex-col">
                    {this.props.repairOrder?.files
                        ?.sort((a, b) => {
                            return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1;
                        })
                        .map((file) => {
                            if (file.type === 'image') {
                                return (
                                    <div key={file.id} className="border-2 rounded-lg mb-2">
                                        <div className="ml-2  mt-4">
                                            <img
                                                src={`${config.fileRootURL}/images/${file.fileName}`}
                                                alt={file.fileName}
                                                title={file.fileName}
                                                className="clickable inspection-item-photo-tooltip inline "
                                                onClick={() => this.viewFullPhoto(file)}
                                            />
                                        </div>
                                        <div className="mx-2">
                                            <div>{file.fileName}</div>
                                            <div>
                                                <span>Added by </span>
                                                <span className="font-semibold">
                                                    <UserFullName userId={file.createdById} />
                                                </span>
                                                <span className="inline">
                                                    <RelativeTime dateTime={file.createdAt} />
                                                </span>
                                            </div>
                                            <div></div>
                                        </div>
                                    </div>
                                );
                            }
                        })}
                </div>
                <div className="flex flex-col ml-auto mt-4">
                    {this.props.repairOrder?.files
                        ?.sort((a, b) => {
                            return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1;
                        })
                        .map((file) => {
                            if (file.type === 'document') {
                                return (
                                    <div key={file.id} className="border-2 rounded-lg mb-2">
                                        <div className="truncate">
                                            <CgFileDocument className="inline mr-2" />
                                            <a
                                                className="text-blue-500 font-bold text-sm hover:underline clickable whitespace-nowrap truncate"
                                                href={`${config.fileRootURL}/documents/${file.fileName}`}
                                                download={`${file.fileName}`}
                                                rel="noreferrer"
                                                title={file.fileName}
                                            >
                                                {file.fileName}
                                            </a>
                                        </div>
                                        <div className="mx-2">
                                            <div>
                                                <span>Added by </span>
                                                <span className="font-semibold">
                                                    <UserFullName userId={file.createdById} />
                                                </span>
                                                <span className="inline">
                                                    <RelativeTime dateTime={file.createdAt} />
                                                </span>
                                            </div>
                                            <div></div>
                                        </div>
                                    </div>
                                );
                            }
                        })}
                </div>
            </div>
        );
    }
}

export default connect()(AttachmentsTab);
