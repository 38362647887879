import React from 'react';
import RepairOrder from 'models/RepairOrderModel';
import CustomerConcerns from './CustomerConcerns';
import CustomerStats from 'components/pages/customers/customerDetails/CustomerStats';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { getCustomer } from 'actions/customerActions';
import Customer from 'models/CustomerModel';
import Overlay from 'components/common/Overlay';

interface Props {
    repairOrder: RepairOrder;

    customer: Customer;
    customerLoading: boolean;
    dispatch: Dispatch;
}

interface State {}

class SummaryTab extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        const { customer } = this.props.repairOrder;
        if (!!customer) {
            const { dispatch } = this.props;
            dispatch<any>(getCustomer(customer.id!, true));
        }
    }

    render() {
        const { customer, customerLoading, repairOrder } = this.props;
        return (
            <div className="mt-4 flex">
                <div className="w-1/2 mb-4 mr-2">
                    {!!repairOrder.customer && (customerLoading ? <Overlay /> : <CustomerStats customer={customer} />)}
                </div>
                <div className="w-1/2">
                    <CustomerConcerns repairOrderId={this.props.repairOrder.id} />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    customer: state.customer.customer,
    customerLoading: state.customer.customerLoading,
});

export default connect(mapStateToProps)(SummaryTab);
