import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import history from 'helpers/history';

import EndOfDayReports from './menus/EndOfDayReports';
import SalesSummaryReports from './menus/SalesSummaryReports';
import AllPaymentsReports from './menus/AllPaymentsReports';
import VendorSummaryReports from './menus/VendorSummaryReports';
import AllInvoicesReports from './menus/AllInvoicesReports';
import UnpaidInvoices from './menus/UnpaidInvoices';
import PaidInvoices from './menus/PaidInvoices';

interface ComponentState {
    activeMenuIndex?: number;
}

interface Props {
    dispatch: Dispatch;
}

class ReportsPage extends React.Component<Props, ComponentState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            activeMenuIndex: undefined,
        };
    }

    componentDidMount() {
        //@ts-ignore
        const { subpageName } = this.props.match.params;
        this.setActiveMenu(subpageName);
    }

    setActiveMenu(subpageName: string) {
        let activeMenuIndex = navigationItems
            .flatMap((item) => item.pages)
            .findIndex((menuItem) => menuItem.location === subpageName);
        if (activeMenuIndex === -1) activeMenuIndex = 0;
        this.setState({ activeMenuIndex });
    }

    render() {
        const { activeMenuIndex } = this.state;
        return (
            <div className="flex">
                <div className="bg-white border-r-2 h-screen pt-4  space-y-10 divide-y-2 w-52 border-t-4">
                    <div className="fixed w-52 bg-white border-r-2 h-screen">
                        {navigationItems.map((group, groupIndex) => {
                            return (
                                <div
                                    key={`reportGroup${groupIndex}`}
                                    className={`${groupIndex !== 0 && 'pt-10'} border-b-2 pb-10`}
                                >
                                    <div className="px-3">
                                        <div className="font-semibold">{group.groupName}</div>
                                        <div>
                                            {group.pages.map((report, _) => {
                                                return (
                                                    <div
                                                        key={report.location}
                                                        className={`clickable rounded-lg px-1.5 py-1 hover:bg-blue-100 hover:text-blue-400 ${
                                                            activeMenuIndex === report.index &&
                                                            'bg-blue-200 font-semibold text-blue-500'
                                                        }`}
                                                        onClick={() => {
                                                            history.push(`/reports/${report.location}`);
                                                            this.setState({ activeMenuIndex: report.index });
                                                        }}
                                                    >
                                                        {report.name}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                {!(activeMenuIndex === undefined) && (
                    <div className="p-4 mb-40">
                        {
                            navigationItems
                                .flatMap((item) => item.pages)
                                .find((report) => report.index === activeMenuIndex)!.component
                        }
                    </div>
                )}
            </div>
        );
    }
}

const navigationItems: {
    groupName: string;
    pages: { name: string; location: string; component?: React.ReactElement; index: number }[];
}[] = [
    {
        groupName: 'Overview',
        pages: [
            {
                name: 'End of Day ',
                location: 'eodReports',
                component: <EndOfDayReports />,
                index: 0,
            },
            {
                name: 'Sales Summary',
                location: 'salesSumary',
                component: <SalesSummaryReports />,
                index: 1,
            },
        ],
    },
    {
        groupName: 'Payments Reports',
        pages: [
            {
                name: 'All Payments',
                location: 'allPayments',
                component: <AllPaymentsReports />,
                index: 2,
            },
        ],
    },
    {
        groupName: 'Purchase Orders',
        pages: [
            {
                name: 'Vendor Summary',
                location: 'vendorSummary',
                component: <VendorSummaryReports />,
                index: 3,
            },
        ],
    },
    {
        groupName: 'Estimates and Invoices',
        pages: [
            {
                name: 'All Invoices',
                location: 'allInvoices',
                component: <AllInvoicesReports />,
                index: 4,
            },
            {
                name: 'Unpaid Invoices',
                location: 'unpaidInvoices',
                component: <UnpaidInvoices />,
                index: 5,
            },
            {
                name: 'Paid Invoices',
                location: 'paidInvoices',
                component: <PaidInvoices />,
                index: 6,
            },
        ],
    },
];

export default connect()(ReportsPage);
