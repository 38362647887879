import React from 'react';
import { FaTrash, FaTimesCircle } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import axios from 'helpers/axios';
import config from 'helpers/config';
import actionTypes from 'helpers/actionTypes';
import { displayConfirmationModal } from 'helpers/services/removeConfirmationService';
import { calculateFeeAmount, getServiceItemTotal, getServiceTotal } from 'helpers/services/priceCalculationService';

import User from 'models/UserModel';
import CannedJob from 'models/CannedJobModel';
import ServiceItemType from 'models/types/ServiceItemType';
import ServiceItem from 'models/ServiceItemModel';
import LaborRate from 'models/LaborRateModel';
import NewDropdown from 'components/common/NewDropdown';
import Fee from 'models/FeeModel';
import Price from 'components/common/Price';
import Tenant from 'models/TenantModel';
import FeeType from 'models/types/FeeType';
import InventoryItem from 'models/InventoryItemModel';
import PriceInput from 'components/common/PriceInput';
import ServiceItemInput from 'components/pages/repairOrder/tabs/ServiceItemInput';
import Service from 'models/ServiceModel';
import CannedJobItem from 'models/CannedJobItem';

interface Props {
    cannedJob: CannedJob;
    currentUser: User;
    tenant: Tenant;
    serviceDeleted: Function;
    repairOrderId?: string;
    laborRates: LaborRate[];
    fees: Fee[];
    cannedJobUpdated: Function;
    dispatch: Dispatch;
}

interface State {
    cannedJob: CannedJob;
    isServiceLoading: boolean;
    isAuthorizationPickerOpen: boolean;
    isLoading: boolean;
}

class CannedJobServiceCard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            cannedJob: props.cannedJob,
            isServiceLoading: false,
            isAuthorizationPickerOpen: false,
            isLoading: false,
        };
        this.addServiceTemplateItem = this.addServiceTemplateItem.bind(this);
        this.createService = this.createService.bind(this);
        this.handleServiceItemChange = this.handleServiceItemChange.bind(this);
        this.updatePartLineItem = this.updatePartLineItem.bind(this);
        this.updatePrice = this.updatePrice.bind(this);
    }

    refreshCard() {
        this.setState({ isLoading: true }, () =>
            axios
                .get(`${config.apiUrl}/service/${this.state.cannedJob.id}`)
                .then((res) => this.setState({ cannedJob: res.data, isLoading: false }))
                .catch((_) => {
                    this.setState({ isLoading: false });
                }),
        );
    }

    addServiceTemplateItem(type: ServiceItemType) {
        const newItem: CannedJobItem = {} as CannedJobItem;
        switch (type) {
            case ServiceItemType.Part: {
                newItem.type = ServiceItemType.Part;
                newItem.price = 0.0;
                newItem.quantity = 1;
                break;
            }
            case ServiceItemType.Labor: {
                newItem.type = ServiceItemType.Labor;
                newItem.quantity = 1;
                newItem.laborRateId = this.props.laborRates.find((rate) => rate.isDefault)?.id;
                break;
            }
            case ServiceItemType.Fee: {
                newItem.type = ServiceItemType.Fee;
                break;
            }
        }
        const { cannedJob } = this.state;
        // cannedJob.items = [...cannedJob.items, newItem];
        cannedJob.items.push(newItem);

        console.log(this.props.laborRates);
        this.setState({ cannedJob });
    }

    saveService() {}

    createService() {
        const { cannedJob, isServiceLoading } = this.state;
        this.setState({ isServiceLoading: !isServiceLoading });
        axios
            .post(`${config.apiUrl}/cannedJob`, cannedJob)
            .then((res) => {
                this.setState({ cannedJob: { ...cannedJob, id: res.data.id } });
            })
            .catch((_) => _);
    }

    updateProperty(name: string, value: any, autoUpdate = true) {
        const updatedService: CannedJob = { ...this.state.cannedJob, [name]: value };
        this.setState({ cannedJob: updatedService });
    }

    handleServiceItemChange(
        event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>,
        index: number,
        updateService = true,
    ) {
        const { name, value } = event.currentTarget;
        const updatedServiceItem: CannedJobItem = { ...this.state.cannedJob.items[index], [name]: value };
        updatedServiceItem.total = getServiceItemTotal(updatedServiceItem as any as ServiceItem, this.props.laborRates);
        const { cannedJob: service } = this.state;
        service.items[index] = updatedServiceItem;
        this.setState({ cannedJob: service });
    }

    updatePrice(event: React.FormEvent<HTMLInputElement>, index: number) {
        const { value } = event.currentTarget;
        this.setState;

        const updatedServiceItem: CannedJobItem = {
            ...this.state.cannedJob.items[index],
            // @ts-ignore
            price: value,
        };
        console.log(updatedServiceItem);
        updatedServiceItem.total = getServiceItemTotal(updatedServiceItem as any as ServiceItem, this.props.laborRates);
        const { cannedJob: service } = this.state;
        service.items[index] = updatedServiceItem;
        this.setState({ cannedJob: service }, () => {
            // this.updateService();
        });
    }

    updatePartLineItem(itemIndex: number, part?: InventoryItem) {
        // if it was deselected, reset fields
        if (!!!part) {
            const lineItemToReset = { ...this.state.cannedJob.items[itemIndex] };

            lineItemToReset.type = ServiceItemType.Part;
            lineItemToReset.quantity = 1;
            lineItemToReset.price = 0.0;
            lineItemToReset.total = 0.0;
            lineItemToReset.subtotal = 0.0;
            lineItemToReset.inventoryItem = null;
            lineItemToReset.inventoryItemId = null;
            lineItemToReset.description = '';

            const { cannedJob: service } = this.state;

            const updatedService = {
                ...service,
                items: [...service.items],
            };

            updatedService.items[itemIndex] = { ...lineItemToReset };

            this.setState({ cannedJob: { ...updatedService } });
            return;
        }

        // new part selected
        const lineItemToUpdate = { ...this.state.cannedJob.items[itemIndex] };
        lineItemToUpdate.inventoryItem = part;
        lineItemToUpdate.inventoryItemId = part?.id;
        lineItemToUpdate.price = part?.retailPrice || 0;
        lineItemToUpdate.total = getServiceItemTotal(lineItemToUpdate as any as ServiceItem, this.props.laborRates);
        lineItemToUpdate.subtotal = getServiceItemTotal(lineItemToUpdate as any as ServiceItem, this.props.laborRates);
        const { cannedJob: service } = this.state;

        const updatedService = {
            ...service,
            items: [...service.items],
        };

        updatedService.items[itemIndex] = lineItemToUpdate;

        this.setState({ cannedJob: updatedService }, () => {
            this.updateService();
        });
    }

    handleServiceItemDelete(serviceItemId: string) {
        displayConfirmationModal('Service Item')
            .then(() => {
                axios.delete(`${config.apiUrl}/service/serviceItem/${serviceItemId}`).then(() => {
                    const service: CannedJob = JSON.parse(JSON.stringify(this.state.cannedJob));
                    const index = service.items.findIndex((item) => item.id === serviceItemId);
                    service.items.splice(index, 1);
                    this.setState({ cannedJob: service });
                    this.props.dispatch({
                        type: actionTypes.UPDATE_REPAIR_ORDER_SERVICE_SUCCESS,
                        payload: service,
                    });
                });
            })
            .catch((_) => _);
    }

    openAuthorizationPicker() {
        this.setState({ isAuthorizationPickerOpen: !this.state.isAuthorizationPickerOpen });
    }

    updateService() {
        const { cannedJob } = this.state;
        this.props.cannedJobUpdated(cannedJob);
    }

    render() {
        const { cannedJob } = this.state;
        const { laborRates, fees, tenant } = this.props;
        return (
            <div className="bg-white my-3 border border-gray-400 rounded-md p-2 divide-y-2 divide-gray-200 overflow-y-visible">
                <div className="py-3">
                    <div className="flex flex-row text-base py-1">
                        <span className="font-semibold mr-4">Add:</span>
                        {[
                            { name: 'Part', type: ServiceItemType.Part },
                            { name: 'Labor', type: ServiceItemType.Labor },
                            { name: 'Fee', type: ServiceItemType.Fee },
                        ].map((itemType, index) => (
                            <span
                                key={index}
                                onClick={() => this.addServiceTemplateItem(itemType.type)}
                                className="bg-green-100 rounded-md text-base font-bold px-2 mx-2 clickable uppercase hover:bg-green-600"
                            >
                                {itemType.name}
                            </span>
                        ))}
                    </div>
                    <div className="overflow-x-auto overflow-y-visible">
                        <table className="items-center bg-transparent border-collapse w-full">
                            <thead>
                                <tr className="bg-blue-600 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                                    <th className="pl-6 pr-64 py-3 rounded-tl-xl">Item</th>
                                    <th className="px-6 py-3">Asignee</th>
                                    <th className="px-6 py-3">Price</th>
                                    <th className="px-6 py-3">Quantity</th>
                                    <th className="px-6 py-3">Status</th>
                                    <th className="px-6 py-3">Subtotal</th>
                                    <th className="px-6 py-3 rounded-tr-xl"></th>
                                </tr>
                            </thead>

                            <tbody>
                                {cannedJob.items?.map((item, index) => (
                                    <tr className="border-t bg-blue-50 group hover:bg-blue-200" key={item.id}>
                                        <td className="px-6 min-w-max align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left items-center">
                                            {item.type !== ServiceItemType.Fee && (
                                                <ServiceItemInput
                                                    serviceItem={item as any as ServiceItem}
                                                    serviceItemChanged={(e: any) =>
                                                        this.handleServiceItemChange(e, index, true)
                                                    }
                                                    handlePartSelected={(inventoryItem: any) => {
                                                        this.updatePartLineItem(index, inventoryItem);
                                                    }}
                                                    handlePartDeselected={() => this.updatePartLineItem(index)}
                                                    onBlur={() => this.updateService()}
                                                />
                                            )}
                                            {item.type === ServiceItemType.Fee && (
                                                <NewDropdown
                                                    isServiceCard={true}
                                                    items={fees}
                                                    bindName={'name'}
                                                    onSelect={(fee: Fee) => {
                                                        this.handleServiceItemChange(
                                                            {
                                                                currentTarget: {
                                                                    name: 'feeId',
                                                                    value: fee.id,
                                                                },
                                                            } as React.FormEvent<any>,
                                                            index,
                                                        );
                                                    }}
                                                    selected={fees.find((fee) => fee.id === item?.feeId)}
                                                    id={item.id}
                                                    placeholder="Select fee"
                                                />
                                            )}
                                        </td>
                                        <td className="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <div className="flex flex-row">
                                                <span className="align-bottom mt-1"></span>
                                            </div>
                                        </td>
                                        <td className="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            {item.type === ServiceItemType.Part && (
                                                <span>
                                                    <span className="font-semibold inline">$</span>
                                                    <PriceInput
                                                        placeholder="0.00"
                                                        name="price"
                                                        // onChange={(e) => console.log(e)}
                                                        onChange={(e) => this.updatePrice(e, index)}
                                                        amount={item.price}
                                                        className="outline-none w-24 placeholder-gray-400 font-medium px-3 py-2 focus:ring-1 focus:ring-blue-600 rounded-lg text-right"
                                                    />
                                                </span>
                                            )}
                                            {item.type === ServiceItemType.Labor && (
                                                <NewDropdown
                                                    isServiceCard={true}
                                                    items={laborRates}
                                                    bindName={'name'}
                                                    onSelect={(laborRate: LaborRate) => {
                                                        this.handleServiceItemChange(
                                                            {
                                                                currentTarget: {
                                                                    name: 'laborRateId',
                                                                    value: laborRate.id,
                                                                },
                                                            } as React.FormEvent<any>,
                                                            index,
                                                        );
                                                    }}
                                                    selected={laborRates.find((rate) => rate.id === item?.laborRateId)}
                                                    id={item.id}
                                                />
                                            )}
                                            {item.type === ServiceItemType.Fee && (
                                                <Price
                                                    clasName="font-semibold text-blue-500"
                                                    price={fees.find((fee) => item.feeId == fee.id)?.amount!}
                                                />
                                            )}
                                        </td>
                                        <td className="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <div className="">
                                                <input
                                                    className="outline-none w-20 placeholder-gray-400 font-medium px-3 py-2 focus:ring-1 focus:ring-blue-600 rounded-lg mr-8"
                                                    placeholder="1"
                                                    type="number"
                                                    min="0"
                                                    name="quantity"
                                                    value={item.quantity}
                                                    onChange={(e) => this.handleServiceItemChange(e, index)}
                                                    disabled={item.type === ServiceItemType.Fee}
                                                />
                                            </div>
                                        </td>
                                        <td className="px-6 align-middle border-l-0 border-r-0 text-xs  p-4"></td>
                                        <td className="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <Price
                                                clasName="border-none outline-none w-24 placeholder-gray-400 font-medium px-3 py-2 group-hover:bg-blue-100 rounded-lg"
                                                price={getServiceItemTotal(
                                                    item as any as ServiceItem,
                                                    laborRates,
                                                    fees,
                                                )}
                                            />
                                        </td>
                                        <td className="px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                            <FaTrash
                                                size={15}
                                                className="opacity-0 group-hover:opacity-100 clickable "
                                                onClick={() => this.handleServiceItemDelete(item.id)}
                                            />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="flex pt-2">
                    <div className="ml-auto flex mr-4">
                        <div className="flex mr-4">
                            <div className="mr-1.5 font-semibold">Service Subtotal: </div>
                            <span className="text-green-700 font-bold">
                                {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                                    Number(getServiceTotal(cannedJob as any as Service, laborRates)),
                                )}
                            </span>
                        </div>
                        {fees
                            .filter((fee) => fee.isActive)
                            .map((fee, indx) => {
                                return (
                                    <div className="flex mr-4" key={indx}>
                                        <div className="mr-1.5 font-semibold">
                                            {fee.name}
                                            {fee.type === FeeType.Percentage && ` (${fee.amount}%)`}:
                                        </div>
                                        <span className="text-green-700 font-bold">
                                            {new Intl.NumberFormat('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            }).format(
                                                Number(
                                                    calculateFeeAmount(fee, cannedJob as any as Service, laborRates),
                                                ),
                                            )}
                                        </span>
                                    </div>
                                );
                            })}
                        <div className="flex">
                            <div className="mr-1.5 font-semibold">Service Total: </div>
                            <span className="text-green-700 font-bold">
                                {/* Includes tax */}
                                <Price
                                    price={Number(
                                        getServiceTotal(
                                            cannedJob as any as Service,
                                            laborRates,
                                            true,
                                            tenant?.tenantSettings,
                                        ),
                                    )}
                                />
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => ({
    currentUser: state.user.currentUser,
    tenant: state.tenant?.tenant,
});

export default connect(mapStateToProps)(CannedJobServiceCard);
