import React from 'react';

interface Props {}

interface State {}

class UnpaidPaidInvoices extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }
    render() {
        return <div>Unpaid Invoices</div>;
    }
}

export default UnpaidPaidInvoices;
