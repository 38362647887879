import axios from '../helpers/axios';
import config from '../helpers/config';
import { Dispatch } from 'redux';
import actionTypes from '../helpers/actionTypes';
import InventoryItem from 'models/InventoryItemModel';

const { apiUrl } = config;

export interface GetInventoryRequest {
    searchPhrase: string;
    page: number;
    itemsPerPage: number;
}

export interface NewInventoryItemRequest {
    itemCode: string;
    description: string;
    retailPrice: number;
    cost: number;
    quantity: number;
    brand: string;
    location: string;
    comment: string;
    supplierId: number;
}

export const getInventory = (requestParams: GetInventoryRequest) => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.GET_INVENTORY_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.GET_INVENTORY_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.GET_INVENTORY_FAILURE, payload });

    dispatch(request());

    axios
        .get(`${apiUrl}/inventory`, { params: requestParams })
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};

// prettier-ignore
export const getInventoryItem = (inventoryItemId: string, withRepairOrders = false) => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.GET_INVENTORY_ITEM_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.GET_INVENTORY_ITEM_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.GET_INVENTORY_ITEM_FAILURE, payload });

    dispatch(request());

    axios
        .get(`${apiUrl}/inventory/${inventoryItemId}`, {
            params: {
                withRepairOrders: Number(withRepairOrders),
            },
        })
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};

export const updateInventoryItem = (inventoryitem: InventoryItem) => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.UPDATE_INVENTORY_ITEM_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.UPDATE_INVENTORY_ITEM_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.UPDATE_INVENTORY_ITEM_FAILURE, payload });

    dispatch(request());

    axios
        .put(`${apiUrl}/inventory`, inventoryitem)
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};

export const createInventoryItem = (newInventoryItem: NewInventoryItemRequest) => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.CREATE_INVENTORY_ITEM_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.CREATE_INVENTORY_ITEM_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.CREATE_INVENTORY_ITEM_FAILURE, payload });

    dispatch(request());

    axios
        .post(`${apiUrl}/inventory`, newInventoryItem)
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};

export const getInventoryOverview = () => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.GET_INVENTORY_OVERVIEW_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.GET_INVENTORY_OVERVIEW_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.GET_INVENTORY_OVERVIEW_FAILURE, payload });

    dispatch(request());

    axios
        .get(`${config.apiUrl}/inventory/overview`)
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};
