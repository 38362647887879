import React from 'react';
import history from 'helpers/history';
import RepairOrder from 'models/RepairOrderModel';
import TableColumn from 'models/TableColumnModel';
import { FaCheckCircle, FaEdit, FaRegTrashAlt, FaSort, FaTimesCircle } from 'react-icons/fa';

import Avatar from 'react-avatar';
import ColumnsPicker from 'components/common/ColumnsPicker';
import RelativeTime from 'components/common/RelativeTime';
import WorkflowStatus from 'models/WorkflowStatusModel';

interface Props {
    repairOrders: RepairOrder[];
    workflowStatuses: WorkflowStatus[];
}

interface State {
    isColumnsPickerOpen: boolean;
    tableColumns: any[];
    activeTabIndex: string;
    tabs: any[];
}

class JobListView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isColumnsPickerOpen: false,
            tableColumns: repairOrderListColumns,
            activeTabIndex: '99',
            tabs: [],
        };
    }

    componentDidMount() {
        this.buildTabs();
    }

    buildTabs() {
        const tabs = [
            {
                value: '99',
                name: 'All',
                count: this.props.repairOrders.length,
            },
            ...this.props.workflowStatuses.map((workflowStatus) => {
                return {
                    value: workflowStatus?.id,
                    name: workflowStatus?.name,
                };
            }),
        ];

        this.setState({ tabs });
    }

    isColumnActive(columnName: string) {
        return this.state.tableColumns.find((column) => column.name === columnName)?.active;
    }

    getTable() {
        const { tableColumns, activeTabIndex } = this.state;
        const repairOrders =
            activeTabIndex === '99'
                ? this.props.repairOrders
                : this.props.repairOrders.filter((RO) => RO.workflowStatus?.id === activeTabIndex);

        return (
            <div className="overflow-x-auto">
                <table className="items-center w-full ">
                    <thead>
                        <tr className=" text-white bg-gray-200 font-extrabold  text-left text-xs uppercase align-middle">
                            {tableColumns.map(
                                (column, index) =>
                                    column.active && (
                                        <th key={index} className={`pl-6 py-3 ${index === 0 && 'rounded-tl-md'}`}>
                                            <span className="clickable text-blue-500 font-bold ">
                                                <span>{column.text}</span>
                                                {!!column.sortable && <FaSort className="inline ml-1" />}
                                            </span>
                                        </th>
                                    ),
                            )}
                            <th className="px-6 py-3 rounded-tr-md"></th>
                        </tr>
                    </thead>

                    <tbody>
                        {!!repairOrders &&
                            repairOrders.map((RO) => (
                                <tr
                                    className={`border-b font-semibold hover:bg-blue-200 clickable bg-white border-r-2 border-l-2`}
                                    key={RO.id}
                                    onClick={() => {
                                        history.push(`/repairOrder/${RO.id}`);
                                    }}
                                >
                                    {this.isColumnActive('repairOrderNumber') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400 ">
                                            <span className="align-baseline">
                                                <span>#{RO.repairOrderNumber}</span>
                                            </span>
                                        </td>
                                    )}
                                    {this.isColumnActive('orderName') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400">
                                            <span>
                                                <span>{RO.title}</span>
                                            </span>
                                        </td>
                                    )}
                                    {this.isColumnActive('customer') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3">
                                            <div>
                                                <div>
                                                    {!!RO.vehicle ? (
                                                        <span>
                                                            {RO.vehicle?.year} {RO.vehicle?.make} {RO.vehicle?.model}
                                                        </span>
                                                    ) : (
                                                        <span className="text-gray-400 font-medium">No vehicle</span>
                                                    )}
                                                </div>
                                                <div>
                                                    {!!RO.customer ? (
                                                        <span className="font-medium">
                                                            {RO.customer?.firstName} {RO.customer?.lastName}
                                                        </span>
                                                    ) : (
                                                        <span className="text-gray-400 font-medium">No customer</span>
                                                    )}
                                                </div>
                                            </div>
                                        </td>
                                    )}
                                    {this.isColumnActive('total') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3">
                                            $
                                            {RO.services
                                                .map((service) => service.total)
                                                .reduce((a, b) => +a + +b, 0)
                                                .toFixed(2)}
                                        </td>
                                    )}
                                    {this.isColumnActive('due') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400">
                                            <span>
                                                <span>{RO.createdAt}</span>
                                            </span>
                                        </td>
                                    )}

                                    {this.isColumnActive('paidStatus') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400">
                                            {Math.random() < 0.5 ? (
                                                <span className="bg-green-500 rounded-lg text-white px-2">Paid</span>
                                            ) : (
                                                <span className="bg-yellow-500 rounded-lg text-white px-2">Unpaid</span>
                                            )}
                                        </td>
                                    )}
                                    {this.isColumnActive('workflow') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400">
                                            <span>
                                                <span>{RO.workflowStatus?.name}</span>
                                            </span>
                                        </td>
                                    )}
                                    {this.isColumnActive('inspectionStatus') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400">
                                            <span>
                                                <span>{RO.workflowStatus?.name}</span>
                                            </span>
                                        </td>
                                    )}
                                    {this.isColumnActive('orderStatus') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400">
                                            <span>
                                                <span>{}</span>
                                            </span>
                                        </td>
                                    )}
                                    {this.isColumnActive('authorizationStatus') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400">
                                            <span>
                                                <FaCheckCircle className="text-green-500" />
                                            </span>
                                        </td>
                                    )}
                                    {this.isColumnActive('technicians') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400">
                                            <span>
                                                {!!(RO?.asignees?.length > 0) &&
                                                    RO.asignees.map((asignee, index) => (
                                                        <Avatar
                                                            name={`${asignee.firstName} ${asignee.lastName}`}
                                                            round
                                                            size={'22'}
                                                            className="clickable"
                                                            key={index}
                                                        />
                                                    ))}
                                            </span>
                                        </td>
                                    )}
                                    {this.isColumnActive('dateCreated') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400">
                                            <span>
                                                <RelativeTime dateTime={RO.createdAt} />
                                            </span>
                                        </td>
                                    )}
                                    {this.isColumnActive('labels') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400">
                                            {RO.labels?.map((label, indx) => (
                                                <span
                                                    key={indx}
                                                    style={{ backgroundColor: label.color }}
                                                    className="mr-2 px-1 py-1 rounded-lg hover:opacity-80 text-xs"
                                                >
                                                    <span className="font-semibold text-white inline-flex whitespace-nowrap">
                                                        <FaTimesCircle
                                                            // onClick={() => this.removeLabel(indx)}
                                                            className="inline mr-1 clickable align-baseline text-xs"
                                                        />
                                                        {label.title}
                                                    </span>
                                                </span>
                                            ))}
                                        </td>
                                    )}

                                    <td className="px-6 align-middle whitespace-nowrap p-3 non-clickable">
                                        <FaRegTrashAlt
                                            size={15}
                                            className="inline clickable mr-1"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                // this.handleDelete(RO);
                                            }}
                                        />
                                        <FaEdit
                                            size={15}
                                            className="inline clickable"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                // onEdit(RO.id!);
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        );
    }

    render() {
        const { repairOrders } = this.props;
        const { tableColumns, activeTabIndex, tabs } = this.state;
        return (
            <div className="">
                <div className="flex my-2">
                    <div className="ml-auto">
                        <ColumnsPicker
                            columns={tableColumns}
                            onChange={(updatedColumns) => this.setState({ tableColumns: updatedColumns })}
                        />
                    </div>
                </div>
                {tabs.map((tab, index) => {
                    return (
                        <div
                            className={`inline-flex px-5 border-b-4 ${
                                activeTabIndex === tab.value
                                    ? ' border-blue-500 '
                                    : '  border-gray-500 hover:text-blue-400'
                            }`}
                            key={index}
                        >
                            <span
                                className={`clickable text-lg ${
                                    activeTabIndex === tab.value
                                        ? 'font-bold text-blue-500 border-blue-500 '
                                        : ' font-medium text-gray-400 border-gray-500 hover:text-blue-400'
                                }`}
                                onClick={() => this.setState({ activeTabIndex: tab.value })}
                            >
                                {tab.name}{' '}
                                <span className="font-bold rounded-full bg-gray-100 py-1 px-2 text-sm">
                                    {index === 0
                                        ? repairOrders.length
                                        : repairOrders.filter((RO) => RO.workflowStatus?.id === tab.value).length}
                                </span>
                            </span>
                        </div>
                    );
                })}
                {this.getTable()}

                {repairOrders?.length < 1 && (
                    <div className="py-8 flex justify-center border-b-2 border-l-2 border-r-2 rounded-b-lg bg-white">
                        <h2 className="text-lg font-semibold">You do not have any active repair orders</h2>
                    </div>
                )}
            </div>
        );
    }
}

export default JobListView;

const repairOrderListColumns: TableColumn[] = [
    {
        name: 'repairOrderNumber',
        active: true,
        text: 'RO #',
        sortable: true,
    },
    {
        name: 'orderName',
        active: true,
        text: 'Order Name',
        sortable: true,
    },
    {
        name: 'customer',
        active: true,
        text: 'Customer',
        sortable: true,
    },
    {
        name: 'total',
        active: true,
        text: 'Total',
        sortable: true,
    },
    {
        name: 'due',
        active: true,
        text: 'Due',
        sortable: true,
    },
    {
        name: 'paidStatus',
        active: true,
        text: 'Paid Status',
        sortable: true,
    },
    {
        name: 'workflow',
        active: true,
        text: 'Workflow',
        sortable: true,
    },
    {
        name: 'inspectionStatus',
        active: true,
        text: 'Inspection Status',
        sortable: true,
    },
    {
        name: 'orderStatus',
        active: true,
        text: 'Order Status',
        sortable: true,
    },

    {
        name: 'authorizationStatus',
        active: true,
        text: 'Authorization Status',
        sortable: true,
    },
    {
        name: 'technicians',
        active: true,
        text: 'Technicians',
        sortable: true,
    },
    {
        name: 'dateCreated',
        active: true,
        text: 'Date Created',
        sortable: true,
    },
    {
        name: 'labels',
        active: true,
        text: 'Labels',
        sortable: true,
    },
];
