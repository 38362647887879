import React from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa';

interface Props {
    currentPage: number;
    totalItems: number;
    pageSize: number;
    pageChanged: Function;
}

const Pagination: React.FC<Props> = ({ currentPage = 1, totalItems = 0, pageSize = 0, pageChanged }: Props) => {
    const totalPages = Math.ceil(totalItems / pageSize);

    const getPageNumbers = () => {
        const pages = [];
        for (let pageNumber = 1; pageNumber <= totalPages; pageNumber++) {
            pages.push(pageNumber);
        }
        return pages;
    };
    return (
        <div className="flex">
            <div
                className={`${
                    currentPage > 1 ? 'clickable hover:bg-blue-300 bg-white' : 'bg-gray-200'
                }  flex mr-1 rounded-full border border-gray-200 items-center w-8 h-8 justify-center font-semibold text-sm`}
                onClick={() => {
                    if (currentPage > 1) {
                        pageChanged(currentPage - 1);
                    }
                }}
            >
                <FaArrowLeft className="inline align-baseline" />
            </div>
            {getPageNumbers().map((pageNumber) => (
                <div
                    key={pageNumber}
                    className={`flex mr-1 rounded-full border-2 border-gray-200 items-center w-8 h-8 justify-center font-semibold text-sm ${
                        pageNumber === currentPage ? 'bg-blue-500 text-white' : 'bg-white clickable hover:bg-blue-300'
                    } `}
                    onClick={() => pageChanged(pageNumber)}
                >
                    {pageNumber}
                </div>
            ))}

            <div
                className={`${
                    currentPage < totalPages ? 'clickable hover:bg-blue-300 bg-white' : 'bg-gray-200'
                } flex mr-1 rounded-full border border-gray-200 items-center w-8 h-8 justify-center font-semibold text-sm`}
                onClick={() => {
                    if (currentPage !== totalPages) {
                        pageChanged(currentPage + 1);
                    }
                }}
            >
                <FaArrowRight className="inline align-baseline" />
            </div>
        </div>
    );
};

export default Pagination;
