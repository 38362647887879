import { ErrorMessage, Field } from 'formik';
import React from 'react';

interface Props {
    label?: string;
    required?: boolean;
    minLength?: number;
    maxLength?: number;
    placeholder?: string;
    onChange?: (e: React.FormEvent<HTMLInputElement>) => any;
    errorMessage?: string;
    type?: string;
    readonly?: boolean;
    value?: string;
    name?: string;
    id?: string;
    autocompleteDisabled?: boolean;
    error?: boolean;
}

const InputFieldNew: React.FC<Props> = (props: Props) => (
    <div>
        <Field
            name={props.name!}
            id={props.id}
            placeholder={props.placeholder}
            onChange={props.onChange}
            required={props.required}
            readOnly={props.readonly}
            type={props.type}
            value={props.value}
            autoComplete={props.autocompleteDisabled ? 'off' : ''}
            className={props.error ? 'error' : ''}
        />
        {props.errorMessage && <ErrorMessage name={props.name!} component="div" className="field-error" />}

        {/* <input
            placeholder={props.placeholder}
            onChange={props.onChange}
            required={props.required}
            readOnly={props.readonly}
            type={props.type}
            value={props.value}
            name={props.name}
            autoComplete={props.autocompleteDisabled ? 'off' : ''}
            className={props.error ? 'error' : ''}
        />
        <br />
        <small>{props.errorMessage}</small> */}
    </div>
);

export default InputFieldNew;
