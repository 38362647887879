import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateAdminRoute: React.SFC<any> = ({
    component: Component,
    ...rest
}: {
    component: React.ComponentType<any>;
}) => (
    <Route
        {...rest}
        render={(props) =>
            localStorage.getItem('token') ? (
                <Component {...props} />
            ) : (
                <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )
        }
    />
);

export default PrivateAdminRoute;
