import React, { useState } from 'react';
import { BsQuestionCircle } from 'react-icons/bs';
import { FaRegTimesCircle } from 'react-icons/fa';

interface Props {
    text: string;
    id: string;
}

const InfoTooltip: React.FC<Props> = ({ text, id }: Props) => {
    const [isOpen, setIsOpen] = useState(false);

    id = 'infoTooltip' + id;

    const togglePicker = (open: boolean) => {
        if (open) {
            setIsOpen(true);
            document.addEventListener('click', onClickOutside);
        } else {
            setIsOpen(false);
            document.removeEventListener('click', onClickOutside);
        }
    };

    const onClickOutside = (e: any) => {
        const target = document.querySelector(`#${id}`);
        const withinBoundaries = e.composedPath().includes(target);

        if (withinBoundaries) {
        } else {
            togglePicker(false);
        }
    };

    return (
        <div className="relative">
            <div className={`rounded-full clickable`} onClick={() => togglePicker(!isOpen)}>
                <BsQuestionCircle size={16} className={`text-blue-600  hover:text-blue-800`} />
            </div>

            {isOpen && (
                <div
                    className="font-normal absolute bottom-8 rounded-lg p-2 left-1/2 transform -translate-x-1/2  border-2 border-blue-800 shadow-2xl w-96 bg-blue-500 text-white"
                    id={id}
                >
                    <div className="flex">
                        <div>{text}</div>
                        <div className="float-right mb-4 ml-auto">
                            <FaRegTimesCircle size={16} className="clickable" onClick={() => setIsOpen(false)} />
                        </div>
                    </div>
                    <div className="ml-44">
                        <div className="inline-block absolute w-3 h-3 mt-1 transform rotate-45 bg-blue-500  border-b-2 border-r-2 border-blue-800 "></div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default InfoTooltip;
