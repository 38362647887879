import Inspection from 'models/InspectionModel';
import InspectionTaskStatus from 'models/types/InspectionTaskStatus';
import React from 'react';
import { AiFillFileAdd } from 'react-icons/ai';
import { MdAddAPhoto, MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import axios from 'helpers/axios';
import config from 'helpers/config';
import FullPhotoView from 'components/common/FullPhotoView';
import { FaCheck, FaExclamation } from 'react-icons/fa';
import { CgFileDocument } from 'react-icons/cg';
import { CiCircleInfo, CiCircleMore } from 'react-icons/ci';

import HoverTooltip from 'components/common/HoverTooltip';
import moment from 'moment';
import dateFormats from 'helpers/dateFormats';

interface Props {
    inspection: Inspection;
    ratingUpdated: (rating: InspectionTaskStatus, taskIndex: number) => void;
    handleChange: (e: any, taskIndex: number) => void;
    updateTask: (taskIndex: number) => void;
    completeInspection: () => void;
}

interface State {
    isExpanded: boolean;
    isPhotoExpanded: boolean;
    expandedPhoto?: any | null;
}

class InspectionCard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isExpanded: false,
            isPhotoExpanded: false,
        };
    }

    componentDidMount() {
        // @ts-ignore
        if (this.props.inspection?.isNew) {
            this.setState({
                isExpanded: true,
            });
        }
    }

    addPhoto(taskId: string, taskIndex: number, isPhotoInput = true) {
        const imageInput = document.createElement('input');
        imageInput.type = 'file';
        imageInput.style.display = 'none';

        if (isPhotoInput) {
            imageInput.accept = '.gif,.jpg,.jpeg,.png';
        } else {
            imageInput.accept = '.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf';
        }

        document.body.appendChild(imageInput);

        imageInput.onchange = (e) => {
            // @ts-ignore
            const file = e?.target?.files[0];

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = (_) => {
                const fileUploadRequest = [
                    {
                        dataURL: reader.result,
                        file: {
                            lastModified: file?.lastModified,
                            name: file?.name,
                            size: file?.size,
                        },
                        taskId,
                    },
                ];

                document.body.removeChild(imageInput);

                axios
                    .post(`${config.apiUrl}/file/${isPhotoInput ? 'images' : 'documents'}`, fileUploadRequest)
                    .then((_) => this.props.updateTask(taskIndex));
            };
        };

        imageInput.click();
    }

    viewFullPhoto(photo: any) {
        this.setState({
            isPhotoExpanded: true,
            expandedPhoto: photo,
        });
    }

    render() {
        const { inspection } = this.props;
        const { isExpanded, isPhotoExpanded, expandedPhoto } = this.state;
        return (
            <div className="border-2 border-gray-400 p-2 mb-4 bg-white">
                {isPhotoExpanded && (
                    <FullPhotoView
                        photoSourceURL={`${config.fileRootURL}/images/${expandedPhoto.fileName}`}
                        fileName={expandedPhoto.fileName}
                        onClose={() => this.setState({ isPhotoExpanded: false, expandedPhoto: null })}
                    />
                )}
                <div className="flex border-b-2 border-gray-500 py-4">
                    <h2 className="text-xl font-mono font-semibold">{inspection.name}</h2>

                    <div className="ml-auto">
                        <HoverTooltip
                            content={
                                <div className="bg-white p-4 rounded-lg">
                                    <div className="mb-4">
                                        <span>Created by </span>
                                        <span className="font-bold">
                                            {inspection?.createdBy?.firstName} {inspection?.createdBy?.lastName}{' '}
                                        </span>
                                        <span>{moment(inspection?.createdAt).format(dateFormats.USA_DATETIME)}</span>
                                    </div>
                                    {!!inspection?.completedBy && (
                                        <div className="mb-4">
                                            <span>Completed by </span>
                                            <span className="font-bold">
                                                {inspection?.completedBy?.firstName} {inspection?.completedBy?.lastName}{' '}
                                            </span>
                                            <span>
                                                {moment(inspection?.completionDate).format(dateFormats.USA_DATETIME)}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            }
                        >
                            <CiCircleInfo className="mr-2 clickable text-blue-700 m-1 mb-2 inline text-3xl" />
                        </HoverTooltip>
                        <HoverTooltip
                            content={
                                <div className="bg-white p-4 rounded-lg">
                                    <div className="mb-4">
                                        <span>Created by </span>
                                        <span className="font-bold">
                                            {inspection?.createdBy?.firstName} {inspection?.createdBy?.lastName}{' '}
                                        </span>
                                        <span>{moment(inspection?.createdAt).format(dateFormats.USA_DATETIME)}</span>
                                    </div>
                                    {!!inspection?.completedBy && (
                                        <div className="mb-4">
                                            <span>Completed by </span>
                                            <span className="font-bold">
                                                {inspection?.completedBy?.firstName} {inspection?.completedBy?.lastName}{' '}
                                            </span>
                                            <span>
                                                {moment(inspection?.completionDate).format(dateFormats.USA_DATETIME)}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            }
                        >
                            <CiCircleMore className="mr-2 clickable text-blue-700 m-1 mb-2 inline text-3xl"></CiCircleMore>
                        </HoverTooltip>

                        <span
                            className={`p-2 border-2 font-semibold font-mono rounded-lg  clickable ${
                                inspection.isCompleted ? 'border-gray-400 bg-green-500 text-white' : 'border-blue-500'
                            }`}
                        >
                            {inspection.isCompleted ? (
                                <span>
                                    <FaCheck className="text-white m-1 mb-2 inline" />
                                    <span>Completed</span>
                                </span>
                            ) : (
                                'In Progress'
                            )}
                        </span>
                    </div>
                    <div className="ml-4">
                        <span
                            className="inline-flex align-middle clickable text-xl"
                            onClick={() => this.setState({ isExpanded: !isExpanded })}
                        >
                            {isExpanded ? (
                                <MdOutlineKeyboardArrowUp title="Collapse" />
                            ) : (
                                <MdOutlineKeyboardArrowDown title="Expand" />
                            )}
                        </span>
                    </div>
                </div>
                {isExpanded && (
                    <div className="divide-y-2 divide-gray-200">
                        <div className="py-4 flex bg-gray-100 px-2">
                            <div className="flex">
                                <div className="p-3 border rounded-lg mr-4 bg-white">
                                    <span className="font-semibold rounded-full bg-green-200 mr-2 p-1">
                                        {
                                            inspection.tasks?.filter((task) => task.rating === InspectionTaskStatus.OK)
                                                .length
                                        }{' '}
                                    </span>
                                    Checked and OK
                                </div>
                                <div className="p-3 border rounded-lg mr-4 bg-white">
                                    <span className="font-semibold rounded-full bg-yellow-200 mr-2 p-1">
                                        {
                                            inspection.tasks?.filter(
                                                (task) => task.rating === InspectionTaskStatus.NeedsAttention,
                                            ).length
                                        }{' '}
                                    </span>
                                    May need attention
                                </div>
                                <div className="p-3 border rounded-lg mr-4 bg-white">
                                    <span className="font-semibold rounded-full bg-red-200 mr-2 p-1">
                                        {
                                            inspection.tasks?.filter(
                                                (task) => task.rating === InspectionTaskStatus.NeedsRepair,
                                            ).length
                                        }{' '}
                                    </span>
                                    Needs immediate attention
                                </div>
                                <div className="p-3 border rounded-lg mr-4 bg-white">
                                    <span className="font-semibold rounded-full bg-gray-200 mr-2 p-1">
                                        {inspection.tasks?.filter((task) => !!!task.rating).length}{' '}
                                    </span>
                                    Not Inspected
                                </div>
                            </div>
                            <div className="ml-auto flex ">
                                <button
                                    className="bg-green-400 hover:bg-green-600 rounded-none border border-black px-3 py-0  font-bold"
                                    onClick={() => {
                                        inspection.tasks?.forEach((task, index) => {
                                            if (task.rating !== InspectionTaskStatus.OK) {
                                                this.props.ratingUpdated(InspectionTaskStatus.OK, index);
                                            }
                                        });
                                    }}
                                >
                                    Pass All
                                </button>
                                <button
                                    className="ml-4  hover:bg-red-100 text-red-500 bg-white rounded-none border border-black px-3 py-0  font-bold"
                                    onClick={() => {
                                        inspection.tasks?.forEach((task, index) => {
                                            if (!!task.rating) {
                                                this.props.ratingUpdated(task.rating, index);
                                            }
                                        });
                                    }}
                                >
                                    Reset All
                                </button>
                            </div>
                        </div>
                        {inspection.tasks?.map((task, index) => {
                            return (
                                <div key={inspection.id + index} className="py-4">
                                    <div className="font-semibold">
                                        #{index + 1} {task.description}
                                    </div>
                                    <div className="flex">
                                        <textarea
                                            name="finding"
                                            className="bg-gray-50 rounded-md border w-2/5 px-2 font-medium py-2 focus:ring-1 focus:outline-none focus:border-blue-500"
                                            value={task.finding}
                                            onChange={(e) => this.props.handleChange(e, index)}
                                            onBlur={() => this.props.updateTask(index)}
                                        ></textarea>
                                        <div className="ml-10 pt-4">
                                            <div
                                                className={`rounded-full text-sm border border-green-600 inline-block mr-2 align-middle clickable ${
                                                    task.rating === InspectionTaskStatus.OK && 'bg-green-600'
                                                }`}
                                                onClick={() => this.props.ratingUpdated(InspectionTaskStatus.OK, index)}
                                            >
                                                <FaCheck className="text-white m-1" />
                                            </div>
                                            <div
                                                className={`rounded-full text-sm border border-yellow-600 inline-block mr-2 align-middle clickable ${
                                                    task.rating === InspectionTaskStatus.NeedsAttention &&
                                                    'bg-yellow-600'
                                                }`}
                                                onClick={() =>
                                                    this.props.ratingUpdated(InspectionTaskStatus.NeedsAttention, index)
                                                }
                                            >
                                                <FaExclamation className="text-white m-1" />
                                            </div>
                                            <div
                                                className={`rounded-full text-sm border border-red-600 inline-block mr-2 align-middle clickable ${
                                                    task.rating === InspectionTaskStatus.NeedsRepair && 'bg-red-600'
                                                }`}
                                                onClick={() =>
                                                    this.props.ratingUpdated(InspectionTaskStatus.NeedsRepair, index)
                                                }
                                            >
                                                <FaExclamation className="text-white m-1" />
                                            </div>
                                        </div>

                                        <div className="ml-auto text-2xl pt-4">
                                            <AiFillFileAdd
                                                className="clickable inline"
                                                onClick={() => this.addPhoto(task.id, index, false)}
                                            >
                                                <input type="file" alt="Submit" width="48" height="48"></input>
                                            </AiFillFileAdd>
                                            <MdAddAPhoto
                                                className="clickable inline"
                                                onClick={() => this.addPhoto(task.id, index)}
                                            >
                                                <input type="image" alt="Submit" width="48" height="48"></input>
                                            </MdAddAPhoto>
                                        </div>
                                    </div>
                                    <div className="flex flex-row-reverse">
                                        {task.files
                                            ?.sort((a, b) => {
                                                return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1;
                                            })
                                            .map((file) => {
                                                if (file.type === 'image') {
                                                    return (
                                                        <div key={file.id}>
                                                            <div className="ml-2 border-2 mt-4">
                                                                <img
                                                                    src={`${config.fileRootURL}/images/${file.fileName}`}
                                                                    alt={file.fileName}
                                                                    title={file.fileName}
                                                                    className="clickable inspection-item-photo-tooltip inline "
                                                                    onClick={() => this.viewFullPhoto(file)}
                                                                />
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            })}
                                    </div>
                                    <div className="flex flex-col ml-auto mt-4">
                                        {task.files
                                            ?.sort((a, b) => {
                                                return new Date(a.createdAt) > new Date(b.createdAt) ? 1 : -1;
                                            })
                                            .map((file) => {
                                                if (file.type === 'document') {
                                                    return (
                                                        <div key={file.id} className="flex flex-row-reverse mt-1">
                                                            <div className="truncate">
                                                                <a
                                                                    className="text-blue-500 font-bold text-sm hover:underline clickable whitespace-nowrap truncate"
                                                                    href={`${config.fileRootURL}/documents/${file.fileName}`}
                                                                    download={`${file.fileName}`}
                                                                    rel="noreferrer"
                                                                    title={file.fileName}
                                                                >
                                                                    {file.fileName}
                                                                </a>
                                                            </div>
                                                            <CgFileDocument className="inline mr-2" />
                                                        </div>
                                                    );
                                                }
                                            })}
                                    </div>
                                </div>
                            );
                        })}
                        <div className="flex pt-2">
                            <div>
                                <span className="button-delete link warning" onClick={() => console.log('delete')}>
                                    Delete
                                </span>
                            </div>
                            <div className="ml-auto">
                                {inspection.isCompleted ? (
                                    <button
                                        disabled
                                        className="bg-gray-400 rounded-lg border  border-gray-300 text-gray-800 px-3 py-1   font-bold"
                                        onClick={() => {
                                            this.props.completeInspection();
                                        }}
                                    >
                                        Completed
                                    </button>
                                ) : (
                                    <button
                                        className="bg-green-400 hover:bg-green-600 rounded-lg border-2 border-black px-3 py-1 uppercase  font-bold"
                                        onClick={() => {
                                            this.props.completeInspection();
                                        }}
                                    >
                                        Complete
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default InspectionCard;
