import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FaCarSide, FaEdit, FaRegTrashAlt, FaSort, FaUser } from 'react-icons/fa';

import { getCustomers, getCustomersOverview } from 'actions/customerActions';
import Pagination from 'components/common/Pagination';
import history from 'helpers/history';
import placeholders from 'helpers/placeholders';
import TableColumn from 'models/TableColumnModel';
import CustomerModal from 'components/modals/CustomerModal';
import Customer from 'models/CustomerModel';
import axios from 'helpers/axios';
import config from 'helpers/config';
import { displayConfirmationModal } from 'helpers/services/removeConfirmationService';
import ColumnsPicker from 'components/common/ColumnsPicker';
import User from 'models/UserModel';
import actionTypes from 'helpers/actionTypes';
import PhoneNumberType from 'models/types/PhoneNumberTypes';
import { GiRotaryPhone } from 'react-icons/gi';
import { MdPhoneIphone } from 'react-icons/md';
import getFullVehicleName from 'helpers/vehicleFullNameHelper';

const { apiUrl } = config;

interface Props {
    customers: Customer[];
    currentUser: User;
    customersLoading: boolean;
    customersCount: number;
    dispatch: Dispatch;
}

interface ComponentState {
    errorMessage: string | null;
    searchPhrase: string;
    page: number;
    itemsPerPage: number;
    customersCount: number;
    tableColumns: TableColumn[];
    [x: string]: any;
}

class CustomersListPage extends React.Component<Props, ComponentState> {
    constructor(props: Props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.getCustomers = this.getCustomers.bind(this);
        this.pageChanged = this.pageChanged.bind(this);
        this.modalClosed = this.modalClosed.bind(this);

        this.state = {
            errorMessage: null,
            searchPhrase: '',
            page: 1,
            itemsPerPage: 15,
            customersCount: 0,
            isCreateCustomerModalOpen: false,
            isEditCustomerModalOpen: false,
            tableColumns: customerListColumns,
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch<any>(getCustomersOverview());
        this.getCustomers();
    }

    getCustomers() {
        const { dispatch } = this.props;
        const { page, searchPhrase } = this.state;
        let { itemsPerPage } = this.state;
        if (this.state.searchPhrase?.length > 0) {
            itemsPerPage = this.props.customersCount;
        }
        dispatch<any>(getCustomers({ page, itemsPerPage, searchPhrase }));
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const { name, value } = event.currentTarget;
        this.setState(
            {
                [name]: value,
            },
            this.getCustomers,
        );
    }

    openCustomer(customerId: string) {
        history.push(`/customer/${customerId}`);
    }

    editCustomer(customerId: string) {
        this.setState({ isEditCustomerModalOpen: true, editableCustomerId: customerId });
    }

    pageChanged(page?: number) {
        if (page) {
            this.setState({ page }, () => this.getCustomers());
        }
    }

    deleteCustomer(customer: Customer) {
        displayConfirmationModal(`${customer.firstName} ${customer.lastName}`)
            .then(() => {
                axios
                    .delete(`${apiUrl}/customer/${customer.id}`)
                    .then(() => {
                        this.componentDidMount();
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            })
            .catch((_) => _);
    }

    isColumnActive(columnName: string) {
        return !!this.props.currentUser?.userSetting?.customerListColumns
            ? (JSON.parse(this.props.currentUser?.userSetting?.customerListColumns) as TableColumn[]).find(
                  (column) => column.name === columnName,
              )?.active
            : this.state.tableColumns.find((column) => column.name === columnName)?.active;
    }

    modalClosed(customerCreated?: boolean, customerEdited?: boolean) {
        this.setState({ isCreateCustomerModalOpen: false, isEditCustomerModalOpen: false, editableCustomerId: null });
        if (customerCreated || customerEdited) {
            this.componentDidMount();
        }
    }

    getCustomerModal() {
        const { isCreateCustomerModalOpen, isEditCustomerModalOpen, editableCustomerId } = this.state;
        if (isEditCustomerModalOpen && !!editableCustomerId) {
            return (
                <CustomerModal
                    customerId={editableCustomerId}
                    onClose={(customerCreated?: boolean, customerEdited?: boolean) =>
                        this.modalClosed(customerCreated, customerEdited)
                    }
                ></CustomerModal>
            );
        } else if (isCreateCustomerModalOpen) {
            return (
                <CustomerModal
                    onClose={(customerCreated?: boolean, customerEdited?: boolean) =>
                        this.modalClosed(customerCreated, customerEdited)
                    }
                ></CustomerModal>
            );
        }
    }

    updateVisibleColumns(updatedColumns: TableColumn[]) {
        const { userSetting } = this.props.currentUser;
        userSetting.customerListColumns = JSON.stringify(updatedColumns);
        const { dispatch } = this.props;
        dispatch({ type: actionTypes.UPDATE_USER_SETTINGS_SUCCESS, payload: userSetting });
        axios.put(`${config.apiUrl}/user/settings`, userSetting);
        this.setState({ tableColumns: updatedColumns });
    }

    getPhoneNumberTypeIcon(phoneNumberType: PhoneNumberType) {
        if (phoneNumberType === PhoneNumberType.Mobile) {
            return <MdPhoneIphone className="inline text-lg" />;
        } else {
            return <GiRotaryPhone className="inline text-lg" />;
        }
    }

    render() {
        const { page, itemsPerPage, isCreateCustomerModalOpen, isEditCustomerModalOpen, tableColumns } = this.state;
        const { customersCount, customers, customersLoading, currentUser } = this.props;
        return (
            <div className="list-page">
                <h2 className="list-page__title">Customer List</h2>

                {isEditCustomerModalOpen || isCreateCustomerModalOpen ? this.getCustomerModal() : null}

                <div className="search-add-row items-center mt-3">
                    <input
                        onChange={this.handleChange}
                        name="searchPhrase"
                        placeholder={placeholders.CUSTOMER_SEARCH}
                        className="bg-white placeholder  placeholder-gray-400 placeholder-font-bold rounded-xl border border-green-500 px-2 py-1 font-medium w-1/3 focus:ring-1 focus:outline-none focus:border-blue-500"
                    ></input>
                    <button
                        className="bg-green-600 hover:bg-green-700 py-1 px-5 rounded-2xl font-medium text-base ml-auto"
                        onClick={() => this.setState({ isCreateCustomerModalOpen: !isCreateCustomerModalOpen })}
                    >
                        + Add new
                    </button>
                </div>
                <div className="flex items-center my-2">
                    <div>
                        <h4 className="font-semibold my-2">{customersCount} customers found</h4>
                    </div>
                    <div className="ml-auto">
                        <ColumnsPicker
                            onChange={(updatedColumns) => this.updateVisibleColumns(updatedColumns)}
                            columns={
                                currentUser?.userSetting?.customerListColumns
                                    ? JSON.parse(currentUser.userSetting.customerListColumns)
                                    : tableColumns
                            }
                        />
                    </div>
                </div>
                {
                    <div className="overflow-x-auto">
                        <table className="items-center bg-transparent border-collapse w-full">
                            <thead>
                                <tr className=" bg-blue-500 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                                    {(currentUser?.userSetting.customerListColumns
                                        ? (JSON.parse(currentUser.userSetting.customerListColumns) as TableColumn[])
                                        : tableColumns
                                    ).map(
                                        (column, index) =>
                                            column.active && (
                                                <th
                                                    key={index}
                                                    className={`pl-6 py-3 ${index === 0 && 'rounded-tl-md'}`}
                                                >
                                                    <span className="clickable">
                                                        <span>{column.text}</span>
                                                        {!!column.sortable && <FaSort className="inline ml-1" />}
                                                    </span>
                                                </th>
                                            ),
                                    )}
                                    <th className="px-6 py-3 rounded-tr-md">Actions</th>
                                </tr>
                            </thead>

                            <tbody>
                                {!!customers &&
                                    customers.map((customer, index) => (
                                        <tr
                                            className={`border-b border-l-2 border-r-2 font-semibold hover:bg-blue-200 clickable bg-white`}
                                            key={'customer' + index}
                                            onClick={() => {
                                                history.push(`/customer/${customer.id}`);
                                            }}
                                        >
                                            {this.isColumnActive('firstName') && (
                                                <td className="px-6 align-middle whitespace-nowrap p-3">
                                                    <span>
                                                        <FaUser className="inline align-baseline mr-2 text-lg text-gray-600" />
                                                        <span>{customer.firstName}</span>
                                                    </span>
                                                    <div>
                                                        {customer?.labels?.map((label, indx) => (
                                                            <span
                                                                key={indx}
                                                                style={{ backgroundColor: label.color }}
                                                                className="mr-2 px-1 py-1 rounded-lg hover:opacity-80 text-xs"
                                                            >
                                                                <span className="font-semibold text-white inline-flex whitespace-nowrap">
                                                                    {label.title}
                                                                </span>
                                                            </span>
                                                        ))}
                                                    </div>
                                                </td>
                                            )}
                                            {this.isColumnActive('lastName') && (
                                                <td className="px-6 align-middle whitespace-nowrap p-3">
                                                    <span>{customer.lastName}</span>
                                                </td>
                                            )}
                                            {this.isColumnActive('email') && (
                                                <td className="px-6 align-middle whitespace-nowrap p-3">
                                                    <span>{customer.primaryEmail}</span>
                                                </td>
                                            )}
                                            {this.isColumnActive('phoneNumbers') && (
                                                <td className="px-6 align-middle whitespace-nowrap p-3 non-clickable">
                                                    {customer?.phoneNumbers.map((number, index) => (
                                                        <span className="inline" key={'phoneNumber' + index}>
                                                            {index ? ', ' : ''}
                                                            {this.getPhoneNumberTypeIcon(number.type)}
                                                            <a
                                                                href={`tel:${number.phoneNumber}`}
                                                                onClick={(e) => e.stopPropagation()}
                                                                key={'phoneNumberLink' + number.id}
                                                            >
                                                                {number.phoneNumber}
                                                            </a>
                                                        </span>
                                                    ))}
                                                </td>
                                            )}
                                            {this.isColumnActive('vehicles') && (
                                                <td className="px-6 align-middle whitespace-nowrap p-3 non-clickable">
                                                    {customer?.vehicles?.map((vehicle, index) => {
                                                        return !!(index < 2) ? (
                                                            <div key={`customerVehicle${index}`}>
                                                                <span
                                                                    className="align-baseline font-semibold link"
                                                                    onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        history.push(`/vehicle/${vehicle.id}`);
                                                                    }}
                                                                >
                                                                    <FaCarSide className="inline mr-2 text-lg text-gray-600" />
                                                                    <span>{getFullVehicleName(vehicle)}</span>
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            <>
                                                                {index === 2 && (
                                                                    <span
                                                                        title={customer?.vehicles
                                                                            ?.map((vehicle, index) => {
                                                                                if (index > 1) {
                                                                                    return (
                                                                                        getFullVehicleName(vehicle) +
                                                                                        '\n'
                                                                                    );
                                                                                }
                                                                                return;
                                                                            })
                                                                            .join('')}
                                                                    >
                                                                        ...
                                                                    </span>
                                                                )}
                                                            </>
                                                        );
                                                    })}
                                                </td>
                                            )}
                                            <td className="px-6 align-middle whitespace-nowrap p-3 non-clickable">
                                                <FaRegTrashAlt
                                                    size={15}
                                                    className="inline clickable mr-1"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.deleteCustomer(customer);
                                                    }}
                                                />
                                                <FaEdit
                                                    size={15}
                                                    className="inline clickable"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.editCustomer(String(customer.id));
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        {customers?.length < 1 && !customersLoading && (
                            <div className="py-8 flex justify-center border-b-2 border-l-2 border-r-2 rounded-b-lg  bg-white">
                                <h2 className="text-lg font-semibold">No customers found</h2>
                            </div>
                        )}
                    </div>
                }
                <div className="mt-4">
                    <Pagination
                        currentPage={page}
                        pageSize={itemsPerPage}
                        totalItems={customersCount}
                        pageChanged={this.pageChanged}
                    ></Pagination>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    customers: state.customer.customersList,
    customersLoading: state.customer.customersLoading,
    customersCount: state.customer.customersCount,
    currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(CustomersListPage);

const customerListColumns: TableColumn[] = [
    {
        name: 'firstName',
        active: true,
        sortable: true,
        text: 'First Name',
    },
    {
        name: 'lastName',
        active: true,
        sortable: true,
        text: 'Last Name',
    },
    {
        name: 'email',
        active: true,
        sortable: true,
        text: 'Email',
    },
    {
        name: 'phoneNumbers',
        active: true,
        sortable: false,
        text: 'Phone numbers',
    },
    {
        name: 'vehicles',
        active: true,
        sortable: true,
        text: 'Vehicles',
    },
];
