import axios from 'helpers/axios';
import config from 'helpers/config';
import VehicleModel from 'models/VehicleModel';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Divider } from 'semantic-ui-react';

interface Props {
    vehicleSelected: (vehicle: VehicleModel) => void;
    registerManuallySelected: () => void;
}

const VINSearch: React.FC<Props> = ({ vehicleSelected, registerManuallySelected }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isExistingVIN, setIsExistingVIN] = useState(false);
    const [vehicle, setVehicle] = useState<VehicleModel | null>(null);
    const [searchError, setSearchError] = useState<string | null>(null);
    const [existingVehicle, setExistingVehicle] = useState<VehicleModel | null>(null);

    const searchVIN = (VIN: string) => {
        setIsLoading(true);
        axios
            .get(`${config.apiUrl}/misc/VIN/${VIN}`)
            .then((res) => setVehicle(res.data as VehicleModel))
            .catch((err) => {
                console.log(err);
                setVehicle(null), setSearchError(err?.message);
            })
            .finally(() => setIsLoading(false));

        axios
            .get<VehicleModel[]>(`${config.apiUrl}/misc/existingVehicles/${VIN}`)
            .then((res) => {
                if (res.data[0]?.VIN?.toLowerCase() === VIN.toLowerCase()) {
                    setExistingVehicle(res.data[0]);
                    setIsExistingVIN(true);
                } else {
                    setIsExistingVIN(false);
                    setExistingVehicle(null);
                }
            })
            .catch((err) => {
                setVehicle(null), setSearchError(err?.message);
                setIsExistingVIN(false);
            })
            .finally(() => setIsLoading(false));
    };

    return (
        <div>
            <div className="text-lg font-semibold">Enter VIN or Year/Make/Model</div>
            <div>
                <input
                    onChange={(e) => searchVIN(e.target.value)}
                    type="text"
                    autoFocus
                    className="border-2 rounded-lg p-2 w-full focus:outline-none focus:border-blue-500 font-semibold text-lg uppercase"
                    id="VIN_search"
                />
            </div>
            {isExistingVIN && (
                <div className="bg-red-300 text-sm p-2 rounded-lg border-2 border-red-600 font-semibold text-red-600 mt-4">
                    Vehicle with this VIN already exists in the system: {` `}
                    <Link to={`/vehicle/${existingVehicle?.id}`} className="underline">
                        <span className="font-extrabold clickable">
                            {existingVehicle?.year} {existingVehicle?.make} {existingVehicle?.model}{' '}
                            {existingVehicle?.series}, {existingVehicle?.engine}{' '}
                            {existingVehicle?.customer && (
                                <span>
                                    owned by
                                    <span> </span>
                                    {existingVehicle?.customer?.firstName} {existingVehicle?.customer?.lastName}
                                </span>
                            )}
                        </span>
                    </Link>
                </div>
            )}
            {!!vehicle && (
                <div className="mt-2">
                    <div className="font-semibold">Match found! Click to register:</div>
                    <div
                        className="bg-white p-2 text-base border rounded-lg clickable hover:bg-blue-200"
                        onClick={() => vehicleSelected({ ...vehicle, isFromVinLookup: true })}
                    >
                        <div className="font-semibold">
                            {vehicle?.year} {vehicle?.make} {vehicle?.model} {vehicle?.series}, {vehicle?.engine}
                        </div>
                        <div className="text-gray-500">
                            VIN: {(document.getElementById('VIN_search') as HTMLInputElement).value}
                        </div>
                    </div>
                </div>
            )}
            {!!searchError && (
                <div>
                    <div className="font-semibold text-red-500">{String(searchError)}</div>
                </div>
            )}
            {isLoading && (
                <div className="h-32">
                    <div>
                        <div className="loading-spinner"></div>
                    </div>
                </div>
            )}
            <Divider horizontal>
                <span className="clickable expand-link">OR</span>
            </Divider>
            <div className="flex justify-center">
                <button
                    className="clickable rounded-lg bg-blue-500 font-semibold px-6 py-4 border-2 border-blue-800 uppercase hover:bg-blue-600"
                    onClick={registerManuallySelected}
                >
                    Register Manually
                </button>
            </div>
        </div>
    );
};

export default VINSearch;
