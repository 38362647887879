import React, { SyntheticEvent } from 'react';

import axios from 'helpers/axios';
import config from 'helpers/config';

import { Icon } from 'semantic-ui-react';
import InfoTooltip from 'components/common/InfoTooltip';
import InspectionModal from 'components/modals/InspectionModal';
import InspectionTemplate from 'models/InspectionTemplateModel';
import { displayConfirmationModal } from 'helpers/services/removeConfirmationService';

interface Props {}

interface State {
    name: string | null;
    boardOrderIndex: number;
    inspectionTemplates: InspectionTemplate[];

    isCreateInspectionModalOpen: boolean;
    isEditInspectionModalOpen: boolean;
    editableInpection?: InspectionTemplate;
}

class InspectionSettings extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            name: null,
            boardOrderIndex: 0,
            inspectionTemplates: [],

            isCreateInspectionModalOpen: false,
            isEditInspectionModalOpen: false,
        };

        this.getValue = this.getValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.modalClosed = this.modalClosed.bind(this);
    }

    componentDidMount() {
        this.getInspectionTemplates();
    }

    getInspectionTemplates() {
        axios
            .get(`${config.apiUrl}/inspection/templates`)
            .then((res) => this.setState({ inspectionTemplates: res.data }))
            .catch((_) => console.log(_));
    }

    getValue(name: string) {
        // @ts-ignore
        return this.state?.companySettings[name] || '';
    }

    handleChange(e: SyntheticEvent) {
        // @ts-ignore
        const { name, value } = e.target;
        // @ts-ignore
        this.setState({ [name]: value });
    }

    modalClosed(inspectionCreated?: boolean, inspectionUpdated?: boolean) {
        this.setState({
            isCreateInspectionModalOpen: false,
            isEditInspectionModalOpen: false,
            editableInpection: undefined,
        });
        if (inspectionCreated || inspectionUpdated) {
            this.componentDidMount();
        }
    }

    handleDelete(inspectionTemplate: InspectionTemplate) {
        displayConfirmationModal(`inspection template ${inspectionTemplate.name} `)
            .then(() => {
                axios
                    .delete(`${config.apiUrl}/inspection/templates/${inspectionTemplate.id}`)
                    .then((_) => this.componentDidMount());
            })
            .catch((_) => _);
    }

    render() {
        // prettier-ignore
        const { isCreateInspectionModalOpen, isEditInspectionModalOpen, editableInpection, inspectionTemplates } =
            this.state;
        return (
            <div className="flex flex-col items-center">
                {isCreateInspectionModalOpen && <InspectionModal onClose={this.modalClosed} />}
                {isEditInspectionModalOpen && (
                    <InspectionModal onClose={this.modalClosed} inspection={editableInpection} />
                )}
                <div className="w-full">
                    <div className="flex flex-row float-right ml-auto mb-2">
                        <div className="create-new-button ml-auto">
                            <button
                                onClick={() =>
                                    this.setState({
                                        isCreateInspectionModalOpen: true,
                                    })
                                }
                                className="font-semibold py-1 px-3 rounded-xl hover:bg-green-500 border border-green-500 text-green-500 hover:text-white"
                            >
                                + Add new
                            </button>
                        </div>
                    </div>
                    <table className="items-center w-full text-white  font-semibold text-left text-xs align-middle">
                        <thead>
                            <tr className="bg-blue-500 h-12 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                                <th className="pl-6 rounded-tl-md">Title</th>
                                <th className="pl-6">Tasks</th>
                                <th className="pl-6">Order</th>
                                <th className="pl-6 rounded-tr-md"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {inspectionTemplates.map((template, index) => (
                                <tr
                                    key={index}
                                    className={`text-black font-semibold bg-white border-b border-r-2 border-l-2 hover:bg-blue-100`}
                                >
                                    <>
                                        <td className=" px-6 min-w-max align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left flex items-center">
                                            {template.name}
                                        </td>
                                        <td className="px-6 align-middle h-5 border-l-0 border-r-0 text-sm py-0">
                                            {template.tasks?.length}
                                        </td>
                                        <td className="px-6 align-middle h-5 border-l-0 border-r-0 text-sm py-0 ">
                                            <div className="flex">
                                                <div className="ml-1">
                                                    <InfoTooltip
                                                        text="Here you can configure the order or kanban board columns"
                                                        id={template.id}
                                                    />
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-6 align-middle h-5 border-l-0 border-r-0 text-sm py-0 -clickable">
                                            <div className="item-action">
                                                <Icon
                                                    className="clickable"
                                                    name="trash alternate"
                                                    onClick={() => this.handleDelete(template)}
                                                ></Icon>
                                                <Icon
                                                    className="clickable"
                                                    name="edit"
                                                    onClick={() => {
                                                        this.setState({
                                                            editableInpection: template,
                                                            isEditInspectionModalOpen: true,
                                                        });
                                                    }}
                                                ></Icon>
                                            </div>
                                        </td>
                                    </>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {inspectionTemplates?.length < 1 && !isCreateInspectionModalOpen && (
                        <div className="py-8 flex justify-center border-b-2 border-l-2 border-r-2 rounded-b-lg bg-white w-full">
                            <h2 className="text-lg font-semibold">You have no inspection templates yet</h2>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default InspectionSettings;
