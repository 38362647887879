import Modal from 'components/common/Modal';
import axios from 'helpers/axios';
import config from 'helpers/config';
import CannedJob from 'models/CannedJobModel';
import React from 'react';
import { Divider, Form, Transition } from 'semantic-ui-react';
import CannedJobServiceCard from './CannedJobServiceCard';
import LaborRate from 'models/LaborRateModel';

interface Props {
    onClose: (cannedJobCreated?: boolean, cannedJobEdited?: boolean, cannedJobId?: string) => void;
}

interface State {
    isFormExpanded: boolean;
    activeTab: number;
    formErrors: any;
    isLoading: boolean;

    cannedJob: Partial<CannedJob>;

    title: string;
    description: string;
    notes: string;
    modalHeader: string;

    laborRates: LaborRate[];
}

class CannedJobModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isFormExpanded: false,
            activeTab: 0,
            formErrors: {},
            isLoading: false,
            title: '',
            description: '',
            notes: '',

            modalHeader: 'Add New Canned Job',
            cannedJob: {
                title: '',
                description: '',
                items: [],
            },

            laborRates: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.getValue = this.getValue.bind(this);
    }

    componentDidMount() {
        this.getLaborRates();
    }

    getLaborRates = () => {
        axios.get(`${config.apiUrl}/laborRate`).then((response) => {
            this.setState({ laborRates: response.data });
        });
    };

    save = () => {
        const { cannedJob } = this.state;
        this.setState({ isLoading: true });

        console.log(cannedJob);
        axios.post(`${config.apiUrl}/cannedJob`, cannedJob).then((response) => {
            this.setState({ isLoading: false });
            this.props.onClose(true, false);
        });
    };

    handleChange = (e: any) => {
        const { name, value } = e.target;
        const { cannedJob } = this.state;
        // @ts-ignore
        cannedJob[name] = value;
        this.setState({ cannedJob });
    };

    getValue = (name: string) => {
        return (this.state.cannedJob[name as keyof CannedJob] as string) || '';
    };

    render() {
        const { formErrors, isLoading, modalHeader, cannedJob, laborRates } = this.state;
        return (
            <Modal
                headerText={modalHeader}
                isOpen={true}
                onClose={() => this.props.onClose(false, false)}
                onSave={this.save}
                isLoading={isLoading}
                size="wide"
            >
                <div>
                    <Form size="large">
                        <Form.Group unstackable widths={2}>
                            <Form.Input
                                required
                                placeholder="Title"
                                label="Title"
                                name="title"
                                onChange={this.handleChange}
                                value={this.getValue('title')}
                                error={formErrors.title}
                            />
                            <Form.Input
                                required={true}
                                placeholder="Description"
                                label="Description"
                                name="description"
                                onChange={this.handleChange}
                                value={this.getValue('description')}
                                error={formErrors.description}
                            />
                        </Form.Group>
                        <Form.Group unstackable widths={2}></Form.Group>
                        <CannedJobServiceCard
                            cannedJob={cannedJob as CannedJob}
                            cannedJobUpdated={(updatedJob: CannedJob) => {
                                this.setState({ cannedJob: updatedJob });
                            }}
                            serviceDeleted={() => {}}
                            laborRates={laborRates}
                            fees={[]}
                        />
                    </Form>
                </div>
            </Modal>
        );
    }
}

export default CannedJobModal;
