import axios from 'helpers/axios';
import config from 'helpers/config';
import Tenant from 'models/TenantModel';
import React from 'react';

interface Props {}

interface State {
    tenant?: Tenant;
}

class TenantSettingsAdministration extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        // @ts-ignore
        const { tenantId } = this.props.match.params;
        axios.get(`${config.apiUrl}/admin/tenant/${tenantId}`).then((res) => this.setState({ tenant: res.data }));
    }

    render() {
        const { tenant } = this.state;
        return (
            <div>
                {!!tenant && (
                    <div className="p-12">
                        <div className="text-2xl">
                            <span>Tenant: </span>
                            <span className="font-bold">{tenant.businessName}</span>
                        </div>

                        <div className="mt-4">
                            <div className="font-semibold text-lg text-gray-700">Users: </div>
                            {tenant?.users.map((user) => {
                                return (
                                    <div className="font-semibold" key={user.id}>
                                        <div>
                                            <span>
                                                {user.firstName} {user.lastName}
                                            </span>
                                            {user.isAdmin && <span className="font-bold text-blue-800"> - Admin</span>}{' '}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export default TenantSettingsAdministration;
