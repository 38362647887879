import Modal from 'components/common/Modal';
import InternalChat from 'components/common/comments/InternalChat';
import axios from 'helpers/axios';
import config from 'helpers/config';
import InternalChatSubject from 'models/types/InternalChatSubjectType';
import React, { useEffect, useState } from 'react';

interface Props {
    chatId: string | null;
    subject: InternalChatSubject | null;
    subjectId: string | null;
    onClose: () => void;
}
const InternalChatModal = (props: Props) => {
    const [chatId, setChatId] = useState<string | null>(props.chatId);

    useEffect(() => {
        if (chatId === null) {
            axios
                .post(`${config.apiUrl}/chat/chat`, {
                    chatSubject: props.subject,
                    subjectId: props.subjectId,
                })
                .then((response) => {
                    setChatId(response.data.chatId);
                });
        }
    }, []);

    return (
        <Modal isOpen={true} onClose={props.onClose} headerText={'Comments'}>
            {chatId && <InternalChat chatId={chatId} />}
        </Modal>
    );
};

export default InternalChatModal;
