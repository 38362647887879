import React from 'react';

import RelativeTime from 'components/common/RelativeTime';
import Customer from 'models/CustomerModel';
import Price from 'components/common/Price';

interface Props {
    customer: Customer;
}

const CustomerStats: React.FC<Props> = ({ customer }: Props) => {
    return (
        <div className="border rounded-lg p-4 divide-y-2 divide-blue-500 border-black">
            {!!customer && !!customer.stats && (
                <>
                    <h2 className="uppercase text-lg font-semibold">Quick look</h2>
                    <div>
                        <span className="inline-block py-4 text-sm">
                            <b>{customer?.fullName}</b> has visited <b>{customer.stats.completedRepairOrdersCount}</b>{' '}
                            times* spending{' '}
                            <span className="text-green-500 font-semibold">
                                <Price price={customer.stats.lifetimeSpend} />
                            </span>
                        </span>
                        <div className="grid grid-cols-4">
                            <div className="mr-16">
                                <div className="text-gray">Last visit</div>
                                <div className="font-semibold text-lg float-left">
                                    <RelativeTime dateTime={customer.stats.lastRoDate!} />
                                </div>
                            </div>

                            <div className="mr-16">
                                <div className="text-gray">Lifetime Spend</div>
                                <div className="font-semibold text-lg">
                                    <Price price={customer.stats.lifetimeSpend!} />
                                </div>
                            </div>
                            <div className="mr-16">
                                <div className="text-gray">Lifetime ARO</div>
                                <div className="font-semibold text-lg">
                                    <Price price={customer.stats.lifetimeARO!} />
                                </div>
                            </div>
                            <div className="mr-16">
                                <div className="text-gray">Lifetime Profit</div>
                                <div className="font-semibold text-lg">
                                    <Price price={customer.stats.lifetimeProfit!} />
                                </div>
                            </div>
                            <div className="mr-16">
                                <div className="text-gray">Lifetime ARO Profit</div>
                                <div className="font-semibold text-lg">
                                    <Price price={customer.stats.lifetimeAROProfit!} />
                                </div>
                            </div>
                            {!!customer.stats.lastCompletedROTotal && (
                                <div className="mr-16">
                                    <div className="text-gray">Lifetime Archived RO Total</div>
                                    <div className="font-semibold text-lg">
                                        <Price price={customer.stats.lastCompletedROTotal} />
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="text-sm font-bold text-gray-500 pt-4">
                            * Does not include active Repair Orders.
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default CustomerStats;
