import React from 'react';

import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import Booking from 'models/BookingModel';
import { FaCalendar } from 'react-icons/fa';
import AppointmentModal from 'components/modals/AppointmentModal';
import axios from 'helpers/axios';
import config from 'helpers/config';
import BookingModel from 'models/BookingModel';
require('react-big-calendar/lib/css/react-big-calendar.css');

const localizer = momentLocalizer(moment);

interface Props {}

interface State {
    isBookingModalOpen: boolean;

    isCreateAppointmentModalOpen: boolean;
    isEditAppointmentModalOpen: boolean;
    editableAppointmentId: string | null;
    appointments: BookingModel[];
}

class CalendarPageV2 extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isBookingModalOpen: false,
            isCreateAppointmentModalOpen: false,
            isEditAppointmentModalOpen: false,
            editableAppointmentId: null,
            appointments: [],
        };

        this.modalClosed = this.modalClosed.bind(this);
    }

    componentDidMount() {
        axios.get(`${config.apiUrl}/appointment/`).then((res) => {
            this.setState({ appointments: res.data });
        });

        // @ts-ignore
        const openNewAppointmentModal = this.props.history.location.state?.openNewAppointmentModal;
        if (openNewAppointmentModal) {
            setTimeout(() => this.openBookingModal(), 333);
        }
    }

    eventStyleGetter() {
        const style = {
            backgroundColor: 'red',
            borderRadius: '0px',
            opacity: 0.8,
            border: '0px',
        };
        return {
            style: style,
        };
    }

    openBookingModal(booking?: Booking) {
        if (!booking) {
            this.setState({
                isBookingModalOpen: true,
            });
        }
    }

    modalClosed(appointmentCreated?: boolean, appointmentEdited?: boolean) {
        this.setState({
            isCreateAppointmentModalOpen: false,
            isEditAppointmentModalOpen: false,
            editableAppointmentId: null,
            isBookingModalOpen: false,
        });
        if (appointmentCreated || appointmentEdited) {
            this.componentDidMount();
        }
    }

    getCalendarEvents(appointments: BookingModel[]): any[] {
        return appointments.map((appointment) => {
            return {
                title: (
                    <div className="font-bold">
                        <div>{appointment?.description || 'Appointment'}</div>
                        <div className="text-sm my-0 py-0">
                            {appointment.customer?.firstName} {appointment.customer?.lastName}
                        </div>
                        <div className="text-sm my-0 py-0">
                            {`${appointment?.vehicle?.year ? `${appointment?.vehicle.year} ` : ''}${
                                appointment?.vehicle?.make
                            } ${appointment?.vehicle?.model}`}
                        </div>
                    </div>
                ),
                start: new Date(appointment?.startDate!),
                end: new Date(appointment?.endDate!),
                onselect: (_: any) => {
                    this.openBookingModal(appointment);
                },
                id: appointment.id,
            };
        });
    }

    render() {
        const { isBookingModalOpen, appointments } = this.state;
        return (
            <div className="page w-11/12 pb-96 overflow-y-visible">
                {isBookingModalOpen && <AppointmentModal appointmentId={undefined} onClose={this.modalClosed} />}
                <div className="flex mb-2">
                    <div>
                        <h2 className="list-page__title">Calendar</h2>
                    </div>
                    <button
                        className="font-semibold py-2 px-3 rounded-xl hover:bg-green-500 border border-green-500
                            text-green-500 hover:text-white ml-auto
                            "
                        onClick={() => this.openBookingModal()}
                    >
                        <FaCalendar className="inline mr-2 align-baseline" />
                        <span>New Appointment</span>
                    </button>
                </div>
                <Calendar
                    localizer={localizer}
                    events={this.getCalendarEvents(appointments)}
                    startAccessor="start"
                    endAccessor="end"
                    style={{ height: 500 }}
                    onSelectEvent={(e) => e.onselect()}
                    onSelectSlot={(e) => {
                        console.log(e);
                    }}
                    onSelecting={(e: any) => {
                        console.log(e);
                        e.onselect();
                        return false;
                    }}
                    selectable={true}
                    // eventPropGetter={this.eventStyleGetter}
                />
            </div>
        );
    }
}

export default CalendarPageV2;
