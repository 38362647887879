import React from 'react';
import ImageUploading, { ImageType } from 'react-images-uploading';
import Modal from './Modal';
import { Icon } from 'semantic-ui-react';
import axios from '../../helpers/axios';
import config from '../../helpers/config';
import FileModel from '../../models/FileModel';
import { displayConfirmationModal } from '../../helpers/services/removeConfirmationService';

interface Props {
    onClose: (photosAdded: boolean) => void;
    relatedEntityTypeName: string;
    relatedEntityId?: string;

    existingImages?: FileModel[];
}

interface State {
    newImages: ImageType[];
    existingImages?: FileModel[];
}

class PhotoManagementModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            newImages: [],
            existingImages: props.existingImages,
        };

        this.handleChange = this.handleChange.bind(this);
        this.saveFiles = this.saveFiles.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleChange(images: any) {
        this.setState({ newImages: images });
    }

    saveFiles() {
        const { relatedEntityTypeName, relatedEntityId, onClose } = this.props;
        const files = this.state.newImages.map((image) => {
            return {
                dataURL: image.dataURL,
                file: {
                    lastModified: image.file?.lastModified,
                    name: image.file?.name,
                    size: image.file?.size,
                },
                [`${relatedEntityTypeName}Id`]: relatedEntityId,
            };
        });

        if (files.length > 0) {
            axios.post(`${config.apiUrl}/file/images`, files).then(() => onClose(true));
        } else {
            onClose(false);
        }
    }

    handleClose() {
        if (this.state.newImages.length > 0) {
            displayConfirmationModal()
                .then(() => {
                    this.props.onClose(false);
                })
                .catch();
        } else {
            this.props.onClose(false);
        }
    }

    handleDelete(image: ImageType, isNewImage: boolean, index?: number) {
        displayConfirmationModal('photo')
            .then(() => {
                if (!isNewImage) {
                    axios
                        .delete(`${config.apiUrl}/file/${image.id}`)
                        .then(() => {
                            this.setState((prevState) => ({
                                existingImages: prevState.existingImages?.filter(
                                    (existingImage) => existingImage.id !== image.id,
                                ),
                            }));
                        })
                        .catch();
                } else {
                    this.setState((prevState) => ({
                        newImages: prevState.newImages?.filter((_, newImageIndex) => newImageIndex !== index),
                    }));
                }
            })
            .catch();
    }

    render() {
        const { existingImages, newImages: images } = this.state;

        return (
            <>
                <Modal
                    headerText={'Manage images'}
                    isOpen={true}
                    onClose={this.handleClose}
                    onSave={this.saveFiles}
                    size="wide"
                >
                    <ImageUploading multiple onChange={this.handleChange} value={images}>
                        {({ imageList, onImageUpload, dragProps }) => (
                            <div className="upload__image-wrapper">
                                <div className="image-upload__dropzone" onClick={onImageUpload} {...dragProps}>
                                    <div className="upload-icon">
                                        <Icon name="upload" />
                                    </div>
                                    <span>
                                        Drop your image here or <b className="link">browse</b>
                                    </span>
                                </div>

                                {imageList.map((image, index) => (
                                    <div key={index} className="image-item image-modal">
                                        <img
                                            src={image.dataURL}
                                            alt={image.file?.name}
                                            title={image.file?.name}
                                            width="66%"
                                        />
                                        <span>
                                            <small onClick={() => this.handleDelete(image, true, index)}>
                                                <span className="link warning">Delete</span>
                                            </small>
                                        </span>
                                    </div>
                                ))}
                                {existingImages?.map((image, index) => (
                                    <div key={index} className="image-item image-modal">
                                        <img
                                            src={`${config.fileRootURL}/images/${image.fileName}`}
                                            alt={image.fileName}
                                            title={image.fileName}
                                            width="66%"
                                        />
                                        <span>
                                            <small onClick={() => this.handleDelete(image, false)}>
                                                <span className="link warning">Delete</span>
                                            </small>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        )}
                    </ImageUploading>
                </Modal>
            </>
        );
    }
}

export default PhotoManagementModal;
