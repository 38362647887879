import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Avatar from 'react-avatar';

import axios from 'helpers/axios';
import config from 'helpers/config';
import actionTypes from 'helpers/actionTypes';
import { displayConfirmationModal } from 'helpers/services/removeConfirmationService';
import { calculateFeeAmount } from 'helpers/services/priceCalculationService';

import User from 'models/UserModel';
import Service from 'models/ServiceModel';
import ServiceItemType from 'models/types/ServiceItemType';
import ServiceItem from 'models/ServiceItemModel';
import Label from 'models/LabelModel';

import UserPicker from 'components/common/UserPicker';
import AuthorizationStatus from '../AuthorizationStatus';
import { getActivityLog } from 'actions/repairOrderActions';
import LaborRate from 'models/LaborRateModel';
import HoverTooltip from 'components/common/HoverTooltip';
import Fee from 'models/FeeModel';
import Price from 'components/common/Price';
import Tenant from 'models/TenantModel';
import FeeType from 'models/types/FeeType';
import ServiceCardItemRow from './ServiceCardItemRow';
import FormStateType from 'models/generic/FormState';

interface Props {
    service: Service;
    currentUser: User;
    tenant: Tenant;
    serviceDeleted: Function;
    repairOrderId?: string;
    laborRates: LaborRate[];
    fees: Fee[];
    dispatch: Dispatch;
}

interface State {
    service: Service;
    isServiceLoading: boolean;
    isAuthorizationPickerOpen: boolean;
    isLoading: boolean;

    editableServiceItemIndex?: number;
}

class ServiceCard extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            service: props.service,
            isServiceLoading: false,
            isAuthorizationPickerOpen: false,
            isLoading: false,
        };
        this.addServiceItem = this.addServiceItem.bind(this);
        this.createService = this.createService.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updateService = this.updateService.bind(this);
        this.updateProperty = this.updateProperty.bind(this);
        this.saveService = this.saveService.bind(this);
        this.deleteService = this.deleteService.bind(this);
        this.refreshCard = this.refreshCard.bind(this);
        this.updateLabels = this.updateLabels.bind(this);
        this.updateAsignees = this.updateAsignees.bind(this);
        this.removeLabel = this.removeLabel.bind(this);
        this.handleServiceItemDelete = this.handleServiceItemDelete.bind(this);
        this.saveServiceItem = this.saveServiceItem.bind(this);
    }

    refreshCard() {
        this.setState({ isLoading: true }, () =>
            axios
                .get(`${config.apiUrl}/service/${this.state.service.id}`)
                .then((res) => {
                    this.setState({ service: res.data, isLoading: false });
                    this.props.dispatch({
                        type: actionTypes.UPDATE_REPAIR_ORDER_SERVICE_SUCCESS,
                        payload: res.data,
                    });
                })
                .catch((_) => {
                    this.setState({ isLoading: false });
                }),
        );
    }

    addServiceItem(type: ServiceItemType) {
        if (this.state.isLoading) return;

        const newItem: ServiceItem = {} as ServiceItem;
        newItem.serviceId = this.state.service.id;
        switch (type) {
            case ServiceItemType.Part: {
                newItem.type = ServiceItemType.Part;
                newItem.priceCents = 0;
                newItem.quantity = 1;
                newItem.totalCents = 0;
                break;
            }
            case ServiceItemType.Labor: {
                const defaultRate = this.props.laborRates.find((rate) => rate.isDefault);
                newItem.type = ServiceItemType.Labor;
                newItem.quantity = 1;
                newItem.laborRateId = defaultRate?.id;
                newItem.priceCents = defaultRate?.rateCents || 0;
                newItem.totalCents = defaultRate?.rate! * 100 || 0;
                break;
            }
            case ServiceItemType.Fee: {
                newItem.type = ServiceItemType.Fee;
                newItem.priceCents = 0;
                newItem.quantity = 1;
                newItem.totalCents = 0;
                break;
            }
        }
        newItem.isNew = true;

        const { service } = this.state;
        service.items = [...service.items, newItem];

        // axios
        //     .post(`${config.apiUrl}/serviceItem`, newItem)
        //     .then((_) => {
        //         this.props.dispatch({
        //             type: actionTypes.UPDATE_REPAIR_ORDER_SERVICE_SUCCESS,
        //             payload: service,
        //         });
        //         // this.refreshCard();
        //     })
        //     .catch((_) => _);

        this.setState({ service });
        if (type === ServiceItemType.Part) {
            this.setState({ editableServiceItemIndex: service.items.length - 1 }, () => {});
        }
    }

    saveService() {
        if (!!this.state.service.id) {
            this.updateService();
        } else {
            this.createService();
        }
    }

    saveServiceItem(serviceItem: ServiceItem) {
        const isNew = serviceItem.id === undefined;
        if (isNew) {
            axios
                .post(`${config.apiUrl}/serviceItem`, serviceItem)
                .then((_) => {
                    this.refreshCard();
                })
                .catch((_) => _);
        } else {
            // this.updateServiceItem(serviceItem);
        }
    }

    updateLabels(labels: Label[], serviceItemIndex: number) {
        const service = this.state.service;
        service.items[serviceItemIndex].labels = labels;
        this.setState({ service }, () => this.updateService());
    }

    updateAsignees(users: User[], serviceItemIndex: number) {
        const service = this.state.service;
        if (users?.length > 0) {
            service.items[serviceItemIndex].asignee = users[0];
            service.items[serviceItemIndex].asigneeId = users[0].id;
        } else {
            service.items[serviceItemIndex].asignee = null;
            service.items[serviceItemIndex].asigneeId = null;
        }
        this.setState({ service }, () => this.updateService());
    }

    deleteService() {
        displayConfirmationModal('service')
            .then(() => {
                const { id } = this.state.service;
                const { serviceDeleted, dispatch, repairOrderId } = this.props;
                if (!!id) {
                    axios
                        .delete(`${config.apiUrl}/service/${id}`)
                        .then(() => {
                            dispatch<any>(getActivityLog(repairOrderId!));
                            this.props.dispatch({
                                type: actionTypes.DELETE_REPAIR_ORDER_SERVICE_SUCCESS,
                                payload: id,
                            });

                            serviceDeleted();
                        })
                        .catch((_) => _);
                } else {
                    serviceDeleted();
                }
            })
            .catch((_) => _);
    }

    createService() {
        const { service, isServiceLoading } = this.state;
        this.setState({ isServiceLoading: !isServiceLoading });
        service.repairOrderId = this.props.repairOrderId!;
        axios
            .post(`${config.apiUrl}/repairOrder/service/${service.repairOrderId}`, service)
            .then((res) => {
                const { dispatch } = this.props;
                dispatch<any>(getActivityLog(service.repairOrderId));
                this.setState({ service: { ...service, id: res.data.id } });
            })
            .catch((_) => _);
    }

    updateService() {
        const { service, isServiceLoading } = this.state;
        this.setState({ isServiceLoading: !isServiceLoading });
        service.repairOrderId = this.props.repairOrderId!;
        axios
            .put(`${config.apiUrl}/service`, service)
            .then((_) => {
                this.props.dispatch({
                    type: actionTypes.UPDATE_REPAIR_ORDER_SERVICE_SUCCESS,
                    payload: service,
                });
                this.refreshCard();
            })
            .catch((_) => _);
    }

    handleChange(event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>, autoUpdate = false) {
        const { name, value } = event.currentTarget;

        const updatedService: Service = { ...this.state.service, [name]: value };
        this.setState({ service: updatedService }, () => {
            if (autoUpdate) this.updateService();
        });
    }

    updateProperty(name: string, value: any, autoUpdate = true) {
        const updatedService: Service = { ...this.state.service, [name]: value };
        this.setState({ service: updatedService }, () => {
            if (autoUpdate) this.updateService();
        });
    }

    removeLabel(serviceItemId: string, labelIndex: number) {
        const service: Service = JSON.parse(JSON.stringify(this.state.service));
        const index = service.items.findIndex((item) => item.id === serviceItemId);
        service.items[index].labels.splice(labelIndex, 1);
        this.setState({ service }, this.updateService);
    }

    handleServiceItemDelete(serviceItemId: string) {
        displayConfirmationModal('Service Item')
            .then(() => {
                axios.delete(`${config.apiUrl}/service/serviceItem/${serviceItemId}`).then(() => {
                    const service: Service = JSON.parse(JSON.stringify(this.state.service));
                    const index = service.items.findIndex((item) => item.id === serviceItemId);
                    service.items.splice(index, 1);
                    this.setState({ service });
                    this.props.dispatch({
                        type: actionTypes.UPDATE_REPAIR_ORDER_SERVICE_SUCCESS,
                        payload: service,
                    });
                    this.refreshCard();
                });
            })
            .catch((_) => _);
    }

    openAuthorizationPicker() {
        this.setState({ isAuthorizationPickerOpen: !this.state.isAuthorizationPickerOpen });
    }

    getDisctinctAsignees() {
        const allASignees = this.state.service?.items
            ?.map((service) => service.asignee)
            .filter((asingee) => asingee !== null);
        // @ts-ignore
        return allASignees?.filter(
            (asignee, index, self) => self.findIndex((item) => item?.id === asignee?.id) === index,
        );
    }

    render() {
        const { service } = this.state;
        const { laborRates, fees } = this.props;
        return (
            <div className="bg-white my-3 border border-gray-400 rounded-md p-2 divide-y-2 divide-gray-200 overflow-y-visible">
                <div>
                    <div className="flex">
                        <input
                            className="border-none outline-none w-1/3 placeholder-gray-400 font-bold pb-3 inline"
                            placeholder="Type in the name for this service"
                            name="title"
                            value={service.title}
                            onChange={this.handleChange}
                            onBlur={() => this.saveService()}
                            autoFocus={false}
                        />
                        <div className="ml-auto float-right flex">
                            <div className="inline flex mr-6">
                                <div className="font-semibold text-gray-500">Tech: </div>
                                {!!service.asigneeId && (
                                    <Avatar
                                        name={`${service.asignee?.firstName} ${service.asignee?.lastName}`}
                                        round
                                        size={'22'}
                                        className="clickable mr-2"
                                    />
                                )}
                                <UserPicker
                                    usersSelected={(users: User[]) => this.updateAsignees(users, 1)}
                                    userIds={[service.asigneeId!]}
                                    allowMultiple={false}
                                />
                            </div>
                            <span className="inline">
                                <HoverTooltip
                                    content={
                                        <div className="bg-white p-4 rounded-lg">
                                            <div className="mb-4">
                                                This service is{' '}
                                                {service.isRecommended ? (
                                                    <span className="font-bold">recommended.</span>
                                                ) : (
                                                    <span>
                                                        <span className="font-bold">not</span> marked as{' '}
                                                        <span className="font-bold">recommended</span>.
                                                    </span>
                                                )}
                                            </div>
                                            <div>
                                                <div>
                                                    Recommended services are not included in RO total unless authorized
                                                    by client.
                                                </div>
                                                <div>All service are not authorized by default.</div>
                                            </div>
                                        </div>
                                    }
                                >
                                    <span
                                        className={`font-bold clickable border-2 mr-6 text-xs p-1 rounded-lg  align-top ${
                                            service.isRecommended
                                                ? 'text-green-500 border-green-500'
                                                : 'text-gray-600 border-gray-600'
                                        }`}
                                        onClick={() => this.updateProperty('isRecommended', !service.isRecommended)}
                                    >
                                        R
                                    </span>
                                </HoverTooltip>
                            </span>
                            <AuthorizationStatus
                                currentStatus={service.authorizationStatus}
                                className="float-right"
                                onChange={(e: any) => this.handleChange(e, true)}
                                iconWithText
                            />
                        </div>
                    </div>
                    <div className="text-sm text-blue-700 font-semibold clickable pb-3 flex">
                        {this.getDisctinctAsignees()?.map((asignee) => {
                            return (
                                <div key={asignee?.id} className="mr-2">
                                    {asignee?.firstName} {asignee?.lastName}
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="py-2">
                    <input
                        className="border-none outline-none w-1/3 placeholder-gray-400 text-base font-bold"
                        placeholder="Add Note..."
                        name="note"
                        value={service.note || undefined}
                        onChange={this.handleChange}
                        onBlur={() => this.saveService()}
                    />
                </div>
                <div className="py-3">
                    <div className="flex flex-row text-base py-1">
                        <span className="font-semibold mr-4">Add:</span>
                        {[
                            { name: 'Part', type: ServiceItemType.Part },
                            { name: 'Labor', type: ServiceItemType.Labor },
                            { name: 'Fee', type: ServiceItemType.Fee },
                        ].map((itemType, index) => (
                            <span
                                key={index}
                                onClick={() => this.addServiceItem(itemType.type)}
                                className="bg-green-100 rounded-md text-base font-bold px-2 mx-2 clickable uppercase hover:bg-green-600"
                            >
                                {itemType.name}
                            </span>
                        ))}
                    </div>
                    <div className="overflow-x-auto overflow-y-visible">
                        <table className="items-center bg-transparent border-collapse w-full">
                            <thead>
                                <tr className="bg-blue-600 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                                    <th className="pl-6 py-3 pr-64">Labor</th>
                                    <th className="px-6 py-3">Asignee</th>
                                    <th className="px-6 py-3">Rate</th>
                                    <th className="px-6 py-3">Hours</th>
                                    <th className="px-6 py-3">Status</th>
                                    <th className="px-6 py-3">Subtotal</th>
                                    <th className="px-6 py-3"></th>
                                </tr>
                            </thead>

                            <tbody>
                                {service.items
                                    ?.filter((item) => item.type === ServiceItemType.Labor)
                                    ?.map((item, idx) => (
                                        <ServiceCardItemRow
                                            item={item}
                                            index={idx}
                                            fees={fees}
                                            laborRates={laborRates}
                                            removeLabel={this.removeLabel}
                                            handleServiceItemDelete={this.handleServiceItemDelete}
                                            updateLabels={this.updateLabels}
                                            key={'labor' + idx}
                                            updateAsignees={this.updateAsignees}
                                            saveServiceItem={this.saveServiceItem}
                                            state={item.isNew ? FormStateType.Create : FormStateType.Readonly}
                                        />
                                    ))}
                                {service.items?.filter((item) => item.type === ServiceItemType.Labor).length === 0 && (
                                    <tr className="bg-gray-400 text-white">
                                        <td colSpan={7} className="text-center">
                                            No labor added
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        <table className="items-center bg-transparent border-collapse w-full">
                            <thead>
                                <tr className="bg-blue-600 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                                    <th className="pl-6 pr-64 py-3">Part</th>
                                    <th className="px-6 py-3">Price</th>
                                    <th className="px-6 py-3">Quantity</th>
                                    <th className="px-6 py-3">Status</th>
                                    <th className="px-6 py-3">Subtotal</th>
                                    <th className="px-6 py-3 "></th>
                                </tr>
                            </thead>

                            <tbody>
                                {service.items
                                    ?.filter((item) => item.type === ServiceItemType.Part)
                                    ?.map((item, idx) => (
                                        <ServiceCardItemRow
                                            item={item}
                                            index={idx}
                                            fees={fees}
                                            laborRates={laborRates}
                                            removeLabel={this.removeLabel}
                                            handleServiceItemDelete={this.handleServiceItemDelete}
                                            updateLabels={this.updateLabels}
                                            key={'part' + idx}
                                            updateAsignees={this.updateAsignees}
                                            state={item.isNew ? FormStateType.Create : FormStateType.Readonly}
                                            saveServiceItem={this.saveServiceItem}
                                        />
                                    ))}

                                {service.items?.filter((item) => item.type === ServiceItemType.Part).length === 0 && (
                                    <tr className="bg-gray-400 text-white">
                                        <td colSpan={7} className="text-center">
                                            No parts added
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>

                        {!!service.items?.filter((item) => item.type === ServiceItemType.Fee).length && (
                            <table className="items-center bg-transparent border-collapse w-full">
                                <thead>
                                    <tr className="bg-blue-600 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                                        <th className="pl-6 pr-64 py-3">Fee</th>
                                        <th className="px-6 py-3">Price</th>
                                        <th className="px-6 py-3">Quantity</th>
                                        <th className="px-6 py-3">Status</th>
                                        <th className="px-6 py-3">Subtotal</th>
                                        <th className="px-6 py-3 "></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {service.items
                                        ?.filter((item) => item.type === ServiceItemType.Fee)
                                        ?.map((item, idx) => (
                                            <ServiceCardItemRow
                                                item={item}
                                                index={idx}
                                                fees={fees}
                                                laborRates={laborRates}
                                                removeLabel={this.removeLabel}
                                                handleServiceItemDelete={this.handleServiceItemDelete}
                                                updateLabels={this.updateLabels}
                                                key={'part' + idx}
                                                updateAsignees={this.updateAsignees}
                                                state={item.isNew ? FormStateType.Create : FormStateType.Readonly}
                                                saveServiceItem={this.saveServiceItem}
                                            />
                                        ))}
                                </tbody>
                            </table>
                        )}
                    </div>
                </div>

                <div className="flex pt-2">
                    <button className="bg-none rounded-2xl px-2 text-red-600" onClick={() => this.deleteService()}>
                        Delete
                    </button>
                    <div className="ml-auto flex mr-4">
                        <div className="flex mr-4">
                            <div className="mr-1.5 font-semibold">Service Subtotal: </div>
                            <span className="text-green-700 font-bold">
                                <Price price={service.totalCents / 100} />
                            </span>
                        </div>
                        {fees
                            .filter((fee) => fee.isActive)
                            .map((fee, indx) => {
                                return (
                                    <div className="flex mr-4" key={indx}>
                                        <div className="mr-1.5 font-semibold">
                                            {fee.name}
                                            {fee.type === FeeType.Percentage && ` (${fee.amount}%)`}:
                                        </div>
                                        <span className="text-green-700 font-bold">
                                            <Price price={Number(calculateFeeAmount(fee, service, laborRates))} />
                                        </span>
                                    </div>
                                );
                            })}
                        <div className="flex">
                            <div className="mr-1.5 font-semibold">Service Total: </div>
                            <span className="text-green-700 font-bold">
                                <Price price={service.grandTotalCents / 100} />
                            </span>
                        </div>
                    </div>
                    <button
                        className="bg-green-600 px-2 rounded-2xl font-medium text-base"
                        onClick={() => this.saveService()}
                    >
                        Save
                    </button>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state: any) => ({
    currentUser: state.user.currentUser,
    tenant: state.tenant?.tenant,
});

export default connect(mapStateToProps)(ServiceCard);
