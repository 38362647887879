import React from 'react';
import moment from 'moment';

import history from 'helpers/history';
import RepairOrder from 'models/RepairOrderModel';

import RelativeTime from 'components/common/RelativeTime';
import UserFullName from 'components/common/UserFullName';
import { getServiceTotal } from 'helpers/services/priceCalculationService';
import LaborRate from 'models/LaborRateModel';
import Price from 'components/common/Price';

interface Props {
    repairOrders: RepairOrder[];
    laborRates: LaborRate[];
}

const VehicleRepairOrders: React.FC<Props> = ({ repairOrders, laborRates }: Props) => {
    const getRoTotal = (RO: RepairOrder) => {
        const { services } = RO;
        const grandTotal = Number(
            // Does not include tax
            services
                ?.map((service) => getServiceTotal(service, laborRates))
                .reduce((a, b) => +a + +b, 0)
                .toFixed(2),
        );
        return grandTotal;
    };

    return (
        <div className="w-10/12 mt-4 overflow-x-auto">
            <table className="items-center bg-transparent border w-full">
                <thead className="border">
                    <tr className=" bg-blue-600 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                        <th className="pl-6 py-3 rounded-tl-xl">RO #</th>
                        <th className="pl-6 py-3 ">Title</th>
                        <th className="px-6 py-3">Odometer</th>
                        <th className="px-6 py-3">Services Count</th>
                        <th className="px-6 py-3">Date Created</th>
                        <th className="px-6 py-3">Created By</th>
                        <th className="px-6 py-3">Total</th>
                        <th className="px-6 py-3">Due</th>
                        <th className="px-6 py-3">Workflow Status</th>
                        <th className="px-6 py-3">Order Status</th>
                        <th className="px-6 py-3 rounded-tr-xl">Labels</th>
                    </tr>
                </thead>

                <tbody>
                    {repairOrders?.map((repairOrder, index) => (
                        <tr
                            className={`border group font-medium hover:bg-blue-200 clickable ${
                                index % 2 === 0 ? 'bg-gray-100' : 'bg-blue-100'
                            }`}
                            key={index}
                            onClick={() => history.push(`/repairOrder/${repairOrder.id}`)}
                        >
                            <td className="px-6 min-w-max align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2 text-left flex items-center">
                                <span className="font-semibold text-gray-500">#{repairOrder.repairOrderNumber}</span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span title={repairOrder.title}>{repairOrder.title}</span>
                            </td>

                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>
                                    {repairOrder?.odometerIn}{' '}
                                    {repairOrder?.odometerOut && `- ${repairOrder.odometerOut}`}{' '}
                                </span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>{repairOrder.services?.length}</span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span className="float-left">
                                    <RelativeTime dateTime={repairOrder.createdAt} />
                                </span>
                                <br />
                                <span>
                                    <span className="text-sm">
                                        {moment(repairOrder.createdAt).format('MM/DD/YYYY')}
                                    </span>
                                </span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                {!!repairOrder?.createdById && <UserFullName userId={repairOrder?.createdById} />}
                            </td>

                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <Price price={getRoTotal(repairOrder)} />
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                {!!repairOrder.dueDate ? (
                                    <>
                                        <span className="float-left">
                                            <RelativeTime dateTime={repairOrder.dueDate} />
                                        </span>
                                        <br />
                                        <span>
                                            <span className="text-sm">
                                                {moment(repairOrder.dueDate).format('MM/DD/YYYY HH:mm')}
                                            </span>
                                        </span>
                                    </>
                                ) : (
                                    <span>-/-</span>
                                )}
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>{repairOrder.workflowStatus?.name}</span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>{repairOrder?.status === 1 ? 'Invoice' : 'Estimate'}</span>
                            </td>

                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                {repairOrder.labels?.map((label, indx) => (
                                    <div
                                        key={indx}
                                        style={{ backgroundColor: label.color }}
                                        className="mr-2 px-1 py-1 rounded-lg hover:opacity-80 text-xs"
                                    >
                                        <span className="font-semibold text-white inline-flex whitespace-nowrap">
                                            {label.title}
                                        </span>
                                    </div>
                                ))}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {Number(repairOrders?.length) < 1 && (
                <div className="border py-4 px-4 wb-white text-gray-600 text-lg font-semibold text-center">
                    This vehicle has no repair orders yet
                </div>
            )}
        </div>
    );
};

export default VehicleRepairOrders;
