import RelativeTime from 'components/common/RelativeTime';
import UserFullName from 'components/common/UserFullName';
import LoanerBookingModel from 'models/LoanerBookingModel';
import moment from 'moment';
import React from 'react';

interface Props {
    bookings: LoanerBookingModel[];
}

interface State {}

class LoanerBookingsTab extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        const { bookings } = this.props;
        return (
            <div className="mt-4 overflow-x-auto">
                <table className="items-center bg-transparent border w-full">
                    <thead className="border">
                        <tr className=" bg-blue-600 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                            <th className="pl-6 py-3 rounded-tl-xl">RO #</th>
                            <th className="px-6 py-3">Title</th>
                            <th className="px-6 py-3">Vehicle</th>
                            <th className="px-6 py-3">Customer</th>
                            <th className="px-6 py-3">Services Count</th>
                            <th className="px-6 py-3">Date Created</th>
                            <th className="px-6 py-3">Created By</th>
                            <th className="px-6 py-3">Total</th>
                            <th className="px-6 py-3">Due</th>
                            <th className="px-6 py-3">Workflow Status</th>
                            <th className="px-6 py-3">Order Status</th>
                            <th className="px-6 py-3 rounded-tr-xl">Labels</th>
                        </tr>
                    </thead>

                    <tbody>
                        {bookings?.map((booking, index) => (
                            <tr
                                className={`border group font-medium hover:bg-blue-200 clickable ${
                                    index % 2 === 0 ? 'bg-gray-100' : 'bg-blue-100'
                                }`}
                                key={index}
                            >
                                <td className="px-6 min-w-max align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2 text-left flex items-center">
                                    <span className="font-semibold text-gray-500">
                                        #{booking.repairorder?.repairOrderNumber}
                                    </span>
                                </td>
                                <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                    {/* <span>{booking.} </span> */}
                                    idk
                                </td>
                                <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                    {/* <span>{getRepairOrderVehicleName(booking.)} </span> */}
                                    sneed
                                </td>
                                <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                    <span>
                                        {booking.customer
                                            ? booking.customer.firstName + booking.customer.lastName
                                            : 'N/A'}
                                    </span>
                                </td>

                                <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                    {/* <span>{booking.services?.length}</span> */}
                                </td>
                                <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                    <span className="float-left">
                                        <RelativeTime dateTime={booking.createdAt} />
                                    </span>
                                    <br />
                                    <span>
                                        <span className="text-sm">
                                            {moment(booking.createdAt).format('MM/DD/YYYY HH:mm')}
                                        </span>
                                    </span>
                                </td>
                                <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                    {!!booking?.createdById && <UserFullName userId={booking?.createdById} />}
                                </td>

                                <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                    $
                                    {/* {booking.services
                                        .map((service) => service.total)
                                        .reduce((a, b) => +a + +b, 0)
                                        .toFixed(2)} */}
                                </td>
                                <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                    {/* <span>{booking.title}</span> */}
                                </td>
                                <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                    {/* {booking.isArchived ? (
                                        <span className="bg-green-500 rounded-lg p-1.5 text-white font-semibold text-base">
                                            <span>
                                                <FaArchive className="inline mr-1" />
                                            </span>
                                            <span>Archived</span>
                                        </span>
                                    ) : (
                                        <span>{booking.workflowStatus?.name}</span>
                                    )} */}
                                </td>
                                <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                    {/* <span>{booking?.status === 1 ? 'Invoice' : 'Estimate'}</span> */}
                                </td>

                                <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                    {/* {booking.labels?.map((label, indx) => (
                                        <div
                                            key={indx}
                                            style={{ backgroundColor: label.color }}
                                            className="mr-2 px-1 py-1 rounded-lg hover:opacity-80 text-xs"
                                        >
                                            <span className="font-semibold text-white inline-flex whitespace-nowrap">
                                                {label.title}
                                            </span>
                                        </div>
                                    ))} */}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {bookings?.length! < 1 && (
                    <div className="border py-4 px-4 wb-white text-gray-600 text-lg font-semibold text-center">
                        No booking made on this loaner car yet.
                    </div>
                )}
            </div>
        );
    }
}

export default LoanerBookingsTab;
