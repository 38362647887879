import React from 'react';

import CustomerModel from 'models/CustomerModel';
import Modal from 'components/common/Modal';
import CustomerSearch from 'components/common/CustomerSearch';
import { FaUser } from 'react-icons/fa';
import Customer from 'models/CustomerModel';

interface Props {
    onClose: (customer?: Customer) => void;
}

interface ComponentState {
    modalHeader: string;

    customerId: string | undefined;
    customer: Customer | undefined;

    isFormExpanded: boolean;
}

class CustomerPickerModal extends React.Component<Props, ComponentState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            modalHeader: '',
            customerId: undefined,
            customer: undefined,

            isFormExpanded: false,
        };

        this.getValue = this.getValue.bind(this);
        this.handleCustomerSelected = this.handleCustomerSelected.bind(this);
    }

    componentDidMount() {
        this.setState({ modalHeader: 'Start a new chat' });
    }

    getValue(name: string) {
        // @ts-ignore
        return this.state.appointment[name] || '';
    }

    handleCustomerSelected(customer?: CustomerModel) {
        if (customer) {
            this.setState({ customerId: customer.id, customer });
        } else {
            this.setState({
                customerId: undefined,
            });
        }
    }

    render() {
        // prettier-ignore
        const {
            modalHeader,
            customerId,
            customer
        } = this.state;
        return (
            <Modal
                // onDelete={modalState === ModalStateType.Edit ? this.delete : undefined}
                headerText={modalHeader}
                isOpen={true}
                onClose={() => this.props.onClose()}
                isBackgroundWhite={true}
                isWithFooter={false}
            >
                <div className="w-full bg-white">
                    <div className="pt-10 pb-96">
                        <div className="items-baseline flex font-semibold text-sm uppercase">
                            <FaUser className="inline mr-1.5" />
                            <h3>Select a customer:</h3>
                        </div>

                        <CustomerSearch
                            customerSelected={this.handleCustomerSelected}
                            displayDetailsWhenSelected={true}
                        />
                        {!!!customerId && (
                            <div>
                                <span className="font-semibold text-gray-600">No customer found.</span>
                            </div>
                        )}
                    </div>
                    {!!customerId && (
                        <div className="mt-auto">
                            <div
                                className="py-2 px-6 rounded-lg bg-blue-500 hover:bg-blue-600 text-white font-semibold text-center clickable"
                                onClick={() => this.props.onClose(customer)}
                            >
                                Chat
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        );
    }
}

export default CustomerPickerModal;
