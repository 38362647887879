import actionTypes from '../../helpers/actionTypes';
import InventoryItem from '../../models/InventoryItemModel';
import InventoryOverviewModel from '../../models/InventoryOverviewModel';

class initialState {
    inventoryList!: InventoryItem[];
    inventoryListLoading!: boolean;
    inventoryItem!: InventoryItem;
    inventoryItemLoading!: boolean;
    newInventoryItemLoading!: boolean;
    newInventoryItem!: InventoryItem;
    inventoryOverview!: InventoryOverviewModel;
    inventoryItemUpdating!: boolean;
}

const inventoryReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case actionTypes.GET_INVENTORY_REQUEST:
            return {
                ...state,
                inventoryListLoading: true,
            };
        case actionTypes.GET_INVENTORY_SUCCESS:
            return {
                ...state,
                inventoryList: action.payload,
                inventoryListLoading: false,
            };
        case actionTypes.GET_INVENTORY_ITEM_REQUEST:
            return {
                ...state,
                inventoryItemLoading: true,
            };
        case actionTypes.GET_INVENTORY_ITEM_SUCCESS:
            return {
                ...state,
                inventoryItem: action.payload,
                inventoryItemLoading: false,
                newinventoryItem: action.payload,
            };
        case actionTypes.CREATE_INVENTORY_ITEM_REQUEST:
            return {
                ...state,
                newinventoryItemLoading: true,
            };
        case actionTypes.CREATE_INVENTORY_ITEM_SUCCESS:
            return {
                ...state,
                newinventoryItem: action.payload,
                newinventoryItemLoading: false,
            };
        case actionTypes.GET_INVENTORY_OVERVIEW_SUCCESS:
            return {
                ...state,
                inventoryOverview: action.payload,
            };
        case actionTypes.UPDATE_INVENTORY_ITEM_REQUEST:
            return {
                ...state,
                inventoryItemUpdating: true,
            };
        case actionTypes.UPDATE_INVENTORY_ITEM_SUCCESS:
            return {
                ...state,
                inventoryItem: action.payload,
                inventoryItemUpdating: false,
            };
        default: {
            return state;
        }
    }
};

export default inventoryReducer;
