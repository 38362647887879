import React, { useState } from 'react';
import DateTimeInput from 'components/common/DateTimeInput';
import Modal from 'components/common/Modal';
import ClockIn from 'models/ClockIn';
import config from 'helpers/config';
import axios from 'helpers/axios';
import moment from 'moment';

interface Props {
    timeLog: ClockIn;
    handleClose: () => void;
}

const TimeLogModal = ({ timeLog, handleClose }: Props) => {
    // Use useState to create local state for timeLog
    const [localTimeLog, setLocalTimeLog] = useState<ClockIn>(timeLog);
    // const [isInvalidDateTime, setIsInvalidDateTime] = useState(false);

    // Function to update the local timeLog
    const handleTimeChange = (newValue: any, property: 'clockInTime' | 'clockOutTime' | 'description') => {
        if (newValue === 'Invalid date') {
            setLocalTimeLog((prevTimeLog) => ({
                ...prevTimeLog,
            }));
        } else {
            setLocalTimeLog((prevTimeLog) => ({
                ...prevTimeLog,
                [property]: newValue,
            }));
        }
    };

    // Function to update the timeLog in the database
    const handleUpdate = () => {
        axios
            .put(`${config.apiUrl}/timeLog`, localTimeLog)
            .then(handleClose)
            .catch((_) => console.log(_));
    };

    return (
        <Modal
            isOpen={true}
            onClose={handleClose}
            headerText={'Edit Clock In'}
            // onDelete={modalState === ModalStateType.Edit ? this.delete : undefined}
            onSave={handleUpdate}
            isSaveDisabled={moment(localTimeLog?.clockOutTime).isBefore(localTimeLog?.clockInTime)}
        >
            <div>
                <div>
                    <div className="font-semibold text-gray-600">Clock In:</div>
                    <DateTimeInput
                        handleChange={(newValue) => handleTimeChange(newValue, 'clockInTime')}
                        dateTime={localTimeLog?.clockInTime}
                        id={localTimeLog.id + 'in'}
                        isInvalid={moment(localTimeLog?.clockOutTime).isBefore(localTimeLog?.clockInTime)}
                    ></DateTimeInput>
                </div>

                <div>
                    <div className="font-semibold text-gray-600">Clock Out:</div>

                    <DateTimeInput
                        handleChange={(newValue) => handleTimeChange(newValue, 'clockOutTime')}
                        dateTime={localTimeLog?.clockOutTime}
                        id={localTimeLog.id + 'out'}
                        isInvalid={moment(localTimeLog?.clockOutTime).isBefore(localTimeLog?.clockInTime)}
                    ></DateTimeInput>
                    {moment(localTimeLog?.clockOutTime).isBefore(localTimeLog?.clockInTime) && (
                        <div className="text-red-700 text-sm font-bold">Clock Out must be after Clock In</div>
                    )}
                </div>
                <div>
                    <div className="font-semibold text-gray-600">Comment:</div>
                    <textarea
                        className="rounded-md border border-gray-300 focus:outline-none focus:border-blue-500 bg-gray-100 px-4 py-2 w-full"
                        placeholder="Comment"
                        onChange={(e) => handleTimeChange(e.target.value, 'description')}
                        value={localTimeLog?.description || ''}
                        rows={4}
                    />
                </div>
            </div>
        </Modal>
    );
};

export default TimeLogModal;
