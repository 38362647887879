import React, { SyntheticEvent } from 'react';
import Label from 'models/LabelModel';
import axios from 'helpers/axios';
import config from 'helpers/config';
import { Icon } from 'semantic-ui-react';
import { displayConfirmationModal } from 'helpers/services/removeConfirmationService';

interface Props {
    labelType: { title: string; value: string };
}

interface State {
    labels: Label[];
    isCreateNewLabelActive: boolean;
    title: string | null;
    color: string | null;
    editableLabelId: string | null;
}

class LabelGroupList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            labels: [],
            isCreateNewLabelActive: false,
            title: null,
            color: null,
            editableLabelId: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.createNewLabel = this.createNewLabel.bind(this);
        this.updateLabel = this.updateLabel.bind(this);
    }

    componentDidMount() {
        axios
            .get(`${config.apiUrl}/label/${this.props.labelType.value}`)
            .then((res) => this.setState({ labels: res.data }));
    }

    createNewLabel() {
        const { title, color } = this.state;
        const label = {
            subject: this.props.labelType.value,
            title,
            color: !!color ? color : '#000',
        };
        axios
            .post(`${config.apiUrl}/label/`, label)
            .then((_) =>
                this.setState({ title: null, color: null, isCreateNewLabelActive: false }, () =>
                    this.componentDidMount(),
                ),
            );
    }

    updateLabel() {
        const { title, color, editableLabelId } = this.state;
        const label = {
            title,
            color: !!color ? color : '#000',
            id: editableLabelId,
        };
        axios
            .put(`${config.apiUrl}/label`, label)
            .then((_) =>
                this.setState({ title: null, color: null, editableLabelId: null }, () => this.componentDidMount()),
            );
    }

    handleChange(e: SyntheticEvent) {
        // @ts-ignore
        const { name, value } = e.target;
        // @ts-ignore
        this.setState({ [name]: value });
    }

    editLabel(e: Event, label: Label) {
        this.setState({
            editableLabelId: label.id,
            color: label.color,
            title: label.title,
            isCreateNewLabelActive: false,
        });
    }

    getEditableRow(isEdit: boolean) {
        return (
            <>
                <td className="table-cell">
                    <input
                        type="text"
                        name="title"
                        className="bg-gray-50 rounded-md border w-full px-2 font-medium py-2 focus:ring-1 focus:outline-none focus:border-blue-500"
                        onChange={this.handleChange}
                        value={this.state.title!}
                        autoFocus
                    ></input>
                </td>
                <td className="table-cell">
                    <input type="color" name="color" onChange={this.handleChange} value={this.state.color!}></input>
                </td>
                <td className="table-cell">
                    <div className="item-action">
                        <button
                            onClick={() =>
                                this.setState({
                                    editableLabelId: null,
                                    isCreateNewLabelActive: false,
                                    color: null,
                                    title: null,
                                })
                            }
                            className="bg-gray-100 rounded-lg font-semibold text-lg text-gray-700 px-2 border"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={isEdit ? this.updateLabel : this.createNewLabel}
                            className="bg-green-500 rounded-lg font-semibold text-lg text-white px-2 border"
                        >
                            Save
                        </button>
                    </div>
                </td>
            </>
        );
    }

    handleDelete(label: Label) {
        displayConfirmationModal(`label: "${label.title}"`)
            .then(() => {
                axios.delete(`${config.apiUrl}/label/${label.id}`).then(() => {
                    this.componentDidMount();
                    this.setState({ title: null, color: null, editableLabelId: null }, () => this.componentDidMount());
                });
            })
            .catch((_) => _);
    }

    render() {
        const { labels, isCreateNewLabelActive, editableLabelId } = this.state;
        return (
            <div className="label__group pb-10 border-b-2 border-gray-500 border-dashed">
                <div className="label__group__header">
                    <h4 className="text-lg font-semibold">{this.props.labelType.title}</h4>
                    <div className="create-new-button">
                        <button
                            onClick={() =>
                                this.setState({
                                    isCreateNewLabelActive: !isCreateNewLabelActive,
                                    editableLabelId: null,
                                    title: null,
                                    color: null,
                                })
                            }
                            className="bg-green-600 py-1 px-2 rounded-2xl font-medium text-base ml-auto"
                        >
                            + Add new
                        </button>
                    </div>
                </div>

                <table className="items-center w-full text-white  font-semibold text-left text-xs align-middle">
                    <thead>
                        <tr className="bg-blue-500 h-12 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                            <th className="pr-64 pl-6 rounded-tl-md">Text</th>
                            <th className="pl-6 pr-64">Color</th>
                            <th className="pl-6 pr-64 rounded-tr-md"></th>
                        </tr>
                    </thead>
                    <tbody>
                        {labels.map((label, index) => (
                            <tr
                                key={index}
                                className={`text-black font-medium bg-white border-b border-r-2 border-l-2 hover:bg-blue-100`}
                            >
                                {label.id === editableLabelId ? (
                                    this.getEditableRow(true)
                                ) : (
                                    <>
                                        <td className=" px-6 min-w-max align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left flex items-center">
                                            {label.title}
                                        </td>
                                        <td className="px-6 align-middle h-5 border-l-0 border-r-0 text-sm  py-0 ">
                                            <div
                                                style={{ backgroundColor: label.color }}
                                                className="h-2/5 rounded-lg w-1/2 bg-black"
                                            ></div>
                                        </td>
                                        <td className="px-6 align-middle h-5 border-l-0 border-r-0 text-sm  py-0 -clickable">
                                            <div className="item-action">
                                                <Icon
                                                    className="clickable"
                                                    name="trash alternate"
                                                    onClick={() => this.handleDelete(label)}
                                                ></Icon>
                                                <Icon
                                                    className="clickable"
                                                    name="edit"
                                                    onClick={(e: Event) => this.editLabel(e, label)}
                                                ></Icon>
                                            </div>
                                        </td>
                                    </>
                                )}
                            </tr>
                        ))}
                        {isCreateNewLabelActive && (
                            <tr className="text-black font-medium bg-white border-b border-r-2 border-l-2">
                                {this.getEditableRow(false)}
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        );
    }
}

export default LabelGroupList;
