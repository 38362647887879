import RepairOrder from 'models/RepairOrderModel';
import React from 'react';

import InternalChat from 'components/common/comments/InternalChat';

interface Props {
    repairOrder: RepairOrder;
}

interface State {}

class MessagesTab extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div className="mt-4 bg-white p-4 rounded-xl border">
                <InternalChat chatId={this.props.repairOrder.internalChatId} repairOrder={this.props.repairOrder} />
            </div>
        );
    }
}

export default MessagesTab;
