import Modal from 'components/common/Modal';
import Toggle from 'components/common/Toggle';
import React from 'react';
import { FaSms } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';

interface Props {
    onClose: (estimateSent?: boolean) => void;
}

interface State {
    isLoading: boolean;
    isSmsSelected: boolean;
    isEmailSelected: boolean;
    isCollectPaymentsSelected: boolean;
    isRequestAuthorizationSelected: boolean;
    isDisplayMessagesSelected: boolean;
}

class SendEstimateModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: true,
            isSmsSelected: true,
            isEmailSelected: true,
            isCollectPaymentsSelected: true,
            isRequestAuthorizationSelected: true,
            isDisplayMessagesSelected: true,
        };

        this.save = this.save.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.setState({ isLoading: false });
    }

    save() {
        console.log('sending estimate');
    }

    handleChange(event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) {
        const { name, value } = event.currentTarget;

        // @ts-ignore
        this.setState({ [name]: value });
    }

    render() {
        const {
            isLoading,
            isEmailSelected,
            isSmsSelected,
            isCollectPaymentsSelected,
            isRequestAuthorizationSelected,
            isDisplayMessagesSelected,
        } = this.state;
        return (
            <Modal
                headerText={'Send Estimate'}
                isOpen={true}
                onClose={() => this.props.onClose(false)}
                onSave={this.save}
                isLoading={isLoading}
                isBackgroundWhite={true}
            >
                <div className="w-full bg-white">
                    <div className="flex">
                        <div className="w-3/5 border-gray-300 border-r-2">
                            <h2 className="font-semibold mb-1.5 text-blue-500">Method Of Contact</h2>

                            <div className="flex">
                                <div
                                    className={`p-2 w-1/2 mx-1 rounded-lg text-blue-600  text-2xl clickable border-blue-500 ${
                                        isSmsSelected ? 'bg-blue-200 border-2' : 'hover:bg-blue-100 border'
                                    }`}
                                    onClick={() => this.setState({ isSmsSelected: !isSmsSelected })}
                                >
                                    <FaSms className="inline mr-2 align-baseline" />
                                    <span>SMS</span>
                                </div>
                                <div
                                    className={`p-2 w-1/2 mx-1 rounded-lg text-blue-600  text-2xl clickable border-blue-500 ${
                                        isEmailSelected ? 'bg-blue-200 border-2' : 'hover:bg-blue-100 border'
                                    }`}
                                    onClick={() => this.setState({ isEmailSelected: !isEmailSelected })}
                                >
                                    <HiOutlineMail className="inline mr-2 align-baseline" />
                                    <span>Email</span>
                                </div>
                            </div>
                        </div>

                        <div className="mt-10 pl-2">
                            <div className="mb-4">
                                <Toggle
                                    onChange={(newValue) => this.setState({ isCollectPaymentsSelected: newValue })}
                                    value={isCollectPaymentsSelected}
                                    name="collectPayments"
                                    label="Collect Payments"
                                />
                            </div>
                            <div className="mb-4">
                                <Toggle
                                    onChange={(newValue) => this.setState({ isRequestAuthorizationSelected: newValue })}
                                    value={isRequestAuthorizationSelected}
                                    name="requestAuthorization"
                                    label="Request Authorization"
                                />
                            </div>
                            <div className="mb-4">
                                <Toggle
                                    onChange={(newValue) => this.setState({ isDisplayMessagesSelected: newValue })}
                                    value={isDisplayMessagesSelected}
                                    name="displayMessages"
                                    label="Display Messages"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default SendEstimateModal;
