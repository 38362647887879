import React from 'react';

import { Button } from 'semantic-ui-react';
import actionTypes from '../actionTypes';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import store from '../../store/store';

interface Props {
    isOpen: boolean;
    dispatch: Dispatch;
}

let itemToDelete: string | undefined;
let isDeleteSelected: boolean;

class DeleteConfirmationGlobal extends React.Component<Props, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal(confirm: boolean) {
        isDeleteSelected = confirm;
        this.props.dispatch({ type: actionTypes.CLOSE_DELETE_CONFIRMATION, payload: {} });
    }

    render() {
        const { isOpen } = this.props;
        return isOpen ? (
            <div className="remove-confirmation-modal">
                <div className="remove-confirmation-modal__body">
                    <h2>Delete Confirmation</h2>
                    <div>Are you sure you want to delete {itemToDelete ? <b>{itemToDelete}</b> : 'this item'}?</div>
                    <div className="modal-buttons">
                        <div className="button-cancel">
                            <Button color="grey" onClick={() => this.closeModal(false)}>
                                Cancel
                            </Button>
                        </div>
                        <div className="button-delete">
                            <Button color="red" onClick={() => this.closeModal(true)}>
                                Delete
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        ) : null;
    }
}

const mapStateToProps = (state: any) => ({
    isOpen: state.misc.isDeleteConfirmationModalOpen,
});

export default connect(mapStateToProps)(DeleteConfirmationGlobal);

export const displayConfirmationModal = (itemToDeleteName?: string) =>
    new Promise<any>(async (resolve, reject) => {
        {
            itemToDelete = itemToDeleteName;
            const listener = () => {
                if (!store.getState().misc.isDeleteConfirmationModalOpen) {
                    isDeleteSelected ? resolve({}) : reject({});
                }
            };

            store.dispatch({ type: actionTypes.OPEN_DELETE_CONFIRMATION, payload: {} });
            setTimeout(() => {
                store.subscribe(listener);
            }, 500);
        }
    });
