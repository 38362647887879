import React from 'react';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import TableColumn from 'models/TableColumnModel';

interface Props {
    columns: TableColumn[];
    onChange: (columns: TableColumn[]) => void;
}

interface State {
    isColumnsPickerOpen: boolean;
    disableLastColumns: boolean;
}

class ColumnsPicker extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isColumnsPickerOpen: false,
            disableLastColumns: false,
        };

        this.toggleColumnsPicker = this.toggleColumnsPicker.bind(this);
        this.onClickOutside = this.onClickOutside.bind(this);
    }

    toggleColumnsPicker(open: boolean, e?: React.MouseEvent) {
        if (e) {
            e.stopPropagation();
        }
        if (open) {
            this.setState({ isColumnsPickerOpen: true });
            document.addEventListener('click', this.onClickOutside);
        } else {
            this.setState({ isColumnsPickerOpen: false });
            document.removeEventListener('click', this.onClickOutside);
        }
    }

    onClickOutside(e: any) {
        const target = document.querySelector(`#columnpicker`);
        const withinBoundaries = e.composedPath().includes(target);

        if (withinBoundaries) {
        } else {
            this.toggleColumnsPicker(false);
        }
    }

    render() {
        const { isColumnsPickerOpen, disableLastColumns } = this.state;
        const { columns } = this.props;
        return (
            <div className="relative">
                <div
                    className={`clickable border border-gray-300 rounded-lg py-1 px-4 font-medium bg-gray-100 ${
                        !!isColumnsPickerOpen && 'border-blue-500'
                    }`}
                    onClick={(e) => this.toggleColumnsPicker(!isColumnsPickerOpen, e)}
                >
                    <span className="mr-1 align-middle">Columns</span>
                    <span className="inline-flex align-middle">
                        {isColumnsPickerOpen ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}
                    </span>
                </div>

                {!!isColumnsPickerOpen && (
                    <div
                        className="absolute bg-white border border-gray-500  right-0 w-max z-50 clickable rounded mt-2 shadow-lg"
                        id={`columnpicker`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        {columns.map((column, index) => (
                            <div
                                className="px-3 py-2 clickable hover:bg-blue-50 border-b border-gray-400 align-middle"
                                key={index}
                                onClick={() => {
                                    if (disableLastColumns && column.active) {
                                        return;
                                    }
                                    columns[index].active = !column.active;
                                    this.setState({
                                        disableLastColumns: columns.filter((col) => col.active).length === 1,
                                    });
                                    this.props.onChange(columns);
                                }}
                            >
                                <input
                                    className="mr-2 h-4 w-4 rounded-lg"
                                    checked={column.active}
                                    type="checkbox"
                                    name={column.name}
                                    id={column.name}
                                    disabled={disableLastColumns && column.active}
                                />
                                <span className="mb-1 font-semibold text-blue-400">{column.text}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

export default ColumnsPicker;
