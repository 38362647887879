import React from 'react';
import { Icon } from 'semantic-ui-react';
import Customer from 'models/CustomerModel';
import VehicleModel from 'models/VehicleModel';
import VehicleModal from 'components/modals/vehicle/VehicleModal';
import axios from 'helpers/axios';
import config from 'helpers/config';
import { FaCar } from 'react-icons/fa';
import { Link } from 'react-router-dom';
// import { ImNewTab } from 'react-icons/im';

interface Props {
    customer?: Customer;
    selectedVehicle?: VehicleModel;
    vehicleSelected: Function;
}

interface State {
    isNewVehicleFormOpen: boolean;
    selectedVehicle?: VehicleModel;
    isCreateVehicleModalOpen: boolean;
    isVinCopiedTooltipOpen: boolean;
    [x: string]: any;
}

class VehicleSection extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isCreateVehicleModalOpen: false,
            isNewVehicleFormOpen: false,
            isVinCopiedTooltipOpen: false,
        };

        this.vehicleSelected = this.vehicleSelected.bind(this);
        this.modalClosed = this.modalClosed.bind(this);
    }

    getVehiclesList() {
        const { vehicles } = this.props.customer!;
        return (
            <>
                <table className="items-center bg-transparent border w-full">
                    <thead className="border">
                        <tr className="bg-gray-400 text-white border font-semibold text-left text-base uppercase align-middle">
                            <th className="pl-6 py-3">Make</th>
                            <th className="px-6 py-3">Model</th>
                            <th className="px-6 py-3">Year</th>
                            <th className="px-6 py-3">License Plate</th>
                        </tr>
                    </thead>

                    <tbody>
                        {vehicles?.map((vehicle, index) => (
                            <tr
                                className={`border group font-semibold hover:bg-blue-200 clickable ${
                                    index % 2 === 0 ? '' : 'bg-blue-50'
                                }`}
                                key={index}
                                onClick={() => this.vehicleSelected(vehicle)}
                            >
                                <td className="px-6 min-w-max align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-1 text-left flex items-center">
                                    <span>{vehicle.make}</span>
                                </td>
                                <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-1">
                                    <span>{vehicle.model}</span>
                                </td>
                                <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-1">
                                    <span>{vehicle.year}</span>
                                </td>
                                <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-1">
                                    <span>{vehicle.licensePlate}</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {vehicles.length < 1 && (
                    <div className="border py-4 px-4 wb-white text-gray-600 text-lg font-semibold text-center">
                        This customer has no registered vehicles
                    </div>
                )}
            </>
        );
    }

    vehicleSelected(vehicle: VehicleModel) {
        this.setState({ selectedVehicle: vehicle });
        this.props.vehicleSelected(vehicle.id);
    }

    getVehicleOverview(vehicle: VehicleModel) {
        const { isVinCopiedTooltipOpen } = this.state;
        return (
            <div className="grid grid-cols-2 gap-x-4">
                {vehicle?.licensePlate && (
                    <div>
                        License plate: <b>{vehicle.licensePlate}</b>
                    </div>
                )}
                {vehicle?.VIN && (
                    <div>
                        <div className="flex">
                            <div>
                                VIN:{' '}
                                {isVinCopiedTooltipOpen ? (
                                    <>
                                        <b className="uppercase">{vehicle.VIN}</b>
                                        <input value={vehicle.VIN} id="vinSelect" className="hidden" />
                                    </>
                                ) : (
                                    <b className="uppercase">{vehicle.VIN}</b>
                                )}
                            </div>
                            <div className="relative">
                                {isVinCopiedTooltipOpen && (
                                    <div
                                        className="font-normal absolute bottom-8 rounded-lg p-2 left-1/2 transform -translate-x-1/2  border-2 shadow-2xl bg-gray-500 text-white"
                                        id="vinCopiedTooltip"
                                    >
                                        <div className="flex flex-col">
                                            <div>Copied to clipboard!</div>
                                        </div>
                                    </div>
                                )}
                                <button
                                    onClick={() => {
                                        this.setState({ isVinCopiedTooltipOpen: true }, () => {
                                            const copyText = document.getElementById('vinSelect')!;
                                            // @ts-ignore
                                            copyText.select();
                                            // @ts-ignore
                                            copyText.setSelectionRange(0, 99999);
                                            // @ts-ignore
                                            navigator.clipboard.writeText(copyText.value);
                                        });
                                        setTimeout(() => {
                                            this.setState({ isVinCopiedTooltipOpen: false });
                                        }, 1000);
                                    }}
                                    className="ml-2 border border-black px-2 rounded-lg text-black text-sm hover:bg-gray-300 relative"
                                >
                                    COPY
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {vehicle?.engine && (
                    <div>
                        Engine: <b>{vehicle.engine}</b>
                    </div>
                )}
                {vehicle?.transmissionType && (
                    <div>
                        Transmission: <b>{vehicle.transmissionType}</b>
                    </div>
                )}
                {vehicle?.color && (
                    <div>
                        Color: <b>{vehicle.color}</b>
                    </div>
                )}
            </div>
        );
    }

    modalClosed(vehicleCreated?: boolean, vehicleUpdated?: boolean, vehicleId?: string) {
        if (vehicleCreated && vehicleId) {
            this.props.vehicleSelected(vehicleId, true);
            this.getNewlyCreatedVehicle(vehicleId);
            setTimeout(() => this.setState({ isCreateVehicleModalOpen: false }), 1000);
        } else {
            this.setState({ isCreateVehicleModalOpen: false });
        }
    }

    getNewlyCreatedVehicle(vehicleId: string) {
        axios.get(`${config.apiUrl}/vehicle/${vehicleId}`).then((res) => this.vehicleSelected(res.data));
    }

    getVehicleModal() {
        return <VehicleModal onClose={this.modalClosed} customer={this.props.customer}></VehicleModal>;
    }

    render() {
        const { customer, selectedVehicle, vehicleSelected } = this.props;
        const { isCreateVehicleModalOpen } = this.state;
        return (
            <>
                {!!isCreateVehicleModalOpen && this.getVehicleModal()}
                <div className="divide-y-2 divide-gray-200">
                    <div className="flex w-full pb-3">
                        <span className="flex flex-row items-center">
                            <FaCar size={17} />
                            <h2 className=" pl-3 text-lg font-semibold align-bottom">
                                {!!selectedVehicle ? (
                                    <Link to={`/vehicle/${selectedVehicle.id}`} target="_blank">
                                        {selectedVehicle.year} {selectedVehicle.make} {selectedVehicle.model}{' '}
                                        {selectedVehicle.series}
                                    </Link>
                                ) : (
                                    'Select a Vehicle'
                                )}
                            </h2>
                        </span>
                        <div className="ml-auto flex">
                            {/* <div className="flex mr-12 text-blue-500 clickable hover:text-blue-600">
                                <span className="mr-2">More</span>
                                <ImNewTab className="mt-1" />
                            </div> */}
                            <div>
                                {!selectedVehicle ? (
                                    <button
                                        disabled={!customer}
                                        className="bg-blue-500 px-2 rounded-2xl font-medium text-base ml-auto disabled:opacity-50"
                                        onClick={() =>
                                            this.setState({ isCreateVehicleModalOpen: !isCreateVehicleModalOpen })
                                        }
                                    >
                                        + Add new
                                    </button>
                                ) : (
                                    <div>
                                        <Icon
                                            name="cancel"
                                            className="clickable"
                                            onClick={() => vehicleSelected(null)}
                                            size="large"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="pt-3">
                        <div className="w-full">
                            {!!selectedVehicle && this.getVehicleOverview(selectedVehicle)}
                            {!!customer && !!!selectedVehicle && this.getVehiclesList()}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default VehicleSection;
