import ManualAUthorizationModal from 'components/modals/ManualAuthorizationModal';
import LaborRate from 'models/LaborRateModel';
import Service from 'models/ServiceModel';
import React, { useEffect, useState } from 'react';
import AuthorizationStatus from '../AuthorizationStatus';
import axios from 'helpers/axios';
import config from 'helpers/config';
import Authorization from 'models/AuthorizationModel';
import AuthorizationModal from 'components/pages/estimate/AuthorizationModal';
import RelativeTime from 'components/common/RelativeTime';
import HoverTooltip from 'components/common/HoverTooltip';
import ServiceAuthorizationStatus from 'models/types/ServiceAuthorizationStatus';
import Price from 'components/common/Price';

interface Props {
    services: Service[];
    laborRates: LaborRate[];
    repairOrderId: string;
}

const RepairOrderAuthorizations: React.FC<Props> = ({ services, laborRates, repairOrderId }: Props) => {
    const [isManualAuthModalOpen, setIsManualAuthModalOpen] = useState(false);
    const [authorizations, setAuthorizations] = useState([]);
    const [isSignatureModalOpen, setIsSignatureModalOpen] = useState(false);
    const [authorizationToView, setAuthorizationToView] = useState<Authorization | undefined>();

    useEffect(() => {
        axios
            .get(`${config.apiUrl}/authorization/${repairOrderId}`)
            .then((res) => setAuthorizations(res.data))
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const getAuthorizationStatusName = (status: ServiceAuthorizationStatus) => {
        switch (status) {
            case ServiceAuthorizationStatus.NotAuthorized: {
                return 'Not Authorized';
            }
            case ServiceAuthorizationStatus.Authorized: {
                return 'Authorized';
            }
            case ServiceAuthorizationStatus.Declined: {
                return 'Declined';
            }
        }
    };

    return (
        <>
            {services?.length ? (
                <div>
                    <div>
                        {services?.map((service, index) => (
                            <div key={index}>
                                <HoverTooltip
                                    content={
                                        <div className="bg-white p-4 font-semibold rounded-lg">
                                            {getAuthorizationStatusName(service.authorizationStatus)}
                                        </div>
                                    }
                                    isSmall
                                >
                                    <AuthorizationStatus
                                        withPicker={false}
                                        className="inline mr-2"
                                        currentStatus={service.authorizationStatus}
                                    />
                                </HoverTooltip>
                                <span>
                                    {service.title}
                                    <span className="ml-2 font-semibold">
                                        <Price price={service.totalCents / 100} />
                                    </span>
                                </span>
                            </div>
                        ))}
                        {authorizations.map((authorization: Authorization) => (
                            <div key={authorization.id}>
                                <div
                                    onClick={() => {
                                        setIsSignatureModalOpen(true);
                                        setAuthorizationToView(authorization);
                                    }}
                                    key={authorization.id}
                                >
                                    <span>Authorized</span>{' '}
                                    <span>
                                        <RelativeTime floatRight={false} dateTime={authorization.createdAt} />
                                    </span>{' '}
                                    <span className="clickable text-blue-500 font-semibold text-xs">View</span>
                                </div>
                            </div>
                        ))}
                        {isSignatureModalOpen && (
                            <AuthorizationModal
                                authorization={authorizationToView!}
                                onClose={() => {
                                    setIsSignatureModalOpen(false);
                                    setAuthorizationToView(undefined);
                                }}
                                services={services}
                            />
                        )}
                    </div>
                    <div className="grid place-items-center">
                        <button
                            className="bg-blue-600 rounded-2xl px-3 py-1 w-1/3 font-bold"
                            onClick={() => setIsManualAuthModalOpen(!isManualAuthModalOpen)}
                        >
                            Authorize
                        </button>
                    </div>
                    {!!isManualAuthModalOpen && (
                        <ManualAUthorizationModal
                            services={services}
                            onClose={(_) => setIsManualAuthModalOpen(false)}
                        />
                    )}
                </div>
            ) : (
                <div className="font-bold text-sm text-gray-700 text-center">No services to authorize yet</div>
            )}
        </>
    );
};

export default RepairOrderAuthorizations;
