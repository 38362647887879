import React, { SyntheticEvent } from 'react';

import axios from 'helpers/axios';
import config from 'helpers/config';

import WorkflowStatus from 'models/WorkflowStatusModel';
import { Icon } from 'semantic-ui-react';
import InfoTooltip from 'components/common/InfoTooltip';
import RepairOrder from 'models/RepairOrderModel';
import { displayConfirmationModal } from 'helpers/services/removeConfirmationService';
import { displayWarningModal } from 'helpers/services/warningModalService';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { IoMdReorder } from 'react-icons/io';
import { MdOutlineReorder } from 'react-icons/md';

interface Props {}

interface State {
    workflowStatuses: WorkflowStatus[];

    isCreateNewWorkflowStatusActive: boolean;
    editableWorkflowStatusId: string | null;
    name: string | null;
    boardOrderIndex: number;
    activeRepairOrders: RepairOrder[];
}

class WorkflowSettings extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            workflowStatuses: [],
            isCreateNewWorkflowStatusActive: false,
            editableWorkflowStatusId: null,
            name: null,
            boardOrderIndex: 0,
            activeRepairOrders: [],
        };

        this.getValue = this.getValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.createNew = this.createNew.bind(this);
        this.update = this.update.bind(this);
    }

    componentDidMount() {
        this.getWorkflowStatuses();
        this.getActiveRepairOrders();
    }

    getActiveRepairOrders() {
        axios
            .get(`${config.apiUrl}/repairOrder/board`)
            .then((res) => this.setState({ activeRepairOrders: res.data }))
            .catch((_) => console.log(_));
    }

    getWorkflowStatuses() {
        axios
            .get(`${config.apiUrl}/tenant/workflowStatuses`)
            .then((res) => this.setState({ workflowStatuses: res.data }));
    }

    getValue(name: string) {
        // @ts-ignore
        return this.state?.companySettings[name] || '';
    }

    handleChange(e: SyntheticEvent) {
        // @ts-ignore
        const { name, value } = e.target;
        // @ts-ignore
        this.setState({ [name]: value });
    }

    createNew() {
        const { name, boardOrderIndex } = this.state;
        const workflowStatus = {
            boardOrderIndex,
            name,
        };
        axios
            .post(`${config.apiUrl}/tenant/workflowStatuses`, workflowStatus)
            .then((_) =>
                this.setState({ name: null, boardOrderIndex: 9999, isCreateNewWorkflowStatusActive: false }, () =>
                    this.componentDidMount(),
                ),
            );
    }

    update() {
        const { name, boardOrderIndex, editableWorkflowStatusId } = this.state;
        const workflowStatus = {
            boardOrderIndex,
            name,
            id: editableWorkflowStatusId,
        };

        axios.put(`${config.apiUrl}/tenant/workflowStatus`, workflowStatus).then((_) =>
            this.setState(
                {
                    name: null,
                    boardOrderIndex: 9999,
                    isCreateNewWorkflowStatusActive: false,
                    editableWorkflowStatusId: null,
                },
                () => this.componentDidMount(),
            ),
        );
    }

    handleDelete(workflowStatus: WorkflowStatus) {
        if (this.state.activeRepairOrders.find((RO) => RO.workflowStatusId === workflowStatus.id)) {
            displayWarningModal(
                'You cannot delete the workflow column which has active repair orders. Empty the column before deleting it.',
            );
        } else {
            displayConfirmationModal(`workflow status ${workflowStatus.name} `)
                .then(() => {
                    axios.delete(`${config.apiUrl}/tenant/workflowStatus/${workflowStatus.id}`).then((_) =>
                        this.setState(
                            {
                                name: null,
                                boardOrderIndex: 9999,
                                isCreateNewWorkflowStatusActive: false,
                                editableWorkflowStatusId: null,
                            },
                            () => this.componentDidMount(),
                        ),
                    );
                })
                .catch((_) => _);
        }
    }

    getEditableRow(isEdit: boolean) {
        const { name, boardOrderIndex } = this.state;
        return (
            <>
                <td className="table-cell">
                    <input
                        type="text"
                        name="name"
                        className="bg-gray-50 rounded-md border w-full px-2 font-semibold py-2 focus:ring-1 focus:outline-none focus:border-blue-500"
                        onChange={this.handleChange}
                        value={name!}
                        autoFocus
                    ></input>
                </td>
                <td className="table-cell">
                    <input
                        className="bg-gray-50 rounded-md border w-full px-2 font-semibold py-2 focus:ring-1 focus:outline-none focus:border-blue-500"
                        type="number"
                        name="boardOrderIndex"
                        onChange={this.handleChange}
                        value={boardOrderIndex}
                    ></input>
                </td>
                <td className="table-cell">
                    <div className="item-action">
                        <button
                            onClick={() =>
                                this.setState({
                                    editableWorkflowStatusId: null,
                                    isCreateNewWorkflowStatusActive: false,
                                    name: null,
                                    boardOrderIndex: 0,
                                })
                            }
                            className="bg-gray-100 rounded-lg font-semibold text-lg text-gray-700 px-2 border"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={isEdit ? this.update : this.createNew}
                            className="bg-green-500 rounded-lg font-semibold text-lg text-white px-2 border"
                        >
                            Save
                        </button>
                    </div>
                </td>
            </>
        );
    }

    render() {
        const { workflowStatuses, isCreateNewWorkflowStatusActive, editableWorkflowStatusId } = this.state;
        return (
            <div className="flex flex-col items-center">
                <div>
                    <div className="flex flex-row float-right ml-auto mb-2">
                        <div className="create-new-button ml-auto">
                            <button
                                onClick={() =>
                                    this.setState({
                                        isCreateNewWorkflowStatusActive: !isCreateNewWorkflowStatusActive,
                                        editableWorkflowStatusId: null,
                                        name: null,
                                        boardOrderIndex: 0,
                                    })
                                }
                                className="font-semibold py-1 px-3 rounded-xl hover:bg-green-500 border border-green-500 text-green-500 hover:text-white mr-2"
                            >
                                <span>Reorder</span>
                                <IoMdReorder className="inline mr-2 align-baseline" />
                            </button>
                            <button
                                // disabled={isNewRepairOrderLoading}
                                className="font-semibold py-2 px-3 rounded-xl hover:bg-green-500 border border-green-500 text-green-500 hover:text-white"
                                // onClick={() => this.goToRepairOrder()}
                            >
                                <MdOutlineReorder className="inline mr-2 align-baseline" />
                                <span>Reorder</span>
                            </button>
                            <button
                                onClick={() =>
                                    this.setState({
                                        isCreateNewWorkflowStatusActive: !isCreateNewWorkflowStatusActive,
                                        editableWorkflowStatusId: null,
                                        name: null,
                                        boardOrderIndex: 0,
                                    })
                                }
                                className="font-semibold py-1 px-3 rounded-xl hover:bg-green-500 border border-green-500 text-green-500 hover:text-white"
                            >
                                + Add new
                            </button>
                        </div>
                    </div>
                    <table className="items-center w-full text-white  font-semibold text-left text-xs align-middle">
                        <thead>
                            <tr className="bg-blue-500 h-12 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                                <th className="pr-64 pl-64 rounded-tl-md">Title</th>
                                <th className="pl-6 pr-64">Order</th>
                                <th className="pl-6 pr-64 rounded-tr-md"></th>
                            </tr>
                        </thead>

                        <DragDropContext onDragEnd={() => console.log('ree')}>
                            <Droppable droppableId="workflow-item-droppable" type="">
                                {(provided, _snapshot) => (
                                    <tbody ref={provided.innerRef} {...provided.droppableProps}>
                                        {workflowStatuses.map((workflowStatus, index) => {
                                            return (
                                                <Draggable
                                                    draggableId={workflowStatus.id}
                                                    key={workflowStatus.id}
                                                    index={index}
                                                >
                                                    {(provided, _snapshot) => {
                                                        return (
                                                            <tr
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                ref={provided.innerRef}
                                                                // isDragging={snapshot.isDragging}
                                                                className={`text-black font-semibold bg-white border-b border-r-2 border-l-2 hover:bg-blue-100`}
                                                            >
                                                                <td className=" px-6 min-w-max text-black align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left flex items-center">
                                                                    {workflowStatus.name}
                                                                </td>
                                                                <td className="px-6 align-middle h-5 border-l-0 border-r-0 text-sm  py-0 ">
                                                                    <div className="flex">
                                                                        {workflowStatus.boardOrderIndex}
                                                                        <div className="ml-1">
                                                                            <InfoTooltip
                                                                                text="Here you can configure the order or kanban board columns"
                                                                                id={workflowStatus.id}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td className="px-6 align-middle h-5 border-l-0 border-r-0 text-sm  py-0 -clickable">
                                                                    <div className="item-action">
                                                                        <Icon
                                                                            className="clickable"
                                                                            name="trash alternate"
                                                                            onClick={() =>
                                                                                this.handleDelete(workflowStatus)
                                                                            }
                                                                        ></Icon>
                                                                        <Icon
                                                                            className="clickable"
                                                                            name="edit"
                                                                            onClick={() => {
                                                                                this.setState({
                                                                                    editableWorkflowStatusId:
                                                                                        workflowStatus.id,
                                                                                    name: workflowStatus.name,
                                                                                    boardOrderIndex:
                                                                                        workflowStatus.boardOrderIndex,
                                                                                    isCreateNewWorkflowStatusActive:
                                                                                        false,
                                                                                });
                                                                            }}
                                                                        ></Icon>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    }}
                                                </Draggable>
                                            );
                                        })}
                                    </tbody>
                                )}
                            </Droppable>
                        </DragDropContext>

                        <tbody>
                            {workflowStatuses.map((status, index) => (
                                <tr
                                    key={index}
                                    className={`text-black font-semibold bg-white border-b border-r-2 border-l-2 hover:bg-blue-100`}
                                >
                                    {status.id === editableWorkflowStatusId ? (
                                        this.getEditableRow(true)
                                    ) : (
                                        <>
                                            <td className=" px-6 min-w-max align-middle border-l-0 border-r-0 text-sm whitespace-nowrap p-4 text-left flex items-center">
                                                {status.name}
                                            </td>
                                            <td className="px-6 align-middle h-5 border-l-0 border-r-0 text-sm  py-0 ">
                                                <div className="flex">
                                                    {status.boardOrderIndex}
                                                    <div className="ml-1">
                                                        <InfoTooltip
                                                            text="Here you can configure the order or kanban board columns"
                                                            id={status.id}
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 align-middle h-5 border-l-0 border-r-0 text-sm  py-0 -clickable">
                                                <div className="item-action">
                                                    <Icon
                                                        className="clickable"
                                                        name="trash alternate"
                                                        onClick={() => this.handleDelete(status)}
                                                    ></Icon>
                                                    <Icon
                                                        className="clickable"
                                                        name="edit"
                                                        onClick={() => {
                                                            this.setState({
                                                                editableWorkflowStatusId: status.id,
                                                                name: status.name,
                                                                boardOrderIndex: status.boardOrderIndex,
                                                                isCreateNewWorkflowStatusActive: false,
                                                            });
                                                        }}
                                                    ></Icon>
                                                </div>
                                            </td>
                                        </>
                                    )}
                                </tr>
                            ))}
                            {isCreateNewWorkflowStatusActive && (
                                <tr className="text-black font-semibold bg-white border-b border-r-2 border-l-2">
                                    {this.getEditableRow(false)}
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default WorkflowSettings;
