import axios from 'helpers/axios';
import config from 'helpers/config';
import User from 'models/UserModel';
import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { connect } from 'react-redux';

interface Props {
    currentUser: User;
}

interface State {
    isCheckoutRedirectPending: boolean;
}

class SubscriptionSettings extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isCheckoutRedirectPending: false,
        };
    }

    redirectToCheckout(subscriptionPaymentID: string) {
        if (!this.state.isCheckoutRedirectPending) {
            axios
                .get(`${config.apiUrl}/subscription/${subscriptionPaymentID}`)
                .then((res) => (window.location.href = res.data.stripeCheckoutURL))
                .catch((_) => _);
            this.setState({
                isCheckoutRedirectPending: true,
            });
        }
    }

    render() {
        return (
            <div>
                {this.props?.currentUser?.tenant?.isInDemo && (
                    <div>
                        <div className="font-semibold text-lg p-4 bg-white border-blue-500 border rounded-lg ">
                            You are currently on 14 day free trial
                        </div>
                        <div className="text-center pt-10">
                            <div className="font-semibold text-2xl mb-4">Pick a plan</div>
                            <div className="flex w-full px-10">
                                <div className="bg-white border border-gray rounded-lg p-4 w-1/3 mr-4">
                                    <div className="font-semibold text-lg font-mono mt-6">Basic</div>
                                    <div className="font-semibold text-2xl">
                                        $99.00 <span className="font-semibold text-gray-500">/month</span>
                                    </div>
                                    <div
                                        onClick={() => this.redirectToCheckout('price_1L1zetK1Pc8TsyJLQGyckKCe')}
                                        className="rounded-xl clickable border-2 border-blue-600 hover:bg-blue-300 font-semibold font-mono text-2xl"
                                    >
                                        Get Started
                                    </div>
                                    <div className="mt-4 text-left font-semibold text-gray-700">
                                        <div>
                                            <FaCheckCircle className="text-green-500 inline-block mr-2" />
                                            Unlimited Repair Orders
                                        </div>
                                        <div>
                                            <FaCheckCircle className="text-green-500 inline-block mr-2" />
                                            Calendars &amp; Appointments
                                        </div>
                                        <div>
                                            <FaCheckCircle className="text-green-500 inline-block mr-2" />
                                            Inventory &amp; Vendor Management
                                        </div>
                                        <div>
                                            <FaCheckCircle className="text-green-500 inline-block mr-2" />
                                            Canned Jobs &amp; Sublets
                                        </div>
                                        <div>
                                            <FaCheckCircle className="text-green-500 inline-block mr-2" />
                                            Digital Vehicle Inspections (DVI)
                                        </div>
                                        <div>
                                            <FaCheckCircle className="text-green-500 inline-block mr-2" />
                                            One-Way Messaging
                                        </div>
                                        <div>
                                            <FaCheckCircle className="text-green-500 inline-block mr-2" />
                                            Digital Authorizations &amp; Invoicing
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-white  border border-yellow-400 border-gray rounded-lg p-4 w-1/3 mr-4">
                                    <div className="font-semibold rounded-t-lg bg-yellow-400 text-lg font-mono -m-4 mb-4">
                                        Most popular
                                    </div>
                                    <div className="font-semibold text-lg font-mono">Grow</div>
                                    <div className="font-semibold text-2xl">
                                        $199.00 <span className="font-semibold text-gray-500">/month</span>
                                    </div>
                                    <div
                                        onClick={() => this.redirectToCheckout('price_1L1zetK1Pc8TsyJLQGyckKCe')}
                                        className="rounded-xl clickable border border-blue-600 text-blue-600 hover:bg-blue-300 font-semibold font-mono text-2xl"
                                    >
                                        Get Started
                                    </div>
                                    <div className="mt-4 text-left font-semibold text-gray-700">
                                        <div>
                                            <FaCheckCircle className="text-green-500 inline-block mr-2" />
                                            Unlimited Repair Orders
                                        </div>
                                        <div>
                                            <FaCheckCircle className="text-green-500 inline-block mr-2" />
                                            Calendars &amp; Appointments
                                        </div>
                                        <div>
                                            <FaCheckCircle className="text-green-500 inline-block mr-2" />
                                            Inventory &amp; Vendor Management
                                        </div>
                                        <div>
                                            <FaCheckCircle className="text-green-500 inline-block mr-2" />
                                            Canned Jobs &amp; Sublets
                                        </div>
                                        <div>
                                            <FaCheckCircle className="text-green-500 inline-block mr-2" />
                                            Digital Vehicle Inspections (DVI)
                                        </div>
                                        <div>
                                            <FaCheckCircle className="text-green-500 inline-block mr-2" />
                                            One-Way Messaging
                                        </div>
                                        <div>
                                            <FaCheckCircle className="text-green-500 inline-block mr-2" />
                                            Digital Authorizations &amp; Invoicing
                                        </div>
                                    </div>
                                </div>

                                <div className="bg-white  border border-gray rounded-lg p-4 w-1/3">
                                    <div className="font-semibold text-lg font-mono mt-6">Pro</div>
                                    <div className="font-semibold text-2xl">
                                        $250.00 <span className="font-semibold text-gray-500">/month</span>
                                    </div>
                                    <div
                                        onClick={() => this.redirectToCheckout('price_1L1zetK1Pc8TsyJLQGyckKCe')}
                                        className="rounded-xl clickable border-2 border-blue-600 hover:bg-blue-300 font-semibold font-mono text-2xl"
                                    >
                                        Get Started
                                    </div>
                                    <div className="mt-4 text-left font-semibold text-gray-700">
                                        <div>
                                            <FaCheckCircle className="text-green-500 inline-block mr-2" />
                                            Unlimited Repair Orders
                                        </div>
                                        <div>
                                            <FaCheckCircle className="text-green-500 inline-block mr-2" />
                                            Calendars &amp; Appointments
                                        </div>
                                        <div>
                                            <FaCheckCircle className="text-green-500 inline-block mr-2" />
                                            Inventory &amp; Vendor Management
                                        </div>
                                        <div>
                                            <FaCheckCircle className="text-green-500 inline-block mr-2" />
                                            Canned Jobs &amp; Sublets
                                        </div>
                                        <div>
                                            <FaCheckCircle className="text-green-500 inline-block mr-2" />
                                            Digital Vehicle Inspections (DVI)
                                        </div>
                                        <div>
                                            <FaCheckCircle className="text-green-500 inline-block mr-2" />
                                            One-Way Messaging
                                        </div>
                                        <div>
                                            <FaCheckCircle className="text-green-500 inline-block mr-2" />
                                            Digital Authorizations &amp; Invoicing
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(SubscriptionSettings);
