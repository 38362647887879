import { Dispatch } from 'redux';

import PhoneNumber from 'models/PhoneNumberModel';
import Customer from 'models/CustomerModel';

import axios from 'helpers/axios';
import config from 'helpers/config';
import actionTypes from 'helpers/actionTypes';

const { apiUrl } = config;

export interface GetCustomersRequest {
    searchPhrase: string;
    page: number;
    itemsPerPage: number;
}

export interface NewCustomerRequest {
    firstName: string;
    lastName: string;
    phone: string;
    mobile?: string;
    phoneNumbers?: PhoneNumber[];
    email?: string;
    address?: string;
    postCode?: string;
    isBusinessCustomer?: string;
    companyName?: string;
    customerSource?: string | number;
    preferredMethodOfContact?: string | number;
}

export const getCustomers = (requestParams: GetCustomersRequest) => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.GET_CUSTOMERS_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.GET_CUSTOMERS_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.GET_CUSTOMERS_FAILURE, payload });

    dispatch(request());

    axios
        .get(`${apiUrl}/customer`, { params: requestParams })
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};

export const getCustomersOverview = () => (dispatch: Dispatch) => {
    const success = (payload: any) => ({ type: actionTypes.GET_CUSTOMERS_OVERVIEW_SUCCESS, payload });

    axios.get(`${config.apiUrl}/customer/overview`).then((res) => {
        dispatch(success(res.data));
    });
};

// prettier-ignore
export const getCustomer =
    (customerId: string, withLifetimeStats = false) =>
    (dispatch: Dispatch) => {
        const request = () => ({ type: actionTypes.GET_CUSTOMER_REQUEST });
        const success = (payload: any) => ({ type: actionTypes.GET_CUSTOMER_SUCCESS, payload });
        const failure = (payload: any) => ({ type: actionTypes.GET_CUSTOMER_FAILURE, payload });

        dispatch(request());

        axios
            .get(`${apiUrl}/customer/${customerId}`, {
                params: {
                    withLifetimeStats: Number(withLifetimeStats),
                },
            })
            .then((res) => {
                dispatch(success(res.data));
            })
            .catch((err) => {
                dispatch(failure(err.message));
            });
    };

export const createCustomer = (newCustomer: NewCustomerRequest) => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.CREATE_CUSTOMER_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.CREATE_CUSTOMER_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.CREATE_CUSTOMER_FAILURE, payload });

    dispatch(request());

    axios
        .post(`${apiUrl}/customer`, newCustomer)
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};

export const updateCustomer = (customer: Customer) => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.UPDATE_CUSTOMER_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.UPDATE_CUSTOMER_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.UPDATE_CUSTOMER_FAILURE, payload });

    dispatch(request());

    axios
        .put(`${apiUrl}/customer`, customer)
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};
