import React from 'react';
import RelativeTime from 'components/common/RelativeTime';
import PaymentModal from 'components/modals/paymentModal/PaymentModal';
import axios from 'helpers/axios';
import config from 'helpers/config';
import { getPaymentTypeName } from 'helpers/payments/paymentsService';
import Payment from 'models/PaymentModel';
import RepairOrder from 'models/RepairOrderModel';
import Service from 'models/ServiceModel';
import LaborRate from 'models/LaborRateModel';
import ServiceItemType from 'models/types/ServiceItemType';
import ServiceAuthorizationStatus from 'models/types/ServiceAuthorizationStatus';
import { FaPlusCircle } from 'react-icons/fa';
import Price from 'components/common/Price';

interface Props {
    repairOrder: RepairOrder;
    services: Service[];
    laborRates: LaborRate[];
}

interface State {
    payments: Payment[];
    isPaymentsModalOpen: boolean;
    payment?: Payment;

    grandTotal: number;
    paidToDate: number;
}

class Payments extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isPaymentsModalOpen: false,
            payments: [],
            grandTotal: 0,
            paidToDate: 0,
        };
    }

    componentDidMount() {
        this.getPayments();
    }

    getPayments() {
        axios
            .get(`${config.apiUrl}/repairOrder/payments/${this.props.repairOrder.id}`)
            .then((response) => {
                this.setState({ payments: response.data });
            })
            .catch((err) => console.log(err));
    }

    getSubtotal() {
        const { services } = this.props;
        const totalCents = services.reduce((acc, item) => acc + item.totalCents, 0);
        return totalCents / 100;
    }

    getGrandTotal() {
        const { services } = this.props;
        const totalCents = services.reduce((acc, item) => acc + item.grandTotalCents, 0);
        return totalCents / 100;
    }

    getPaidToDate() {
        const { payments } = this.state;
        return payments.reduce((acc, payment) => acc + payment.amountCents, 0) / 100;
    }

    openPaymentModal(payment?: Payment) {
        if (payment) {
            this.setState({ isPaymentsModalOpen: true, payment });
        } else {
            this.setState({ isPaymentsModalOpen: true });
        }
    }

    getRemainingBalance() {
        return this.getSubtotal() - this.getPaidToDate();
    }

    getTotalByServiceItemType(serviceItemType: ServiceItemType) {
        const { services } = this.props;
        const servicesToInclude = services?.filter(
            (service) =>
                !(service.isRecommended && service.authorizationStatus !== ServiceAuthorizationStatus.Authorized),
        );

        const totalCents = servicesToInclude
            ?.flatMap((service) => service.items)
            .filter((item) => item.type === serviceItemType)
            .reduce((acc, item) => acc + item.totalCents, 0);
        return totalCents / 100;
    }

    render() {
        const { repairOrder, services } = this.props;
        const { isPaymentsModalOpen, payment, payments, paidToDate, grandTotal } = this.state;

        return (
            <div>
                {isPaymentsModalOpen && (
                    <PaymentModal
                        repairOrder={{ ...repairOrder, services: services }}
                        onClose={() => {
                            this.setState({ isPaymentsModalOpen: false, payment: undefined });
                            this.getPayments();
                        }}
                        payment={payment}
                        leftToPay={this.getRemainingBalance()}
                    />
                )}
                <div className="grid place-items-center pb-2">
                    <button
                        className="bg-green-600 rounded-2xl px-3 py-1  font-bold border border-gray-600 break-keep"
                        onClick={() => this.openPaymentModal()}
                    >
                        <FaPlusCircle className="inline align-text-top mr-1" />
                        <span>New&nbsp;Payment</span>
                    </button>
                </div>
                {payments.map((payment) => (
                    <div
                        key={payment.id}
                        className="flex clickable hover:underline mb-2 border-b border-dashed"
                        onClick={() => this.openPaymentModal(payment)}
                    >
                        <div>
                            <div className="font-semibold text-green-600">
                                <Price price={payment.amountCents / 100} />
                            </div>
                            <div className="text-sm text-gray-400 font-semibold">
                                {getPaymentTypeName(payment.paymentType)}
                            </div>
                        </div>
                        <div className="text-gray-600 clickable hover:underline ml-auto">
                            <RelativeTime dateTime={payment.paymentDate} />
                        </div>
                    </div>
                ))}

                <div className="font-semibold">
                    <span className="mr-2">Total Parts:</span>
                    <span className="text-gray-700">
                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                            this.getTotalByServiceItemType(ServiceItemType.Part),
                        )}
                    </span>
                </div>

                <div className="font-semibold">
                    <span className="mr-2">Total Labor:</span>
                    <span className="text-gray-700">
                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                            this.getTotalByServiceItemType(ServiceItemType.Labor),
                        )}
                    </span>
                </div>

                <div className="font-semibold pb-1 border-b border-gray-500">
                    <span className="mr-2">Total Fees:</span>
                    <span className="text-gray-700">
                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                            this.getTotalByServiceItemType(ServiceItemType.Fee),
                        )}
                    </span>
                </div>

                <div className="font-semibold">
                    <span className="mr-2">Subtotal:</span>
                    <span className="text-gray-700">
                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                            this.getSubtotal(),
                        )}
                    </span>
                </div>

                <div className="font-semibold pb-1 border-b border-gray-500">
                    <span className="mr-2">Tax:</span>
                    <span className="text-gray-700">
                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(0)}
                    </span>
                </div>

                <div className="font-semibold">
                    <span className="mr-2">Grand Total:</span>
                    <span className="text-gray-700">
                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                            this.getGrandTotal(),
                        )}
                    </span>
                </div>

                <div className="font-semibold">
                    <span className="mr-2">Paid to date:</span>
                    <span className="text-green-700">
                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                            this.getPaidToDate(),
                        )}
                    </span>
                </div>
                <div className="font-semibold">
                    <span className="mr-2">Remaining balance:</span>
                    <span className={grandTotal - paidToDate === 0 ? 'text-green-700' : 'text-yellow-700'}>
                        {new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(
                            this.getRemainingBalance(),
                        )}
                    </span>
                </div>
            </div>
        );
    }
}

export default Payments;
