import React from 'react';
import axios from '../../../helpers/axios';
import config from '../../../helpers/config';
import history from '../../../helpers/history';
import Overlay from '../../common/Overlay';

interface Props {}

interface State {
    verificationId: string;
}

class EmailVerificationPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            verificationId: '',
        };
    }

    componentDidMount() {
        // @ts-ignore
        const { verificationId } = this.props.match.params;
        this.setState({ verificationId });

        axios
            .post(`${config.apiUrl}/auth/verify-email/${verificationId}`)
            .then((_) => {
                setTimeout(() => {
                    history.push('/login');
                }, 2500);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    render() {
        return (
            <div>
                <Overlay />
            </div>
        );
    }
}

export default EmailVerificationPage;
