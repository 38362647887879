import { Route, Switch } from 'react-router-dom';
import React from 'react';
import Home from 'components/Home';
import NotFound from 'components/NotFound';
import BookingPage from 'components/pages/booking/BookingPage';
import PrivateRoute from './privateRoute';
import LoginPage from 'components/pages/login/LoginPage';
import RegistrationPage from 'components/pages/registration/RegistrationPage';
import RegistrationPageNew from 'components/pages/registration/RegistrationPageNew';
import VehiclesPage from 'components/pages/vehicles/VehiclesListPage';
import CustomersListPage from 'components/pages/customers/CustomersListPage';
import InventoryListPage from 'components/pages/inventory/InventoryListPage';
import CustomerDetailsPage from 'components/pages/customers/customerDetails/CustomerDetailsPage';
import VehicleDetailsPage from 'components/pages/vehicles/vehicleDetails/VehicleDetailsPage';
import SuppliersListPage from 'components/pages/suppliers/SuppliersListPage';
import JobListPage from 'components/pages/jobCentre/jobList/JobListPage';
import SettingsPage from 'components/pages/settings/SettingsPage';
import SubscriptionPage from 'components/pages/subscription/SubscriptionPage';
import EmailVerificationPage from 'components/pages/emailVerification/EmailVerificationPage';
import DevPage from 'components/pages/dev/DevPage';
import RepairOrderPage from 'components/pages/repairOrder/RepairOrderPage';
import ReportsPage from 'components/pages/reports/ReportsPage';

import TitleService from './services/titleService';
import SupplierDetailsPage from 'components/pages/suppliers/supplierDetails/SupplierDetailsPage';
import ArchivePage from 'components/pages/archive/ArchivePage';
import CalendarPageV2 from 'components/pages/calendar/CalendarPageV2';
import EmployeeRegistrationPage from 'components/pages/registration/EmployeeRegistrationPage';
import InventoryDetailsPage from 'components/pages/inventory/inventoryDetails.tsx/InventoryDetailsPage';
import ScrollToTop from './ScrollToTop';
import EstimatePage from 'components/pages/estimate/EstimatePage';
import PrivateAdminRoute from './privateAdminRoute';
import AdminPortal from 'components/pages/dev/AdminPage';
import TenantSettingsAdministration from 'components/pages/dev/TenantSettingAdministration';
import TextingPage from 'components/pages/texting/TextingPage';
import TimeLogsPage from 'components/pages/timeLogs/TimeLogsPage';
import LoanerDetailsPage from 'components/pages/inventory/loaners/LoanerDetailsPage';
import UserDetailsPage from 'components/pages/settings/menus/users/UserDetailsPage';
import CannedJobDetailsPage from 'components/pages/settings/cannedjobs/CannedJobDetailsPage';

const routes = () => (
    <>
        <ScrollToTop />
        <Switch>
            <PrivateRoute exact path="/" component={Home} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/register" component={RegistrationPage} />
            <Route exact path="/registerNew" component={RegistrationPageNew} />
            <Route exact path="/employeeRegistration/:verificationId" component={EmployeeRegistrationPage} />
            <Route exact path="/subscribe" component={SubscriptionPage} />
            <Route exact path="/estimate/:repairOrderId" component={EstimatePage} />
            <Route exact path="/verify-email/:verificationId" component={EmailVerificationPage} />
            <PrivateRoute exact path="/booking/:bookingId?" component={BookingPage} />
            <PrivateRoute exact path="/repairOrder/:repairOrderId?" component={RepairOrderPage} />
            <PrivateRoute exact path="/customer/:customerId?" component={CustomerDetailsPage} />
            <PrivateRoute exact path="/user/:userId?" component={UserDetailsPage} />
            <PrivateRoute exact path="/vehicle/:vehicleId?" component={VehicleDetailsPage} />
            <PrivateRoute exact path="/loaner/:loanerId?" component={LoanerDetailsPage} />
            <PrivateRoute exact path="/supplier/:supplierId?" component={SupplierDetailsPage} />
            <PrivateRoute exact path="/jobCentre" component={JobListPage} />
            <PrivateRoute exact path="/calendar" component={CalendarPageV2} />
            <PrivateRoute exact path="/texting" component={TextingPage} />
            <PrivateRoute exact path="/vehicles" component={VehiclesPage} />
            <PrivateRoute exact path="/customers" component={CustomersListPage} />
            <PrivateRoute exact path="/inventory" component={InventoryListPage} />
            <PrivateRoute exact path="/inventory/:inventoryItemId?" component={InventoryDetailsPage} />
            <PrivateRoute exact path="/suppliers" component={SuppliersListPage} />
            <PrivateRoute exact path="/reports/:subpageName" component={ReportsPage} />
            <PrivateRoute exact path="/timeLogs" component={TimeLogsPage} />
            <PrivateRoute exact path="/archive" component={ArchivePage} />
            <PrivateRoute exact path="/settings/:subpageName" component={SettingsPage} />
            <PrivateRoute exact path="/settings/cannedJob/:cannedJobId?" component={CannedJobDetailsPage} />
            <PrivateRoute exact path="/dev" component={DevPage} />
            <PrivateAdminRoute exact path="/admin_portal" component={AdminPortal} />
            <PrivateAdminRoute exact path="/admin_portal/tenant/:tenantId" component={TenantSettingsAdministration} />
            <Route path="*" component={NotFound} />
        </Switch>
        <TitleService />
    </>
);
export default routes;
