import React from 'react';

import { Button } from 'semantic-ui-react';
import actionTypes from '../actionTypes';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import store from '../../store/store';
import { MdWarning } from 'react-icons/md';

interface Props {
    isOpen: boolean;
    dispatch: Dispatch;
}

let warningText: string | undefined;

class WarningModalGlobal extends React.Component<Props, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.props.dispatch({ type: actionTypes.CLOSE_WARNING_MODAL, payload: {} });
    }

    render() {
        const { isOpen } = this.props;
        return isOpen ? (
            <div className="remove-confirmation-modal">
                <div className="remove-confirmation-modal__body">
                    <h2 className="font-bold">
                        <MdWarning className="text-4xl text-yellow-400 inline-block mb-4" />
                        <span>Warning</span>
                    </h2>
                    <div className="font-semibold">{warningText}</div>
                    <div className="modal-buttons">
                        <div className="button-cancel"></div>
                        <div className="button-delete">
                            <Button color="grey" onClick={() => this.closeModal()}>
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        ) : null;
    }
}

const mapStateToProps = (state: any) => ({
    isOpen: state.misc.isWarningModalOpen,
});

export default connect(mapStateToProps)(WarningModalGlobal);

export const displayWarningModal = (text?: string) =>
    new Promise<any>(async (resolve) => {
        {
            warningText = text;
            const listener = () => {
                if (!store.getState().misc.isWarningModalOpen) {
                    resolve({});
                }
            };

            store.dispatch({ type: actionTypes.OPEN_WARNING_MODAL, payload: {} });
            setTimeout(() => {
                store.subscribe(listener);
            }, 500);
        }
    });
