import { resolveUserName } from 'helpers/userNameResolver';
import React, { useEffect, useState } from 'react';

interface Props {
    userId: string;
}

const UserFullName: React.FC<Props> = ({ userId }: Props) => {
    const [userFullName, setUserFullName] = useState('');

    useEffect(() => {
        resolveUserName(userId)
            .then((createdByUserFullName) => setUserFullName(createdByUserFullName))
            .catch((_) => _);
    }, [userId]);

    return <>{userFullName}</>;
};

export default UserFullName;
