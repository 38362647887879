import LoanerModal from 'components/modals/vehicle/LoanerModal';
import axios from 'helpers/axios';
import config from 'helpers/config';
import LoanerVehicle from 'models/LoanerVehicleModel';
import TableColumn, { TableColumOrderStatus } from 'models/TableColumnModel';

import history from 'helpers/history';
import React from 'react';
import { FaCarSide, FaEdit, FaRegTrashAlt, FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';
import HoverTooltip from 'components/common/HoverTooltip';
import { BiCommentDetail } from 'react-icons/bi';

interface Props {}

interface State {
    isLoanerModalOpen: boolean;
    editableLoanerId: string | null;

    loanerVehicles: LoanerVehicle[];
    tableColumns: TableColumn[];
}

class LoanerCarsTab extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoanerModalOpen: false,
            editableLoanerId: null,

            loanerVehicles: [],
            tableColumns: loanerListColumns,
        };
    }

    componentDidMount() {
        this.getLoanerCars();
    }

    getLoanerCars() {
        axios
            .get(`${config.apiUrl}/loaner`)
            .then((response) => {
                this.setState({ loanerVehicles: response.data });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    getLoanerModal() {
        const { isLoanerModalOpen, editableLoanerId } = this.state;
        if (isLoanerModalOpen && !!editableLoanerId) {
            return (
                <LoanerModal
                    loanerId={editableLoanerId}
                    onClose={(loanerCreated?: boolean, loanerUpdated?: boolean) =>
                        this.modalClosed(loanerCreated, loanerUpdated)
                    }
                ></LoanerModal>
            );
        } else if (isLoanerModalOpen && !editableLoanerId) {
            return (
                <LoanerModal
                    onClose={(loanerCreated?: boolean, loanerUpdated?: boolean) =>
                        this.modalClosed(loanerCreated, loanerUpdated)
                    }
                ></LoanerModal>
            );
        }
    }

    modalClosed(loanerCreated?: boolean, loanerUpdated?: boolean) {
        this.setState({ isLoanerModalOpen: false, editableLoanerId: null });
        if (loanerCreated || loanerUpdated) {
            this.componentDidMount();
        }
    }

    isColumnActive(columnName: string) {
        return this.state.tableColumns.find((column) => column.name === columnName)?.active;
    }

    render() {
        const { isLoanerModalOpen, tableColumns, loanerVehicles } = this.state;
        return (
            <div className="list-page">
                {isLoanerModalOpen ? this.getLoanerModal() : null}
                {/* <div>
                </div> */}
                <div className="flex  mb-8  ">
                    <h2 className="list-page__title">Loaner Cars</h2>
                    {/* <h1 className="font-semibold text-lg">Loaner Cars</h1> */}
                    <button
                        className=" bg-green-600 py-1 px-2 rounded-2xl font-medium text-base ml-auto"
                        onClick={() => this.setState({ isLoanerModalOpen: !isLoanerModalOpen })}
                    >
                        + Add new
                    </button>
                </div>
                <div>
                    <table className="items-center w-full">
                        <thead>
                            <tr className=" bg-blue-500 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                                {tableColumns.map(
                                    (column, index) =>
                                        column.active && (
                                            <th key={index} className={`pl-6 py-3 ${index === 0 && 'rounded-tl-md'}`}>
                                                <span
                                                    className="clickable"
                                                    // onClick={() => column.sortable && this.props.onOrder(column.name)}
                                                >
                                                    <span>{column.text}</span>
                                                    {!!column.sortable &&
                                                        (column?.orderStatus === TableColumOrderStatus.None ? (
                                                            <FaSort className="inline ml-1" />
                                                        ) : column?.orderStatus === TableColumOrderStatus.Ascending ? (
                                                            <FaSortDown className="inline ml-1" />
                                                        ) : (
                                                            <FaSortUp className="inline ml-1" />
                                                        ))}
                                                </span>
                                            </th>
                                        ),
                                )}
                                <th className="px-6 py-3 rounded-tr-md">Actions</th>
                            </tr>
                        </thead>

                        <tbody>
                            {!!loanerVehicles &&
                                loanerVehicles.map((loaner, index) => (
                                    <tr
                                        className={`border-b font-semibold hover:bg-blue-200 clickable bg-white border-r-2 border-l-2`}
                                        key={index}
                                        onClick={() => {
                                            history.push(`/loaner/${loaner.id}`);
                                        }}
                                    >
                                        {this.isColumnActive('model') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3 ">
                                                <span className="align-baseline">
                                                    <FaCarSide className="inline mr-2 text-lg text-gray-600" />
                                                    <span>{loaner.model}</span>
                                                </span>
                                            </td>
                                        )}
                                        {this.isColumnActive('make') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3">
                                                <span>{loaner.make}</span>
                                            </td>
                                        )}
                                        {this.isColumnActive('series') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3">
                                                <span>{loaner.series}</span>
                                            </td>
                                        )}
                                        {this.isColumnActive('year') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3">
                                                <span>{loaner.year}</span>
                                            </td>
                                        )}
                                        {this.isColumnActive('licensePlate') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3">
                                                <span>{loaner.licensePlate}</span>
                                            </td>
                                        )}
                                        {this.isColumnActive('VIN') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3">{loaner.VIN}</td>
                                        )}

                                        {this.isColumnActive('notes') && (
                                            <td className="px-6 align-middle  p-3">
                                                {loaner.notes?.length && (
                                                    <HoverTooltip
                                                        content={
                                                            <div className="bg-white p-4 rounded-lg">
                                                                <div className="mb-4">{loaner.notes}</div>
                                                            </div>
                                                        }
                                                    >
                                                        <BiCommentDetail
                                                            className="text-green-500 hover:text-green-700 text-lg"
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                            }}
                                                        />
                                                    </HoverTooltip>
                                                )}
                                                {loaner.internalChatId && (
                                                    <BiCommentDetail
                                                        className="text-red-500 hover:text-red-700 text-lg"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                        }}
                                                    />
                                                )}
                                            </td>
                                        )}

                                        <td className="px-6 align-middle whitespace-nowrap p-3 non-clickable">
                                            <FaRegTrashAlt
                                                size={15}
                                                className="inline clickable mr-1"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    // this.handleDelete(loaner);
                                                }}
                                            />
                                            <FaEdit
                                                size={15}
                                                className="inline clickable"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    // onEdit(loaner.id!);
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    {loanerVehicles?.length < 1 && (
                        <div className="py-8 flex justify-center border-b-2 border-l-2 border-r-2 rounded-b-lg bg-white">
                            <h2 className="text-lg font-semibold">No loaner cars found</h2>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default LoanerCarsTab;

const loanerListColumns: TableColumn[] = [
    {
        name: 'model',
        active: true,
        text: 'Model',
        sortable: true,
        orderStatus: TableColumOrderStatus.None,
    },
    {
        name: 'make',
        active: true,
        text: 'Make',
        sortable: true,
        orderStatus: TableColumOrderStatus.None,
    },
    {
        name: 'series',
        active: true,
        text: 'Series',
        sortable: true,
        orderStatus: TableColumOrderStatus.None,
    },
    {
        name: 'year',
        active: true,
        text: 'Year',
        sortable: true,
        orderStatus: TableColumOrderStatus.None,
    },
    {
        name: 'VIN',
        active: true,
        text: 'VIN',
        sortable: true,
        orderStatus: TableColumOrderStatus.None,
    },
    {
        name: 'notes',
        active: true,
        text: 'Note',
        sortable: false,
        orderStatus: TableColumOrderStatus.None,
    },
];
