import React from 'react';
import history from 'helpers/history';

import { Icon } from 'semantic-ui-react';
import axios from 'helpers/axios';
import config from 'helpers/config';
import CannedJob from 'models/CannedJobModel';
import CannedJobModal from './CannedJobModal';
import Price from 'components/common/Price';

interface Props {}

interface State {
    cannedjobs: CannedJob[];
    isLoading: boolean;
    isCreateCannedJobModalOpen: boolean;
    isEditCannedJobModalOpen: boolean;
    editableCannedJobId: string | null;
}

class CannedJobSettingsPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            cannedjobs: [],
            isLoading: true,
            isCreateCannedJobModalOpen: false,
            isEditCannedJobModalOpen: false,
            editableCannedJobId: null,
        };
    }

    componentDidMount() {
        this.getCannedJobs();
    }

    getCannedJobs() {
        axios.get(`${config.apiUrl}/cannedjob`).then((res) => this.setState({ cannedjobs: res.data }));
    }

    editCannedJob(e: Event, cannedjobId: string) {
        e.stopPropagation();
        this.setState({ isEditCannedJobModalOpen: true, editableCannedJobId: cannedjobId });
    }

    goToCannedJobDetails(cannedjobId: string) {
        history.push(`cannedjob/${cannedjobId}`);
    }

    handleClose(created: boolean | undefined, edited: boolean | undefined, id?: string) {
        if (created || edited) {
            this.getCannedJobs();
        }
        this.setState({
            isCreateCannedJobModalOpen: false,
            isEditCannedJobModalOpen: false,
            editableCannedJobId: null,
        });
    }

    render() {
        const { cannedjobs, isCreateCannedJobModalOpen } = this.state;
        return (
            <div>
                <div className="settings-page__header mb-8">
                    <h2 className="text-2xl font-semibold">CannedJobs</h2>

                    {isCreateCannedJobModalOpen && (
                        <CannedJobModal onClose={(created, edited, id) => this.handleClose(created, edited, id)} />
                    )}
                    <button
                        className="bg-green-600 py-1 px-2 rounded-2xl font-medium text-base ml-auto"
                        onClick={() => this.setState({ isCreateCannedJobModalOpen: !isCreateCannedJobModalOpen })}
                    >
                        + Add new
                    </button>
                </div>

                <div className="overflow-x-auto">
                    <table className="items-center w-full">
                        <thead>
                            <tr className="bg-blue-500 h-12 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                                <td className="table-cell rounded-tl-md">Title</td>
                                <td className="table-cell">Description</td>
                                <td className="table-cell">Items</td>
                                <td className="table-cell">Grand Total</td>
                                <th className="px-6 py-3 rounded-tr-md"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {cannedjobs?.map((cannedjob, _) => (
                                <tr
                                    className="border-b h-12 font-semibold hover:bg-blue-200 clickable bg-white border-r-2 border-l-2"
                                    key={cannedjob.id}
                                    onClick={() => this.goToCannedJobDetails(cannedjob.id)}
                                >
                                    <td className="table-cell clickable">{cannedjob.title}</td>
                                    <td className="table-cell">{cannedjob.description}</td>
                                    <td className="table-cell clickable">{cannedjob.items?.length}</td>
                                    <td className="table-cell">
                                        <Price price={cannedjob.total} />{' '}
                                    </td>
                                    <td className="table-cell non-clickable">
                                        <Icon
                                            className="clickable"
                                            name="edit"
                                            onClick={(e: Event) => this.editCannedJob(e, String(cannedjob.id))}
                                        />
                                        <Icon
                                            className="clickable"
                                            name="trash alternate"
                                            size="small"
                                            // onClick={(e: Event) => this.deleteCannedJob(e, String(cannedjob.id))}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {cannedjobs?.length < 1 && (
                        <div className="py-8 flex justify-center border-b-2 border-l-2 border-r-2 rounded-b-lg bg-white w-full">
                            <h2 className="text-lg font-semibold">You do not have any canned jobs setup yet</h2>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

export default CannedJobSettingsPage;
