import React from 'react';
import history from 'helpers/history';

import { BiMessageAdd } from 'react-icons/bi';

import axios from 'helpers/axios';
import config from 'helpers/config';
import ConversationModel from 'models/ConversationModel';
import CustomerPickerModal from 'components/modals/CustomerPickerModal';
import Customer from 'models/CustomerModel';
import ConversationStatusType from 'models/types/ConversationStatusType';
import Avatar from 'react-avatar';
import MessageModel from 'models/MessageModel';
import MessageSenderType from 'models/types/MessageSenderType';
import { FaEnvelope, FaPhoneSquare, FaSearch } from 'react-icons/fa';
import User from 'models/UserModel';
import RelativeTime from 'components/common/RelativeTime';
import moment from 'moment';
// import { BsSend } from 'react-icons/bs';
import { IoMdSend } from 'react-icons/io';

interface Props {}

interface State {
    isCustomerPickerModalOpen: boolean;

    isCreateAppointmentModalOpen: boolean;
    conversations: ConversationModel[];
    filteredConversations: ConversationModel[];
    users: User[];

    selectedConversation: ConversationModel | null;
}

class TextingPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isCustomerPickerModalOpen: false,
            isCreateAppointmentModalOpen: false,
            conversations: [],
            filteredConversations: [],
            users: [],

            selectedConversation: null,
        };

        this.startConversation = this.startConversation.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
    }

    componentDidMount() {
        axios.get(`${config.apiUrl}/messaging/conversations`).then((res) => {
            this.setState({ conversations: res.data, filteredConversations: res.data });
        });

        this.getAllUsers();
        document.body.style.overflowY = 'hidden';
    }

    componentWillUnmount() {
        document.body.style.overflowY = 'auto';
    }

    getAllUsers() {
        axios.get(`${config.apiUrl}/user`).then((res) => {
            this.setState({ users: res.data });
        });
    }

    openCustomerPicker() {
        this.setState({
            isCustomerPickerModalOpen: true,
        });
    }

    startConversation(customer?: Customer) {
        this.setState({ isCustomerPickerModalOpen: false });
        if (!!!customer) return;

        const newConversation: Partial<ConversationModel> = {
            customerId: customer.id!,
            messages: [],
            status: ConversationStatusType.Active,
            customer,
        };
        this.setState({
            conversations: [newConversation as ConversationModel, ...this.state.conversations],
            filteredConversations: [newConversation as ConversationModel, ...this.state.conversations],
            selectedConversation: newConversation as ConversationModel,
        });
    }

    sendMessage() {
        const { selectedConversation } = this.state;
        // @ts-ignore
        const messageContent = document.getElementById('text-message-content')?.innerText;
        const message: Partial<MessageModel> = {
            senderType: MessageSenderType.Tenant,
            content: messageContent,
        };
        if (!!!selectedConversation?.id) {
            selectedConversation!.messages = [message as MessageModel];

            axios.post(`${config.apiUrl}/messaging/conversation`, selectedConversation).then((res) => {
                const newConversation = res.data as ConversationModel;
                newConversation.customer = selectedConversation!.customer;
                newConversation.customerId = selectedConversation!.customerId;
                this.setState({ selectedConversation: newConversation });
            });
        } else if (selectedConversation.id) {
            axios.post(`${config.apiUrl}/messaging/conversation/${selectedConversation.id}`, message).then((res) => {
                selectedConversation.messages = [res.data, ...selectedConversation.messages];
                this.setState({ selectedConversation });
            });
        }
        // @ts-ignore
        document.getElementById('text-message-content').innerHTML = '';
    }

    getSenderUser(message: MessageModel) {
        if (message.senderType === MessageSenderType.Tenant) {
            const user = this.state.users?.find((user) => user.id === message.createdById);
            return (
                <span>
                    {user?.firstName} {user?.lastName}
                </span>
            );
        } else if (message.senderType === MessageSenderType.Customer) {
            const { selectedConversation } = this.state;

            return (
                <span>
                    {selectedConversation?.customer?.firstName} {selectedConversation?.customer?.lastName}
                </span>
            );
        }
    }

    renderMessages() {
        const { selectedConversation } = this.state;
        const messages = selectedConversation?.messages || [];

        const messageGroups = [];
        let currentGroup: any[] = [];

        for (let i = 0; i < messages.length; i++) {
            const message = messages[i];
            const prevMessage = i !== messages.length - 1 ? messages[i + 1] : null;

            if (
                !prevMessage ||
                message.senderType !== prevMessage.senderType ||
                message.createdById !== prevMessage.createdById ||
                // @ts-ignore
                Math.abs(new Date(message.createdAt) - new Date(prevMessage.createdAt)) > 300000 // 5 minutes
            ) {
                // Start a new message group
                currentGroup.push(message);
                messageGroups.push(currentGroup);
                currentGroup = [];
            } else {
                // Add to the current message group
                currentGroup.push(message);
            }
        }

        if (currentGroup.length > 0) {
            messageGroups.push(currentGroup);
        }

        return messageGroups.map((messageGroup, _) => {
            const firstMessage = messageGroup[0];

            const senderInfo = (
                <div
                    className={`${
                        firstMessage.senderType === MessageSenderType.Tenant ? ' float-right ml-auto' : ' float-left'
                    } text-xs font-bold text-gray-500`}
                >
                    <span className="inline">
                        <span className="mr-1.5">{this.getSenderUser(firstMessage)}</span>
                        <RelativeTime
                            dateTime={firstMessage.createdAt}
                            floatRight={firstMessage.senderType === MessageSenderType.Tenant}
                            displayExactTimePopup={false}
                        />
                    </span>
                </div>
            );

            return messageGroup.map((message, index) => (
                <div key={message.id} className={` w-auto flex flex-col float-right mb-0.5 group`}>
                    {message.senderType === MessageSenderType.Tenant && <div className="w-1/2"></div>}
                    {index === messageGroup.length - 1 && senderInfo}
                    <div
                        className={`${
                            message.senderType === MessageSenderType.Tenant ? ' ml-auto ' : ' mr-auto flex-row-reverse '
                        } flex`}
                    >
                        <div
                            className={`${
                                message.senderType === MessageSenderType.Tenant ? 'mr-6' : 'mr-6'
                            } group-hover:opacity-100 opacity-0 text-gray-400 font-semibold ml-auto align-bottom`}
                        >
                            {moment(message.createdAt).format('YYYY-MM-DD HH:mm')}
                        </div>
                        <div
                            className={`${
                                message.senderType === MessageSenderType.Tenant
                                    ? 'bg-blue-600 text-white float-right ml-auto max-w-5xl'
                                    : 'bg-gray-200 text-black float-left  max-w-5xl mr-6'
                            } rounded-xl px-4 py-1.5 whitespace-pre-wrap`}
                        >
                            {message.content}
                        </div>
                    </div>
                </div>
            ));
        });
    }

    openConversation(conversation: ConversationModel) {
        this.setState({ selectedConversation: conversation }, () => {
            const inputField = document.getElementById('text-message-content');
            inputField!.removeEventListener('paste', this.stripTextStyleOnPaste);
            inputField!.addEventListener('paste', this.stripTextStyleOnPaste);
        });
    }

    stripTextStyleOnPaste(e: Event) {
        e.preventDefault();
        //@ts-ignore
        const text = (e.originalEvent || e).clipboardData.getData('text/plain');
        //@ts-ignore
        document.execCommand('insertHTML', false, text);
    }

    filterConversations(searchText: string) {
        const { conversations } = this.state;
        if (!searchText) {
            return conversations;
        }

        const filteredConversations = conversations.filter((conversation) => {
            const customer = conversation.customer;
            if (!customer) {
                return false;
            }
            const fullName = customer.fullName;
            const phoneNumber = customer.phoneNumbers[0]?.phoneNumber;
            return fullName?.toLowerCase().includes(searchText.toLowerCase()) || phoneNumber?.includes(searchText);
        });

        this.setState({ filteredConversations });
    }

    render() {
        const { isCustomerPickerModalOpen, conversations, selectedConversation, filteredConversations } = this.state;
        return (
            <div className="pl-4  bg-gray-50">
                {isCustomerPickerModalOpen && <CustomerPickerModal onClose={this.startConversation} />}
                <div className="flex h-screen">
                    <div className="w-1/6  bg-gray-50  h-5/6 ">
                        <div className="h-auto flex-row-reverse  border-b py-4 pl-4 border-r  border-gray-300">
                            <div>
                                <button
                                    className=" mr-4 font-semibold py-2 px-3 rounded-xl hover:bg-green-500 border border-green-500
                                text-green-500 hover:text-white ml-auto
                                "
                                    onClick={() => this.openCustomerPicker()}
                                >
                                    <BiMessageAdd className="inline mr-2 " />
                                    <span>New message</span>
                                </button>
                            </div>

                            <div className="relative">
                                <input
                                    type="text"
                                    className="mt-2 rounded-xl border-gray-600 bg-gray-300 border w-10/12 px-2 py-1  "
                                    placeholder="Search contacts..."
                                    onChange={(e) => this.filterConversations(e.target.value)}
                                    id="search-contacts"
                                />
                                <button
                                    onClick={() => document.getElementById('search-contacts')?.setAttribute('', '')}
                                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-black bg-white"
                                >
                                    {/* This is a simple 'x' text, but you can use any icon or image */}x
                                </button>
                            </div>
                            <div>
                                <div className="flex items-center">
                                    <input className="bg-slate-50 hover:bg-red-200 rounded-3xl h-12 w-56" />
                                    <FaSearch className="-ml-9" />
                                </div>
                            </div>
                        </div>
                        <div className="h-5/6 overflow-y-scroll pt-8 border-r  border-gray-300 border-b">
                            {!!!conversations.length && (
                                <div>
                                    <span className="text-gray-600 font-semibold">
                                        You do not have any messages yet
                                    </span>
                                </div>
                            )}
                            {filteredConversations?.map((conversation) => {
                                return (
                                    <div
                                        className={`w-full clickable hover:bg-gray-100 flex py-3 pl-4 ${
                                            selectedConversation?.id === conversation?.id &&
                                            'bg-blue-200 hover:bg-blue-300'
                                        }`}
                                        key={conversation.id}
                                        onClick={() => this.openConversation(conversation)}
                                    >
                                        <div className="mr-2.5">
                                            <Avatar name={conversation.customer.fullName} round size={'33'} />
                                        </div>
                                        <div>
                                            <div className="font-semibold">{conversation.customer?.fullName}</div>
                                            <div className="">
                                                {conversation.customer?.phoneNumbers[0]?.phoneNumber}
                                            </div>
                                            <div className="text-gray-400 text-sm overflow-ellipsis whitespace-nowrap overflow-x-hidden ">
                                                <RelativeTime
                                                    dateTime={conversation.messages[0]?.createdAt}
                                                    floatRight={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="w-5/6 page-height-max bg-white pt-4">
                        {!!!selectedConversation && (
                            <div className="flex flex-col justify-center items-center h-screen">
                                <div className="text-center">
                                    <p>Your messages: Send SMS and emails to your customer</p>
                                    <button
                                        className="mt-4 font-semibold py-2 px-3 rounded-xl hover:bg-green-500 border border-green-500
                        text-green-500 hover:text-white"
                                        onClick={() => this.openCustomerPicker()}
                                    >
                                        <BiMessageAdd className="inline mr-2" />
                                        <span>Send message</span>
                                    </button>
                                </div>
                            </div>
                        )}

                        {!!selectedConversation && (
                            <div className="px-4 h-full flex flex-col">
                                <div
                                    className="border-b-2 border-gray-300"
                                    style={{ height: '5%', maxHeight: '5%', minHeight: '5%' }}
                                >
                                    <div className="flex ">
                                        <Avatar
                                            className="mr-2"
                                            name={selectedConversation.customer?.fullName}
                                            round
                                            size={'33'}
                                        />
                                        <div className="mt-2 mr-6">
                                            <p className="font-semibold ">{selectedConversation.customer?.fullName}</p>
                                        </div>
                                        <div className="pb-2 mt-2 font-semibold">
                                            {!!selectedConversation.customer?.primaryEmail && (
                                                <span>
                                                    <FaEnvelope className="text-blue-500 inline align-baseline mr-1" />
                                                    <a
                                                        className="mr-2"
                                                        href={`mailto:${selectedConversation.customer.primaryEmail}`}
                                                    >
                                                        {selectedConversation.customer.primaryEmail}
                                                    </a>
                                                </span>
                                            )}
                                        </div>
                                        <div className="mt-2 font-semibold">
                                            {selectedConversation.customer?.phoneNumbers?.map((number) => (
                                                <span key={number.phoneNumber}>
                                                    <FaPhoneSquare className="text-blue-500 inline align-baseline mr-1" />
                                                    <a className="mr-2" href={`tel:${number.phoneNumber}`}>
                                                        {number.phoneNumber}
                                                    </a>
                                                </span>
                                            ))}
                                        </div>
                                        <div className="mt-2 font-semibold flex">
                                            <div className="mr-1 text-gray-600">
                                                {selectedConversation.customer?.repairOrders?.length
                                                    ? 'Active repair orders:'
                                                    : 'No active repair orders'}
                                            </div>
                                            {selectedConversation.customer?.repairOrders?.map((RO) => {
                                                return (
                                                    <div
                                                        className="font-semibold text-blue-700 clickable mr-2"
                                                        key={RO.repairOrderNumber}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            history.push(`/repairOrder/${RO.id}`);
                                                        }}
                                                    >
                                                        #{RO.repairOrderNumber}
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-grow overflow-y-auto ">
                                    {!selectedConversation?.messages?.length && (
                                        <div className="flex flex-col justify-center items-center pt-8 ">
                                            <div className="text-center">
                                                <Avatar
                                                    name={selectedConversation.customer?.fullName}
                                                    round
                                                    size={'33'}
                                                />
                                                <p>{selectedConversation.customer?.fullName}</p>
                                            </div>
                                        </div>
                                    )}
                                    <div className="flex-col-reverse flex overflow-y-auto h-full ">
                                        {this.renderMessages()}
                                    </div>
                                </div>
                                <div className="h-auto pb-4">
                                    <div className="flex p-3 items-center border-t border-gray-300">
                                        {/* <textarea
                                            // type="text"
                                            rows={1}
                                            name="text-message-content"
                                            placeholder="Type your message..."
                                            className="flex-grow p-2 rounded-full border border-gray-300 focus:outline-none focus:border-blue-500 bg-gray-100 px-4 py-2"
                                        /> */}
                                        <span
                                            className=" p-1 rounded-md border border-gray-300 focus:outline-none focus:border-blue-500 bg-gray-100 px-4 py-2"
                                            contentEditable={true}
                                            id="text-message-content"
                                            style={{
                                                display: 'block',
                                                // border: 'solid 1px black',
                                                width: '100%',
                                                minHeight: '50px',
                                                whiteSpace: 'pre-wrap',
                                                wordBreak: 'break-word',
                                            }}
                                        ></span>

                                        <button
                                            onClick={() => this.sendMessage()}
                                            className="ml-3 p-2 bg-white border-2 border-blue-700 text-blue-700 hover:text-white rounded-full hover:bg-blue-600 focus:outline-none"
                                        >
                                            <IoMdSend />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default TextingPage;
