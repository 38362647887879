import React from 'react';
import axios from '../../../helpers/axios';
import config from '../../../helpers/config';
import moment from 'moment';

import Modal from '../../common/Modal';
import ModalStateType from '../../../models/generic/ModalState';
import PhoneNumber from '../../../models/PhoneNumberModel';
import SupplierModel from '../../../models/SupplierModel';
import RepairOrder from 'models/RepairOrderModel';
import PaymentType from 'models/types/PaymentType';
import Payment from 'models/PaymentModel';
import PaymentOverview from './PaymentOverview';
import { getActivityLog } from 'actions/repairOrderActions';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { displayConfirmationModal } from 'helpers/services/removeConfirmationService';
import { getPaymentTypeName } from 'helpers/payments/paymentsService';
import PriceInput from 'components/common/PriceInput';

interface Props {
    repairOrder: RepairOrder;
    onClose: (paymentCreated?: boolean, paymentUpdated?: boolean) => void;

    dispatch: Dispatch;
    payment?: Payment;
    leftToPay?: number;
}

interface State {
    modalState: ModalStateType;
    modalHeader: string;
    supplier: SupplierModel;
    isLoading: boolean;
    isFormExpanded: boolean;
    phoneNumbers: PhoneNumber[];
    paymentType: PaymentType | null;

    paymentAmount: number;
    paymentDate: string;
    note: string;
    referenceNumber: string;
    lastCardDigits?: number;
}

class PaymentModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            modalState: ModalStateType.Create,
            modalHeader: '',
            isLoading: false,
            isFormExpanded: false,
            supplier: { phoneNumbers: [{ type: 0 }] } as SupplierModel,
            phoneNumbers: [],
            paymentType: null,
            paymentDate: moment().format('YYYY-MM-DD'),
            note: '',
            referenceNumber: '',
            paymentAmount: 0,
        };

        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    componentDidMount() {
        if (this.props.payment) {
            this.setState({ modalState: ModalStateType.Overview }, () => this.setModalHeader());
        } else {
            this.setState(
                {
                    modalState: ModalStateType.Create,
                    paymentAmount: this.props.leftToPay ? this.props.leftToPay : 0,
                },
                () => this.setModalHeader(),
            );
        }
    }

    setModalHeader() {
        if (this.state.modalState === ModalStateType.Overview) {
            this.setState({ modalHeader: 'Payment Details' });
        } else {
            this.setState({ modalHeader: 'New Payment' });
        }
    }

    updatePayment(paymentData: any) {
        axios
            .put(`${config.apiUrl}/repairOrder/payment`, paymentData)
            .then((_) => {
                const { dispatch } = this.props;
                dispatch<any>(getActivityLog(paymentData.repairorderId));
                this.props.onClose(false, true);
            })
            .catch((_) => {
                this.setState({ isLoading: false });
            });
    }

    addPayment() {
        const { paymentAmount, note, paymentDate, paymentType, referenceNumber, lastCardDigits } = this.state;
        const paymentData = {
            // @ts-ignore
            amount: paymentAmount.replace(/,/g, ''),
            note,
            paymentDate,
            paymentType,
            referenceNumber,
            lastCardDigits,
        };

        const repairOrderId = this.props.repairOrder.id;
        axios
            .post(`${config.apiUrl}/repairOrder/payment/${repairOrderId}`, paymentData)
            .then((_) => {
                const { dispatch } = this.props;
                dispatch<any>(getActivityLog(repairOrderId));

                this.props.onClose(true, false);
            })
            .catch((_) => {
                this.setState({ isLoading: false });
            });
    }

    getValue(name: string) {
        // @ts-ignore
        return this.state.supplier[name];
    }

    handlePhoneNumberChange(phoneNumbers: PhoneNumber[]) {
        // @ts-ignore
        const updatedSupplier: SupplierModel = { ...this.state.supplier, phoneNumbers };
        this.setState({ supplier: updatedSupplier });
    }

    getPriceInputValue() {
        return `$${this.state.paymentAmount}`;
    }

    handleChange(event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) {
        const { name, value } = event.currentTarget;

        // @ts-ignore
        this.setState({ [name]: value });
    }

    handleDelete() {
        const { payment } = this.props;

        if (!!payment) {
            displayConfirmationModal(
                `${getPaymentTypeName(payment.paymentType)} payment of ${new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(payment.amountCents / 100)}`,
            )
                .then(() => {
                    axios.delete(`${config.apiUrl}/repairOrder/payment/${payment?.id}`).then(() => {
                        const { dispatch } = this.props;
                        dispatch<any>(getActivityLog(payment.repairorderId));
                        this.props.onClose(false, true);
                    });
                })
                .catch((_) => _);
        }
    }

    handleUpdate() {
        const { paymentAmount, note, paymentDate, paymentType, referenceNumber, lastCardDigits } = this.state;
        const paymentData = {
            amountCents: paymentAmount,
            note,
            paymentDate,
            paymentType,
            referenceNumber,
            lastCardDigits,
            repairorderId: this.props.payment?.repairorderId,
            id: this.props.payment?.id,
        };

        this.updatePayment(paymentData);
    }

    render() {
        // prettier-ignore
        const { modalHeader, isLoading, paymentType, paymentDate, modalState, note, referenceNumber, lastCardDigits } = this.state;
        const { payment } = this.props;
        return (
            <Modal
                headerText={modalHeader}
                isOpen={true}
                onClose={() => this.props.onClose(false, false)}
                isLoading={isLoading}
                isBackgroundWhite={true}
                isDeleteHidden={modalState !== ModalStateType.Edit}
                onDelete={this.handleDelete}
                onSave={this.handleUpdate}
                saveText={'Update'}
                isSaveHidden={modalState !== ModalStateType.Edit}
            >
                <div className="flex">
                    {modalState === ModalStateType.Overview && payment && (
                        <PaymentOverview
                            payment={this.props.payment!}
                            onEdit={() =>
                                this.setState({
                                    modalState: ModalStateType.Edit,
                                    paymentAmount: payment.amount,
                                    paymentType: payment.paymentType,
                                    paymentDate: moment(payment.paymentDate).format('YYYY-MM-DD'),
                                    note: payment.note,
                                    referenceNumber: payment.referenceNumber,
                                    lastCardDigits:
                                        payment.lastCardDigits === 0 ? undefined : Number(payment.lastCardDigits),
                                })
                            }
                        />
                    )}
                    {modalState !== ModalStateType.Overview && (
                        <>
                            <div className="w-3/5 mr-2">
                                <div>
                                    <PriceInput
                                        placeholder="0.00"
                                        label="Retail Price"
                                        name="amount"
                                        // @ts-ignore
                                        onChange={(e) => this.setState({ paymentAmount: e.target.value })}
                                        amount={this.state.paymentAmount}
                                        className="border-2 rounded-lg p-2 w-full focus:outline-none focus:border-blue-500 font-semibold"
                                    />
                                </div>
                                <div className="flex my-2">
                                    {paymentTypes.map((paymentType, index) => (
                                        <div
                                            onClick={() => this.setState({ paymentType: paymentType.type })}
                                            key={index}
                                            className={`p-2 w-1/4 mx-1 ${
                                                paymentType.type === this.state.paymentType
                                                    ? 'bg-blue-500 text-white'
                                                    : 'text-blue-500 hover:bg-gray-200'
                                            } rounded-lg  text-2xl clickable border-blue-200 border`}
                                        >
                                            {paymentType.text}
                                        </div>
                                    ))}
                                </div>
                                {!!paymentType && (
                                    <div>
                                        <div className="my-2">
                                            <input
                                                type="text"
                                                onChange={this.handleChange}
                                                value={note}
                                                placeholder="Note"
                                                name="note"
                                                autoComplete="off"
                                                className="border-2 rounded-lg p-2 w-full focus:outline-none focus:border-blue-500 font-semibold"
                                            ></input>
                                        </div>
                                        <div className="my-2">
                                            <input
                                                value={paymentDate.toString()}
                                                onChange={this.handleChange}
                                                name="paymentDate"
                                                type="date"
                                                className="border-2 rounded-lg p-2 w-full focus:outline-none focus:border-blue-500 font-semibold"
                                            />
                                        </div>
                                        <div className="my-2">
                                            <input
                                                type="text"
                                                value={referenceNumber}
                                                onChange={this.handleChange}
                                                placeholder="Reference #"
                                                name="referenceNumber"
                                                autoComplete="off"
                                                className="border-2 rounded-lg p-2 w-full focus:outline-none focus:border-blue-500 font-semibold"
                                            ></input>
                                        </div>
                                        {paymentType === PaymentType.Card && (
                                            <div className="my-2">
                                                <input
                                                    type="number"
                                                    onChange={this.handleChange}
                                                    value={lastCardDigits}
                                                    placeholder="1234"
                                                    pattern="[0-9]+"
                                                    maxLength={4}
                                                    name="lastCardDigits"
                                                    autoComplete="off"
                                                    className="border-2 rounded-lg p-2 w-full focus:outline-none focus:border-blue-500 font-semibold"
                                                ></input>
                                            </div>
                                        )}
                                    </div>
                                )}
                                {modalState === ModalStateType.Create && !!paymentType && (
                                    <div>
                                        <button
                                            onClick={() => this.addPayment()}
                                            className="bg-green-600 text-2xl rounded-lg p-2 w-full font-semibold hover:bg-green-700"
                                        >
                                            Record{' '}
                                            {new Intl.NumberFormat('en-US', {
                                                style: 'currency',
                                                currency: 'USD',
                                            }).format(this.state.paymentAmount)}{' '}
                                        </button>
                                    </div>
                                )}
                            </div>
                            <div className="w-2/5 bg-gray-300 p-3 rounded-lg">
                                <div className="font-semibold">Estimate</div>
                            </div>
                        </>
                    )}
                </div>
            </Modal>
        );
    }
}

export default connect()(PaymentModal);

const paymentTypes = [
    {
        type: PaymentType.Card,
        text: 'Card',
    },
    {
        type: PaymentType.Check,
        text: 'Check',
    },
    {
        type: PaymentType.Cash,
        text: 'Cash',
    },
    {
        type: PaymentType.Other,
        text: 'Other',
    },
];
