import React from 'react';
import { Divider, Form, Grid, Transition } from 'semantic-ui-react';
import axios from '../../helpers/axios';
import config from '../../helpers/config';

import Modal from '../common/Modal';
import ModalStateType from '../../models/generic/ModalState';
import PhoneNumber from '../../models/PhoneNumberModel';
import SupplierModel from '../../models/SupplierModel';
import PhoneNumbers from '../common/PhoneNumbers';
import { validatePhoneNumbers } from 'helpers/validators/phoneNumberValidators';

interface Props {
    supplierId?: string;
    onClose: (supplierCreated?: boolean, supplierUpdated?: boolean) => void;
}

interface State {
    modalState: ModalStateType;
    modalHeader: string;
    supplier: SupplierModel;
    isLoading: boolean;
    isFormExpanded: boolean;
    phoneNumbers: PhoneNumber[];
}

class SupplierModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            modalState: this.props.supplierId ? ModalStateType.Edit : ModalStateType.Create,
            modalHeader: '',
            isLoading: true,
            isFormExpanded: false,
            supplier: { phoneNumbers: [{ type: 0 }] } as SupplierModel,
            phoneNumbers: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
        this.save = this.save.bind(this);
    }

    componentDidMount() {
        if (this.state.modalState === ModalStateType.Edit) {
            this.setState({ isLoading: true });
            this.getSupplier();
        } else {
            this.setState({ isLoading: false });
            this.setModalHeader();
        }
    }

    getSupplier() {
        axios
            .get(`${config.apiUrl}/supplier/${this.props.supplierId}`)
            .then((response) => {
                this.setState({ supplier: response.data, isLoading: false });
                this.setModalHeader();
            })
            .catch((err) => console.log(err));
    }

    setModalHeader() {
        if (this.state.modalState === ModalStateType.Edit) {
            const { companyName } = this.state.supplier!;
            const modalHeader = `${companyName}`;
            this.setState({ modalHeader });
        } else {
            this.setState({ modalHeader: 'Create new supplier ' });
        }
    }

    save() {
        this.setState({ isLoading: true });

        if (this.state.modalState === ModalStateType.Create) {
            this.createSupplier(this.state.supplier);
        } else {
            this.updateSupplier(this.state.supplier);
        }
    }

    updateSupplier(supplierData: any) {
        supplierData.phoneNumbers = validatePhoneNumbers(supplierData.phoneNumbers);
        axios
            .put(`${config.apiUrl}/supplier`, supplierData)
            .then((_) => this.props.onClose(false, true))
            .catch((_) => {
                this.setState({ isLoading: false });
            });
    }

    createSupplier(supplierData: any) {
        supplierData.phoneNumbers = validatePhoneNumbers(supplierData.phoneNumbers);
        axios
            .post(`${config.apiUrl}/supplier`, supplierData)
            .then((_) => {
                this.props.onClose(true, false);
            })
            .catch((_) => {
                this.setState({ isLoading: false });
            });
    }

    getValue(name: string) {
        // @ts-ignore
        return this.state.supplier[name];
    }

    handleChange(event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) {
        const { name, value } = event.currentTarget;

        // @ts-ignore
        const updatedSupplier: SupplierModel = { ...this.state.supplier, [name]: value };
        this.setState({ supplier: updatedSupplier });
    }

    handlePhoneNumberChange(phoneNumbers: PhoneNumber[]) {
        // @ts-ignore
        const updatedSupplier: SupplierModel = { ...this.state.supplier, phoneNumbers };
        this.setState({ supplier: updatedSupplier });
    }

    render() {
        const { modalHeader, isLoading, isFormExpanded } = this.state;
        const { phoneNumbers } = this.state.supplier;
        return (
            <Modal
                headerText={modalHeader}
                isOpen={true}
                onClose={() => this.props.onClose(false, false)}
                onSave={this.save}
                isLoading={isLoading}
            >
                <div>
                    <Grid>
                        <Grid.Row columns={1}>
                            <Grid.Column width="16">
                                <Form>
                                    <Form.Group unstackable widths={2}>
                                        <Form.Input
                                            required
                                            name={'companyName'}
                                            label="Company Name"
                                            onChange={this.handleChange}
                                            value={this.getValue('companyName')}
                                        />
                                        <Form.Input
                                            name={'website'}
                                            label="Website URL"
                                            onChange={this.handleChange}
                                            value={this.getValue('website')}
                                        />
                                    </Form.Group>
                                    <Form.Group unstackable widths={2}>
                                        <Form.Input
                                            required
                                            name={'email'}
                                            label="Email"
                                            onChange={this.handleChange}
                                            value={this.getValue('email')}
                                        />
                                    </Form.Group>
                                    <PhoneNumbers
                                        phoneNumbers={phoneNumbers?.length > 0 ? phoneNumbers : [{} as PhoneNumber]}
                                        phoneNumberUpdated={this.handlePhoneNumberChange}
                                    ></PhoneNumbers>
                                    <Divider horizontal>
                                        <span
                                            className="clickable expand-link"
                                            onClick={() => this.setState({ isFormExpanded: !isFormExpanded })}
                                        >
                                            {isFormExpanded ? 'Less' : 'More'}
                                        </span>
                                    </Divider>
                                    <Transition.Group animation="fade up" duration="200">
                                        {isFormExpanded && (
                                            <Form.Group>
                                                <Form.TextArea
                                                    placeholder="Notes"
                                                    label="Notes"
                                                    name="note"
                                                    onChange={this.handleChange}
                                                    value={this.getValue('note')}
                                                />
                                            </Form.Group>
                                        )}
                                    </Transition.Group>
                                    <Form.Group>{/* <AddressFields name=""></AddressFields> */}</Form.Group>
                                </Form>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>
            </Modal>
        );
    }
}

export default SupplierModal;
