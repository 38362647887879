import React from 'react';
import moment from 'moment';
import { FaArchive } from 'react-icons/fa';

import RelativeTime from 'components/common/RelativeTime';
import UserFullName from 'components/common/UserFullName';

import RepairOrder from 'models/RepairOrderModel';
import VehicleModel from 'models/VehicleModel';

import history from 'helpers/history';

interface Props {
    repairOrders: RepairOrder[];
    vehicles: VehicleModel[];
}

const CustomerRepairOrders: React.FC<Props> = ({ repairOrders, vehicles }: Props) => {
    const getRepairOrderVehicleName = (vehicleId: string) => {
        const vehicle = vehicles?.find((vehicle) => vehicle.id === vehicleId);
        return `${vehicle?.year ? `${vehicle.year} ` : ''}${vehicle?.make ? vehicle?.make : ''} ${
            vehicle?.model ? vehicle?.model : ''
        }`;
    };

    return (
        <div className="mt-4 overflow-x-auto">
            <table className="items-center bg-transparent border w-full">
                <thead className="border">
                    <tr className=" bg-blue-600 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                        <th className="pl-6 py-3 rounded-tl-xl">RO #</th>
                        <th className="px-6 py-3">Title</th>
                        <th className="px-6 py-3">Vehicle</th>
                        <th className="px-6 py-3">Odometer</th>
                        <th className="px-6 py-3">Services Count</th>
                        <th className="px-6 py-3">Date Created</th>
                        <th className="px-6 py-3">Created By</th>
                        <th className="px-6 py-3">Total</th>
                        <th className="px-6 py-3">Due</th>
                        <th className="px-6 py-3">Workflow Status</th>
                        <th className="px-6 py-3">Order Status</th>
                        <th className="px-6 py-3 rounded-tr-xl">Labels</th>
                    </tr>
                </thead>

                <tbody>
                    {repairOrders?.map((repairOrder, index) => (
                        <tr
                            className={`border group font-medium hover:bg-blue-200 clickable ${
                                index % 2 === 0 ? 'bg-gray-100' : 'bg-blue-100'
                            }`}
                            key={index}
                            onClick={() => history.push(`/repairOrder/${repairOrder.id}`)}
                        >
                            <td className="px-6 min-w-max align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2 text-left flex items-center">
                                <span className="font-semibold text-gray-500">#{repairOrder.repairOrderNumber}</span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>{repairOrder.title} </span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>{getRepairOrderVehicleName(repairOrder.vehicleId)} </span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>
                                    {repairOrder?.odometerIn}{' '}
                                    {repairOrder?.odometerOut && `- ${repairOrder.odometerOut}`}{' '}
                                </span>
                            </td>

                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>{repairOrder.services?.length}</span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span className="float-left">
                                    <RelativeTime dateTime={repairOrder.createdAt} />
                                </span>
                                <br />
                                <span>
                                    <span className="text-sm">
                                        {moment(repairOrder.createdAt).format('MM/DD/YYYY HH:mm')}
                                    </span>
                                </span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                {!!repairOrder?.createdById && <UserFullName userId={repairOrder?.createdById} />}
                            </td>

                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                $
                                {repairOrder.services
                                    .map((service) => service.total)
                                    .reduce((a, b) => +a + +b, 0)
                                    .toFixed(2)}
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>{repairOrder.title}</span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                {repairOrder.isArchived ? (
                                    <span className="bg-green-500 rounded-lg p-1.5 text-white font-semibold text-base">
                                        <span>
                                            <FaArchive className="inline mr-1" />
                                        </span>
                                        <span>Archived</span>
                                    </span>
                                ) : (
                                    <span>{repairOrder.workflowStatus?.name}</span>
                                )}
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>{repairOrder?.status === 1 ? 'Invoice' : 'Estimate'}</span>
                            </td>

                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                {repairOrder.labels?.map((label, indx) => (
                                    <div
                                        key={indx}
                                        style={{ backgroundColor: label.color }}
                                        className="mr-2 px-1 py-1 rounded-lg hover:opacity-80 text-xs"
                                    >
                                        <span className="font-semibold text-white inline-flex whitespace-nowrap">
                                            {label.title}
                                        </span>
                                    </div>
                                ))}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {repairOrders?.length! < 1 && (
                <div className="border py-4 px-4 wb-white text-gray-600 text-lg font-semibold text-center">
                    This customer has no repair orders yet.
                </div>
            )}
        </div>
    );
};

export default CustomerRepairOrders;
