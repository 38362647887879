import actionTypes from '../../helpers/actionTypes';
import Tenant from '../../models/TenantModel';

class InitialState {
    tenant?: Tenant;
}

const tenantReducer = (state = InitialState, action: any): any => {
    switch (action.type) {
        case actionTypes.GET_TENANT_SETTINGS_SUCCESS:
            return {
                ...state,
                tenant: action.payload,
            };
        default: {
            return state;
        }
    }
};

export default tenantReducer;
