import LaborRate from 'models/LaborRateModel';
import RepairOrder from 'models/RepairOrderModel';
import React from 'react';

import { Droppable } from 'react-beautiful-dnd';
import { CgMore } from 'react-icons/cg';
import JobTicket from './JobTicket';

interface Props {
    column: any;
    tasks: RepairOrder[];
    laborRates: LaborRate[];
    jobArchivedOrDeleted: (repairOrderId: string) => void;
}

interface State {}

class BoardColumn extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        const { column, tasks, laborRates, jobArchivedOrDeleted } = this.props;
        tasks.sort((task1, task2) => task1.columnOrderIndex - task2.columnOrderIndex);
        return (
            <div className="bg-gray-50 border-2 px-2 py-2 h-full">
                <div className="flex border-b">
                    <h2 className="font-bold pb-2 text-lg">{column.name}</h2>
                    <div className="ml-auto inline-flex">
                        <span className="rounded-full bg-gray-300 mr-3 flex items-center w-6 h-6 justify-center font-semibold text-sm">
                            {tasks.length}
                        </span>
                        <div className=" text-lg">
                            <CgMore size={20} className="clickable" />
                        </div>
                    </div>
                </div>
                <Droppable droppableId={column.id}>
                    {(provided) => {
                        return (
                            <div {...provided.droppableProps} ref={provided.innerRef} className="h-full pt-1">
                                {tasks.map((RO, index) => (
                                    <JobTicket
                                        job={RO}
                                        index={index}
                                        key={RO.id}
                                        jobArchived={() => jobArchivedOrDeleted(RO.id!)}
                                        laborRates={laborRates}
                                    />
                                ))}
                                {provided.placeholder}
                            </div>
                        );
                    }}
                </Droppable>
            </div>
        );
    }
}

export default BoardColumn;
