import { resolveCustomerName } from 'helpers/customerNameResolver';
import React, { useEffect, useState } from 'react';

interface Props {
    customerId: string;
}

const UserFullName: React.FC<Props> = ({ customerId }: Props) => {
    const [userFullName, setUserFullName] = useState('');

    useEffect(() => {
        if (!!customerId) {
            resolveCustomerName(customerId)
                .then((createdByUserFullName) => setUserFullName(createdByUserFullName))
                .catch((_) => _);
        }
    }, []);

    return <>{userFullName}</>;
};

export default UserFullName;
