import actionTypes from '../../helpers/actionTypes';
import VehicleModel from '../../models/VehicleModel';

class initialState {
    vehicles!: VehicleModel[];
    vehiclesLoading!: boolean;
    vehicle!: VehicleModel;
    vehicleLoading!: boolean;
    vehicleUpdating!: boolean;
    isNewVehicleLoading!: boolean;
    vehiclesCount!: number;
}

const vehicleReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case actionTypes.GET_VEHICLES_REQUEST:
            return {
                ...state,
                vehiclesLoading: true,
            };
        case actionTypes.GET_VEHICLES_SUCCESS:
            return {
                ...state,
                vehicles: action.payload,
                vehiclesLoading: false,
            };
        case actionTypes.GET_VEHICLE_REQUEST:
            return {
                ...state,
                vehicleLoading: true,
            };
        case actionTypes.GET_VEHICLE_SUCCESS:
            return {
                ...state,
                vehicle: action.payload,
                vehicleLoading: false,
            };
        case actionTypes.CREATE_VEHICLE_REQUEST:
            return {
                ...state,
                isNewVehicleLoading: true,
            };
        case actionTypes.CREATE_VEHICLE_SUCCESS:
            return {
                ...state,
                vehicle: action.payload,
                isNewVehicleLoading: false,
            };
        case actionTypes.UPDATE_VEHICLE_REQUEST:
            return {
                ...state,
                vehicleUpdating: true,
            };
        case actionTypes.UPDATE_VEHICLE_SUCCESS:
            return {
                ...state,
                vehicle: action.payload,
                vehicleUpdating: false,
            };
        case actionTypes.DELETE_VEHICLE_SUCCESS: {
            // @ts-ignore
            const vehicles = state.vehicles.filter((vehicle) => vehicle.id !== action.payload);
            return {
                ...state,
                vehicles,
            };
        }
        case actionTypes.GET_VEHICLES_OVERVIEW_SUCCESS: {
            return {
                ...state,
                vehiclesCount: action.payload.count,
            };
        }
        default:
            return state;
    }
};

export default vehicleReducer;
