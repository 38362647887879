import React, { useState } from 'react';

interface Props {
    odometerIn: number;
    odometerOut: number;
    onSave: (odometerIn: number, odometerOut: number) => void;
}

const OdometerInOut: React.FC<Props> = (props: Props) => {
    const [isEditable, setIsEditable] = useState(false);
    const [odometerIn, setOdometerIn] = useState(props.odometerIn);
    const [odometerOut, setOdometerOut] = useState(props.odometerOut);

    const toggleState = () => {
        if (isEditable) {
            props.onSave(odometerIn, odometerOut);
        }
        setIsEditable(!isEditable);
    };

    return (
        <div className="rounded-lg border-2 inline-block p-2">
            <div className="flex items-baseline">
                <div className="font-semibold mr-2">Odometer</div>
                <div className="mr-1">In: </div>

                <input
                    className="border border-blue-200 rounded-lg px-2 pt-0 w-24 disabled:border-0 mr-4"
                    type="number"
                    disabled={!isEditable}
                    value={odometerIn}
                    onChange={(e: any) => {
                        setOdometerIn(e.target.value);
                    }}
                />

                <div className="mr-1">Out: </div>

                <input
                    className="border border-blue-200 rounded-lg px-2 pt-0 w-24 disabled:border-0 mr-4"
                    type="number"
                    disabled={!isEditable}
                    value={odometerOut || undefined}
                    onChange={(e: any) => {
                        setOdometerOut(e.target.value);
                    }}
                />
                <button
                    className="p-1 rounded-lg bg-green-600 font-semibold text-sm px-2"
                    onClick={() => toggleState()}
                >
                    {isEditable ? 'Save' : 'Edit'}
                </button>
            </div>

            <div></div>
        </div>
    );
};

export default OdometerInOut;
