import Toggle from 'components/common/Toggle';
import AccessPointTypes from 'models/types/AccessPointTypes';
import React from 'react';

interface Props {
    name: string;
}
const AccessPointsTab: React.FC<Props> = () => {
    return (
        <div>
            {accessPointTypes.map((AP) => {
                return (
                    <div key={AP.key} className="flex">
                        <div className="font-semibold text-gray-500">{AP.text}</div>
                        <div className="ml-auto mb-1.5">
                            <Toggle
                                value={true}
                                name={AP.text}
                                onChange={function (): void {
                                    throw new Error('Function not implemented.');
                                }}
                            />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const accessPointTypes = [
    { value: AccessPointTypes.Dashboard, key: AccessPointTypes.Dashboard, text: 'Mobile' },
    { value: AccessPointTypes.Calendar, key: AccessPointTypes.Calendar, text: 'Work' },
    { value: AccessPointTypes.Inventory, key: AccessPointTypes.Inventory, text: 'Office' },
    { value: AccessPointTypes.Reports, key: AccessPointTypes.Reports, text: 'Home' },
    { value: AccessPointTypes.Settings, key: AccessPointTypes.Settings, text: 'Fax' },
];

export default AccessPointsTab;
