import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { getInventoryItem, updateInventoryItem } from 'actions/inventoryActions';
import InventoryItem from 'models/InventoryItemModel';
import { FaEdit, FaSave } from 'react-icons/fa';
import InventoryRepairOrders from './tabs/InventoryRepairOrders';
import FilesList from 'components/common/FilesList';

interface Props {
    dispatch: Dispatch;
    inventoryItem: InventoryItem;
    inventoryItemLoading: boolean;
    inventoryItemUpdating: boolean;
}

interface State {
    isEditMode: boolean;
    activeTab: number;
    inventoryItem?: InventoryItem;
}

class InventoryDetailsPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            activeTab: 0,
            isEditMode: false,
        };

        this.getInventoryItem();
        this.getInventoryItem = this.getInventoryItem.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    getInventoryItem() {
        // @ts-ignore
        const { inventoryItemId } = this.props.match.params;
        const { dispatch } = this.props;
        dispatch<any>(getInventoryItem(inventoryItemId, true));
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const { name, value } = event.currentTarget;

        // @ts-ignore
        const inventoryItem: InventoryItem = { ...this.state.inventoryItem, [name]: value };
        this.setState({ inventoryItem });
    }

    getTabs() {
        return [
            {
                title: 'Repair Orders',
                component: (
                    <InventoryRepairOrders
                        inventoryItem={this.props.inventoryItem}
                        repairOrders={this.props.inventoryItem?.repairOrders}
                    />
                ),
            },
            {
                title: 'Files',
                component: <FilesList files={this.props.inventoryItem?.files} entityName="inventory item" />,
            },
        ];
    }

    getValue(propertyName: string) {
        // @ts-ignore
        // prettier-ignore
        return this.state?.inventoryItem ? this.state?.inventoryItem[propertyName] : this.props?.inventoryItem[propertyName];
    }

    getInventoryDetailsForm() {
        const FORM_INPUT_CLASS = 'rounded-md border-none font-bold w-3/4 pl-4 py-2 focus:ring-1 focus:outline-none';
        const { isEditMode } = this.state;

        return (
            <div className="mt-8 border-2 rounded-xl bg-gray-100 p-6 w-1/2 relative flex flex-col">
                {!!this.props.inventoryItemLoading ||
                    (!!this.props.inventoryItemUpdating && (
                        <div className="absolute left-1/2 top-1/3">
                            <h2 className="font-bold">Loading...</h2>
                        </div>
                    ))}
                <div className="grid grid-cols-2 mb-4">
                    <div className="w-full my-2 pl-4">
                        <span className="inline-block w-1/4">Description</span>
                        <input
                            className={FORM_INPUT_CLASS}
                            type="text"
                            value={this.getValue('description')}
                            name="description"
                            disabled={!isEditMode}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="w-full my-2 pl-4">
                        <span className="inline-block w-1/4">Item Code</span>
                        <input
                            className={FORM_INPUT_CLASS}
                            type="text"
                            value={this.getValue('itemCode')}
                            name="itemCode"
                            disabled={!isEditMode}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="w-full my-2 pl-4">
                        <span className="inline-block w-1/4">Cost</span>
                        <div className="relative inline">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center mr-auto">
                                <span className="font-bold"> $ </span>
                            </div>

                            <input
                                className={`${FORM_INPUT_CLASS} pl-6`}
                                type="text"
                                value={this.getValue('cost')}
                                name="cost"
                                disabled={!isEditMode}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="w-full my-2 pl-4">
                        <span className="inline-block w-1/4">Retail Price</span>
                        <div className="relative inline">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center mr-auto">
                                <span className="font-bold"> $ </span>
                            </div>
                            <input
                                className={`${FORM_INPUT_CLASS} pl-6`}
                                type="text"
                                value={this.getValue('retailPrice')}
                                name="retailPrice"
                                disabled={!isEditMode}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>
                    <div className="w-full my-2 pl-4">
                        <span className="inline-block w-1/4">Brand</span>
                        <input
                            className={FORM_INPUT_CLASS}
                            type="text"
                            value={this.getValue('brand')}
                            name="brand"
                            disabled={!isEditMode}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="w-full my-2 pl-4">
                        <span className="inline-block w-1/4">Location</span>
                        <input
                            className={FORM_INPUT_CLASS}
                            type="text"
                            value={this.getValue('location')}
                            name="location"
                            disabled={!isEditMode}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <button
                    className={`${isEditMode ? 'bg-green-400' : 'bg-gray-400'} rounded-2xl px-4 py-1 font-bold ml-auto`}
                    onClick={() => this.toggleEditMode()}
                >
                    {isEditMode ? <FaSave className="inline mr-2" /> : <FaEdit className="inline mr-2" />}
                    {isEditMode ? 'Save' : 'Edit'}
                </button>
            </div>
        );
    }

    toggleEditMode() {
        const { isEditMode } = this.state;

        if (isEditMode) {
            const { dispatch } = this.props;
            dispatch<any>(updateInventoryItem(this.state.inventoryItem!));
        }

        this.setState({
            isEditMode: !isEditMode,
            inventoryItem: isEditMode ? this.state.inventoryItem : this.props.inventoryItem,
        });
    }

    render() {
        const { activeTab } = this.state;
        const { inventoryItemLoading, inventoryItem } = this.props;

        const tabs = this.getTabs();

        return (
            <div className="bg-white mb-40">
                {!!inventoryItemLoading && <b>Loading...</b>}
                <div className="w-full bg-blue-500 p-8">
                    <h3 className="text-blue-100">Inventory Item</h3>
                    <h2 className="text-blue-50 text-2xl font-semibold">
                        {inventoryItem?.description}{' '}
                        {!!inventoryItem?.itemCode && <span>({inventoryItem.itemCode})</span>}
                    </h2>
                </div>
                {!inventoryItemLoading && inventoryItem && (
                    <div className="pl-8 flex flex-col">
                        <div className="mt-8">{this.getInventoryDetailsForm()}</div>
                        <div className="mt-8">
                            {tabs.map((tab, index) => {
                                return (
                                    <div
                                        className={`inline-flex px-5 border-b-4 ${
                                            activeTab === index
                                                ? ' border-blue-500 '
                                                : '  border-gray-500 hover:text-blue-400'
                                        }`}
                                        key={index}
                                    >
                                        <span
                                            className={`clickable text-lg ${
                                                activeTab === index
                                                    ? 'font-bold text-blue-500 border-blue-500 '
                                                    : ' font-medium text-gray-400 border-gray-500 hover:text-blue-400'
                                            }`}
                                            onClick={() => this.setState({ activeTab: index })}
                                        >
                                            {tab.title}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                        {tabs[activeTab].component}
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    inventoryItem: state.inventory.inventoryItem,
    inventoryItemLoading: state.inventory.inventoryItemLoading,
    inventoryItemUpdating: state.inventory.inventoryItemUpdating,
});

export default connect(mapStateToProps)(InventoryDetailsPage);
