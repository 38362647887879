import React from 'react';
import FormModes from '../../../helpers/FormModes';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import CustomerSection from './CustomerSection';
import VehicleSection from './VehicleSection';
import BookingDetailsSection from './BookingDetailsSection';
import { Grid, Segment } from 'semantic-ui-react';
import Booking from '../../../models/BookingModel';
import { createBooking } from '../../../actions/bookingActions';

interface Props {
    dispatch: Dispatch;
}

interface State {
    mode: FormModes;
    isNewVehicleFormOpen: boolean;
    customerId: string;
    vehicleId: string;
    description: string;
}

class BookingPage extends React.Component<Props, State> {
    state: State;

    constructor(props: any) {
        super(props);

        this.state = {
            mode: FormModes.Edit,
            isNewVehicleFormOpen: false,
            customerId: '',
            vehicleId: '',
            description: '',
        };

        this.customerSelected = this.customerSelected.bind(this);
        this.vehicleSelected = this.vehicleSelected.bind(this);
        this.bookingSaved = this.bookingSaved.bind(this);
    }

    componentDidMount() {
        // @ts-ignore
        const { bookingId } = this.props.match.params;
        const pageMode = !!bookingId ? FormModes.Edit : FormModes.Create;
        this.setState({ mode: pageMode });
    }

    customerSelected(customerId: string) {
        this.setState({ customerId });
    }

    vehicleSelected(vehicleId: string) {
        this.setState({ vehicleId });
    }

    bookingSaved(bookingDetails: Booking) {
        const { customerId, vehicleId } = this.state;
        bookingDetails.customerId = customerId;
        bookingDetails.vehicleId = vehicleId;

        this.props.dispatch<any>(createBooking(bookingDetails));
    }

    render() {
        const { mode, vehicleId } = this.state;
        return (
            <div>
                <Grid divided="vertically">
                    <Grid.Column width={16}>
                        <Segment size="big">
                            <Grid divided>
                                <Grid.Row verticalAlign="middle">
                                    <Grid.Column width={8}>
                                        <CustomerSection customerSelected={this.customerSelected} />
                                    </Grid.Column>

                                    <Grid.Column width={8}>
                                        <VehicleSection vehicleSelected={this.vehicleSelected} />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Segment>
                    </Grid.Column>

                    <Grid.Column width={16}>
                        <BookingDetailsSection
                            bookingSaved={this.bookingSaved}
                            isEditMode={mode === FormModes.Edit}
                            isEnabled={vehicleId.length > 0}
                        />
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

export default connect()(BookingPage);
