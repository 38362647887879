import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import axios from 'helpers/axios';
import config from 'helpers/config';

import RepairOrder from 'models/RepairOrderModel';
import Service from 'models/ServiceModel';
import actionTypes from 'helpers/actionTypes';
import Payments from './Payments';
import RepairOrderActivityLog from './RepairOrderActivityLog';
import { getActivityLog } from 'actions/repairOrderActions';
import RepairOrderStatus from './RepairOrderStatus';
import RepairOrderAuthorizations from './RepairOrderAuthorizations';
import { AiFillDownCircle, AiFillUpCircle } from 'react-icons/ai';
import moment from 'moment';
import { resolveUserName } from 'helpers/userNameResolver';
import UserFullName from 'components/common/UserFullName';
import { GrEdit } from 'react-icons/gr';
import DateTimePicker from 'components/common/DateTimePicker';
import LaborRate from 'models/LaborRateModel';
import UserPicker from 'components/common/UserPicker';
import User from 'models/UserModel';

interface Props {
    repairOrder: RepairOrder;
    services: Service[];
    dispatch: Dispatch;
}

interface State {
    collapsedStates: boolean[];
    createdByUserFullName: string;

    isDueDatePickerOpen: boolean;
    laborRates: LaborRate[];
    users: User[];
    isServiceWriterPickerOpen: boolean;
}

class RepairOrderSideView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            collapsedStates: [false, false, false, false],
            createdByUserFullName: '',
            isDueDatePickerOpen: false,
            laborRates: [],
            isServiceWriterPickerOpen: false,
            users: [],
        };
        this.updateOrderStatus = this.updateOrderStatus.bind(this);
        this.updateWorkflowStatus = this.updateWorkflowStatus.bind(this);
        this.updateDueDate = this.updateDueDate.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            resolveUserName(this.props.repairOrder?.createdById!)
                .then((createdByUserFullName) => this.setState({ createdByUserFullName }))
                .catch((_) => _);
        }, 1500);

        this.getLaborRates();
        this.getUsers();
    }

    getUsers() {
        axios.get(`${config.apiUrl}/user`).then((res) => {
            const users = res.data?.map((user: any) => {
                return { ...user, name: `${user.firstName} ${user.lastName}` };
            });
            this.setState({ users });
        });
    }

    getLaborRates() {
        axios.get(`${config.apiUrl}/laborRate`).then((res) => {
            const rates = res.data?.map((laborRate: LaborRate) => {
                return { ...laborRate, name: `${laborRate.name} - $${laborRate.rate.toFixed(2)}` };
            });
            this.setState({ laborRates: rates });
        });
    }

    updateOrderStatus(status: number) {
        if (status !== this.props.repairOrder.status) {
            axios
                .put(`${config.apiUrl}/repairOrder`, { ...this.props.repairOrder, status })
                .then((_) => {
                    this.props.dispatch({
                        type: actionTypes.GET_REPAIR_ORDER_SUCCESS,
                        payload: { ...this.props.repairOrder, status },
                    });
                    this.props.dispatch<any>(getActivityLog(this.props.repairOrder.id));
                })
                .catch((err) => console.log(err));
        }
    }

    updateWorkflowStatus(workflowStatusId: string) {
        if (workflowStatusId !== this.props.repairOrder.workflowStatusId) {
            axios
                .put(`${config.apiUrl}/repairOrder`, { ...this.props.repairOrder, workflowStatusId })
                .then((_) => {
                    this.props.dispatch({
                        type: actionTypes.GET_REPAIR_ORDER_SUCCESS,
                        payload: { ...this.props.repairOrder, workflowStatusId },
                    });
                    this.props.dispatch<any>(getActivityLog(this.props.repairOrder.id));
                })
                .catch((err) => console.log(err));
        }
    }

    updateDueDate(dueDate: string) {
        this.setState({ isDueDatePickerOpen: false });
        axios
            .put(`${config.apiUrl}/repairOrder`, { ...this.props.repairOrder, dueDate })
            .then((_) => {
                this.props.dispatch({
                    type: actionTypes.GET_REPAIR_ORDER_SUCCESS,
                    payload: { ...this.props.repairOrder, dueDate },
                });
                this.props.dispatch<any>(getActivityLog(this.props.repairOrder.id));
            })
            .catch((err) => console.log(err));
    }

    updateServiceWriter(serviceWriterId: string) {
        this.setState({ isServiceWriterPickerOpen: false });
        axios
            .put(`${config.apiUrl}/repairOrder`, { ...this.props.repairOrder, serviceWriterId })
            .then((_) => {
                this.props.dispatch({
                    type: actionTypes.GET_REPAIR_ORDER_SUCCESS,
                    payload: { ...this.props.repairOrder, serviceWriterId },
                });
                this.props.dispatch<any>(getActivityLog(this.props.repairOrder.id));
            })
            .catch((err) => console.log(err));
    }

    getSidebarComponents() {
        return [
            {
                title: 'Authorization',
                component: (
                    <RepairOrderAuthorizations
                        services={this.props.services}
                        laborRates={this.state.laborRates}
                        repairOrderId={this.props.repairOrder.id}
                    />
                ),
                isCollapsed: this.state.collapsedStates[0],
            },
            {
                title: 'Payments',
                component: (
                    <Payments
                        repairOrder={this.props.repairOrder}
                        services={this.props.services}
                        laborRates={this.state.laborRates}
                    />
                ),
                isCollapsed: this.state.collapsedStates[1],
            },
            {
                title: 'RO Status',
                component: (
                    <RepairOrderStatus
                        status={this.props.repairOrder?.status}
                        workflowStatusId={this.props.repairOrder?.workflowStatusId!}
                        updateOrderStatus={this.updateOrderStatus}
                        updateWorkflowStatus={this.updateWorkflowStatus}
                    />
                ),
                isCollapsed: this.state.collapsedStates[2],
            },
            {
                title: 'Activity Log',
                component: <RepairOrderActivityLog />,
                isCollapsed: this.state.collapsedStates[3],
            },
        ];
    }

    render() {
        const { repairOrder } = this.props;
        const { isDueDatePickerOpen, isServiceWriterPickerOpen, users } = this.state;
        return (
            <div className="ml-4 my-6 pt-4 px-4 bg-white border-2 rounded-xl w-1/4 space-y-6 divide-y divide-gray-200 h-fit">
                <div className="text-center text-green-500 text-lg font-bold">RO #{repairOrder?.repairOrderNumber}</div>
                <div>
                    <h2 className="text-gray-600 font-semibold text-lg text-center mb-3 w-full ">RO Summary </h2>
                    <div className="flex">
                        <div className="font-semibold text-gray-500">Created at:</div>
                        <div className="ml-auto font-bold text-black">
                            {moment(repairOrder?.createdAt).format('MM/DD/YYYY HH:mm')}
                        </div>
                    </div>
                    <div className="flex">
                        <div className="font-semibold text-gray-500">Created by:</div>
                        <div className="ml-auto font-bold text-black">
                            {!!repairOrder?.createdById && <UserFullName userId={repairOrder?.createdById} />}
                        </div>
                    </div>
                    <div className="flex">
                        <div className="font-semibold text-gray-500">Due Date:</div>
                        {isDueDatePickerOpen ? (
                            <div className="ml-auto">
                                <DateTimePicker
                                    onSave={this.updateDueDate}
                                    onCancel={() => this.setState({ isDueDatePickerOpen: false })}
                                    dateTime={repairOrder?.dueDate}
                                />
                            </div>
                        ) : (
                            <div
                                className="ml-auto font-bold text-black clickable flex items-baseline"
                                onClick={() => this.setState({ isDueDatePickerOpen: true })}
                            >
                                <span className="mr-1">
                                    {repairOrder?.dueDate ? (
                                        <span className="text-blue-500 underline">
                                            {moment(repairOrder.dueDate).format('MM/DD/YYYY h:mm a')}
                                        </span>
                                    ) : (
                                        <span className="text-lg">-/-</span>
                                    )}
                                </span>
                                <GrEdit className="text-green-500 text-sm" />
                            </div>
                        )}
                    </div>
                    <div className="flex">
                        <div className="font-semibold text-gray-500">Service Writer:</div>
                        {!isServiceWriterPickerOpen && !!repairOrder?.serviceWriterId && (
                            <div className="ml-auto font-bold text-blue-500 underline flex">
                                <UserFullName userId={repairOrder?.serviceWriterId} />
                                <GrEdit
                                    className="text-green-500 text-sm clickable"
                                    onClick={() => this.setState({ isServiceWriterPickerOpen: true })}
                                />
                            </div>
                        )}
                        {isServiceWriterPickerOpen && (
                            <div
                                id="dropdown"
                                className={` ${
                                    !isServiceWriterPickerOpen && 'hidden'
                                } w-full z-10 absolute  bg-white rounded divide-y divide-gray-100 shadow  border border-blue-500`}
                            >
                                <ul className="py-1 text-sm text-gray-700" aria-labelledby="dropdownDefault">
                                    {users.map((user) => (
                                        <li
                                            className={`block clickable ${
                                                user.id === repairOrder.serviceWriterId
                                                    ? 'bg-blue-400 text-white'
                                                    : 'hover:bg-gray-100'
                                            } py-2 px-4 font-semibold`}
                                            key={user.id}
                                            onClick={() => {
                                                this.updateServiceWriter(user.id);
                                            }}
                                        >
                                            {user.name}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
                {!!repairOrder &&
                    this.getSidebarComponents().map((component, index) => {
                        return (
                            <div key={index} className="pt-2">
                                <div className="flex">
                                    <h2 className="text-gray-600 font-semibold text-lg text-center mb-3 w-full ">
                                        {component.title}
                                    </h2>
                                    <span className="inline ml-auto">
                                        {component.isCollapsed ? (
                                            <AiFillDownCircle
                                                className="clickable text-xl text-gray-600 mt-2"
                                                onClick={() => {
                                                    const updatedCollapsedStates = this.state.collapsedStates;
                                                    updatedCollapsedStates[index] = !updatedCollapsedStates[index];
                                                    this.setState({ collapsedStates: updatedCollapsedStates });
                                                }}
                                            />
                                        ) : (
                                            <AiFillUpCircle
                                                className="clickable text-xl text-gray-600 mt-2"
                                                onClick={() => {
                                                    const updatedCollapsedStates = this.state.collapsedStates;
                                                    updatedCollapsedStates[index] = !updatedCollapsedStates[index];
                                                    this.setState({ collapsedStates: updatedCollapsedStates });
                                                }}
                                            />
                                        )}
                                    </span>
                                </div>
                                {
                                    <div
                                        className={`transition-all duration-500  overflow-x-visible ${
                                            component.isCollapsed
                                                ? 'max-h-0 overflow-y-hidden'
                                                : 'h-fit overflow-y-visible'
                                        }`}
                                    >
                                        <div>{component.component}</div>
                                    </div>
                                }
                            </div>
                        );
                    })}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    services: state.repairOrder.services,
    repairOrder: state.repairOrder.repairOrder,
});

export default connect(mapStateToProps)(RepairOrderSideView);
