import axios from 'helpers/axios';
import config from 'helpers/config';
import history from 'helpers/history';
import Tenant from 'models/TenantModel';
import moment from 'moment';
import React from 'react';

interface Props {}

interface State {
    tenants: Tenant[];
}

class AdminPortal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            tenants: [],
        };
    }

    componentDidMount() {
        this.getTenants();
    }

    getTenants() {
        axios.get(`${config.apiUrl}/admin/tenants`).then((res) => this.setState({ tenants: res.data }));
    }

    render() {
        const { tenants } = this.state;
        return (
            <div className="ml-12 mt-12 mb-12 pb-12" id="main-layout-1">
                <h2 className="text-blue-600 font-bold mb-12 text-lg">Admin Portal</h2>

                <h2 className="text-blue-600 font-bold mb-4">Tenants:</h2>
                {tenants?.map((tenant, _) => {
                    return (
                        <div
                            key={tenant.id}
                            className="text-black w-1/2 p-2.5 rounded-lg border border-gray-600 mb-4 hover:bg-blue-400 clickable"
                            onClick={() => history.push(`admin_portal/tenant/${tenant.id}`)}
                        >
                            <div>{tenant.businessName}</div>
                            <div className="font-semibold text-sm text-gray-600 ">
                                Registered on {moment(tenant.createdAt).format('YYYY-MM-DD HH:mm:ss')}
                            </div>
                            {!!tenant.plan && (
                                <div>
                                    <div>
                                        <span>Plan: </span>
                                        <span className="font-semibold text-green-700">{tenant.plan.name}</span>
                                    </div>
                                </div>
                            )}

                            {tenant.isInDemo && (
                                <div className="font-semibold">
                                    Demo ending in {moment(tenant.trialEnding).format('YYYY-MM-DD HH:mm:ss')}
                                </div>
                            )}
                        </div>
                    );
                })}
                <div></div>
            </div>
        );
    }
}

export default AdminPortal;
