import React from 'react';

interface Props {
    name: string;
    value: boolean;
    disabled?: boolean;
    size?: number;

    onChange?: (newValue: React.FormEvent<any>) => void;
}

const Checkbox: React.FC<Props> = ({ name, onChange, value = false, size = 6, disabled = false }: Props) => {
    return (
        <div className="block">
            <div className="mt-2">
                <input
                    checked={value}
                    type="checkbox"
                    className={`w-${size} h-${size} text-green-600 border-0 rounded-md focus:ring-0`}
                    disabled={disabled}
                    onChange={(e) => {
                        if (onChange) {
                            onChange({
                                currentTarget: {
                                    name,
                                    value: e.target.checked,
                                },
                            } as React.FormEvent<any>);
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default Checkbox;
