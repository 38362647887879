import React from 'react';
import { connect } from 'react-redux';
import { login } from '../../../actions/authActions';
import { Dispatch } from 'redux';
import { Link } from 'react-router-dom';

interface Props {
    errorMessage: string;
    dispatch: Dispatch;
    loggingIn: boolean;
}

interface State {
    username: string;
    password: string;
    isLoginButtonEnabled: boolean;
}

class LoginPage extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);

        this.state = {
            isLoginButtonEnabled: true,
            username: '',
            password: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const { name, value } = event.currentTarget;
        // @ts-ignore
        this.setState({ [name]: value });
    }

    submit(e: any) {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch<any>(login(this.state.username, this.state.password));
    }

    render() {
        return (
            <div className="w-full h-full bg-blue-500 grid place-items-center">
                <div className="sm:w-3/4 md:w-1/3 bg-gray-50 p-10 rounded-lg">
                    <h2 className="text-3xl font-sans font-bold">Log In</h2>
                    <form>
                        <div className="my-2">
                            <input
                                className="border-2 rounded-lg p-2 w-full focus:outline-none focus:border-blue-500 font-semibold"
                                id="login_page_email"
                                placeholder="E-mail address"
                                name="username"
                                onChange={this.handleChange}
                                type="email"
                                autoFocus
                            />
                        </div>
                        <div>
                            <input
                                type="password"
                                placeholder="Password"
                                name="password"
                                onChange={this.handleChange}
                                className="border-2 rounded-lg p-2 w-full focus:outline-none focus:border-blue-500 font-semibold"
                                id="login_page_password"
                            ></input>
                        </div>
                        {this.props.errorMessage && (
                            <div className="text-red-600 font-bold">{this.props.errorMessage}</div>
                        )}
                        <div className="my-2">
                            <button
                                className="w-full bg-green-600 rounded-lg text-center py-2 font-bold hover:opacity-70"
                                onClick={this.submit}
                                disabled={!this.state?.isLoginButtonEnabled}
                            >
                                Log in
                            </button>
                        </div>
                    </form>
                    <div className="relative flex py-5 items-center">
                        <div className="flex-grow border-t border-gray-400"></div>
                        <span className="flex-shrink mx-4 text-gray-400">OR</span>
                        <div className="flex-grow border-t border-gray-400"></div>
                    </div>
                    <div>
                        <div className="text-center font-semibold">
                            <span>
                                Don&apos;t have an account?&nbsp;
                                <Link className="text-blue-400 hover:text-blue-700" to="/register">
                                    Sign Up
                                </Link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    errorMessage: state.auth.errorMessage,
    loggingIn: state.auth.loggingIn,
});

export default connect(mapStateToProps)(LoginPage);
