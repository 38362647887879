import React from 'react';
import { FaCheck, FaTimesCircle } from 'react-icons/fa';
import axios from 'helpers/axios';
import config from 'helpers/config';
import Label from 'models/LabelModel';

interface Props {
    labelType: string;
    labelsSelected: Function;
    labelIds?: string[];
}

interface State {
    labels: Label[];
    selectedLabels: any[];
    id: string;
    isOpen: boolean;
}

class LabelPicker extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            labels: [],
            selectedLabels: [],
            id: `labelPicker${Math.random().toString(36).substr(2, 9)}`,
            isOpen: false,
        };

        this.onClickOutside = this.onClickOutside.bind(this);
    }

    getLabels() {
        const { labelType } = this.props;
        axios.get(`${config.apiUrl}/label/${labelType}`).then((res) => {
            this.setState({ labels: res.data }, this.updateSelectedLabels);
        });
    }

    updateSelectedLabels() {
        const { labels } = this.state;
        const { labelIds } = this.props;
        for (const label of labels) {
            label.isSelected = labelIds?.includes(label.id);
        }
        this.setState({ labels });
    }

    onClickOutside(e: any) {
        const { id } = this.state;
        const target = document.querySelector(`#${id}`);
        const withinBoundaries = e.composedPath().includes(target);

        if (withinBoundaries) {
        } else {
            this.close();
        }
    }

    close() {
        const { id } = this.state;
        // this.props.labelsSelected(this.state.labels.filter((label) => label.isSelected));
        document.getElementById(id)?.classList.toggle('hidden');
        document.removeEventListener('click', this.onClickOutside);
        this.setState({ isOpen: false });
    }

    openPicker() {
        const { labels, id, isOpen } = this.state;

        if (!!isOpen) {
            return;
        }

        if (labels?.length < 1) {
            this.getLabels();
        } else {
            this.updateSelectedLabels();
        }

        document.getElementById(id)?.classList.toggle('hidden');
        document.addEventListener('click', this.onClickOutside);
        this.setState({ isOpen: true });
    }

    toggleLabelSelected(index: number) {
        const { labels, selectedLabels } = this.state;

        labels[index].isSelected = !labels[index].isSelected;
        this.setState({ labels, selectedLabels }, () => {
            this.props.labelsSelected(this.state.labels.filter((label) => label.isSelected));
        });
    }

    render() {
        const { labels, id } = this.state;
        return (
            <div className={`${id}`}>
                <span
                    onClick={() => this.openPicker()}
                    className="bg-gray-200 text-gray-400 px-2 rounded-md font-semibold clickable text-sm py-1s whitespace-nowrap"
                >
                    Add +
                </span>
                <div
                    className="bg-white border border-black mr-3 z-50 text-sm max-w-xls w-56 text-left no-underline break-words rounded-lg absolute font-bold hidden"
                    id={id}
                >
                    <div className="text-gray-700 opacity-75 font-semibold p-3 mb-0 border-b border-solid border-gray-100 uppercase rounded-t-lg">
                        <div className="flex ">
                            <span>Pick a label</span>

                            <FaTimesCircle
                                onClick={() => this.close()}
                                alignmentBaseline="baseline"
                                className="items-baseline clickable ml-auto mr-2"
                            />
                        </div>
                    </div>
                    {labels.map((label, indx) => (
                        <div
                            key={indx}
                            style={{ backgroundColor: label.color }}
                            className="mx-3 p-2 my-2 rounded-lg clickable hover:opacity-80"
                            onClick={() => this.toggleLabelSelected(indx)}
                        >
                            <span className="font-semibold text-white clickable">
                                {!!label.isSelected && <FaCheck className="inline mr-3" />}
                                {label.title}
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default LabelPicker;
