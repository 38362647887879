import React, { useEffect, useState } from 'react';
import history from 'helpers/history';
import { FaCog, FaPowerOff, FaQuestionCircle } from 'react-icons/fa';
import User from 'models/UserModel';
import Avatar from 'react-avatar';
import store from 'store/store';
import actionTypes from 'helpers/actionTypes';

interface Props {
    user: User;
}

const UserAvatar: React.FC<Props> = ({ user }: Props) => {
    const [userName, setUserName] = useState('');
    const [profilePanelExpanded, setProfilePanelExpanded] = useState(false);

    useEffect(() => {
        setUserName(`${user?.firstName} ${user?.lastName}`);
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('token');
        store.dispatch({ type: actionTypes.DELETE_RECENTLY_VIEWED_ROS, payload: {} });
        history.push('/login');
    };

    const onClickOutside = (e: any) => {
        const target = document.querySelector(`#user_avatar_dropdown`);
        const withinBoundaries = e.composedPath().includes(target);

        if (withinBoundaries) {
        } else {
            togglePicker(false);
        }
    };

    const togglePicker = (open: boolean) => {
        if (open) {
            setProfilePanelExpanded(true);
            document.addEventListener('click', onClickOutside);
        } else {
            setProfilePanelExpanded(false);
            document.removeEventListener('click', onClickOutside);
        }
    };

    const redirect = (toPath: string) => {
        history.push(toPath);

        togglePicker(false);
    };

    return (
        <div className="relative clickable">
            <div
                className={`self-center px-2 flex hover:bg-green-800 ${
                    profilePanelExpanded && 'bg-green-800'
                } py-1 rounded-lg border`}
                onClick={() => togglePicker(!profilePanelExpanded)}
            >
                <div>
                    <Avatar name={userName} round size={'33'} />
                </div>
                <div className="ml-1 font-semibold text-white">
                    <div className="pb-0 mb-0">{userName}</div>
                    <div className="text-sm">{user.tenant?.businessName}</div>
                </div>
            </div>
            {!!profilePanelExpanded && (
                <div
                    className="self-center cursor-default absolute bg-white rounded-lg border-gray-300 border-2 py-2 px-4 mt-4 w-72 right-0 flex flex-col divide-y-2 divide-gray-200"
                    id="user_avatar_dropdown"
                >
                    <div>
                        {/* <div className="flex items-center justify-center w-full">
                            <div className="flex flex-grow items-center justify-center">
                                <div className="text-center">
                                    <Avatar name={userName} round size={'33'} className="clickable" />
                                    <div className="font-semibold">{userName}</div>
                                    <div className="text-sm text-gray-500">{user.tenant?.businessName}</div>
                                </div>
                            </div>
                            <div
                                className="clickable rounded-full bg-red-500 text-white px-2 py-1 hover:bg-red-700"
                                onClick={() => togglePicker(false)}
                            >
                                X
                            </div>
                        </div> */}
                        <div className="relative flex w-full justify-center items-center">
                            <div className="absolute inset-y-0 right-0 mr-4 flex items-center -mt-16 -ml-16">
                                <button
                                    type="button"
                                    className="clickable bg-black"
                                    onClick={() => togglePicker(false)}
                                >
                                    X
                                </button>
                            </div>
                            <div className="flex flex-col items-center justify-center">
                                <Avatar name={userName} round size="33" className="clickable" />
                                <div className="font-semibold">{userName}</div>
                                <div className="text-sm text-gray-500">{user.tenant?.businessName}</div>
                            </div>
                        </div>

                        <div className="text-sm font-bold text-gray-600">{user?.email}</div>
                        {user.isAdmin && <div className="font-semibold text-sm text-gray-300">Admin</div>}
                        <div
                            className="bg-yellow-500 text-white font-bold  px-2 rounded-xl inline text-sm clickable"
                            onClick={() => redirect('/settings/subscriptionSettings')}
                        >
                            Trial Plan
                        </div>
                    </div>
                    <div className="mt-4  pt-4">
                        <div className="text-center">
                            <div
                                onClick={() => redirect('/settings/users')}
                                className="flex clickable hover:bg-gray-300 rounded-lg py-2 pl-2 mb-2"
                            >
                                <FaCog className="mr-2 " />
                                <span> Settings</span>
                            </div>
                            <div
                                onClick={() => redirect('/settings/users')}
                                className="flex clickable hover:bg-gray-300 rounded-lg py-2 pl-2"
                            >
                                <FaQuestionCircle className="mr-2 " />
                                <span> Help</span>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4  pt-4">
                        <div className="text-center">
                            <button
                                onClick={handleLogout}
                                className="border border-black px-3 rounded-xl hover:opacity-70 text-gray-500 hover:bg-gray-300   hover:text-black py-2 "
                            >
                                <span>Logout</span>
                                <FaPowerOff className="inline ml-2 align-text-bottom" />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default UserAvatar;
