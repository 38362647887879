import moment from 'moment';
import React, { useEffect, useState } from 'react';

interface Props {
    text?: string;
    disabled?: boolean;
    className?: string;

    dateTime?: string | Date;
    onSave: (value: any) => void;
    onCancel: () => void;
}

const DateTimePicker: React.FC<Props> = (props: Props) => {
    const [date, setDate] = useState<string | undefined>(undefined);
    const [time, setTime] = useState<string | undefined>(undefined);

    const [isDateValid, setIsDateValid] = useState(true);
    const [isTimeValid, setIsTimeValid] = useState(true);

    const handleSave = () => {
        if (!!!date) {
            setIsDateValid(false);
        }
        if (!!!time) {
            setIsTimeValid(false);
        }

        if (!(date && time)) return;
        const dateTime = `${date}T${time}`;
        const UTC = moment(dateTime).format();
        if (!!UTC) {
            props.onSave(UTC);
        }
    };

    useEffect(() => {
        if (!!props.dateTime) {
            const dateTime = moment(props.dateTime);
            setDate(dateTime.format('yyyy-MM-DD'));
            setTime(dateTime.format('hh:mm'));
        }
    }, []);

    return (
        <div>
            <div className="relative flex mb-1">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </div>
                <input
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 py-1 mr-1 ${
                        !isDateValid && 'border-red-700'
                    }`}
                    placeholder="Select date"
                />
                <input
                    type="time"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1 ${
                        !isTimeValid && 'border-red-700'
                    }`}
                ></input>
            </div>
            <div className="flex flex-row-reverse">
                <button
                    className="text-sm rounded-xl px-2 font-semibold border border-gray-500 bg-green-500"
                    onClick={() => handleSave()}
                >
                    Save
                </button>
                <button
                    onClick={() => props.onCancel()}
                    className="text-sm rounded-xl px-2 font-semibold border border-gray-500 bg-gray-400 mr-1"
                >
                    Cancel
                </button>
            </div>
        </div>
    );
};

export default DateTimePicker;
