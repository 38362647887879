import Price from 'components/common/Price';
import RelativeTime from 'components/common/RelativeTime';
import Vehicle from 'models/VehicleModel';
import React from 'react';

interface Props {
    vehicle: Vehicle;
}

const VehicleStats: React.FC<Props> = ({ vehicle: vehicle }: Props) => {
    return (
        <div className="mt-8 border border-black rounded-lg p-4 divide-y-2 divide-gray-200 w-1/2 ">
            <h2 className="uppercase text-lg font-semibold">Quick look</h2>
            <div>
                <span className="inline-block py-4">
                    <b>
                        {vehicle?.make} {vehicle?.model}
                    </b>{' '}
                    has <b>{vehicle?.stats?.completedRepairOrdersCount}</b> repair orders totaling{' '}
                    <span className="text-green-500 font-semibold">
                        <Price price={vehicle?.stats?.lifetimeSpend!} />
                    </span>
                </span>
                <div className="grid grid-cols-4">
                    <div className="mr-16">
                        <div className="text-gray">Last visit</div>
                        <div className="font-semibold text-lg float-left">
                            <RelativeTime dateTime={vehicle?.stats?.lastRoDate!} />
                        </div>
                    </div>

                    <div className="mr-16">
                        <div className="text-gray">Lifetime Spend</div>
                        <div className="font-semibold text-lg">
                            <Price price={vehicle?.stats?.lifetimeSpend!} />
                        </div>
                    </div>
                    <div className="mr-16">
                        <div className="text-gray">Lifetime ARO</div>
                        <div className="font-semibold text-lg">
                            <Price price={vehicle?.stats?.lifetimeARO!} />
                        </div>
                    </div>
                    <div className="mr-16">
                        <div className="text-gray">Lifetime Profit</div>
                        <div className="font-semibold text-lg">
                            <Price price={vehicle?.stats?.lifetimeProfit!} />
                        </div>
                    </div>
                    <div className="mr-16">
                        <div className="text-gray">Lifetime ARO Profit</div>
                        <div className="font-semibold text-lg">
                            <Price price={vehicle?.stats?.lifetimeAROProfit!} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VehicleStats;
