import React from 'react';

interface Props {
    name: string;
    email: string;
}

const RegistrationComplete: React.FC<Props> = (props: Props) => {
    return (
        <div>
            <h3>
                Hi <a>{props.name}</a>,
            </h3>
            <h2>Thanks for joining Repairware!</h2>
            <h2>
                We have sent you an email to <a>{props.email}</a>. Please follow the steps in the email to finish
                setting up your account.
            </h2>
        </div>
    );
};

export default RegistrationComplete;
