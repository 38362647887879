import axios from '../helpers/axios';
import config from '../helpers/config';
import { Dispatch } from 'redux';
import actionTypes from '../helpers/actionTypes';
import VehicleIssue from 'models/VehicleIssue';
import RepairOrder from 'models/RepairOrderModel';

const { apiUrl } = config;

// prettier-ignore
export const updateRepairOrder = (repairOrder: RepairOrder, isStateAlreadyUpdated = false) => 
    (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.UPDATE_REPAIR_ORDER_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.UPDATE_REPAIR_ORDER_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.UPDATE_REPAIR_ORDER_FAILURE, payload });

    dispatch(request());

    axios
        .put(`${apiUrl}/repairOrder/`, repairOrder)
        .then((res) => {
            if (!isStateAlreadyUpdated) {
                dispatch(success(res.data));
            }
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};

export const getActivityLog = (repairOrderId: string) => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.UPDATE_REPAIR_ORDER_ACTIVITY_LOG_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.UPDATE_REPAIR_ORDER_ACTIVITY_LOG_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.UPDATE_REPAIR_ORDER_ACTIVITY_LOG_FAILURE, payload });

    dispatch(request());

    axios
        .get(`${apiUrl}/repairOrder/activityLog/${repairOrderId}`)
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};

export const updateVehicleIssue = (vehicleIssue: VehicleIssue) => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.UPDATE_REPAIR_ORDER_VEHICLE_ISSUES_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.UPDATE_REPAIR_ORDER_VEHICLE_ISSUES_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.UPDATE_REPAIR_ORDER_VEHICLE_ISSUES_FAILURE, payload });

    dispatch(request());

    axios
        .put(`${apiUrl}/repairOrder/vehicleIssues/${vehicleIssue.id}`, vehicleIssue)
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};

export const createVehicleIssue = (vehicleIssue: VehicleIssue, repairOrderId: string) => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.ADD_REPAIR_ORDER_VEHICLE_ISSUE_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.ADD_REPAIR_ORDER_VEHICLE_ISSUE_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.ADD_REPAIR_ORDER_VEHICLE_ISSUE_FAILURE, payload });

    dispatch(request());

    axios
        .post(`${apiUrl}/repairOrder/${repairOrderId}/vehicleIssues`, vehicleIssue)
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};

export const deleteVehicleIssue = (vehicleIssueId: string) => (dispatch: Dispatch) => {
    const request = (payload: string) => ({ type: actionTypes.DELETE_REPAIR_ORDER_VEHICLE_ISSUE_REQUEST, payload });
    const success = (payload: any) => ({ type: actionTypes.DELETE_REPAIR_ORDER_VEHICLE_ISSUE_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.DELETE_REPAIR_ORDER_VEHICLE_ISSUE_FAILURE, payload });

    dispatch(request(vehicleIssueId));

    axios
        .delete(`${apiUrl}/repairOrder/vehicleIssues/${vehicleIssueId}`)
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};
