import React from 'react';
import BaseRegistryGroupList from './BaseRegistryGroupList';

interface Props {}

interface State {}

const baseRegistryGroups = ['Customer Source', 'Product Groups'];

class BaseRegistrySettings extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div>
                <div className="settings-page__header">
                    <h3>Base Registry</h3>
                    {baseRegistryGroups.map((group, index) => (
                        <BaseRegistryGroupList group={group} key={index} />
                    ))}
                </div>
            </div>
        );
    }
}

export default BaseRegistrySettings;
