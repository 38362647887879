enum AccessPointTypes {
    Dashboard = 1,
    DashboardDeleteRO,
    Archive,
    Calendar,
    Inventory,
    Reports,
    Settings,
}

export default AccessPointTypes;
