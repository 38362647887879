const getVehicleListSettings = () => {
    try {
        const settings = JSON.parse(localStorage.getItem('VEHICLE_LIST_SETTINGS')!);
        return settings;
    } catch (err) {
        console.log(err);
        return null;
    }
};

const setVehicleListSettings = (settings: any): void => {
    try {
        localStorage.setItem('VEHICLE_LIST_SETTINGS', settings);
    } catch (err) {
        console.log(err);
    }
};

const getRecentRepairOrders = (): any[] => {
    try {
        const recentRos = JSON.parse(localStorage.getItem('RECENT_REPAIR_ORDERS')!);
        if (!recentRos?.length) {
            throw '';
        }
        return recentRos;
    } catch (err) {
        return [];
    }
};

const setRecentRepairOrders = (recentRos: any): void => {
    try {
        localStorage.setItem('RECENT_REPAIR_ORDERS', recentRos);
    } catch (err) {
        console.log(err);
    }
};

export { getVehicleListSettings, setVehicleListSettings, getRecentRepairOrders, setRecentRepairOrders };
