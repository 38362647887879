import React, { useState } from 'react';
import history from 'helpers/history';
import {
    FaWrench,
    FaBoxes,
    FaCog,
    FaTachometerAlt,
    FaTruck,
    FaCar,
    FaUsers,
    FaHistory,
    FaCalendar,
    FaClock,
} from 'react-icons/fa';
import { BiConversation } from 'react-icons/bi';
import { connect } from 'react-redux';
import User from 'models/UserModel';
import { BsClipboardData } from 'react-icons/bs';

interface NavigationItem {
    name: string;
    location: string;
    className?: string;
    icon: React.ReactElement;
}

const iconSize = '20';

interface Props {
    currentUser?: User;
    location: Location;
}

const SideBar: React.FC<Props> = (props) => {
    const getCurrentLocationIndex = (newRoute: string) =>
        navigationItems.findIndex((route, index) => {
            if (newRoute.includes('settings') && route.location.includes('settings')) {
                return true;
            }
            if (newRoute.includes('reports') && route.location.includes('reports')) {
                return true;
            }
            if (index !== 0) {
                return newRoute.includes(route.location.toLocaleLowerCase());
            }
            if (newRoute === '/' && route.location === '/') {
                return true;
            }
            return false;
        });

    const currentLocationIndex = getCurrentLocationIndex(props.location.pathname.toLocaleLowerCase());
    const [activeNavigationItemindex, setActiveNavigationItemindex] = useState(
        currentLocationIndex > 0 ? currentLocationIndex : 0,
    );

    const [hoverdIconIndex, setHoverdIconIndex] = useState<number | null>(null);

    const navigateTo = (navigationItem: NavigationItem) => {
        history.push(navigationItem.location);
    };

    history.listen((location) => {
        const newLocation = location.pathname.toLowerCase();
        setActiveNavigationItemindex(getCurrentLocationIndex(newLocation));
    });

    const isExpanded = !props.currentUser?.userSetting.isSideBarCollapsed;
    return (
        <div className={`sidebar-container ${!isExpanded && 'collapsed'}`}>
            <div
                className={`sidebar border-r border-blue-600 bg-blue-700 ${!isExpanded && 'collapsed'} ${
                    props.currentUser?.tenant?.isInDemo && ' mt-5'
                }`}
                style={{ backgroundColor: '#2c3745' }}
            >
                {navigationItems.map((item, index) => {
                    return (
                        <button
                            key={item.name}
                            className={`py-4 sm:pl-3 lg:pl-6 clickable w-full relative group ${
                                index === activeNavigationItemindex
                                    ? 'bg-white text-blue-500 border-l-4 border-blue-700 '
                                    : 'hover:bg-blue-400'
                            } ${item.className}`}
                            onClick={() => navigateTo(item)}
                            onMouseEnter={() => setHoverdIconIndex(index)}
                            onMouseLeave={() => setHoverdIconIndex(null)}
                        >
                            <div
                                className={`align-baseline flex lg:text-lg md:text-sm sm:text-sm ${
                                    index === activeNavigationItemindex ? 'text-blue-500' : ''
                                }`}
                            >
                                <span className="mr-2">{item.icon}</span>
                                {isExpanded && <span className="font-semibold">{item.name}</span>}
                            </div>
                            {!isExpanded && hoverdIconIndex === index && (
                                <div className="absolute top-1/2 transform -translate-y-1/2 bg-gray-800 ml-2 rounded-lg p-1 font-semibold flex left-20  group-hover:z-10 opacity-0 group-hover:opacity-100 z-50">
                                    <div className="relative z-10 p-2 text-xs text-white shadow-lg inline">
                                        {item.name}
                                    </div>
                                </div>
                            )}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(SideBar);

const navigationItems: NavigationItem[] = [
    {
        name: 'Dashboard',
        location: '/',
        icon: <FaTachometerAlt size={iconSize} />,
    },
    // {
    //     name: "Bookings",
    //     location: "/bookin"
    // },
    {
        name: 'Job center',
        location: '/jobCentre',
        icon: <FaWrench size={iconSize} />,
    },
    {
        name: 'Calendar',
        location: '/calendar',
        icon: <FaCalendar size={iconSize} />,
    },
    {
        name: 'Texting',
        location: '/texting',
        icon: <BiConversation size={iconSize} />,
    },
    {
        name: 'Vehicles',
        location: '/vehicles',
        icon: <FaCar size={iconSize} />,
    },
    {
        name: 'Customers',
        location: '/customers',
        icon: <FaUsers size={iconSize} />,
    },
    {
        name: 'Inventory',
        location: '/inventory',
        icon: <FaBoxes size={iconSize} />,
    },
    {
        name: 'Suppliers',
        location: '/suppliers',
        icon: <FaTruck size={iconSize} />,
    },
    {
        name: 'Time Logs',
        location: '/timeLogs',
        icon: <FaClock size={iconSize} />,
    },
    {
        name: 'Reports',
        location: '/reports/eodReports',
        icon: <BsClipboardData size={iconSize} />,
    },
    {
        name: 'Archive',
        location: '/archive',
        className: 'mt-auto',
        icon: <FaHistory size={iconSize} />,
    },
    {
        name: 'Settings',
        location: '/settings/companySettings',
        className: 'mb-8',
        icon: <FaCog size={iconSize} />,
    },
];
