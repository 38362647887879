import React from 'react';
import history from 'helpers/history';
import { FaCarSide, FaEdit, FaRegTrashAlt, FaSort, FaSortDown, FaSortUp } from 'react-icons/fa';

import VehicleModel from 'models/VehicleModel';
import TableColumn, { TableColumOrderStatus } from 'models/TableColumnModel';
import { displayConfirmationModal } from 'helpers/services/removeConfirmationService';
import { deleteVehicle } from 'actions/vehicleActions';
import { connect } from 'react-redux';
import { BiCommentDetail } from 'react-icons/bi';
import HoverTooltip from './HoverTooltip';
import InternalChatModal from 'components/modals/InternalChatModal';
import InternalChatSubject from 'models/types/InternalChatSubjectType';

interface State {
    isInternalChatModelVisible?: boolean;
    selectedVehicle?: VehicleModel;
}

interface Props {
    dispatch: any;
    columns: TableColumn[];
    vehicles: VehicleModel[];
    onEdit: (vehicleId: string) => void;
    onOrder: Function;
    isLoading?: boolean;
    hideColumns?: string[];
}

class VehiclesTable extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isInternalChatModelVisible: false,
        };
    }

    isColumnActive(columnName: string) {
        return this.props.columns.find((column) => column.name === columnName)?.active;
    }

    handleDelete(vehicle: VehicleModel) {
        displayConfirmationModal(`${vehicle.make} ${vehicle.model} ${vehicle.VIN ? `(${vehicle.VIN})` : ''}`)
            .then(() => {
                this.props.dispatch(deleteVehicle(String(vehicle.id)));
            })
            .catch((_) => _);
    }

    openInternalChat(vehicle: VehicleModel) {
        this.setState({ isInternalChatModelVisible: true, selectedVehicle: vehicle });
    }

    render() {
        const { vehicles, columns, isLoading, onEdit } = this.props;
        const { isInternalChatModelVisible, selectedVehicle } = this.state;
        return (
            <div className="overflow-x-auto">
                {isInternalChatModelVisible && (
                    <InternalChatModal
                        chatId={selectedVehicle!.internalChatId!}
                        subject={InternalChatSubject.Vehicle}
                        subjectId={selectedVehicle?.id!}
                        onClose={() => {
                            this.setState({ isInternalChatModelVisible: false, selectedVehicle: undefined });
                        }}
                    />
                )}
                <table className="items-center w-full">
                    <thead>
                        <tr className=" bg-blue-500 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                            {columns.map(
                                (column, index) =>
                                    column.active && (
                                        <th key={index} className={`pl-6 py-3 ${index === 0 && 'rounded-tl-md'}`}>
                                            <span
                                                className="clickable"
                                                onClick={() => column.sortable && this.props.onOrder(column.name)}
                                            >
                                                <span>{column.text}</span>
                                                {!!column.sortable &&
                                                    (column?.orderStatus === TableColumOrderStatus.None ? (
                                                        <FaSort className="inline ml-1" />
                                                    ) : column?.orderStatus === TableColumOrderStatus.Ascending ? (
                                                        <FaSortDown className="inline ml-1" />
                                                    ) : (
                                                        <FaSortUp className="inline ml-1" />
                                                    ))}
                                            </span>
                                        </th>
                                    ),
                            )}
                            <th className="px-6 py-3 rounded-tr-md">Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        {!!vehicles &&
                            vehicles.map((vehicle, index) => (
                                <tr
                                    className={`border-b font-semibold hover:bg-blue-200 clickable bg-white border-r-2 border-l-2`}
                                    key={index}
                                    onClick={() => {
                                        history.push(`/vehicle/${vehicle.id}`);
                                    }}
                                >
                                    {this.isColumnActive('model') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3">
                                            <span className="align-baseline">
                                                <FaCarSide className="inline mr-2 text-lg text-gray-600" />
                                                <span>{vehicle.model}</span>
                                            </span>
                                        </td>
                                    )}
                                    {this.isColumnActive('make') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3">
                                            <span>{vehicle.make}</span>
                                        </td>
                                    )}
                                    {this.isColumnActive('series') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3">
                                            <span>{vehicle.series}</span>
                                        </td>
                                    )}
                                    {this.isColumnActive('year') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3">
                                            <span>{vehicle.year}</span>
                                        </td>
                                    )}
                                    {this.isColumnActive('licensePlate') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3">
                                            <span>{vehicle.licensePlate}</span>
                                        </td>
                                    )}
                                    {this.isColumnActive('VIN') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3">{vehicle.VIN}</td>
                                    )}

                                    {this.isColumnActive('notes') && (
                                        <td className="px-6 align-middle p-3">
                                            {vehicle.notes?.length && (
                                                <HoverTooltip
                                                    content={
                                                        <div className="bg-white p-4 rounded-lg">
                                                            <div className="mb-4">{vehicle.notes}</div>
                                                        </div>
                                                    }
                                                >
                                                    <BiCommentDetail
                                                        className="text-green-500 hover:text-green-700 text-lg"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            this.openInternalChat(vehicle);
                                                        }}
                                                    />
                                                </HoverTooltip>
                                            )}
                                            {vehicle.internalChatId && (
                                                <BiCommentDetail
                                                    className="text-red-500 hover:text-red-700 text-lg"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        this.openInternalChat(vehicle);
                                                    }}
                                                />
                                            )}
                                        </td>
                                    )}
                                    {this.isColumnActive('customer') && (
                                        <td className="px-6 align-middle whitespace-nowrap p-3 non-clickable">
                                            <span
                                                className="link"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    history.push(`/customer/${vehicle.customer?.id}`);
                                                }}
                                            >
                                                {vehicle.customer?.firstName} {vehicle.customer?.lastName}
                                            </span>
                                        </td>
                                    )}
                                    <td className="px-6 align-middle whitespace-nowrap p-3 non-clickable">
                                        <FaRegTrashAlt
                                            size={15}
                                            className="inline clickable mr-1"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                this.handleDelete(vehicle);
                                            }}
                                        />
                                        <FaEdit
                                            size={15}
                                            className="inline clickable"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                onEdit(vehicle.id!);
                                            }}
                                        />
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
                {vehicles?.length < 1 && !isLoading && (
                    <div className="py-8 flex justify-center border-b-2 border-l-2 border-r-2 rounded-b-lg bg-white">
                        <h2 className="text-lg font-semibold">No vehicles found</h2>
                    </div>
                )}
            </div>
        );
    }
}

export default connect()(VehiclesTable);
