import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FaUser } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Dropdown, Icon } from 'semantic-ui-react';

import { getCustomers, GetCustomersRequest, getCustomer } from 'actions/customerActions';
import Customer from 'models/CustomerModel';
import CustomerModal from 'components/modals/CustomerModal';

interface Props {
    dispatch: Dispatch;
    customers: Customer[];
    newCustomerLoading: boolean;
    customerSelected: Function;
    newCustomer: Customer;
    customersLoading: boolean;
    customer?: Customer;
}

interface State {
    isNewCustomerFormOpen: boolean;
    isCreateCustomerModalOpen: boolean;
    customerSearchPhrase: string;
    [x: string]: any;
}

class CustomerSection extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isNewCustomerFormOpen: false,
            isCreateCustomerModalOpen: false,
            customerSearchPhrase: '',
        };

        this.getCustomers = this.getCustomers.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.modalClosed = this.modalClosed.bind(this);
    }

    getPreferredContactMethodOptions(): { value: string; text: string; key: number }[] {
        return [
            {
                value: 'phone',
                text: 'Phone',
                key: 1,
            },
            {
                value: 'SMS',
                text: 'SMS',
                key: 2,
            },
            {
                value: 'email',
                text: 'Email',
                key: 3,
            },
        ];
    }

    getCustomerSourceOptions() {
        return [
            {
                value: 'facebook',
                text: 'Facebook',
                key: 1,
            },
            {
                value: 'adwords',
                text: 'Adwords',
                key: 2,
            },
            {
                value: 'refferal',
                text: 'Refferal',
                key: 3,
            },
        ];
    }

    searchCustomers(searchPhrase: string) {
        const { dispatch } = this.props;
        const customerSearchRequest: GetCustomersRequest = {
            searchPhrase,
            page: 0,
            itemsPerPage: 10,
        };
        dispatch<any>(getCustomers(customerSearchRequest));
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const { name, value } = event.currentTarget;
        this.setState({ [name]: value });
    }

    getCustomers() {
        const { customers } = this.props;

        if (!customers) return [];

        return customers?.map((customer) => {
            return {
                key: customer.id,
                value: customer.id,
                text: customer.fullName,
            };
        });
    }

    getCustomerOverview(customer: Customer) {
        return (
            <div className="flex flex-col">
                <div className="flex flex-row w-full">
                    <div className="w-1/3">
                        Phone:{' '}
                        <b>
                            {customer?.phoneNumbers?.map((number) => {
                                return <span key={number.id}>{number.phoneNumber} </span>;
                            })}
                        </b>
                    </div>
                    <div className="w-1/3">
                        Email: <b>{customer?.primaryEmail}</b>
                    </div>
                </div>
            </div>
        );
    }

    handleCustomerSelected(customerId: string) {
        this.props.customerSelected(customerId);
        const { dispatch } = this.props;
        dispatch<any>(getCustomer(customerId));
    }

    modalClosed(customerCreated?: boolean, customerUpdated?: boolean, customerId?: string) {
        if (customerCreated && customerId) {
            this.props.customerSelected(customerId);
        }
        setTimeout(() => this.setState({ isCreateCustomerModalOpen: false }), customerCreated ? 1000 : 0);
    }

    getCustomerModal() {
        return <CustomerModal onClose={this.modalClosed}></CustomerModal>;
    }

    render() {
        const { isNewCustomerFormOpen, isCreateCustomerModalOpen } = this.state;
        const { customer } = this.props;
        return (
            <>
                {isCreateCustomerModalOpen ? this.getCustomerModal() : null}
                <div className="divide-y-2 divide-gray-200">
                    <div className="flex w-full pb-3">
                        <span className="flex flex-row items-center mr-4">
                            <FaUser size={15} />
                            <h2 className=" pl-3 text-lg font-semibold align-bottom">
                                {!!customer ? (
                                    <Link to={`/customer/${customer.id}`} target="_blank">
                                        {customer.fullName}
                                    </Link>
                                ) : (
                                    'Select a Customer'
                                )}
                            </h2>
                        </span>
                        <div>
                            {customer?.labels?.map((label, indx) => (
                                <span
                                    key={indx}
                                    style={{ backgroundColor: label.color }}
                                    className="mr-2 px-1 py-1 rounded-lg hover:opacity-80 text-xs"
                                >
                                    <span className="font-semibold text-white inline-flex whitespace-nowrap">
                                        {label.title}
                                    </span>
                                </span>
                            ))}
                        </div>
                        <div className="ml-auto">
                            {!!customer ? (
                                <Icon
                                    name="cancel"
                                    className="clickable"
                                    onClick={() => this.props.customerSelected(null)}
                                    size="large"
                                />
                            ) : (
                                <div>
                                    <Dropdown
                                        placeholder="Search Customers"
                                        search
                                        options={this.getCustomers()}
                                        selection
                                        onSearchChange={(e, data) => this.searchCustomers(data.searchQuery)}
                                        clearable
                                        onChange={(_, { value }) => this.handleCustomerSelected(value!.toString())}
                                        button
                                        className="tiny"
                                        disabled={isNewCustomerFormOpen}
                                    />
                                    {!!!customer && (
                                        <button
                                            className="bg-blue-500 px-2 rounded-2xl font-medium text-base ml-auto"
                                            onClick={() =>
                                                this.setState({
                                                    isCreateCustomerModalOpen: !isCreateCustomerModalOpen,
                                                })
                                            }
                                        >
                                            + Add new
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="w-full pt-3">{!!customer && this.getCustomerOverview(customer)}</div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: any) => ({
    customers: state.customer.customersList,
    newCustomerLoading: state.customer.newCustomerLoading,
    newCustomer: state.customer.newCustomer,
    customersLoading: state.customer.customersLoading,
});

export default connect(mapStateToProps)(CustomerSection);
