import React, { SyntheticEvent } from 'react';
import { FaEdit, FaPlusCircle, FaTrash } from 'react-icons/fa';

import Price from 'components/common/Price';
import PriceInput from 'components/common/PriceInput';
import LaborRate from 'models/LaborRateModel';

import axios from 'helpers/axios';
import config from 'helpers/config';
import { displayConfirmationModal } from 'helpers/services/removeConfirmationService';
import NewDropdown from 'components/common/NewDropdown';

interface Props {}

interface State {
    laborRates: LaborRate[];
    isCreateNewLaborRateActive: boolean;
    editableRateId: string | null;
    name: string | null;
    rate: number | null;
}

class LaborRateSettings extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            laborRates: [],
            isCreateNewLaborRateActive: false,
            editableRateId: null,
            rate: null,
            name: null,
        };

        this.handleChange = this.handleChange.bind(this);
        this.createNewRate = this.createNewRate.bind(this);
        this.updateRate = this.updateRate.bind(this);
    }

    componentDidMount() {
        this.getLaborRates();
    }

    getLaborRates() {
        axios.get(`${config.apiUrl}/laborRate`).then((res) => this.setState({ laborRates: res.data }));
    }

    createNewRate() {
        const { name, rate } = this.state;
        const laborRate = {
            name,
            rate,
        };

        axios.post(`${config.apiUrl}/laborRate`, laborRate).then((_) => {
            this.getLaborRates();
            this.setState({ name: null, rate: null, isCreateNewLaborRateActive: false });
        });
    }

    updateRate() {
        const { name, rate, editableRateId } = this.state;
        const laborRate = {
            name,
            rate,
            id: editableRateId,
        };

        axios.put(`${config.apiUrl}/laborRate`, laborRate).then((_) => {
            this.setState(
                { name: null, rate: null, isCreateNewLaborRateActive: false, editableRateId: null },
                this.getLaborRates,
            );
        });
    }

    getEditableRow(isEdit: boolean) {
        return (
            <>
                <td className="table-cell">
                    <input
                        name="name"
                        className="bg-gray-50 text-black rounded-md border w-full px-2 font-medium py-2 focus:ring-1 focus:outline-none focus:border-blue-500"
                        onChange={this.handleChange}
                        value={this.state.name!}
                        autoFocus
                    ></input>
                </td>
                <td className="table-cell">
                    <span className="font-semibold inline text-black">$</span>
                    <PriceInput
                        name="rate"
                        className="inline text-black bg-gray-50 rounded-md border px-2 font-medium py-2 focus:ring-1 focus:outline-none focus:border-blue-500"
                        onChange={this.handleChange}
                        amount={this.state.rate!}
                        placeholder="0.00"
                    />
                </td>
                <td className="table-cell">
                    <div className="flex flex-row-reverse">
                        <button
                            onClick={() =>
                                this.setState({
                                    editableRateId: null,
                                    isCreateNewLaborRateActive: false,
                                    name: null,
                                    rate: null,
                                })
                            }
                            className="bg-white rounded-lg font-semibold text-lg text-gray-700 px-2 border w-fit"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={isEdit ? this.updateRate : this.createNewRate}
                            className="bg-green-500 rounded-lg font-semibold text-lg text-white px-2 border w-fit"
                        >
                            Save
                        </button>
                    </div>
                    <div></div>
                </td>
            </>
        );
    }

    handleDelete(laborRate: LaborRate) {
        displayConfirmationModal(`labor rate: "${laborRate.name}"`)
            .then(() => {
                axios.delete(`${config.apiUrl}/laborRate/${laborRate.id}`).then(() => {
                    this.getLaborRates();
                    this.setState({ name: null, rate: null, isCreateNewLaborRateActive: false });
                });
            })
            .catch((_) => _);
    }

    editRate(laborRate: LaborRate) {
        this.setState({
            editableRateId: laborRate.id,
            name: laborRate.name,
            rate: laborRate.rate,
            isCreateNewLaborRateActive: false,
        });
    }

    handleChange(e: SyntheticEvent) {
        // @ts-ignore
        const { name, value } = e.target;
        // @ts-ignore
        this.setState({ [name]: value });
    }

    updateDefaultRate(rate: LaborRate) {
        axios.put(`${config.apiUrl}/laborRate/defaultRate/${rate.id}`).then((_) => {
            this.setState(
                { name: null, rate: null, isCreateNewLaborRateActive: false, editableRateId: null },
                this.getLaborRates,
            );
        });
    }

    render() {
        const { laborRates, isCreateNewLaborRateActive, editableRateId } = this.state;
        return (
            <div className="flex flex-col items-center">
                <div>
                    <table className="items-center w-full text-white  font-semibold text-left text-xs align-middle">
                        <thead>
                            <tr className="bg-blue-500 h-12 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                                <th className="pl-6 pr-64 py-3 rounded-tl-xl">Name</th>
                                <th className="pl-6 pr-64 ">Rate</th>
                                <th className="pl-6 pr-64 rounded-tr-xl"></th>
                            </tr>
                        </thead>
                        <tbody className="border">
                            {laborRates?.map((laborRate, index) => {
                                return laborRate.id === editableRateId ? (
                                    <tr key={laborRate.id}>{this.getEditableRow(true)}</tr>
                                ) : (
                                    <tr key={index} className={`text-black font-medium bg-white  hover:bg-blue-100`}>
                                        <td className="px-6 align-middle h-5 text-sm py-4 border-b text-left items-center">
                                            <div className="whitespace-pre-wrap">{laborRate.name}</div>
                                        </td>
                                        <td className="px-6 align-middle h-5 text-sm py-4 border-b">
                                            <div className="whitespace-pre-wrap">
                                                <Price price={laborRate.rate} />
                                            </div>
                                        </td>
                                        <td className="px-6 align-middle h-5 text-sm py-4 border-b non-clickable">
                                            <div className="flex text-xl float-right">
                                                <FaEdit
                                                    className="clickable mr-2"
                                                    onClick={() => this.editRate(laborRate)}
                                                />
                                                <FaTrash
                                                    className="clickable text-gray-500"
                                                    onClick={() => this.handleDelete(laborRate)}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                );
                            })}
                            {isCreateNewLaborRateActive && (
                                <tr className="text-black font-medium bg-white border-b">
                                    {this.getEditableRow(false)}
                                </tr>
                            )}
                        </tbody>
                    </table>
                    <div className="py-4 px-1  justify-center border-b border-l border-r rounded-b-lg bg-white w-full">
                        <button
                            className={`${
                                isCreateNewLaborRateActive || editableRateId ? 'bg-gray-500' : 'bg-green-500'
                            } rounded-lg px-2 py-1 font-semibold text-white mr-auto ml-3`}
                            onClick={() =>
                                this.setState({
                                    isCreateNewLaborRateActive: !isCreateNewLaborRateActive,
                                    editableRateId: null,
                                    name: '',
                                    rate: 0,
                                })
                            }
                        >
                            {isCreateNewLaborRateActive || editableRateId ? (
                                'Cancel'
                            ) : (
                                <>
                                    <FaPlusCircle className="inline align-text-top mr-1" />
                                    <span>Add New Rate</span>
                                </>
                            )}
                        </button>
                    </div>
                </div>

                <div className="border rounded-xl mt-10 p-8 w-fit bg-white">
                    <div className="flex w-fit">
                        <div className="font-semibold mr-4 inline">Default Rate:</div>
                        <div className="">
                            <NewDropdown
                                items={laborRates}
                                bindName={'name'}
                                onSelect={(laborRate: LaborRate) => {
                                    this.updateDefaultRate(laborRate);
                                }}
                                selected={laborRates.find((rate) => rate.isDefault)}
                                className="w"
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LaborRateSettings;
