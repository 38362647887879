import React from 'react';
import { connect } from 'react-redux';
import User from '../../../models/UserModel';
import { getCurrentUser } from '../../../actions/userActions';
import axios from 'helpers/axios';
import config from 'helpers/config';

interface Props {
    currentUser?: User;
    dispatch: any;
}

interface State {
    isTrialExpired: boolean;
    isCheckoutRedirectPending: boolean;
}

class SubscriptionPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isTrialExpired: false,
            isCheckoutRedirectPending: false,
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch(getCurrentUser());
    }

    redirectToCheckout(subscriptionPaymentID: string) {
        if (!this.state.isCheckoutRedirectPending) {
            axios
                .get(`${config.apiUrl}/subscription/${subscriptionPaymentID}`)
                .then((res) => (window.location.href = res.data.stripeCheckoutURL))
                .catch((_) => _);
            this.setState({
                isCheckoutRedirectPending: true,
            });
        }
    }

    render() {
        let isTrialExpired = false;
        if (this.props.currentUser!) {
            const { tenant } = this.props.currentUser!;

            isTrialExpired = !!(tenant?.isInDemo && new Date(tenant.trialEnding).getTime() < new Date().getTime());
        }
        return (
            <div className="bg-blue-100 h-full">
                {isTrialExpired && (
                    <div className="py-10 text-center bg-green-500 font-semibold text-lg">
                        Your trial of AutoFlow Manager has ended. Subscribe now by picking a plan or contact us if you
                        would like more time
                    </div>
                )}
                <div className="text-center pt-10">
                    <div className="font-semibold text-2xl mb-4">Pick a plan</div>
                    <div className="flex w-full px-10">
                        <div className="bg-white border border-gray rounded-lg p-4 w-1/3 mr-4">
                            <div className="font-semibold text-lg font-mono mt-6">Basic</div>
                            <div className="font-semibold text-2xl">
                                $99.00 <span className="font-semibold text-gray-500">/month</span>
                            </div>
                            <div
                                onClick={() => this.redirectToCheckout('price_1L1zetK1Pc8TsyJLQGyckKCe')}
                                className="rounded-xl clickable border-2 border-blue-600 hover:bg-blue-300 font-semibold font-mono text-2xl"
                            >
                                Get Started
                            </div>
                            <div className="mt-4 text-left font-semibold text-gray-700">
                                <div>
                                    <div className="w-2 h-2 rounded-full text-sm bg-green-600 inline-block mr-2 align-middle"></div>
                                    Unlimited Repair Orders
                                </div>
                                <div>
                                    <div className="w-2 h-2 rounded-full text-sm bg-green-600 inline-block mr-2 align-middle"></div>
                                    Calendars &amp; Appointments
                                </div>
                                <div>
                                    <div className="w-2 h-2 rounded-full text-sm bg-green-600 inline-block mr-2 align-middle"></div>
                                    Inventory &amp; Vendor Management
                                </div>
                                <div>
                                    <div className="w-2 h-2 rounded-full text-sm bg-green-600 inline-block mr-2 align-middle"></div>
                                    Canned Jobs &amp; Sublets
                                </div>
                                <div>
                                    <div className="w-2 h-2 rounded-full text-sm bg-green-600 inline-block mr-2 align-middle"></div>
                                    Digital Vehicle Inspections (DVI)
                                </div>
                                <div>
                                    <div className="w-2 h-2 rounded-full text-sm bg-green-600 inline-block mr-2 align-middle"></div>
                                    One-Way Messaging
                                </div>
                                <div>
                                    <div className="w-2 h-2 rounded-full text-sm bg-green-600 inline-block mr-2 align-middle"></div>
                                    Digital Authorizations &amp; Invoicing
                                </div>
                            </div>
                        </div>
                        <div className="bg-white  border border-yellow-400 border-gray rounded-lg p-4 w-1/3 mr-4">
                            <div className="font-semibold rounded-t-lg bg-yellow-400 text-lg font-mono -m-4 mb-4">
                                Most popular
                            </div>
                            <div className="font-semibold text-lg font-mono">Grow</div>
                            <div className="font-semibold text-2xl">
                                $199.00 <span className="font-semibold text-gray-500">/month</span>
                            </div>
                            <div
                                onClick={() => this.redirectToCheckout('price_1L1zetK1Pc8TsyJLQGyckKCe')}
                                className="rounded-xl clickable border border-blue-600 text-blue-600 hover:bg-blue-300 font-semibold font-mono text-2xl"
                            >
                                Get Started
                            </div>
                            <div className="mt-4 text-left font-semibold text-gray-700">
                                <div>
                                    <div className="w-2 h-2 rounded-full text-sm bg-green-600 inline-block mr-2 align-middle"></div>
                                    Unlimited Repair Orders
                                </div>
                                <div>
                                    <div className="w-2 h-2 rounded-full text-sm bg-green-600 inline-block mr-2 align-middle"></div>
                                    Calendars &amp; Appointments
                                </div>
                                <div>
                                    <div className="w-2 h-2 rounded-full text-sm bg-green-600 inline-block mr-2 align-middle"></div>
                                    Inventory &amp; Vendor Management
                                </div>
                                <div>
                                    <div className="w-2 h-2 rounded-full text-sm bg-green-600 inline-block mr-2 align-middle"></div>
                                    Canned Jobs &amp; Sublets
                                </div>
                                <div>
                                    <div className="w-2 h-2 rounded-full text-sm bg-green-600 inline-block mr-2 align-middle"></div>
                                    Digital Vehicle Inspections (DVI)
                                </div>
                                <div>
                                    <div className="w-2 h-2 rounded-full text-sm bg-green-600 inline-block mr-2 align-middle"></div>
                                    One-Way Messaging
                                </div>
                                <div>
                                    <div className="w-2 h-2 rounded-full text-sm bg-green-600 inline-block mr-2 align-middle"></div>
                                    Digital Authorizations &amp; Invoicing
                                </div>
                            </div>
                        </div>

                        <div className="bg-white  border border-gray rounded-lg p-4 w-1/3">
                            <div className="font-semibold text-lg font-mono mt-6">Pro</div>
                            <div className="font-semibold text-2xl">
                                $250.00 <span className="font-semibold text-gray-500">/month</span>
                            </div>
                            <div
                                onClick={() => this.redirectToCheckout('price_1L1zetK1Pc8TsyJLQGyckKCe')}
                                className="rounded-xl clickable border-2 border-blue-600 hover:bg-blue-300 font-semibold font-mono text-2xl"
                            >
                                Get Started
                            </div>
                            <div className="mt-4 text-left font-semibold text-gray-700">
                                <div>
                                    <div className="w-2 h-2 rounded-full text-sm bg-green-600 inline-block mr-2 align-middle"></div>
                                    Unlimited Repair Orders
                                </div>
                                <div>
                                    <div className="w-2 h-2 rounded-full text-sm bg-green-600 inline-block mr-2 align-middle"></div>
                                    Calendars &amp; Appointments
                                </div>
                                <div>
                                    <div className="w-2 h-2 rounded-full text-sm bg-green-600 inline-block mr-2 align-middle"></div>
                                    Inventory &amp; Vendor Management
                                </div>
                                <div>
                                    <div className="w-2 h-2 rounded-full text-sm bg-green-600 inline-block mr-2 align-middle"></div>
                                    Canned Jobs &amp; Sublets
                                </div>
                                <div>
                                    <div className="w-2 h-2 rounded-full text-sm bg-green-600 inline-block mr-2 align-middle"></div>
                                    Digital Vehicle Inspections (DVI)
                                </div>
                                <div>
                                    <div className="w-2 h-2 rounded-full text-sm bg-green-600 inline-block mr-2 align-middle"></div>
                                    One-Way Messaging
                                </div>
                                <div>
                                    <div className="w-2 h-2 rounded-full text-sm bg-green-600 inline-block mr-2 align-middle"></div>
                                    Digital Authorizations &amp; Invoicing
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(SubscriptionPage);
