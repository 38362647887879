import React from 'react';
import { Divider, Form, Transition } from 'semantic-ui-react';

import axios from 'helpers/axios';
import config from 'helpers/config';
import { validatePhoneNumbers } from 'helpers/validators/phoneNumberValidators';
import { displayConfirmationModal } from 'helpers/services/removeConfirmationService';

import ModalStateType from 'models/generic/ModalState';
import CustomerModel from 'models/CustomerModel';
import PhoneNumber from 'models/PhoneNumberModel';
import Modal from 'components/common/Modal';
import PhoneNumbers from 'components/common/PhoneNumbers';
import Customer from 'models/CustomerModel';

interface Props {
    customerId?: string;
    onClose: (customerCreated?: boolean, customerEdited?: boolean, customerId?: string) => void;
}

interface ComponentState {
    modalState: ModalStateType;
    modalHeader: string;
    customer: CustomerModel;
    isLoading: boolean;
    isFormExpanded: boolean;
    phoneNumbers: PhoneNumber[];
}

class CustomerModal extends React.Component<Props, ComponentState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            modalState: this.props.customerId ? ModalStateType.Edit : ModalStateType.Create,
            modalHeader: '',
            isLoading: true,
            customer: { phoneNumbers: [{ type: 0 }] } as CustomerModel,
            phoneNumbers: [],
            isFormExpanded: false,
        };

        this.getCustomer = this.getCustomer.bind(this);
        this.setModalHeader = this.setModalHeader.bind(this);
        this.getValue = this.getValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
    }

    componentDidMount() {
        if (this.state.modalState === ModalStateType.Edit) {
            this.setState({ isLoading: true });
            this.getCustomer();
        } else {
            this.setState({ isLoading: false });
            this.setModalHeader();
        }
    }

    handleChange(event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) {
        const { name, value } = event.currentTarget;

        // @ts-ignore
        const updatedCustomer: Customer = { ...this.state.customer, [name]: value };
        this.setState({ customer: updatedCustomer });
    }

    handlePhoneNumberChange(phoneNumbers: PhoneNumber[]) {
        // @ts-ignore
        const updatedCustomer: Customer = { ...this.state.customer, phoneNumbers };
        this.setState({ customer: updatedCustomer });
    }

    getCustomer() {
        axios
            .get(`${config.apiUrl}/customer/${this.props.customerId}`)
            .then((response) => {
                this.setState({ customer: response.data, isLoading: false, isFormExpanded: !!response.data.notes });
                this.setModalHeader();
            })
            .catch((err) => console.log(err));
    }

    setModalHeader() {
        if (this.state.modalState === ModalStateType.Edit) {
            const { firstName, lastName } = this.state.customer!;
            const modalHeader = `${firstName} ${lastName}`;
            this.setState({ modalHeader });
        } else {
            this.setState({ modalHeader: 'Create new customer ' });
        }
    }

    getValue(name: string) {
        // @ts-ignore
        return this.state.customer[name] || '';
    }

    save() {
        this.setState({ isLoading: true });

        if (this.state.modalState === ModalStateType.Create) {
            this.createCustomer(this.state.customer);
        } else {
            this.updateCustomer(this.state.customer);
        }
    }

    updateCustomer(customerData: Customer) {
        customerData.phoneNumbers = validatePhoneNumbers(customerData.phoneNumbers);
        axios
            .put(`${config.apiUrl}/customer`, customerData)
            .then((_) => this.props.onClose(false, true))
            .catch((_) => {
                this.setState({ isLoading: false });
            });
    }

    createCustomer(customerData: Customer) {
        customerData.phoneNumbers = validatePhoneNumbers(customerData.phoneNumbers);
        axios
            .post(`${config.apiUrl}/customer`, customerData)
            .then((res) => {
                this.props.onClose(true, false, res.data.customerId);
            })
            .catch((_) => {
                this.setState({ isLoading: false });
            });
    }

    delete() {
        const { fullName, id } = this.state.customer;
        const { onClose } = this.props;
        displayConfirmationModal(fullName)
            .then(() => {
                axios.delete(`${config.apiUrl}/customer/${id}`).then(() => onClose(false, true));
            })
            .catch();
    }

    render() {
        const { modalHeader, modalState, isLoading, isFormExpanded } = this.state;
        const { phoneNumbers } = this.state.customer;
        return (
            <Modal
                onDelete={modalState === ModalStateType.Edit ? this.delete : undefined}
                headerText={modalHeader}
                isOpen={true}
                onClose={() => this.props.onClose(false, false)}
                onSave={this.save}
                isLoading={isLoading}
            >
                <div>
                    <Form size="large">
                        <Form.Group unstackable widths={2}>
                            <Form.Input
                                required
                                placeholder="First Name"
                                label="First Name"
                                name="firstName"
                                onChange={this.handleChange}
                                value={this.getValue('firstName')}
                            />
                            <Form.Input
                                required
                                placeholder="Last Name"
                                label="Last Name"
                                name="lastName"
                                onChange={this.handleChange}
                                value={this.getValue('lastName')}
                            />
                        </Form.Group>
                        <PhoneNumbers
                            phoneNumbers={phoneNumbers?.length > 0 ? phoneNumbers : [{} as PhoneNumber]}
                            phoneNumberUpdated={this.handlePhoneNumberChange}
                        ></PhoneNumbers>
                        <Form.Group unstackable widths={2}>
                            <Form.Input
                                placeholder="Email"
                                label="Email"
                                name="primaryEmail"
                                onChange={this.handleChange}
                                value={this.getValue('primaryEmail')}
                            />
                            <Form.Input
                                placeholder="Address"
                                label="Address"
                                name="address.street"
                                onChange={this.handleChange}
                                value={this.getValue('address.street')}
                            />
                        </Form.Group>
                        <Form.Group widths={2}>
                            <Form.Input
                                placeholder="Zip/Postal Code"
                                label="Zip/Postal Code"
                                name="address.ZIP"
                                onChange={this.handleChange}
                                value={this.getValue('address.ZIP')}
                            />
                        </Form.Group>
                        <Divider horizontal>
                            <span
                                className="clickable expand-link"
                                onClick={() => this.setState({ isFormExpanded: !isFormExpanded })}
                            >
                                {isFormExpanded ? 'Less' : 'More'}
                            </span>
                        </Divider>
                        <Transition.Group animation="fade up" duration="200">
                            {isFormExpanded && (
                                <Form.Group>
                                    <Form.TextArea
                                        placeholder="Notes"
                                        label="Notes"
                                        name="notes"
                                        onChange={this.handleChange}
                                        value={this.getValue('notes')}
                                    />
                                </Form.Group>
                            )}
                        </Transition.Group>
                    </Form>
                </div>
            </Modal>
        );
    }
}

export default CustomerModal;
