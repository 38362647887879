import axios from 'helpers/axios';
import config from 'helpers/config';
import InventoryItem from 'models/InventoryItemModel';
import ServiceItem from 'models/ServiceItemModel';
import ServiceItemType from 'models/types/ServiceItemType';
import React, { useState } from 'react';
import { TiDeleteOutline } from 'react-icons/ti';

interface Props {
    serviceItem: ServiceItem;
    serviceItemChanged: (e: any) => void;
    handlePartSelected?: (part: InventoryItem) => void;
    handlePartDeselected?: () => void;
    onBlur: () => void;
}

const ServiceItemInput: React.FC<Props> = ({
    serviceItem,
    serviceItemChanged,
    onBlur,
    handlePartSelected,
    handlePartDeselected,
}: Props) => {
    const [isInventorySearchExpanded, setIsInventorySearchExpanded] = useState(false);
    const [searchResults, setSearchResults] = useState([]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        serviceItemChanged(e);
        if (serviceItem.type === ServiceItemType.Part) {
            searchInventory(e.target.value);
        }
    };

    const handleOnFocus = () => {
        if (serviceItem.type === ServiceItemType.Part) {
            setIsInventorySearchExpanded(!isInventorySearchExpanded);
            searchInventory('');
        }
    };

    const onPartSelected = (part: InventoryItem) => {
        handlePartSelected!(part);
        setIsInventorySearchExpanded(false);
    };

    const searchInventory = (searchPhrase: string) => {
        const searchParams = {
            page: 1,
            itemsPerPage: 5,
            searchPhrase,
        };
        axios
            .get(`${config.apiUrl}/inventory`, { params: searchParams })
            .then((res) => {
                setSearchResults(res.data);
            })
            .catch((_) => _);
    };

    return (
        <div className="flex flex-col ">
            {!!serviceItem.inventoryItemId ? (
                <div className="flex group border border-opacity-0 hover:border-opacity-100 border-gray-500 py-1 px-2 rounded-lg">
                    <div>
                        <div className="text-sm">{serviceItem.inventoryItem?.description}</div>
                        <div className="text-gray-400 font-semibold text-xs">{serviceItem.inventoryItem?.itemCode}</div>
                    </div>
                    <div className="ml-auto">
                        {!!handlePartDeselected && (
                            <TiDeleteOutline
                                size={15}
                                className="opacity-0 group-hover:opacity-100 clickable mt-1 text-white"
                                onClick={() => handlePartDeselected()}
                            />
                        )}
                    </div>
                </div>
            ) : (
                <div className="flex">
                    <input
                        className="outline-none w-72 placeholder-gray-400 font-medium px-3 py-2 focus:ring-1 focus:ring-blue-600 rounded-lg mb-1"
                        placeholder={
                            serviceItem.type === ServiceItemType.Labor ? 'Enter labor description' : 'Enter part'
                        }
                        autoComplete="off"
                        name="description"
                        value={serviceItem.description || undefined}
                        onChange={(e) => handleChange(e)}
                        onFocus={() => handleOnFocus()}
                        onBlur={() => {
                            setTimeout(() => {
                                onBlur();
                                setIsInventorySearchExpanded(false);
                            }, 333);
                        }}
                    />
                </div>
            )}
            {isInventorySearchExpanded && searchResults?.length > 0 && (
                <div
                    id="dropdown"
                    className="bg-white w-72 mr-3 z-50 mt-9 text-sm max-w-xls  text-left no-underline break-words absolute font-bold border border-gray-700 max-h-96 overflow-y-scroll "
                >
                    <div className="font-semibold text-md bg-blue-100 p-1.5 ">
                        <span className="text-blue-600">{searchResults?.length}</span> <span> results: </span>
                    </div>
                    <ul
                        className="py-1 text-sm text-gray-700 divide-y divide-gray-200 relative"
                        aria-labelledby="dropdownDefault"
                    >
                        {searchResults.map((inventoryItem: InventoryItem, index) => {
                            return (
                                <li
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        onPartSelected(inventoryItem);
                                    }}
                                    key={index}
                                    className="p-1 clickable hover:bg-gray-200"
                                >
                                    <div>
                                        <div>
                                            <span className="font-semibold">{inventoryItem.description}</span>
                                        </div>
                                        <div className="flex">
                                            {!!inventoryItem.itemCode && (
                                                <div className="mr-4">
                                                    <span className="text-gray-400 text-sm">Code: </span>
                                                    <span>{inventoryItem.itemCode}</span>
                                                </div>
                                            )}
                                            {!!inventoryItem.supplier && (
                                                <div>
                                                    <span className="text-gray-400 text-sm">From: </span>
                                                    <span>{inventoryItem.supplier.companyName}</span>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default ServiceItemInput;
