import axios from 'helpers/axios';
import config from 'helpers/config';
import store from 'store/store';
import actionTypes from './actionTypes';

export const resolveCustomerName = (customerId: string) =>
    new Promise<string>(async (resolve, reject) => {
        try {
            const customerFromCache = store.getState().misc.customerNames?.find((u: any) => u.id === customerId);

            if (!!customerFromCache) {
                resolve(customerFromCache.fullName);
            } else {
                axios
                    .get(`${config.apiUrl}/customer/${customerId}`)
                    .then((res) => {
                        const fullName = res.data.firstName + ' ' + res.data.lastName;
                        store.dispatch({ type: actionTypes.ADD_CUSTOMER_NAME, payload: { id: res.data.id, fullName } });
                        resolve(fullName);
                    })
                    .catch(() => resolve(''));
            }
        } catch (error) {
            reject(error);
        }
    });
