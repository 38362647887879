import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import reducers from './reducers/rootReducer';

const loggerMiddleware = createLogger({
    level: 'info',
    collapsed: true,
});

// @ts-ignore
const store = createStore(reducers, applyMiddleware(thunkMiddleware, loggerMiddleware));

export default store;
