import React from 'react';

interface Props {
    group: string;
}

interface State {}

class BaseRegistryGroupList extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }
    render() {
        return <div></div>;
    }
}

export default BaseRegistryGroupList;
