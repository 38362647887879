import React from 'react';

import axios from 'helpers/axios';
import config from 'helpers/config';
import ModalStateType from 'models/generic/ModalState';
import CustomerModel from 'models/CustomerModel';
import PhoneNumber from 'models/PhoneNumberModel';
import Modal from 'components/common/Modal';
import CustomerSearch from 'components/common/CustomerSearch';
import VehicleModel from 'models/VehicleModel';
import { FaCar, FaTimesCircle, FaUser } from 'react-icons/fa';
import moment from 'moment';
import AppointmentModel from 'models/AppointmentModel';

interface Props {
    appointmentId?: string;
    onClose: (bookingCreated?: boolean, bookingEdited?: boolean, customerId?: string) => void;
}

interface ComponentState {
    modalState: ModalStateType;
    modalHeader: string;
    appointment: AppointmentModel;

    customerId: string | undefined;
    vehicleId: string | undefined;
    customerVehicles: VehicleModel[];

    selectedVehicle?: VehicleModel;

    fromDate?: string;
    toDate?: string;

    fromTime?: string;
    toTime?: string;

    isLoading: boolean;
    isFormExpanded: boolean;
    phoneNumbers: PhoneNumber[];
}

class AppointmentModal extends React.Component<Props, ComponentState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            modalState: this.props.appointmentId ? ModalStateType.Edit : ModalStateType.Create,
            modalHeader: '',
            isLoading: true,
            customerId: undefined,
            vehicleId: undefined,

            customerVehicles: [],
            appointment: {} as AppointmentModel,
            phoneNumbers: [],
            isFormExpanded: false,

            fromDate: moment().format('YYYY-MM-DD'),
            toDate: moment().format('YYYY-MM-DD'),

            fromTime: moment().add(59, 'minutes').startOf('hour').format('HH:mm'),
            toTime: moment().add(119, 'minutes').startOf('hour').format('HH:mm'),
        };

        this.getAppointment = this.getAppointment.bind(this);
        this.save = this.save.bind(this);
        this.setModalHeader = this.setModalHeader.bind(this);
        this.getValue = this.getValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handlePhoneNumberChange = this.handlePhoneNumberChange.bind(this);
        this.handleCustomerSelected = this.handleCustomerSelected.bind(this);
    }

    componentDidMount() {
        if (this.state.modalState === ModalStateType.Edit) {
            this.setState({ isLoading: true });
            this.getAppointment();
        } else {
            this.setState({ isLoading: false });
            this.setModalHeader();
        }
    }

    handleChange(event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) {
        const { name, value } = event.currentTarget;

        // @ts-ignore
        const updatedAppointment: Customer = { ...this.state.appointment, [name]: value };
        this.setState({ appointment: updatedAppointment });
    }

    handlePhoneNumberChange(phoneNumbers: PhoneNumber[]) {
        // @ts-ignore
        const updatedAppointment: Customer = { ...this.state.appointment, phoneNumbers };
        this.setState({ appointment: updatedAppointment });
    }

    getAppointment() {
        axios
            .get(`${config.apiUrl}/booking/${this.props.appointmentId}`)
            .then((response) => {
                this.setState({ appointment: response.data, isLoading: false });
                this.setModalHeader();
            })
            .catch((err) => console.log(err));
    }

    setModalHeader() {
        if (this.state.modalState === ModalStateType.Edit) {
            const { title } = this.state.appointment!;
            const modalHeader = title;
            this.setState({ modalHeader });
        } else {
            this.setState({ modalHeader: 'Register new Appointment ' });
        }
    }

    getValue(name: string) {
        // @ts-ignore
        return this.state.appointment[name] || '';
    }

    save() {
        this.setState({ isLoading: true });
        const data = {
            customerId: this.state.customerId,
            vehicleId: this.state.vehicleId,
            startDate: moment(this.state.fromDate + ' ' + this.state.fromTime).toDate(),
            endDate: moment(this.state.toDate + ' ' + this.state.toTime).toDate(),
        } as Partial<AppointmentModel>;

        if (this.state.modalState === ModalStateType.Create) {
            this.createAppointment(data);
        } else {
            this.updateAppointment(data);
        }
    }
    updateAppointment(appointment: Partial<AppointmentModel>) {
        axios
            .post(`${config.apiUrl}/appointment`, appointment)
            .then((res) => {
                this.props.onClose(true, false, res.data.appointmentId);
            })
            .catch((_) => {
                this.setState({ isLoading: false });
            });
    }

    createAppointment(appointment: Partial<AppointmentModel>) {
        axios
            .post(`${config.apiUrl}/appointment`, appointment)
            .then((res) => {
                this.props.onClose(true, false, res.data.appointmentId);
            })
            .catch((_) => {
                this.setState({ isLoading: false });
            });
    }

    getVehiclesList() {
        const { customerVehicles, customerId } = this.state;
        return (
            <>
                <table className="items-center bg-transparent border w-full text-sm">
                    <thead className="border">
                        <tr className="bg-gray-400 text-white border font-semibold text-left text-sm uppercase align-middle">
                            <th className="pl-6 py-3">Make</th>
                            <th className="px-6 py-3">Model</th>
                            <th className="px-6 py-3">Year</th>
                            <th className="px-6 py-3">License Plate</th>
                            <th className="px-6 py-3">VIN</th>
                        </tr>
                    </thead>

                    <tbody>
                        {customerVehicles?.map((vehicle, index) => (
                            <tr
                                className={`border group font-semibold hover:bg-blue-200 clickable ${
                                    index % 2 === 0 ? '' : 'bg-blue-50'
                                }`}
                                key={index}
                                onClick={() => {
                                    this.setState({ vehicleId: vehicle.id, selectedVehicle: vehicle });
                                }}
                            >
                                <td className="px-6 min-w-max align-middle border-l-0 border-r-0 whitespace-nowrap py-1 text-left flex items-center">
                                    <span>{vehicle.make}</span>
                                </td>
                                <td className="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap py-1">
                                    <span>{vehicle.model}</span>
                                </td>
                                <td className="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap py-1">
                                    <span>{vehicle.year}</span>
                                </td>
                                <td className="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap py-1">
                                    <span>{vehicle.licensePlate}</span>
                                </td>
                                <td className="px-6 align-middle border-l-0 border-r-0 whitespace-nowrap py-1">
                                    <span>{vehicle.VIN}</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                {!!customerId && customerVehicles?.length < 1 && (
                    <div className="border py-4 px-4 wb-white text-gray-600 text-lg font-semibold text-center">
                        This customer has no registered vehicles
                    </div>
                )}
                {!customerId && customerVehicles?.length < 1 && (
                    <div className="border py-4 px-4 wb-white text-gray-600 text-lg font-semibold text-center">
                        Select customer first
                    </div>
                )}
            </>
        );
    }

    handleCustomerSelected(customer?: CustomerModel) {
        if (customer) {
            this.setState({ customerId: customer.id, customerVehicles: customer.vehicles! });
        } else {
            this.setState({
                customerId: undefined,
                customerVehicles: [],
                selectedVehicle: undefined,
                vehicleId: undefined,
            });
        }
    }

    render() {
        // prettier-ignore
        const {
            modalHeader,
            isLoading,
            vehicleId,
            selectedVehicle,
            fromDate,
            toDate,
            fromTime,
            toTime,
        } = this.state;
        return (
            <Modal
                // onDelete={modalState === ModalStateType.Edit ? this.delete : undefined}
                headerText={modalHeader}
                isOpen={true}
                onClose={() => this.props.onClose(false, false)}
                onSave={this.save}
                isLoading={isLoading}
                isBackgroundWhite={true}
            >
                <div className="divide-y-2  divide-gray-500 w-full bg-white">
                    <div className="py-10">
                        <div className="items-baseline flex font-semibold text-sm uppercase">
                            <FaUser className="inline mr-1.5" />
                            <h3>Select a customer:</h3>
                        </div>

                        <CustomerSearch
                            customerSelected={this.handleCustomerSelected}
                            displayDetailsWhenSelected={true}
                        />
                    </div>
                    <div className="py-10">
                        <div className="items-baseline flex font-semibold text-sm uppercase">
                            <FaCar className="inline mr-1.5" />
                            <h3>Select a vehicle:</h3>
                        </div>
                        {vehicleId ? (
                            <div>
                                <div className="rounded-lg px-2 py-1 border border-black font-semibold w-full items-baselin bg-blue-300">
                                    <div className="flex">
                                        <span className="mr-2">{`${
                                            selectedVehicle?.year ? `${selectedVehicle.year} ` : ''
                                        }${selectedVehicle?.make} ${selectedVehicle?.model}`}</span>
                                        <div className="ml-auto">
                                            <FaTimesCircle
                                                onClick={() => {
                                                    this.setState({ selectedVehicle: undefined, vehicleId: undefined });
                                                }}
                                                className="inline mr-1 clickable ml-auto"
                                            />
                                        </div>
                                    </div>
                                    <div className="my-0 py-0">
                                        <span className="text-sm font-bold text-gray-600">{selectedVehicle?.VIN}</span>
                                    </div>
                                    <div className="my-0 py-0">
                                        <span className="text-sm font-bold text-gray-600">
                                            {selectedVehicle?.licensePlate}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            this.getVehiclesList()
                        )}
                    </div>
                    <div className="py-10">
                        <div className="flex">
                            <div className="relative flex mb-1 w-1/2 ">
                                <div className="font-bold mr-1">From:</div>
                                <div className="relative flex">
                                    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                        <svg
                                            aria-hidden="true"
                                            className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                    </div>
                                    <input
                                        type="date"
                                        value={fromDate?.toString()}
                                        onChange={(e) => this.setState({ fromDate: e.target.value })}
                                        className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 py-1 mr-1`}
                                        placeholder="Select date"
                                    />
                                    <input
                                        type="time"
                                        value={fromTime}
                                        onChange={(e) => this.setState({ fromTime: e.target.value })}
                                        className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1`}
                                    ></input>
                                </div>
                            </div>
                            <div className="relative flex mb-1 w-1/2 ">
                                <div className="font-bold mr-1">To:</div>
                                <div className="relative flex">
                                    <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                        <svg
                                            aria-hidden="true"
                                            className="w-5 h-5 text-gray-500 dark:text-gray-400"
                                            fill="currentColor"
                                            viewBox="0 0 20 20"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                                                clipRule="evenodd"
                                            ></path>
                                        </svg>
                                    </div>
                                    <input
                                        type="date"
                                        value={toDate?.toString()}
                                        onChange={(e) => this.setState({ toDate: e.target.value })}
                                        className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 py-1 mr-1`}
                                        placeholder="Select date"
                                    />
                                    <input
                                        type="time"
                                        value={toTime}
                                        onChange={(e) => this.setState({ toTime: e.target.value })}
                                        className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1`}
                                    ></input>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

export default AppointmentModal;
