import React from 'react';
import { FaTrashAlt } from 'react-icons/fa';
import { Form } from 'semantic-ui-react';
import PhoneNumber from '../../models/PhoneNumberModel';
import PhoneNumberType from '../../models/types/PhoneNumberTypes';

interface Props {
    phoneNumbers: PhoneNumber[];
    phoneNumberUpdated: (phoneNumber: PhoneNumber[]) => void;
}

interface State {}

class PhoneNumbers extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.addNumber = this.addNumber.bind(this);
        this.phoneNumberUpdated = this.phoneNumberUpdated.bind(this);
    }

    addNumber() {
        const phoneNumbers = [
            ...this.props.phoneNumbers,
            { type: PhoneNumberType.Mobile, phoneNumber: '' } as PhoneNumber,
        ];
        this.props.phoneNumberUpdated(phoneNumbers);
    }

    phoneTypeUpdated(phoneIndex: number, type: number) {
        const { phoneNumbers } = this.props;
        phoneNumbers[phoneIndex].type = type;
        this.props.phoneNumberUpdated(phoneNumbers);
    }

    phoneNumberUpdated(e: any) {
        const { phoneNumbers } = this.props;
        phoneNumbers[e.target.name].phoneNumber = e.target.value;
        this.props.phoneNumberUpdated(this.props.phoneNumbers);
    }

    removeNumber(numberIndex: number) {
        const { phoneNumbers } = this.props;
        phoneNumbers.splice(numberIndex, 1);
        this.props.phoneNumberUpdated(phoneNumbers);
    }

    render() {
        const { phoneNumbers } = this.props;
        return (
            <div className="add-phone">
                <b>Phone numbers</b>
                {phoneNumbers.map((phoneNumber, index) => {
                    return (
                        <Form.Group unstackable widths="3" className="phone-number" key={index}>
                            <Form.Dropdown
                                options={phoneNumberTypes}
                                value={phoneNumber.type}
                                selection
                                onChange={(e: any, data) => this.phoneTypeUpdated(index, Number(data.value))}
                                width="4"
                                fluid
                            ></Form.Dropdown>
                            <Form.Input
                                type="tel"
                                name={`${index}`}
                                value={phoneNumber.phoneNumber}
                                onChange={this.phoneNumberUpdated}
                                key={index}
                                width="8"
                            ></Form.Input>
                            {phoneNumber.phoneNumber?.length > 0 && index !== 0 ? (
                                <FaTrashAlt
                                    className="clickable mt-3 text-red-400"
                                    onClick={() => this.removeNumber(index)}
                                />
                            ) : null}
                        </Form.Group>
                    );
                })}
                <a className="clickable expand-link" onClick={this.addNumber}>
                    Add additional phone number
                </a>
            </div>
        );
    }
}

const phoneNumberTypes = [
    { value: 0, key: 0, text: 'Mobile' },
    { value: 1, key: 1, text: 'Work' },
    { value: 2, key: 2, text: 'Office' },
    { value: 3, key: 3, text: 'Home' },
    { value: 4, key: 4, text: 'Fax' },
];
export default PhoneNumbers;
