import React from 'react';
import history from 'helpers/history';
import CannedJob, { CannedJobWithStats } from 'models/CannedJobModel';
import axios from 'helpers/axios';
import config from 'helpers/config';
import CannedJobServiceCard from './CannedJobServiceCard';
import LaborRate from 'models/LaborRateModel';
import RelativeTime from 'components/common/RelativeTime';
import moment from 'moment';
import UserFullName from 'components/common/UserFullName';
import { FaArchive } from 'react-icons/fa';
import CustomerFullName from 'components/common/CustomerFullName';
import getFullVehicleName from 'helpers/vehicleFullNameHelper';

interface Props {
    match: any;
}

interface State {
    activeTab: number;
    isEditMode: boolean;
    cannedJob?: CannedJobWithStats;
    laborRates: LaborRate[];
    isCannedJobLoading: boolean;
}

class CannedJobDetailsPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isEditMode: false,
            activeTab: 0,
            laborRates: [],
            isCannedJobLoading: false,
        };

        this.getCannedJob = this.getCannedJob.bind(this);
    }

    componentDidMount() {
        const { cannedJobId } = this.props.match.params;
        this.getLaborRates();
        this.getCannedJob(cannedJobId);
    }

    getLaborRates = () => {
        axios.get(`${config.apiUrl}/laborRate`).then((res) => {
            this.setState({ laborRates: res.data });
        });
    };

    getCannedJob(cannedJobId: string) {
        this.setState({ isCannedJobLoading: true });

        axios
            .get(`${config.apiUrl}/cannedJob/${cannedJobId}`)
            .then((res) => this.setState({ cannedJob: res.data, isCannedJobLoading: false }));
    }

    render() {
        const { isCannedJobLoading, cannedJob, laborRates } = this.state;
        return (
            <div className="bg-white mb-40 relative ">
                {isCannedJobLoading && (
                    <div className="page-loading">
                        <div className="spinner"></div>
                    </div>
                )}
                {!!isCannedJobLoading && <b>Loading...</b>}
                <div className="w-full bg-blue-500 p-8 pb-2">
                    <h3 className="text-blue-100">Canned Job</h3>
                    <h2 className="text-blue-50 text-2xl font-semibold">{cannedJob?.title}</h2>
                </div>

                {/* Page body */}
                <div className="px-8">
                    {cannedJob && (
                        <div className=" flex flex-col">
                            <CannedJobServiceCard
                                cannedJob={cannedJob as CannedJob}
                                cannedJobUpdated={(updatedJob: CannedJobWithStats) => {
                                    this.setState({ cannedJob: updatedJob });
                                }}
                                serviceDeleted={() => {}}
                                laborRates={laborRates}
                                fees={[]}
                            />
                        </div>
                    )}

                    {/* List of repair orders with this canned job applied */}
                    <div className="overflow-x-auto">
                        <table className="items-center bg-transparent border w-full">
                            <thead className="border">
                                <tr className=" bg-blue-600 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                                    <th className="pl-6 py-3 rounded-tl-xl">RO #</th>
                                    <th className="px-6 py-3">Title</th>
                                    <th className="px-6 py-3">Customer</th>
                                    <th className="px-6 py-3">Vehicle</th>
                                    <th className="px-6 py-3">Odometer</th>
                                    <th className="px-6 py-3">Services Count</th>
                                    <th className="px-6 py-3">Date Created</th>
                                    <th className="px-6 py-3">Created By</th>
                                    <th className="px-6 py-3">Total</th>
                                    <th className="px-6 py-3">Due</th>
                                    <th className="px-6 py-3">Workflow Status</th>
                                    <th className="px-6 py-3">Order Status</th>
                                    <th className="px-6 py-3 rounded-tr-xl">Labels</th>
                                </tr>
                            </thead>

                            <tbody>
                                {cannedJob?.repairOrders?.map((repairOrder, index) => (
                                    <tr
                                        className={`border group font-medium hover:bg-blue-200 clickable ${
                                            index % 2 === 0 ? 'bg-gray-100' : 'bg-blue-100'
                                        }`}
                                        key={index}
                                        onClick={() => history.push(`/repairOrder/${repairOrder.id}`)}
                                    >
                                        <td className="px-6 min-w-max align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2 text-left flex items-center">
                                            <span className="font-semibold text-gray-500">
                                                #{repairOrder.repairOrderNumber}
                                            </span>
                                        </td>
                                        <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                            <span>{repairOrder.title} </span>
                                        </td>
                                        <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                            <span>
                                                <CustomerFullName customerId={repairOrder?.customerId!} />
                                            </span>
                                        </td>
                                        <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                            <span>{getFullVehicleName(repairOrder?.vehicle!)}</span>
                                        </td>
                                        <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                            <span>
                                                {repairOrder?.odometerIn}{' '}
                                                {repairOrder?.odometerOut && `- ${repairOrder.odometerOut}`}{' '}
                                            </span>
                                        </td>

                                        <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                            <span>{repairOrder.services?.length}</span>
                                        </td>
                                        <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                            <span className="float-left">
                                                <RelativeTime dateTime={repairOrder.createdAt} />
                                            </span>
                                            <br />
                                            <span>
                                                <span className="text-sm">
                                                    {moment(repairOrder.createdAt).format('MM/DD/YYYY HH:mm')}
                                                </span>
                                            </span>
                                        </td>
                                        <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                            {!!repairOrder?.createdById && (
                                                <UserFullName userId={repairOrder?.createdById} />
                                            )}
                                        </td>

                                        <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                            $
                                            {repairOrder.services
                                                ?.map((service) => service.total)
                                                .reduce((a, b) => +a + +b, 0)
                                                .toFixed(2)}
                                        </td>
                                        <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                            <span>{repairOrder.title}</span>
                                        </td>
                                        <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                            {repairOrder.isArchived ? (
                                                <span className="bg-green-500 rounded-lg p-1.5 text-white font-semibold text-base">
                                                    <span>
                                                        <FaArchive className="inline mr-1" />
                                                    </span>
                                                    <span>Archived</span>
                                                </span>
                                            ) : (
                                                <span>{repairOrder.workflowStatus?.name}</span>
                                            )}
                                        </td>
                                        <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                            <span>{repairOrder?.status === 1 ? 'Invoice' : 'Estimate'}</span>
                                        </td>

                                        <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                            {repairOrder.labels?.map((label, indx) => (
                                                <div
                                                    key={indx}
                                                    style={{ backgroundColor: label.color }}
                                                    className="mr-2 px-1 py-1 rounded-lg hover:opacity-80 text-xs"
                                                >
                                                    <span className="font-semibold text-white inline-flex whitespace-nowrap">
                                                        {label.title}
                                                    </span>
                                                </div>
                                            ))}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {cannedJob?.repairOrders?.length! < 1 && (
                            <div className="border py-4 px-4 wb-white text-gray-600 text-lg font-semibold text-center">
                                This canned job was not yet used on any RO.
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default CannedJobDetailsPage;
