import React from 'react';

interface Props {}

interface State {}

class AllInvoicesReports extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }
    render() {
        return <div>All Invoices</div>;
    }
}

export default AllInvoicesReports;
