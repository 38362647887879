import RelativeTime from 'components/common/RelativeTime';
import RepairOrderActivity from 'models/RepairOrderActivity';
import React from 'react';
import { connect } from 'react-redux';

interface Props {
    activityLogs: RepairOrderActivity[];
}

const RepairOrderActivityLog: React.FC<Props> = ({ activityLogs }: Props) => {
    return (
        <div>
            <div className="grid">
                {!!activityLogs &&
                    activityLogs?.map((log) => {
                        return (
                            <div key={log.id} className="mb-2 border-b">
                                <div className="w-2 h-2 rounded-full text-sm bg-blue-600 inline-block mr-2 align-middle"></div>
                                <span className="font-bold mr-4">{log.description}</span>

                                <span className="font-semibold text-gray-500 relative">
                                    <RelativeTime dateTime={log.activityDate} />
                                </span>
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    activityLogs: state.repairOrder.activityLogs,
});

export default connect(mapStateToProps)(RepairOrderActivityLog);
