import React from 'react';
import LabelGroupList from './LabelGroupList';

interface Props {}

interface State {}

const labelGroups = [
    {
        value: 'customer',
        title: 'Customer',
    },
    {
        value: 'order',
        title: 'Repair Order',
    },
    {
        value: 'partStatus',
        title: 'Part Status',
    },
    {
        value: 'laborStatus',
        title: 'Labor Status',
    },
];

class LabelSettings extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }
    render() {
        return (
            <div className="settings-page__label">
                <div className="text-2xl font-semibold text-center">
                    <h3>Labels</h3>
                </div>
                <div className="flex flex-col items-center">
                    {labelGroups.map((labelType, index) => (
                        <LabelGroupList labelType={labelType} key={index} />
                    ))}
                </div>
            </div>
        );
    }
}

export default LabelSettings;
