import React, { FormEvent } from 'react';

import axios from 'helpers/axios';
import config from 'helpers/config';
import PartsMatrix from 'models/PartsMatrixModel';
import { FaEdit, FaPlusCircle, FaRegTrashAlt } from 'react-icons/fa';
import Price from 'components/common/Price';
import PartsMatrixRange from 'models/PartsMatrixRangeModel';
import Toggle from 'components/common/Toggle';
import Percentage from 'components/common/Percentage';
import PriceInputCents from 'components/common/PriceInputCents';
import { connect } from 'react-redux';
import { getPricingMatrices } from 'actions/tenantActions';
import { Dispatch } from 'redux';

interface Props {
    dispatch: Dispatch;
}

interface State {
    matrices: PartsMatrix[];
    isCreateNewMatrixRangeActive: boolean;
    isNewMatrixBeingCreated: boolean;

    editableMatrixRanges: PartsMatrixRange[];
    editableMatrix: PartsMatrix | null;

    existingPartsMatrices: PartsMatrix[];

    editableRateId: string | null;
    name: string | null;
    rate: number | null;
}

class PartsMatricesSettings extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            matrices: [],
            existingPartsMatrices: [],
            isCreateNewMatrixRangeActive: false,
            editableMatrixRanges: [],
            isNewMatrixBeingCreated: false,
            editableRateId: null,
            editableMatrix: null,
            rate: null,
            name: null,
        };

        this.createNewMatrixRange = this.createNewMatrixRange.bind(this);
        this.updateMatrixRange = this.updateMatrixRange.bind(this);
    }

    componentDidMount() {
        this.getMatrices();
    }

    getMatrices() {
        axios.get(`${config.apiUrl}/partsMatrix`).then((res) => {
            this.setState({ existingPartsMatrices: res.data });
            this.props.dispatch<any>(getPricingMatrices());
        });
    }

    createNewMatrixRange() {
        const { name, rate } = this.state;
        const newMatrixRange = {
            name,
            rate,
        };

        axios.post(`${config.apiUrl}/partsMatrix`, newMatrixRange).then((_) => {
            this.getMatrices();
            this.setState({ name: null, rate: null, isCreateNewMatrixRangeActive: false });
        });
    }

    updateMatrixRange() {
        const { name, rate, editableRateId } = this.state;
        const matrixRange = {
            name,
            rate,
            id: editableRateId,
        };

        axios.put(`${config.apiUrl}/partsMatrix`, matrixRange).then((_) => {
            this.setState(
                { name: null, rate: null, isCreateNewMatrixRangeActive: false, editableRateId: null },
                this.getMatrices,
            );
        });
    }

    initNewMatrixRegistration() {
        this.setState({ isNewMatrixBeingCreated: true });

        const editableMatrixRanges = [
            {
                fromCents: 0,
                toCents: 1000,
                markup: 75,
            },
            {
                fromCents: 1001,
                toCents: 10000,
                markup: 20,
            },
        ] as PartsMatrixRange[];

        const newMatrix = {
            name: '',
            ranges: editableMatrixRanges,
        } as PartsMatrix;

        this.setState({ editableMatrixRanges, isNewMatrixBeingCreated: true, editableMatrix: newMatrix });
    }

    addNewRange() {
        const currentMatrix = this.state.editableMatrixRanges;
        const currentLastRange = currentMatrix[currentMatrix.length - 1];
        let lastRange = Number(String(currentMatrix[currentMatrix.length - 1].toCents).replace(/,/g, ''));
        if (lastRange <= 0 || isNaN(lastRange)) {
            // alert('Please fill in the last range ' + lastRange + ' ' + currentMatrix[currentMatrix.length - 1]);
            currentMatrix[currentMatrix.length - 1].toCents = Math.round(currentLastRange.fromCents + 100);
            lastRange = Math.round(currentLastRange.fromCents + 100);
        }

        const newRange = {
            fromCents: lastRange + 1,
            toCents: lastRange + 10000,
            markup: 0,
        } as PartsMatrixRange;

        // range.to = Number(String(range.to).replace(/,/g, ''));

        currentMatrix.push(newRange);

        this.setState({
            editableMatrixRanges: currentMatrix,
        });

        setTimeout(() => {
            const element = document.getElementById('price' + (currentMatrix.length - 1).toString() + 'to');
            element?.focus();
        }, 100);
    }

    updateRangeTo(newValue: number, index: number) {
        const currentMatrix = this.state.editableMatrixRanges;
        currentMatrix[index].toCents = newValue;
        this.setState({ editableMatrixRanges: currentMatrix });
    }

    handleSave() {
        const { editableMatrix, editableMatrixRanges } = this.state;
        editableMatrixRanges.forEach((range) => {
            range.toCents = Number(String(range.toCents).replace(/,/g, ''));
        });
        editableMatrix!.ranges = editableMatrixRanges;

        if (this.state.isNewMatrixBeingCreated) {
            axios.post(`${config.apiUrl}/partsMatrix`, editableMatrix).then((_) => this.getMatrices());
        } else {
            axios.put(`${config.apiUrl}/partsMatrix/`, editableMatrix).then((_) => this.getMatrices());
        }
    }

    isValidRange(priceFrom: number, priceToString: string) {
        const priceTo = Number(priceToString.replace(/,/g, ''));
        return !(priceFrom < 0 || priceTo <= 0 || isNaN(priceFrom) || isNaN(priceTo) || priceFrom >= priceTo);
    }

    handleDelete(range: PartsMatrixRange) {
        const { editableMatrixRanges } = this.state;
        axios.delete(`${config.apiUrl}/partsMatrix/range/${range.id}`).then((_) => {
            this.setState({ editableMatrixRanges: editableMatrixRanges.filter((r) => r !== range) });
        });
    }

    render() {
        const { matrices, isNewMatrixBeingCreated, editableMatrixRanges, editableMatrix, existingPartsMatrices } =
            this.state;
        return (
            <div className="flex flex-col ">
                <button
                    className="font-semibold py-2 px-3 rounded-xl hover:bg-green-500 border border-green-500
                            text-green-500 hover:text-white ml-auto
                            "
                    onClick={() => this.initNewMatrixRegistration()}
                >
                    <FaPlusCircle className="inline mr-2 align-baseline" />
                    <span>New Matrix</span>
                </button>
                <div className="flex mt-4">
                    <div className="mr-12 w-1/4 bg-gray-100 rouded-lg border p-2.5 rounded-lg h-fit">
                        <div className="pb-1.5 border-b-2 border-b-blue-500">
                            <span className="font-semibold text-gray-700">All Matrices:</span>
                        </div>
                        {existingPartsMatrices.map((matrix, index) => (
                            <div
                                key={index}
                                className={` border-b hover:border-b-gray-200 border-b-gray-100 py-2.5 clickable hover:bg-blue-100 pl-2.5 ${
                                    editableMatrix?.id === matrix.id ? 'bg-blue-100' : 'bg-white'
                                }`}
                                onClick={() => {
                                    this.setState({
                                        editableMatrixRanges: matrix.ranges,
                                        isNewMatrixBeingCreated: false,
                                        editableMatrix: matrix,
                                    });
                                }}
                            >
                                <div className="flex justify-between">
                                    <div className="font-semibold">{matrix.name}</div>
                                    <div className="flex">
                                        {matrix.isDefault && (
                                            <span
                                                style={{ backgroundColor: 'red' }}
                                                className="mr-2 px-1 py-1 rounded-lg hover:opacity-80 text-xs"
                                            >
                                                <span className="font-semibold text-white inline-flex whitespace-nowrap">
                                                    Default{' '}
                                                </span>
                                            </span>
                                        )}
                                        <FaEdit
                                            className="clickable mr-2"
                                            onClick={() => {
                                                this.setState({
                                                    editableMatrixRanges: matrix.ranges,
                                                    isNewMatrixBeingCreated: false,
                                                    editableMatrix: matrix,
                                                });
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="grow">
                        {!!(isNewMatrixBeingCreated || !!editableMatrix) && (
                            <div className="border p-2.5 rounded-lg bg-gray-100">
                                <div>
                                    <div className="pb-2">
                                        <label className="font-semibold">Matrix name</label>
                                        <input
                                            type="text"
                                            name="name"
                                            className="bg-gray-50 rounded-md border w-full px-2 text-lg font-semibold py-2 focus:ring-1 focus:outline-none focus:border-blue-500"
                                            autoFocus
                                            value={editableMatrix?.name}
                                            onChange={(e) => {
                                                const newName = e.target?.value;
                                                this.setState((prevState) => ({
                                                    editableMatrix: {
                                                        ...prevState.editableMatrix!,
                                                        name: newName,
                                                    },
                                                }));
                                            }}
                                        />
                                    </div>
                                    <table className="items-center w-full text-white  font-semibold text-left text-xs align-middle">
                                        <thead>
                                            <tr className="bg-blue-500 h-12 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                                                <th className="pl-6  py-3">Cost From</th>
                                                <th className="pl-6  ">Cost To</th>
                                                <th className="pl-6  ">Markup</th>
                                                <th className="pl-6  ">Gross profit</th>
                                                <th className="pl-6 "></th>
                                            </tr>
                                        </thead>
                                        <tbody className="border">
                                            {editableMatrixRanges.map((range, index) => {
                                                return (
                                                    <tr
                                                        className={`text-black font-medium bg-white  hover:bg-blue-100`}
                                                        key={index}
                                                    >
                                                        <td className="px-6 align-middle h-5 text-sm py-4 border-b text-left items-center">
                                                            <div className="whitespace-pre-wrap">
                                                                <Price price={range.fromCents / 100} />
                                                            </div>
                                                        </td>
                                                        <td className="px-6 align-middle h-5 text-sm py-4 border-b">
                                                            <div className="whitespace-pre-wrap">
                                                                <span>
                                                                    {/* <span className="font-semibold inline">$</span> */}
                                                                    {/* <PriceInput
                                                                        placeholder="0.00"
                                                                        name="price"
                                                                        onChange={(e) => this.updateRange(e, index)}
                                                                        amount={range.toCents / 100}
                                                                        id={'price' + index.toString() + 'to'}
                                                                        className={`outline-none w-24 placeholder-gray-400 font-medium px-3 py-2 ${
                                                                            this.isValidRange(
                                                                                range.fromCents,
                                                                                String(range.toCents),
                                                                            )
                                                                                ? 'focus:ring-blue-600 focus:ring-1'
                                                                                : 'focus:ring-red-600 focus:ring-1'
                                                                        }  rounded-lg text-right `}
                                                                        hasError={
                                                                            !this.isValidRange(
                                                                                range.fromCents,
                                                                                String(range.toCents),
                                                                            )
                                                                        }
                                                                    /> */}
                                                                    <PriceInputCents
                                                                        initialPriceCents={range.toCents}
                                                                        handlePriceChange={(priceCents) => {
                                                                            this.updateRangeTo(priceCents, index);
                                                                        }}
                                                                        className={`outline-none w-24 placeholder-gray-400 font-medium px-3 py-2 ${
                                                                            this.isValidRange(
                                                                                range.fromCents,
                                                                                String(range.toCents),
                                                                            )
                                                                                ? 'focus:ring-blue-600 focus:ring-1'
                                                                                : 'focus:ring-red-600 focus:ring-1'
                                                                        }  rounded-lg text-right `}
                                                                        hasError={
                                                                            !this.isValidRange(
                                                                                range.fromCents,
                                                                                String(range.toCents),
                                                                            )
                                                                        }
                                                                    />
                                                                </span>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 align-middle h-5 text-sm py-4 border-b non-clickable">
                                                            <div className="flex ">
                                                                <input
                                                                    className={`outline-none w-16 placeholder-gray-400 font-medium px-0 pl-0.5 py-2 ${
                                                                        range.markup < 0
                                                                            ? 'focus:ring-red-600 focus:ring-2'
                                                                            : 'focus:ring-blue-600 focus:ring-1'
                                                                    }  rounded-lg text-right `}
                                                                    type="number"
                                                                    min={0}
                                                                    value={range?.markup || 0}
                                                                    id={'markup' + index.toString()}
                                                                    onChange={(e) => {
                                                                        const { value } = e.target;
                                                                        this.setState((prevState) => {
                                                                            const newRanges = [
                                                                                ...prevState.editableMatrixRanges,
                                                                            ];
                                                                            newRanges[index].markup = Number(value);
                                                                            return { editableMatrixRanges: newRanges };
                                                                        });
                                                                    }}
                                                                />
                                                                <span className="font-semibold inline pt-1.5">%</span>
                                                            </div>
                                                        </td>
                                                        <td className="px-6 align-middle h-5 text-sm py-4 border-b">
                                                            <div className="flex ">
                                                                <Percentage
                                                                    percentage={
                                                                        (1 * (1 + range.markup / 100) - 1) /
                                                                        (1 * (1 + range.markup / 100))
                                                                    }
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <FaRegTrashAlt
                                                                size={15}
                                                                className="inline clickable mr-1"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    this.handleDelete(range);
                                                                }}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                    <div className="mt-4 flex">
                                        <button
                                            className="bg-blue-500 px-2 py-1.5 rounded-2xl font-medium text-base"
                                            onClick={() => this.addNewRange()}
                                        >
                                            <FaPlusCircle className="inline mr-2 align-baseline" />

                                            <span>Add range</span>
                                        </button>

                                        <div className="ml-auto">
                                            <div className="flex">
                                                <div className="mr-2">
                                                    <Toggle
                                                        onChange={(newValue) =>
                                                            this.setState((prevState) => ({
                                                                editableMatrix: {
                                                                    ...prevState.editableMatrix!,
                                                                    isDefault: newValue,
                                                                },
                                                            }))
                                                        }
                                                        name="isDefaultMatrix"
                                                        label="Is default matrix"
                                                        value={editableMatrix?.isDefault || false}
                                                        isLabelLeft={true}
                                                        disabled={existingPartsMatrices.some(
                                                            (matrix) =>
                                                                matrix.isDefault && matrix.id !== editableMatrix?.id,
                                                        )}
                                                    />
                                                </div>
                                                <button
                                                    className="bg-green-500 px-2 py-1.5 rounded-2xl font-medium text-base
                                                    disabled:opacity-50 disabled:bg-green-500 disabled:cursor-not-allowed
                                                    "
                                                    disabled={editableMatrixRanges.some(
                                                        (range) =>
                                                            !this.isValidRange(range.fromCents, String(range.toCents)),
                                                    )}
                                                    onClick={() => this.handleSave()}
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default connect()(PartsMatricesSettings);
