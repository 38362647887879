import React, { useEffect, useState } from 'react';

// Format the number with commas for thousands
const FormattedNumber = ({ number = 0 }) => {
    const [formattedNumber, setFormattedNumber] = useState(number.toLocaleString());

    useEffect(() => {
        setFormattedNumber(number.toLocaleString());
    }, [number]);

    return <div>{formattedNumber}</div>;
};

export default FormattedNumber;
