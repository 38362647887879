import React from 'react';

interface Props {
    name: string;
    value: boolean;
    label?: string;
    disabled?: boolean;

    isLabelLeft?: boolean;

    onChange: (newValue: boolean) => void;
}

const Toggle: React.FC<Props> = ({
    name,
    onChange,
    label,
    value = false,
    disabled = false,
    isLabelLeft = false,
}: Props) => {
    return (
        <div className="flex  w-full">
            {!!label && isLabelLeft && <div className="ml-3 text-gray-700 font-semibold mr-2 pt-1">{label}</div>}
            <label className={`flex items-center ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}>
                <div className="relative">
                    <input
                        type="checkbox"
                        id={'toggle' + name}
                        className="sr-only "
                        name={name}
                        onChange={() => onChange(!value)}
                        checked={value}
                        disabled={disabled}
                    />
                    <div className={`block ${value ? 'bg-blue-600' : 'bg-gray-600'} w-14 h-8 rounded-full`}></div>
                    <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>
                </div>
                {!!label && !isLabelLeft && <div className="ml-3 text-gray-700 font-semibold">{label}</div>}
            </label>
        </div>
    );
};

export default Toggle;
