import React from 'react';
import history from 'helpers/history';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { Icon } from 'semantic-ui-react';
import { getCurrentUser } from 'actions/userActions';
import axios from 'helpers/axios';
import config from 'helpers/config';
import User from 'models/UserModel';
import UserModal from 'components/modals/user/UserModal';
import moment from 'moment';

interface Props {
    dispatch: Dispatch;
    currentUser: User;
}

interface State {
    users: User[];
    isLoading: boolean;
    isCreateUserModalOpen: boolean;
    isEditUserModalOpen: boolean;
    editableUserId: string | null;
}

class UserSettings extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            users: [],
            isLoading: true,
            isCreateUserModalOpen: false,
            isEditUserModalOpen: false,
            editableUserId: null,
        };
    }

    componentDidMount() {
        this.getUsers();
    }

    getUsers() {
        axios.get(`${config.apiUrl}/user`).then((res) => this.setState({ users: res.data }));
    }

    editUser(e: Event, userId: string) {
        e.stopPropagation();
        this.setState({ isEditUserModalOpen: true, editableUserId: userId });
    }

    // deleteUser(e: Event, id: string) {}

    modalClosed(userCreated?: boolean, userUpdated?: boolean) {
        this.setState({
            isCreateUserModalOpen: false,
            isEditUserModalOpen: false,
            editableUserId: null,
        });
        if (userCreated || userUpdated) {
            this.componentDidMount();
            this.props.dispatch<any>(getCurrentUser());
        }
    }

    getUserModal() {
        const { isCreateUserModalOpen, isEditUserModalOpen, editableUserId } = this.state;

        if (isEditUserModalOpen && !!editableUserId) {
            return (
                <UserModal
                    userId={editableUserId}
                    onClose={(userCreated?: boolean, userUpdated?: boolean) =>
                        this.modalClosed(userCreated, userUpdated)
                    }
                ></UserModal>
            );
        } else if (isCreateUserModalOpen) {
            return (
                <UserModal
                    onClose={(userCreated?: boolean, userUpdated?: boolean) =>
                        this.modalClosed(userCreated, userUpdated)
                    }
                ></UserModal>
            );
        }
    }

    goToUserDetails(userId: string) {
        history.push(`/user/${userId}`);
    }

    render() {
        const { users, isCreateUserModalOpen, isEditUserModalOpen } = this.state;
        const { currentUser } = this.props;
        return (
            <div>
                {(isCreateUserModalOpen || isEditUserModalOpen) && this.getUserModal()}
                <div className="settings-page__header mb-8">
                    <h2 className="text-2xl font-semibold">Users</h2>

                    <button
                        className="bg-green-600 py-1 px-2 rounded-2xl font-medium text-base ml-auto"
                        onClick={() => this.setState({ isCreateUserModalOpen: !isCreateUserModalOpen })}
                    >
                        + Add new
                    </button>
                </div>

                <div className="overflow-x-auto">
                    <table className="items-center w-full">
                        <thead>
                            <tr className="bg-blue-500 h-12 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                                <td className="table-cell rounded-tl-md">First name</td>
                                <td className="table-cell">Last name</td>
                                <td className="table-cell">Email</td>
                                <td className="table-cell">Phone number</td>
                                <td className="table-cell">Date of Registration</td>
                                <th className="px-6 py-3 rounded-tr-md"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {users?.map((user, _) => (
                                <tr
                                    className="border-b h-12 font-semibold hover:bg-blue-200 clickable bg-white border-r-2 border-l-2"
                                    key={user.id}
                                    onClick={() => this.goToUserDetails(user.id)}
                                >
                                    <td className="table-cell clickable">{user.firstName}</td>
                                    <td className="table-cell">{user.lastName}</td>
                                    <td className="table-cell">{user.email}</td>
                                    <td className="table-cell">{user.phoneNumber}</td>
                                    <td className="table-cell">{moment(user.createdAt).format('MMM Do YYYY')}</td>
                                    <td className="table-cell non-clickable">
                                        <Icon
                                            className="clickable"
                                            name="edit"
                                            onClick={(e: Event) => this.editUser(e, String(user.id))}
                                        />
                                        {!(user.isAdmin || user.id === currentUser.id) && (
                                            <Icon
                                                className="clickable"
                                                name="trash alternate"
                                                size="small"
                                                // onClick={(e: Event) => this.deleteUser(e, String(user.id))}
                                            />
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(UserSettings);
