import actionTypes from '../../helpers/actionTypes';
import User from '../../models/UserModel';
import UsersAccessPointModel from '../../models/UsersAccessPointModel';

class initialState {
    currentUser!: User;
    accessPoints!: UsersAccessPointModel[];
}

const userReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case actionTypes.CURRENT_USER_SUCCESS:
            return {
                ...state,
                currentUser: action.payload,
            };
        case actionTypes.USER_ACCESS_POINTS_SUCCESS:
            return {
                ...state,
                accessPoints: action.payload,
            };
        case actionTypes.UPDATE_USER_SETTINGS_SUCCESS:
            return {
                ...state,
                // @ts-ignore
                currentUser: { ...state.currentUser, userSettings: action.payload },
            };
        case actionTypes.DELETE_DEMO_DATA_SUCCESS: {
            return {
                ...state,
                // @ts-ignore
                currentUser: { ...state.currentUser, tenant: { ...state.currentUser.tenant, hasDemoData: false } },
            };
        }

        default: {
            return state;
        }
    }
};

export default userReducer;
