import axios from 'helpers/axios';
import config from 'helpers/config';
import ClockIn from 'models/ClockIn';
import RepairOrder from 'models/RepairOrderModel';
import User from 'models/UserModel';
import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
import { TiDeleteOutline } from 'react-icons/ti';

interface Props {
    user: User;

    timeLogs: ClockIn[];
    onChange: () => void;
    activeRepairOrders: RepairOrder[];
}

const UserClockInCard = ({ user, timeLogs = [], onChange, activeRepairOrders = [] }: Props) => {
    const [isClockedIn, setIsClockedIn] = useState(false);
    const [isROSearchExpanded, setIsROSearchExpanded] = useState(false);
    const [activeClockIn, setActiveClockIn] = useState<ClockIn | null>({ userId: user.id, description: '' } as ClockIn);

    const [startTime, setStartTime] = useState<number | null>(null);
    const [timePassed, setTimePassed] = useState(0);
    const [selectedRO, setSelectedRO] = useState<RepairOrder | null>(null);

    useEffect(() => {
        const intervalId = setInterval(updateTime, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, [startTime]);

    useEffect(() => {
        const currentClockedIn = timeLogs.find((log) => log.userId === user.id && !log.clockOutTime);
        if (currentClockedIn) {
            setIsClockedIn(true);
            setActiveClockIn(currentClockedIn);
            setStartTime(new Date(currentClockedIn.clockInTime).getTime());
        }
    }, [timeLogs]);

    const startClock = () => {
        axios
            .post(`${config.apiUrl}/timeLog/startClockIn`, {
                userId: user.id,
                repairorderId: activeClockIn?.repairorderId,
                // description: noteContent,
                clockInTime: new Date().getTime(),
            })
            .then((res) => {
                setActiveClockIn(res.data);
            })
            .catch((_) => console.log(_));
        setStartTime(new Date().getTime());
        onChange();
    };

    const stopClock = () => {
        axios
            .put(`${config.apiUrl}/timeLog/clockOut/${activeClockIn!.id}`)
            .then((_) => setActiveClockIn(null))
            .catch((_) => console.log(_))
            .finally(() => onChange());
    };

    const updateTimeLog = () => {
        axios
            .put(`${config.apiUrl}/timeLog`, {
                ...activeClockIn,
            })
            .then((res) => {
                setActiveClockIn(res.data);
            })
            .then(() => onChange())
            .catch((_) => console.log(_));
        setStartTime(new Date().getTime());
    };

    const updateTime = () => {
        if (startTime) {
            const currentTime = new Date().getTime();
            const elapsedTime = currentTime - startTime;
            setTimePassed(elapsedTime);
        }
    };

    const formatTime = (value: number) => {
        return value < 10 ? `0${value}` : value;
    };

    const hours = Math.floor(timePassed / (1000 * 60 * 60));
    const minutes = Math.floor((timePassed % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timePassed % (1000 * 60)) / 1000);

    return (
        <div
            className={`h-fit px-16 py-4 rounded-lg border flex justify-center items-center flex-col bg-blue-100  ${
                isClockedIn ? 'border-blue-500' : 'border-gray-500'
            }`}
            key={user.id}
        >
            <Avatar name={`${user.firstName} ${user.lastName}`} round size={'32'} className="clickable" />
            <div className="text-gray-600 font-semibold flex">
                <span className="pr-1.5">{user.firstName}</span>
                <span>{user.lastName}</span>
            </div>

            {isClockedIn && (
                <div className="py-4 text-blue-500 font-bold ">
                    <span className="text-4xl">
                        {formatTime(hours)}:{formatTime(minutes)}:
                    </span>
                    <span className="text-xl">{formatTime(seconds)}</span>
                </div>
            )}
            <div className="flex p-2.5 rounded-lg border border-gray-300 divide-x-2 space-x-3">
                <div className="px-2 flex justify-center items-center flex-col">
                    <div>Today</div>
                    {user.totalTime ? (
                        <div className="text-blue-600 font-semibold">
                            {user.totalTime?.totalTimeToday.hours}h {user.totalTime.totalTimeToday?.minutes}m
                        </div>
                    ) : (
                        <div className="text-gray-600 font-semibold">00:00</div>
                    )}
                </div>
                <div className="px-2 flex justify-center items-center flex-col">
                    <div>This Week</div>
                    {user.totalTime ? (
                        <div className="text-blue-600 font-semibold">
                            {user.totalTime?.totalTimePastWeek.hours}h {user.totalTime.totalTimePastWeek?.minutes}m
                        </div>
                    ) : (
                        <div className="text-gray-600 font-semibold">00:00</div>
                    )}
                </div>
                <div className="px-2 flex justify-center items-center flex-col">
                    <div>This Month</div>
                    {user.totalTime ? (
                        <div className="text-blue-600 font-semibold">
                            {user.totalTime?.totalTimePastMonth.hours}h {user.totalTime.totalTimePastMonth?.minutes}m
                        </div>
                    ) : (
                        <div className="text-gray-600 font-semibold">00:00</div>
                    )}
                </div>
            </div>

            <div className="pt-8">
                {isClockedIn && (
                    <div>
                        <textarea
                            className="rounded-md border border-gray-300 focus:outline-none focus:border-blue-500 bg-gray-100 px-4 py-2"
                            placeholder="Notes"
                            autoFocus={false}
                            onChange={(e) =>
                                setActiveClockIn({ ...(activeClockIn as ClockIn), description: e.target.value })
                            }
                            onBlur={() => {
                                updateTimeLog();
                            }}
                            value={activeClockIn?.description || ''}
                            rows={4}
                        />
                    </div>
                )}
            </div>

            <div className="w-full text-gray-600 float-left">
                <div className="font-bold "> Activity</div>
                <div>
                    {activeClockIn?.repairorderId ? (
                        <div
                            className={`flex group border  border-blue-200 hover:bg-blue-100 hover:border-blue-600 py-1 px-2 rounded-lg`}
                        >
                            <div>
                                <div className="font-semibold text-sm">#{selectedRO?.repairOrderNumber}</div>
                                <div className="text-gray-400 font-semibold text-xs">{selectedRO?.title}</div>
                            </div>
                            <div className="ml-auto">
                                <TiDeleteOutline
                                    size={15}
                                    className=" clickable mt-1 text-black"
                                    onClick={() => setSelectedRO(null)}
                                />
                            </div>
                        </div>
                    ) : (
                        <input
                            className="outline-none w-72 placeholder-gray-400 font-medium px-3 py-2 focus:ring-1 focus:ring-blue-600 rounded-lg mb-1"
                            placeholder={'Search repair orders'}
                            autoComplete="off"
                            name="description"
                            // value={selectedRO?.title || undefined}
                            // onChange={(e) => handleChange(e)}

                            onFocus={() => setIsROSearchExpanded(true)}
                            onBlur={() => {
                                setTimeout(() => {
                                    setIsROSearchExpanded(false);
                                }, 333);
                            }}
                        />
                    )}
                </div>

                {isROSearchExpanded && !selectedRO && (
                    <div
                        id="dropdown"
                        className="bg-white w-72 mr-3 z-50  text-sm max-w-xls  text-left no-underline break-words absolute font-bold border border-gray-700 max-h-96 overflow-y-scroll "
                    >
                        <div className="font-semibold text-md bg-blue-100 p-1.5 ">
                            <span className="text-blue-600">{activeRepairOrders?.length}</span> <span> results: </span>
                        </div>
                        <ul
                            className="py-1 text-sm text-gray-700 divide-y divide-gray-200 relative"
                            aria-labelledby="dropdownDefault"
                        >
                            {activeRepairOrders.map((RO: RepairOrder, index) => {
                                return (
                                    <li
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            // onPartSelected(RO);
                                            setSelectedRO(RO);
                                            if (activeClockIn) {
                                                setActiveClockIn({ ...activeClockIn, repairorderId: RO.id });
                                            }
                                        }}
                                        key={index}
                                        className="p-1 clickable hover:bg-gray-200"
                                    >
                                        <div>
                                            <div>
                                                <span>
                                                    #{RO.repairOrderNumber}
                                                    {` `}
                                                </span>
                                                <span className="font-semibold">{RO.title}</span>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
            </div>
            <div>
                <button
                    className={`font-semibold py-2 px-3 rounded-xl  border text-white ${
                        isClockedIn ? 'bg-red-500' : 'bg-green-500'
                    }`}
                    onClick={() => {
                        setIsClockedIn(!isClockedIn);
                        if (!isClockedIn) {
                            startClock();
                        } else {
                            setStartTime(null);
                            stopClock();
                        }
                    }}
                >
                    <span>{isClockedIn ? 'Clock Out' : 'Clock In'}</span>
                </button>
            </div>
        </div>
    );
};

export default UserClockInCard;
