import FormattedNumber from 'components/common/Number';
import LoanerVehicle from 'models/LoanerVehicleModel';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
    loaner?: LoanerVehicle;
}

interface State {}

class LoanerOverview extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    getLastBooking() {
        const { loaner } = this.props;
        if (loaner?.loanerBookings?.length == 0) {
            return null;
        }
        return loaner?.loanerBookings?.sort(
            (a, b) => new Date(b.startTime).getTime() - new Date(a.startTime).getTime(),
        )[0];
    }
    render() {
        const { loaner } = this.props;
        const lastBooking = this.getLastBooking();

        return (
            <div className="mt-8 border border-black rounded-lg p-4 divide-y-2 divide-gray-200 w-1/2 ">
                <div>
                    <h2 className="uppercase text-lg font-semibold">Quick look</h2>
                </div>
                <div>
                    <div className="flex">
                        <div className="w-1/3">Status: </div>
                        <div className="font-semibold text-gray-700">
                            {loaner?.loanerBookings?.findIndex(
                                (booking) => booking.endTime === null || booking.endTime === undefined,
                            ) !== -1 ? (
                                <span>Booked</span>
                            ) : (
                                <span>Available</span>
                            )}
                        </div>
                    </div>
                    <div className="flex">
                        <div className="w-1/3">Last rented to: </div>
                        <div>
                            <div className="">
                                {lastBooking?.customer ? (
                                    <Link to={`/customer/${lastBooking.customer?.id}`}>
                                        <span className="font-bold w-3/4 link">
                                            {lastBooking.customer?.firstName} {lastBooking.customer?.lastName}
                                        </span>
                                    </Link>
                                ) : (
                                    <span className="font-bold">N/A</span>
                                )}
                            </div>
                            {lastBooking?.customer && (
                                <div className="text-sm font-bold">
                                    <div>{lastBooking.customer?.primaryEmail}</div>
                                    <div>
                                        {lastBooking.customer?.phoneNumbers?.map((number) => (
                                            <div key={number.id}>{number.phoneNumber}</div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className="flex">
                        <div className="w-1/3">Last returned odometer: </div>
                        <div className="font-semibold text-gray-700">
                            <FormattedNumber number={127921} />
                        </div>
                    </div>

                    <div className="flex">
                        <div className="w-1/3">Rented by: </div>
                        <div className="font-semibold text-gray-700">
                            {lastBooking?.createdBy?.firstName} {lastBooking?.createdBy?.lastName}
                        </div>
                    </div>

                    <div className="flex">
                        <div className="w-1/3">: </div>
                        <div className="font-semibold text-gray-700">{}</div>
                    </div>
                </div>
            </div>
        );
    }
}

export default LoanerOverview;
