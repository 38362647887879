import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import RoutingConfiguration from './helpers/routingConfiguration';
import './styles/app.scss';
import DeleteConfirmationGlobal from './helpers/services/removeConfirmationService';
import WarningModalGlobal from './helpers/services/warningModalService';

type Props = {
    history: any;
    store: any;
};

const App = (props: Props) => (
    <Provider store={props.store}>
        <Router history={props.history}>
            <RoutingConfiguration />
        </Router>
        <DeleteConfirmationGlobal />
        <WarningModalGlobal />
    </Provider>
);

export default App;
