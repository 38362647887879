import React from 'react';
import history from 'helpers/history';
import { Draggable } from 'react-beautiful-dnd';
import { CgMore } from 'react-icons/cg';
import {
    FaCalendar,
    FaCar,
    FaCheckCircle,
    FaExclamationTriangle,
    FaTasks,
    FaTimesCircle,
    FaUser,
} from 'react-icons/fa';

import RepairOrder from 'models/RepairOrderModel';
import axios from 'helpers/axios';
import config from 'helpers/config';
import LabelPicker from 'components/common/LabelPicker';
import Label from 'models/LabelModel';
import Avatar from 'react-avatar';
import LaborRate from 'models/LaborRateModel';
import Price from 'components/common/Price';
import { getGrandTotal } from 'helpers/services/priceCalculationService';
import { displayConfirmationModal } from 'helpers/services/removeConfirmationService';
import HoverTooltip from 'components/common/HoverTooltip';
import { connect } from 'react-redux';
import User from 'models/UserModel';
import moment from 'moment';
import dateFormats from 'helpers/dateFormats';
import { GiKeyCard } from 'react-icons/gi';

interface Props {
    job: RepairOrder;
    index: number;
    jobArchived: () => void;
    laborRates: LaborRate[];
    currentUser: User;
}

interface State {
    isMorePickerOpen: boolean;
    labels: Label[];
    isPaid: boolean;
    paymentAmountDue?: number;
}

class JobTicket extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isMorePickerOpen: false,
            labels: this.props.job?.labels,
            isPaid: false,
        };

        this.onClickOutside = this.onClickOutside.bind(this);
        this.togglePicker = this.togglePicker.bind(this);
        this.handleDeleteJobClicked = this.handleDeleteJobClicked.bind(this);
        this.archiveJob = this.archiveJob.bind(this);
    }

    getPaymentAmountDue() {
        const grandTotal = this.props.job?.services?.reduce((acc, service) => acc + service.grandTotalCents, 0) / 100;
        const { payments } = this.props.job;
        const paidToDate = Number(
            payments
                .map((payment) => payment.amountCents / 100)
                .reduce((a, b) => +a + +b, 0)
                .toFixed(2),
        );

        return grandTotal - paidToDate;
    }

    getPaidStatus() {
        const grandTotal = this.props.job?.services?.reduce((acc, service) => acc + service.grandTotalCents, 0) / 100;
        const { payments } = this.props.job;
        const paidToDate = Number(
            payments
                .map((payment) => payment.amountCents / 100)
                .reduce((a, b) => +a + +b, 0)
                .toFixed(2),
        );

        return grandTotal > 0 && grandTotal === paidToDate;
    }

    getGrandTotal(RO: RepairOrder) {
        const { laborRates } = this.props;
        return getGrandTotal(RO?.services, laborRates);
    }

    onClickOutside(e: any) {
        const target = document.querySelector(`#jobCard${this.props.job.id}`);
        const withinBoundaries = e.composedPath().includes(target);

        if (withinBoundaries) {
        } else {
            this.togglePicker(false);
        }
    }

    togglePicker(open: boolean, e?: React.MouseEvent) {
        if (e) {
            e.stopPropagation();
        }
        if (open) {
            this.setState({ isMorePickerOpen: true });
            document.addEventListener('click', this.onClickOutside);
        } else {
            this.setState({ isMorePickerOpen: false });
            document.removeEventListener('click', this.onClickOutside);
        }
    }

    archiveJob() {
        axios
            .post(`${config.apiUrl}/repairOrder/archive/${this.props.job.id}`)
            .then((_) => {
                this.props.jobArchived();
            })
            .catch((_) => _);
    }

    handleDeleteJobClicked() {
        displayConfirmationModal(`RO #${this.props.job?.repairOrderNumber}`)
            .then(() => {
                axios.delete(`${config.apiUrl}/repairOrder/${this.props.job.id}`).then(() => {
                    this.props.jobArchived();
                });
            })
            .catch((_) => _);
    }

    updateLabels(labels: Label[]) {
        this.setState({ labels });
        axios
            .put(`${config.apiUrl}/repairOrder`, { ...this.props.job, labels })
            .then((_) => _)
            .catch((err) => console.log(err));
    }

    removeLabel(labelIndex: number) {
        const { labels } = this.state;
        labels.splice(labelIndex, 1);
        this.setState({ labels });
        axios
            .put(`${config.apiUrl}/repairOrder`, { ...this.props.job, labels })
            .then((_) => _)
            .catch((err) => console.log(err));
    }

    shouldDisplayDueWarning(dueDate: Date): boolean {
        const now = new Date();
        return now.getUTCDate() === new Date(dueDate).getUTCDate();
    }

    getDueDateWarning(dueDate: Date) {
        if (dueDate === null || dueDate === undefined) return null;
        const dueMoment = moment(dueDate);
        const currentMoment = moment();
        const hoursDifference = dueMoment.diff(currentMoment, 'hours');
        if (hoursDifference < 24) {
            return (
                <div className="text-red-500 font-extrabold text-sm">
                    {hoursDifference < 0 ? 'Past Due' : 'Due'} {dueMoment.format(dateFormats.USA_DATETIME)}
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        const { job, index } = this.props;
        const { isMorePickerOpen, labels } = this.state;
        return (
            <Draggable key={job.id} index={index} draggableId={`${job.id}`}>
                {(provided, snapshot) => (
                    <div
                        className={`border rounded-lg mb-4 bg-white hover:bg-blue-50 ${
                            snapshot.isDragging && 'bg-blue-100'
                        }`}
                        key={job.id}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        onClick={() => history.push(`repairOrder/${job.id}`)}
                    >
                        <div className="border-l-4 rounded-md py-2 px-2 border-green-600 m-0 p-0">
                            <div className="flex">
                                {!!job.repairOrderNumber && (
                                    <div className="font-bold text-gray-500 text-sm">RO #{job.repairOrderNumber}</div>
                                )}
                                <div
                                    className="ml-auto relative pl-6 clickable"
                                    onClick={(e) => this.togglePicker(!isMorePickerOpen, e)}
                                >
                                    <CgMore size={15} className="clickable" />
                                    {!!isMorePickerOpen && (
                                        <div
                                            className="absolute bg-white border border-gray-500 py-2 right-0 w-max z-50 clickable rounded"
                                            id={`jobCard${job.id}`}
                                            onClick={(e) => e.stopPropagation()}
                                        >
                                            <div
                                                className="px-2 py-2 clickable hover:bg-blue-50 border-b border-gray-400"
                                                onClick={() => history.push(`repairOrder/${job.id}`)}
                                            >
                                                Go to details page
                                            </div>
                                            <div
                                                className="px-2 py-2 clickable hover:bg-blue-50 font-semibold"
                                                onClick={this.archiveJob}
                                            >
                                                Archive
                                            </div>
                                            <div
                                                className="px-2 py-2 clickable hover:bg-blue-50 font-semibold text-red-400"
                                                onClick={this.handleDeleteJobClicked}
                                            >
                                                Delete
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className="flex">
                                <span className="font-semibold text-blue-500">{job.title}</span>
                            </div>
                            <div className="non-clickable mb-1" onClick={(e) => e.stopPropagation()}>
                                <div className="items-baseline">
                                    <span className="inline-flex mr-2">
                                        <LabelPicker
                                            labelsSelected={(labels: any) => this.updateLabels(labels)}
                                            labelType="order"
                                            labelIds={labels?.map((label) => label.id)}
                                        />
                                    </span>

                                    {labels?.map((label, indx) => (
                                        <span
                                            key={indx}
                                            style={{ backgroundColor: label.color }}
                                            className="mr-2 px-1 py-1 rounded-lg hover:opacity-80 text-xs"
                                        >
                                            <span className="font-semibold text-white inline-flex whitespace-nowrap">
                                                <FaTimesCircle
                                                    onClick={() => this.removeLabel(indx)}
                                                    className="inline mr-1 clickable align-baseline text-xs"
                                                />
                                                {label.title}
                                            </span>
                                        </span>
                                    ))}
                                </div>
                            </div>
                            <div className="flex">
                                <div>
                                    <FaCar className="inline mr-2 align-baseline text-gray-500" />
                                    <span>
                                        {job.vehicle?.year} {job.vehicle?.make} {job.vehicle?.model}
                                    </span>
                                </div>
                            </div>
                            <div>
                                <FaUser className="inline mr-2 align-baseline text-gray-500" />
                                <span>
                                    {job.customer?.firstName} {job.customer?.lastName}
                                </span>
                            </div>
                            {job.keyTag && (
                                <div>
                                    <GiKeyCard className="inline mr-2 text-2xl" />
                                    <span>{job.keyTag}</span>
                                </div>
                            )}
                            <div className="pt-2">
                                {this.getDueDateWarning(job?.dueDate)}
                                <div className="flex">
                                    {!!(job?.asignees?.length > 0) && (
                                        <div className="pr-2">
                                            {job.asignees.map((asignee, index) => (
                                                <Avatar
                                                    name={`${asignee.firstName} ${asignee.lastName}`}
                                                    round
                                                    size={'22'}
                                                    className="clickable"
                                                    key={index}
                                                />
                                            ))}
                                        </div>
                                    )}
                                    <div className="pr-2 py-2">
                                        <FaCheckCircle className="text-green-500" />
                                    </div>
                                    {!!job.inspections?.length && (
                                        <HoverTooltip
                                            content={
                                                <div className="bg-white p-4 rounded-lg">
                                                    <div className="mb-4">
                                                        This service has{' '}
                                                        {!!!job.inspections.find(
                                                            (inspection) => !inspection.isCompleted,
                                                        )
                                                            ? 'all inspections complete'
                                                            : 'pending inspections'}
                                                    </div>
                                                </div>
                                            }
                                        >
                                            <div
                                                className={`pr-2 py-2 ${
                                                    !!!job.inspections.find((inspection) => !inspection.isCompleted) &&
                                                    'text-green-500'
                                                }`}
                                            >
                                                <FaTasks />
                                            </div>
                                        </HoverTooltip>
                                    )}
                                    <div className="pr-2 py-2">
                                        <FaCalendar />
                                    </div>

                                    <div className="py-0.5">
                                        {job?.dueDate && this.shouldDisplayDueWarning(job.dueDate) && (
                                            <div className="p-0.5 px-1 bg-red-600 text-xs text-white text-semibold rounded-lg font-bold inline-block">
                                                <FaExclamationTriangle className="inline mr-1 mb-1" />
                                                <span>Due Today</span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="ml-auto font-bold align-bottom items-end self-end">
                                        <Price price={this.getGrandTotal(job)} />
                                    </div>
                                </div>
                                <div className="flex">
                                    {!!job?.payments?.length && (
                                        <div className="font-bold ml-auto">
                                            {this.getPaidStatus() ? (
                                                <span className="text-green-600">Paid</span>
                                            ) : (
                                                <span className="text-yellow-700">
                                                    Due: <Price price={this.getPaymentAmountDue()} />
                                                </span>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </Draggable>
        );
    }
}

const mapStateToProps = (state: any) => ({
    currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(JobTicket);
