import React from 'react';

import history from 'helpers/history';
import RepairOrder from 'models/RepairOrderModel';
import InventoryItem from 'models/InventoryItemModel';

interface Props {
    inventoryItem: InventoryItem;
    repairOrders: RepairOrder[];
}

const InventoryRepairOrders: React.FC<Props> = ({ inventoryItem, repairOrders = [] }: Props) => {
    return (
        <div className="mt-4 overflow-x-auto">
            <table className="items-center bg-transparent border w-full">
                <thead className="border">
                    <tr className=" bg-blue-600 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                        <th className="pl-3 py-3 rounded-tl-xl">RO #</th>
                        <th className="pl-6 py-3">RO Name</th>
                        <th className="px-6 py-3">Units sold</th>

                        <th className="px-6 py-3 rounded-tr-xl">Sales Revenue</th>
                    </tr>
                </thead>

                <tbody>
                    {repairOrders.map((repairOrder, index) => (
                        <tr
                            className={`border group font-medium hover:bg-blue-200 clickable ${
                                index % 2 === 0 ? 'bg-gray-100' : 'bg-blue-100'
                            }`}
                            key={index}
                            onClick={() => history.push(`/repairOrder/${repairOrder.id}`)}
                        >
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2 text-left flex items-center">
                                <span className="font-semibold text-gray-500">#{repairOrder.repairOrderNumber}</span>
                            </td>
                            <td className="px-6 min-w-max align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>{repairOrder.title}</span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>
                                    {
                                        repairOrder.services
                                            .map((service) =>
                                                service.items.filter(
                                                    (item) => item.inventoryItemId === inventoryItem.id,
                                                ),
                                            )
                                            .flat().length
                                    }
                                </span>
                            </td>

                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>
                                    $
                                    {(
                                        repairOrder.services
                                            .map((service) =>
                                                service.items.filter(
                                                    (item) => item.inventoryItemId === inventoryItem.id,
                                                ),
                                            )
                                            .flat().length * inventoryItem?.retailPrice
                                    ).toFixed(2)}
                                </span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {repairOrders?.length! < 1 && (
                <div className="border py-4 px-4 wb-white text-gray-600 text-lg font-semibold text-center">
                    This inventory items is not linked to any repair order yet
                </div>
            )}
        </div>
    );
};

export default InventoryRepairOrders;
