import React from 'react';

interface Props {
    photoSourceURL: string;
    fileName: string;
    onClose: () => void;
}

interface State {}

class FullPhotoView extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }

    render() {
        const { fileName, photoSourceURL, onClose } = this.props;
        return (
            <div className="fixed z-99999 bg-opacity-75 bg-black w-full h-full top-0 left-0 pt-20 overflow-y-scroll">
                <div className="w-1/2 m-auto bg-white p-2 rounded-lg border-2 border-blue-500">
                    <div className=" flex">
                        <div className="pt-2">
                            <span>{fileName}</span>
                        </div>
                        <div className="text-3xl clickable ml-auto" onClick={onClose}>
                            X
                        </div>
                    </div>
                    <img src={photoSourceURL} alt={fileName} title={fileName} className="clickable" />
                </div>
            </div>
        );
    }
}

export default FullPhotoView;
