const actionTypes = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    CURRENT_USER_REQUEST: 'CURRENT_USER_REQUEST',
    CURRENT_USER_SUCCESS: 'CURRENT_USER_SUCCESS',
    CURRENT_USER_FAILURE: 'CURRENT_USER_FAILURE',

    USER_ACCESS_POINTS_REQUEST: 'USER_ACCESS_POINTS_REQUEST',
    USER_ACCESS_POINTS_SUCCESS: 'USER_ACCESS_POINTS_SUCCESS',
    USER_ACCESS_POINTS_FAILURE: 'USER_ACCESS_POINTS_FAILURE',

    UPDATE_USER_SETTINGS_SUCCESS: 'UPDATE_USER_SETTINGS_SUCCESS',

    GET_CUSTOMERS_REQUEST: 'GET_CUSTOMERS_REQUEST',
    GET_CUSTOMERS_SUCCESS: 'GET_CUSTOMERS_SUCCESS',
    GET_CUSTOMERS_FAILURE: 'GET_CUSTOMERS_FAILURE',

    GET_CUSTOMERS_OVERVIEW_REQUEST: 'GET_CUSTOMERS_OVERVIEW_REQUEST',
    GET_CUSTOMERS_OVERVIEW_SUCCESS: 'GET_CUSTOMERS_OVERVIEW_SUCCESS',
    GET_CUSTOMERS_OVERVIEW_FAILURE: 'GET_CUSTOMERS_OVERVIEW_FAILURE',

    GET_INVENTORY_OVERVIEW_REQUEST: 'GET_INVENTORY_OVERVIEW_REQUEST',
    GET_INVENTORY_OVERVIEW_SUCCESS: 'GET_INVENTORY_OVERVIEW_SUCCESS',
    GET_INVENTORY_OVERVIEW_FAILURE: 'GET_INVENTORY_OVERVIEW_FAILURE',

    GET_CUSTOMER_REQUEST: 'GET_CUSTOMER_REQUEST',
    GET_CUSTOMER_SUCCESS: 'GET_CUSTOMER_SUCCESS',
    GET_CUSTOMER_FAILURE: 'GET_CUSTOMER_FAILURE',

    CREATE_CUSTOMER_REQUEST: 'CREATE_CUSTOMER_REQUEST',
    CREATE_CUSTOMER_SUCCESS: 'CREATE_CUSTOMER_SUCCESS',
    CREATE_CUSTOMER_FAILURE: 'CREATE_CUSTOMER_FAILURE',

    UPDATE_CUSTOMER_REQUEST: 'UPDATE_CUSTOMER_REQUEST',
    UPDATE_CUSTOMER_SUCCESS: 'UPDATE_CUSTOMER_SUCCESS',
    UPDATE_CUSTOMER_FAILURE: 'UPDATE_CUSTOMER_FAILURE',

    GET_VEHICLES_REQUEST: 'GET_VEHICLES_REQUEST',
    GET_VEHICLES_SUCCESS: 'GET_VEHICLES_SUCCESS',
    GET_VEHICLES_FAILURE: 'GET_VEHICLES_FAILURE',

    GET_VEHICLES_OVERVIEW_REQUEST: 'GET_VEHICLES_OVERVIEW_REQUEST',
    GET_VEHICLES_OVERVIEW_SUCCESS: 'GET_VEHICLES_OVERVIEW_SUCCESS',
    GET_VEHICLES_OVERVIEW_FAILURE: 'GET_VEHICLES_OVERVIEW_FAILURE',

    GET_VEHICLE_REQUEST: 'GET_VEHICLE_REQUEST',
    GET_VEHICLE_SUCCESS: 'GET_VEHICLE_SUCCESS',
    GET_VEHICLE_FAILURE: 'GET_VEHICLE_FAILURE',

    CREATE_VEHICLE_REQUEST: 'CREATE_VEHICLE_REQUEST',
    CREATE_VEHICLE_SUCCESS: 'CREATE_VEHICLE_SUCCESS',
    CREATE_VEHICLE_FAILURE: 'CREATE_VEHICLE_FAILURE',

    DELETE_VEHICLE_REQUEST: 'DELETE_VEHICLE_REQUEST',
    DELETE_VEHICLE_SUCCESS: 'DELETE_VEHICLE_SUCCESS',
    DELETE_VEHICLE_FAILURE: 'DELETE_VEHICLE_FAILURE',

    UPDATE_VEHICLE_REQUEST: 'UPDATE_VEHICLE_REQUEST',
    UPDATE_VEHICLE_SUCCESS: 'UPDATE_VEHICLE_SUCCESS',
    UPDATE_VEHICLE_FAILURE: 'UPDATE_VEHICLE_FAILURE',

    GET_INVENTORY_REQUEST: 'GET_INVENTORY_REQUEST',
    GET_INVENTORY_SUCCESS: 'GET_INVENTORY_SUCCESS',
    GET_INVENTORY_FAILURE: 'GET_INVENTORY_FAILURE',

    GET_INVENTORY_ITEM_REQUEST: 'GET_INVENTORY_ITEM_REQUEST',
    GET_INVENTORY_ITEM_SUCCESS: 'GET_INVENTORY_ITEM_SUCCESS',
    GET_INVENTORY_ITEM_FAILURE: 'GET_INVENTORY_ITEM_FAILURE',

    CREATE_INVENTORY_ITEM_REQUEST: 'CREATE_INVENTORY_ITEM_REQUEST',
    CREATE_INVENTORY_ITEM_SUCCESS: 'CREATE_INVENTORY_ITEM_SUCCESS',
    CREATE_INVENTORY_ITEM_FAILURE: 'CREATE_INVENTORY_ITEM_FAILURE',

    UPDATE_INVENTORY_ITEM_REQUEST: 'UPDATE_INVENTORY_ITEM_REQUEST',
    UPDATE_INVENTORY_ITEM_SUCCESS: 'UPDATE_INVENTORY_ITEM_SUCCESS',
    UPDATE_INVENTORY_ITEM_FAILURE: 'UPDATE_INVENTORY_ITEM_FAILURE',

    CREATE_BOOKING_REQUEST: 'CREATE_BOOKING_REQUEST',
    CREATE_BOOKING_SUCCESS: 'CREATE_BOOKING_SUCCESS',
    CREATE_BOOKING_FAILURE: 'CREATE_BOOKING_FAILURE',

    GET_SUPPLIERS_REQUEST: 'GET_SUPPLIERS_REQUEST',
    GET_SUPPLIERS_SUCCESS: 'GET_SUPPLIERS_SUCCESS',
    GET_SUPPLIERS_FAILURE: 'GET_SUPPLIERS_FAILURE',

    GET_SUPPLIERS_OVERVIEW_REQUEST: 'GET_SUPPLIERS_OVERVIEW_REQUEST',
    GET_SUPPLIERS_OVERVIEW_SUCCESS: 'GET_SUPPLIERS_OVERVIEW_SUCCESS',
    GET_SUPPLIERS_OVERVIEW_FAILURE: 'GET_SUPPLIERS_OVERVIEW_FAILURE',

    GET_SUPPLIER_REQUEST: 'GET_SUPPLIER_REQUEST',
    GET_SUPPLIER_SUCCESS: 'GET_SUPPLIER_SUCCESS',
    GET_SUPPLIER_FAILURE: 'GET_SUPPLIER_FAILURE',

    CREATE_SUPPLIER_REQUEST: 'CREATE_SUPPLIER_REQUEST',
    CREATE_SUPPLIER_SUCCESS: 'CREATE_SUPPLIER_SUCCESS',
    CREATE_SUPPLIER_FAILURE: 'CREATE_SUPPLIER_FAILURE',

    UPDATE_SUPPLIER_REQUEST: 'UPDATE_SUPPLIER_REQUEST',
    UPDATE_SUPPLIER_SUCCESS: 'UPDATE_SUPPLIER_SUCCESS',
    UPDATE_SUPPLIER_FAILURE: 'UPDATE_SUPPLIER_FAILURE',

    DELETE_DEMO_DATA_REQUEST: 'DELETE_DEMO_DATA_REQUEST',
    DELETE_DEMO_DATA_SUCCESS: 'DELETE_DEMO_DATA_SUCCESS',
    DELETE_DEMO_DATA_FAILURE: 'DELETE_DEMO_DATA_FAILURE',

    GET_TENANT_SETTINGS_REQUEST: 'GET_TENANT_SETTINGS_REQUEST',
    GET_TENANT_SETTINGS_SUCCESS: 'GET_TENANT_SETTINGS_SUCCESS',
    GET_TENANT_SETTINGS_FAILURE: 'GET_TENANT_SETTINGS_FAILURE',

    GET_PRICING_MATRICES_REQUEST: 'GET_PRICING_MATRICES_REQUEST',
    GET_PRICING_MATRICES_SUCCESS: 'GET_PRICING_MATRICES_SUCCESS',
    GET_PRICING_MATRICES_FAILURE: 'GET_PRICING_MATRICES_FAILURE',

    REPAIR_ORDER_PAGE_CLOSED: 'REPAIR_ORDER_PAGE_CLOSED',

    GET_REPAIR_ORDER_REQUEST: 'GET_REPAIR_ORDER_SEQUEST',
    GET_REPAIR_ORDER_SUCCESS: 'GET_REPAIR_ORDER_SUCCESS',
    GET_REPAIR_ORDER_FAILURE: 'GET_REPAIR_ORDER_SAILURE',

    UPDATE_REPAIR_ORDER_REQUEST: 'UPDATE_REPAIR_ORDER_SEQUEST',
    UPDATE_REPAIR_ORDER_SUCCESS: 'UPDATE_REPAIR_ORDER_SUCCESS',
    UPDATE_REPAIR_ORDER_FAILURE: 'UPDATE_REPAIR_ORDER_SAILURE',

    GET_REPAIR_ORDER_SERVICES_REQUEST: 'GET_REPAIR_ORDER_SERVICES_SEQUEST',
    GET_REPAIR_ORDER_SERVICES_SUCCESS: 'GET_REPAIR_ORDER_SERVICES_SUCCESS',
    GET_REPAIR_ORDER_SERVICES_FAILURE: 'GET_REPAIR_ORDER_SERVICES_SAILURE',

    UPDATE_REPAIR_ORDER_SERVICE_REQUEST: 'UPDATE_REPAIR_ORDER_SERVICE_SEQUEST',
    UPDATE_REPAIR_ORDER_SERVICE_SUCCESS: 'UPDATE_REPAIR_ORDER_SERVICE_SUCCESS',
    UPDATE_REPAIR_ORDER_SERVICE_FAILURE: 'UPDATE_REPAIR_ORDER_SERVICE_FAILURE',

    ADD_REPAIR_ORDER_SERVICE_SUCCESS: 'ADD_REPAIR_ORDER_SERVICE_FAILURE',

    UPDATE_REPAIR_ORDER_ACTIVITY_LOG_REQUEST: 'UPDATE_REPAIR_ORDER_ACTIVITY_LOG_SEQUEST',
    UPDATE_REPAIR_ORDER_ACTIVITY_LOG_SUCCESS: 'UPDATE_REPAIR_ORDER_ACTIVITY_LOG_SUCCESS',
    UPDATE_REPAIR_ORDER_ACTIVITY_LOG_FAILURE: 'UPDATE_REPAIR_ORDER_ACTIVITY_LOG_SAILURE',

    UPDATE_REPAIR_ORDER_VEHICLE_ISSUES_REQUEST: 'UPDATE_REPAIR_ORDER_VEHICLE_ISSUES_SEQUEST',
    UPDATE_REPAIR_ORDER_VEHICLE_ISSUES_SUCCESS: 'UPDATE_REPAIR_ORDER_VEHICLE_ISSUES_SUCCESS',
    UPDATE_REPAIR_ORDER_VEHICLE_ISSUES_FAILURE: 'UPDATE_REPAIR_ORDER_VEHICLE_ISSUES_SAILURE',

    ADD_REPAIR_ORDER_VEHICLE_ISSUE_REQUEST: 'ADD_REPAIR_ORDER_VEHICLE_ISSUE_REQUEST',
    ADD_REPAIR_ORDER_VEHICLE_ISSUE_SUCCESS: 'ADD_REPAIR_ORDER_VEHICLE_ISSUE_SUCCESS',
    ADD_REPAIR_ORDER_VEHICLE_ISSUE_FAILURE: 'ADD_REPAIR_ORDER_VEHICLE_ISSUE_SAILURE',

    DELETE_REPAIR_ORDER_VEHICLE_ISSUE_REQUEST: 'DELETE_REPAIR_ORDER_VEHICLE_ISSUE_REQUEST',
    DELETE_REPAIR_ORDER_VEHICLE_ISSUE_SUCCESS: 'DELETE_REPAIR_ORDER_VEHICLE_ISSUE_SUCCESS',
    DELETE_REPAIR_ORDER_VEHICLE_ISSUE_FAILURE: 'DELETE_REPAIR_ORDER_VEHICLE_ISSUE_SAILURE',

    DELETE_REPAIR_ORDER_SERVICE_REQUEST: 'DELETE_REPAIR_ORDER_SERVICE_SEQUEST',
    DELETE_REPAIR_ORDER_SERVICE_SUCCESS: 'DELETE_REPAIR_ORDER_SERVICE_SUCCESS',
    DELETE_REPAIR_ORDER_SERVICE_FAILURE: 'DELETE_REPAIR_ORDER_SERVICE_SAILURE',

    OPEN_DELETE_CONFIRMATION: 'OPEN_DELETE_CONFIRMATION',
    CLOSE_DELETE_CONFIRMATION: 'CLOSE_DELETE_CONFIRMATION',

    OPEN_WARNING_MODAL: 'OPEN_WARNING_MODAL',
    ADD_CUSTOMER_NAME: 'ADD_CUSTOMER_NAME',
    UPDATE_RECENTLY_VIEWED_ROS: 'UPDATE_RECENTLY_VIEW_ROS',
    DELETE_RECENTLY_VIEWED_ROS: 'DELETE_RECENTLY_VIEW_ROS',
    CLOSE_WARNING_MODAL: 'CLOSE_WARNING_MODAL',
};

export default actionTypes;
