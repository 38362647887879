import React from 'react';

interface Props {
    items: any[];
    bindName: string;
    onSelect: (selectedItem: any) => void;

    selected?: any;
    placeholder?: string;
    disabled?: boolean;
    className?: string;
    verticalPadding?: string;
    id?: string;
    isServiceCard?: boolean;
}

interface State {
    isExpanded: boolean;
}

class NewDropdown extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isExpanded: false,
        };

        this.onClickOutside = this.onClickOutside.bind(this);
    }

    togglePicker(open: boolean) {
        this.setState({ isExpanded: open }, () => {
            if (open) {
                document.addEventListener('click', this.onClickOutside);
            } else {
                document.removeEventListener('click', this.onClickOutside);
            }
        });
    }

    onClickOutside(e: any) {
        const target = document.querySelector(`#id${this.props.id}`);
        const withinBoundaries = e.composedPath().includes(target);

        if (withinBoundaries) {
        } else {
            this.togglePicker(false);
        }
    }

    render() {
        const {
            items,
            bindName,
            selected,
            onSelect,
            placeholder = 'Select',
            disabled = false,
            className = '',
            verticalPadding = '',
            id = '',
            isServiceCard = false,
        } = this.props;
        const { isExpanded } = this.state;
        return (
            <div className="z-50">
                <div className={`w-full ${!isServiceCard && 'w-44 relative'}`}>
                    <button
                        id="dropdownDefault"
                        className={`${
                            disabled && 'cursor-default'
                        } ${className} w-full border border-blue-500 bg-white text-blue-500 font-bold   focus:ring-1 focus:outline-none focus:ring-blue-300  rounded-lg  px-4 ${
                            !!verticalPadding ? verticalPadding : 'py-1'
                        }  text-center inline-flex items-center`}
                        type="button"
                        onClick={() => this.togglePicker(!isExpanded)}
                        disabled={disabled}
                    >
                        {!!selected && items.find((item) => item.id === selected?.id)[bindName]}
                        {!!!selected && placeholder}
                        {!!!disabled && (
                            <svg
                                className="ml-auto w-4 h-4"
                                aria-hidden="true"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path strokeLinecap="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                            </svg>
                        )}
                    </button>
                    <div
                        id={'id' + id}
                        className={` ${!isExpanded && 'hidden'} absolute z-10 ${
                            !isServiceCard && 'w-full'
                        }  bg-white rounded divide-y divide-gray-100 shadow  border border-blue-500`}
                    >
                        <ul className="py-1 text-sm text-gray-700" aria-labelledby="dropdownDefault">
                            {items.map((item) => (
                                <li
                                    className={`block clickable ${
                                        item.id === selected?.id ? 'bg-blue-400 text-white' : 'hover:bg-gray-100'
                                    } py-2 px-4 font-semibold`}
                                    key={item.id}
                                    onClick={() => {
                                        this.togglePicker(false);
                                        onSelect(item);
                                    }}
                                >
                                    {item[bindName]}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
}

export default NewDropdown;
