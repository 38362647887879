enum DateRangeType {
    AllTime = 'AllTime',
    Last24Hours = 'Last24Hours',
    PastWeek = 'PastWeek',
    PastMonth = 'PastMonth',
    PastYear = 'PastYear',
    Custom = 'Custom',
}

export default DateRangeType;
