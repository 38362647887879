import axios from '../helpers/axios';
import config from '../helpers/config';
import { Dispatch } from 'redux';
import actionTypes from '../helpers/actionTypes';
import PhoneNumber from '../models/PhoneNumberModel';
import Supplier from 'models/SupplierModel';

const { apiUrl } = config;

export interface GetSuppliersRequest {
    searchPhrase: string;
    page: number;
    itemsPerPage: number;
}

export interface NewSupplierRequest {
    firstName: string;
    lastName: string;
    phone: string;
    mobile?: string;
    phoneNumbers?: PhoneNumber[];
    email?: string;
    address?: string;
    postCode?: string;
    isBusinessSupplier?: string;
    companyName?: string;
    supplierSource?: string | number;
    preferredMethodOfContact?: string | number;
}

export const getSuppliers = (requestParams: GetSuppliersRequest) => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.GET_SUPPLIERS_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.GET_SUPPLIERS_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.GET_SUPPLIERS_FAILURE, payload });

    dispatch(request());

    axios
        .get(`${apiUrl}/supplier`, { params: requestParams })
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};

export const getSuppliersOverview = () => (dispatch: Dispatch) => {
    const success = (payload: any) => ({ type: actionTypes.GET_SUPPLIERS_OVERVIEW_SUCCESS, payload });

    axios.get(`${config.apiUrl}/supplier/overview`).then((res) => {
        dispatch(success(res.data));
    });
};

export const getSupplier = (supplierId: number | string) => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.GET_SUPPLIER_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.GET_SUPPLIER_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.GET_SUPPLIER_FAILURE, payload });

    dispatch(request());

    axios
        .get(`${apiUrl}/supplier/${supplierId}`)
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};

export const createSupplier = (newSupplier: NewSupplierRequest) => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.CREATE_SUPPLIER_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.CREATE_SUPPLIER_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.CREATE_SUPPLIER_FAILURE, payload });

    dispatch(request());

    axios
        .post(`${apiUrl}/supplier`, newSupplier)
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};

export const updateSupplier = (supplier: Supplier) => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.UPDATE_SUPPLIER_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.UPDATE_SUPPLIER_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.UPDATE_SUPPLIER_FAILURE, payload });

    dispatch(request());

    axios
        .put(`${apiUrl}/supplier`, supplier)
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};
