import React from 'react';

import history from 'helpers/history';
import VehicleModel from 'models/VehicleModel';

interface Props {
    vehicles: VehicleModel[];
}

const CustomerVehicles: React.FC<Props> = ({ vehicles }: Props) => {
    return (
        <div className="w-4/5 mt-4">
            <table className="items-center bg-transparent border w-full">
                <thead className="border">
                    <tr className=" bg-blue-600 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                        <th className="pl-6 py-3 rounded-tl-xl">Make</th>
                        <th className="px-6 py-3">Model</th>
                        <th className="px-6 py-3">Year</th>
                        <th className="px-6 py-3">License Plate</th>
                        <th className="px-6 py-3">VIN</th>
                        <th className="px-6 py-3">Color</th>
                        <th className="px-6 py-3 rounded-tr-xl">Mileage</th>
                    </tr>
                </thead>

                <tbody>
                    {vehicles?.map((vehicle, index) => (
                        <tr
                            className={`border group font-medium hover:bg-blue-200 clickable ${
                                index % 2 === 0 ? 'bg-gray-100' : 'bg-blue-100'
                            }`}
                            key={index}
                            onClick={() => history.push(`/vehicle/${vehicle.id}`)}
                        >
                            <td className="px-6 min-w-max align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2 text-left flex items-center">
                                <span>{vehicle.make}</span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>{vehicle.model}</span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>{vehicle.year}</span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>{vehicle.licensePlate}</span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>{vehicle.VIN}</span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>{vehicle.color}</span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>{vehicle.mileage}</span>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {vehicles?.length! < 1 && (
                <div className="border py-4 px-4 wb-white text-gray-600 text-lg font-semibold text-center">
                    This customer has no registered vehicles.
                </div>
            )}
        </div>
    );
};

export default CustomerVehicles;
