import React from 'react';
import FormModes from '../../../helpers/FormModes';
import { Grid, Header, Form } from 'semantic-ui-react';
import Booking from '../../../models/BookingModel';

interface Props {
    isEditMode: boolean;
    isEnabled: boolean;
    bookingSaved: Function;
}

interface State {
    mode: FormModes;
    booking?: Booking;
    [x: string]: any;
}

class BookingDetailsSection extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            mode: FormModes.Create,
            description: '',
            mileage: Number,
            booking: undefined,
        };

        this.handleChange = this.handleChange.bind(this);
        this.saveBoking = this.saveBoking.bind(this);
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const { name, value } = event.currentTarget;
        this.setState({ [name]: value });
    }

    saveBoking() {
        const { mileage, description } = this.state;
        const booking: Booking = { mileage, description };
        this.props.bookingSaved(booking);
    }

    render() {
        const { booking } = this.state;
        const { isEditMode, isEnabled } = this.props;

        return (
            <div className="customer-info">
                <Grid>
                    <Grid.Column width="12">
                        <Header icon="calendar" content="Booking" size="tiny" />
                    </Grid.Column>
                    <Grid.Column width="12">
                        <Form size="large">
                            <Form.Group unstackable columns={2}>
                                <Form.Input
                                    value={booking?.mileage}
                                    name={'mileage'}
                                    width="6"
                                    label="Current Mileage"
                                    disabled={isEditMode}
                                    onChange={this.handleChange}
                                    type="number"
                                />
                                <Form.Input
                                    value={booking?.description}
                                    name={'description'}
                                    label="Description"
                                    disabled={isEditMode}
                                    width="6"
                                    onChange={this.handleChange}
                                />
                            </Form.Group>
                            <Form.Group unstackable columns={2}>
                                <Form.Input
                                    required
                                    placeholder="Start Date"
                                    label="Start Date"
                                    name="start"
                                    onChange={this.handleChange}
                                    value={booking?.startDate}
                                    type="datetime-local"
                                    width="6"
                                />
                                <Form.Input
                                    required
                                    placeholder="End Date"
                                    label="End Date"
                                    name="endDate"
                                    onChange={this.handleChange}
                                    value={booking?.endDate}
                                    type="datetime-local"
                                    width="6"
                                />
                            </Form.Group>
                            <Form.Group unstackable widths="2">
                                <Form.Button color="blue" onClick={this.saveBoking} disabled={!isEnabled}>
                                    SAVE
                                </Form.Button>
                            </Form.Group>
                        </Form>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }
}

export default BookingDetailsSection;
