import PaymentType from 'models/types/PaymentType';

export const getPaymentTypeName = (paymentType: PaymentType): string => {
    switch (paymentType) {
        case PaymentType.Card:
            return 'Card';
        case PaymentType.Cash:
            return 'Cash';
        case PaymentType.Check:
            return 'Check';
        case PaymentType.Other:
            return 'Other';
        default:
            return 'Other';
    }
};
