import React, { useEffect, useState } from 'react';
import { IoMdNotifications } from 'react-icons/io';

import axios from 'helpers/axios';
import config from 'helpers/config';

import RelativeTime from 'components/common/RelativeTime';

import Notification from 'models/NotificationsModel';
import history from 'helpers/history';

interface Props {
    className?: string;
    withPicker?: boolean;
    onChange?: Function;
    iconWithText?: boolean;
}

const Notifications: React.FC<Props> = ({ className = '', withPicker = true }: Props) => {
    const [isPickerOpen, setIsPickerOpen] = useState(false);

    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [isFirstNotificationLoad, setIsFirstNotificationLoad] = useState(true);
    const id = 'notificationsPicker';

    const getNotifications = () => {
        axios
            .get(`${config.apiUrl}/notification`)
            .then(async (res) => {
                const newNotifications: Notification[] = res.data.filter((notification: Notification) => {
                    return !notifications.find((n) => n.id === notification.id);
                });

                if (newNotifications.length > 0 && !isFirstNotificationLoad) {
                    try {
                        const audio = new Audio('../assets/notification_sound.wav');
                        await audio.play().catch((e) => console.error(e));
                    } catch (e) {
                        console.error(e);
                    }
                }

                setIsFirstNotificationLoad(false);
                setNotifications(res.data);
            })
            .catch((err) => console.error(err));
    };

    useEffect(() => {
        const intervalId = setInterval(getNotifications, 30000);

        return () => clearInterval(intervalId);
    }, [notifications]);

    const onClickOutside = (e: any) => {
        const target = document.querySelector(`#${id}`);
        const withinBoundaries = e.composedPath().includes(target);

        if (withinBoundaries) {
        } else {
            togglePicker(false);
        }
    };

    const togglePicker = (open: boolean) => {
        if (open) {
            setIsPickerOpen(true);
            document.addEventListener('click', onClickOutside);
        } else {
            setIsPickerOpen(false);
            document.removeEventListener('click', onClickOutside);
        }
    };

    const handleNotificationClicked = (notification: Notification) => {
        if (notification.repairorderId) {
            history.push(`/repairOrder/${notification.repairorderId}`);
            setIsPickerOpen(false);
        }
    };

    return (
        <div className={`relative ${className} mr-12`}>
            <div
                className={`rounded-full clickable  hover:bg-green-800 ${isPickerOpen && 'bg-green-800'} relative`}
                onClick={() => togglePicker(!isPickerOpen)}
            >
                <IoMdNotifications
                    size={32}
                    className={`text-white  ${
                        isPickerOpen ? ' bg-white text-blue-700' : 'text-blue-500 bg-blue-400 hover:text-blue-700'
                    } border-2 border-white rounded-full mt-2`}
                />
            </div>
            {!!notifications.length && (
                <span className="bg-red-600 text-white font-semibold rounded-full px-2 py-0.5 absolute -top-0.5 -right-0.5 text-sm">
                    {notifications.length}
                </span>
            )}

            {!!withPicker && isPickerOpen && (
                <div
                    className="absolute top-16 right-1/4 transform translate-x-1/4 bg-white border  shadow-2xl w-96  rounded-lg px-2 py-4 h-96 overflow-y-scroll"
                    id={id}
                >
                    <div className="font-semibold text-gray-600 text-lg">Notifications</div>
                    {notifications.map((notification) => {
                        return (
                            <div
                                className="rounded-lg border border-blue-500 clickable hover:bg-blue-100 px-2 py-1 flex flex-col hover:border-blue-400 border-transparent mb-2"
                                key={notification.id}
                                onClick={() => handleNotificationClicked(notification)}
                            >
                                <div>
                                    <span
                                        className="text-base"
                                        dangerouslySetInnerHTML={{ __html: notification.message }}
                                    ></span>
                                </div>
                                <div className="text-left float-left text-gray-500 font-semibold text-sm">
                                    <RelativeTime dateTime={notification.createdAt} />
                                </div>
                            </div>
                        );
                    })}
                    {!!!notifications.length && (
                        <div className="rounded-lg hover:bg-blue-300 px-2 py-1 flex flex-col hover:border-blue-400 border-transparent border mb-2">
                            <div>No notifications yet</div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Notifications;
