import Modal from 'components/common/Modal';
import Price from 'components/common/Price';
import RelativeTime from 'components/common/RelativeTime';
import Authorization from 'models/AuthorizationModel';
import Service from 'models/ServiceModel';
import React from 'react';
import SignatureCanvas from 'react-signature-canvas';

interface Props {
    authorization: Authorization;
    services: Service[];
    onClose: () => void;
}

interface State {
    authorizedServices: Service[];
    declinedServices: Service[];
}

let signatureRef: SignatureCanvas | null = null;

class AuthorizationModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            authorizedServices: [],
            declinedServices: [],
        };
    }

    componentDidMount() {
        setTimeout(() => {
            if (signatureRef && this.props.authorization.authorizationSignature) {
                signatureRef.fromData(JSON.parse(this.props.authorization.authorizationSignature));
                signatureRef.off();
            }
            this.setState({ authorizedServices: JSON.parse(this.props.authorization.authorizedServices) });
            const declinedServiceIDs = this.props.authorization.declinedServices;
            const declinedServices = this.props.services.filter((service) => declinedServiceIDs.includes(service.id));
            this.setState({ declinedServices });
        }, 150);
    }

    render() {
        const { authorization } = this.props;
        const { authorizedServices } = this.state;
        return (
            <Modal
                headerText={'Authorization'}
                isOpen={true}
                onClose={() => this.props.onClose()}
                isBackgroundWhite={true}
                isDeleteHidden={true}
                saveText={'Authorize'}
                isSaveHidden={false}
            >
                <div className=" divide-y ">
                    <div>
                        <div>
                            <span>
                                Authorized {}
                                <span className="font-semibold">
                                    <RelativeTime floatRight={false} dateTime={authorization.createdAt} />
                                </span>
                            </span>
                        </div>
                    </div>
                    <div>
                        <div>
                            <span className="font-semibold">Services Approved:</span>
                        </div>
                        {authorizedServices.map((service) => (
                            <div className="ml-2" key={service.id}>
                                <span>* </span>
                                <span>{service.title}</span> -{' '}
                                <span className="text-green-500 font-semibold">
                                    <Price price={service.total} />
                                </span>
                            </div>
                        ))}
                    </div>
                    <div>
                        <div>
                            <span className="font-semibold">Services Declined:</span>
                        </div>
                        {this.state.declinedServices.map((service) => (
                            <div className="ml-2" key={service.id}>
                                <span>* </span>
                                <span>{service.title}</span> -{' '}
                                <span className="text-red-500 font-semibold">
                                    <Price price={service.total} />
                                </span>
                            </div>
                        ))}
                    </div>

                    <div>
                        <div> Signature:</div>
                        <SignatureCanvas
                            ref={(ref: any) => {
                                signatureRef = ref;
                            }}
                            penColor="black"
                            canvasProps={{ height: 200, className: 'w-full border border-gray-500' }}
                        />
                    </div>
                </div>
            </Modal>
        );
    }
}

export default AuthorizationModal;
