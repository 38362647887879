import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import Pagination from 'components/common/Pagination';
import VehiclesTable from 'components/common/VehiclesTable';
import VehicleModal from 'components/modals/vehicle/VehicleModal';
import { getVehicles, getVehiclesOverview } from 'actions/vehicleActions';
import VehicleModel from 'models/VehicleModel';
import ColumnsPicker from 'components/common/ColumnsPicker';
import TableColumn, { TableColumOrderStatus } from 'models/TableColumnModel';
import User from 'models/UserModel';

import actionTypes from 'helpers/actionTypes';
import placeholders from 'helpers/placeholders';
import config from 'helpers/config';
import axios from 'helpers/axios';

interface ComponentState {
    errorMessage: string | null;
    searchPhrase: string;
    page: number;
    itemsPerPage: number;
    orderBy?: string;
    isDescending?: boolean;
    vehiclesCount: number;
    isCreateVehicleModalOpen: boolean;
    isEditVehicleModalOpen: boolean;
    editableVehicleId: string | null;
    tableColumns: TableColumn[];
    [x: string]: any;
}

interface Props {
    vehicles: VehicleModel[];
    vehiclesLoading: boolean;
    vehiclesCount: number;
    currentUser: User;
    dispatch: Dispatch;
}

class VehiclesListPage extends React.Component<Props, ComponentState> {
    constructor(props: Props) {
        super(props);
        this.getVehicles = this.getVehicles.bind(this);
        this.pageChanged = this.pageChanged.bind(this);
        this.handleSearch = this.handleSearch.bind(this);

        this.state = {
            page: 1,
            orderBy: '',
            isDescending: false,
            itemsPerPage: 15,
            vehiclesCount: 0,
            searchPhrase: '',
            errorMessage: null,
            editableVehicleId: null,
            isCreateVehicleModalOpen: false,
            isEditVehicleModalOpen: false,
            tableColumns: vehicleListColumns,
        };
    }

    componentDidMount() {
        const { dispatch } = this.props;
        dispatch<any>(getVehiclesOverview());
        this.getVehicles();
    }

    getVehicles() {
        const { dispatch } = this.props;
        const { page, itemsPerPage, searchPhrase, orderBy, isDescending } = this.state;
        dispatch<any>(getVehicles({ page, itemsPerPage, searchPhrase, orderBy, isDescending }));
    }

    handleSearch(event: React.FormEvent<HTMLInputElement>) {
        const { vehiclesCount } = this.props;
        const searchPhrase = event.currentTarget.value;
        if (searchPhrase?.length > 1) {
            this.setState(
                {
                    searchPhrase,
                    page: 1,
                    itemsPerPage: vehiclesCount,
                },
                this.getVehicles,
            );
        } else {
            this.setState(
                {
                    searchPhrase: '',
                    page: 1,
                    itemsPerPage: 15,
                },
                () => this.componentDidMount(),
            );
        }
    }

    pageChanged(page?: number) {
        if (page) {
            this.setState({ page }, this.getVehicles);
        }
    }

    editVehicle(vehicleId: string) {
        this.setState({ isEditVehicleModalOpen: true, editableVehicleId: vehicleId });
    }

    modalClosed(vehicleCreated?: boolean, vehicleUpdated?: boolean) {
        this.setState({ isCreateVehicleModalOpen: false, isEditVehicleModalOpen: false, editableVehicleId: null });
        if (vehicleCreated || vehicleUpdated) {
            this.componentDidMount();
        }
    }

    getVehicleModal() {
        const { isCreateVehicleModalOpen, isEditVehicleModalOpen, editableVehicleId } = this.state;
        if (isEditVehicleModalOpen && !!editableVehicleId) {
            return (
                <VehicleModal
                    vehicleId={editableVehicleId}
                    onClose={(vehicleCreated?: boolean, vehicleEdited?: boolean) =>
                        this.modalClosed(vehicleCreated, vehicleEdited)
                    }
                ></VehicleModal>
            );
        } else if (isCreateVehicleModalOpen) {
            return (
                <VehicleModal
                    onClose={(vehicleCreated?: boolean, vehicleEdited?: boolean) =>
                        this.modalClosed(vehicleCreated, vehicleEdited)
                    }
                ></VehicleModal>
            );
        }
    }

    order(columnName: string) {
        const column = vehicleListColumns.find((tableColumn) => tableColumn.name === columnName);

        if (column?.orderStatus === TableColumOrderStatus.None) {
            column.orderStatus = TableColumOrderStatus.Ascending;
        } else if (column?.orderStatus === TableColumOrderStatus.Ascending) {
            column.orderStatus = TableColumOrderStatus.Descending;
        } else if (column?.orderStatus === TableColumOrderStatus.Descending) {
            column.orderStatus = TableColumOrderStatus.Ascending;
        }

        const orderByString = `${column?.name}.${
            column?.orderStatus === TableColumOrderStatus.Ascending ? 'DESC' : 'ASC'
        }`;

        vehicleListColumns.forEach((col) => {
            if (col !== column) {
                col.orderStatus = TableColumOrderStatus.None;
            }
        });
        this.setState(
            { orderBy: orderByString, isDescending: column?.orderStatus === TableColumOrderStatus.Descending },
            () => this.getVehicles(),
        );
    }

    updateVisibleColumns(updatedColumns: TableColumn[]) {
        const { userSetting } = this.props.currentUser;
        userSetting.vehicleListColumns = JSON.stringify(updatedColumns);
        const { dispatch } = this.props;
        dispatch({ type: actionTypes.UPDATE_USER_SETTINGS_SUCCESS, payload: userSetting });
        axios.put(`${config.apiUrl}/user/settings`, userSetting);
        this.setState({ tableColumns: updatedColumns });
    }

    render() {
        const { page, itemsPerPage, isCreateVehicleModalOpen, isEditVehicleModalOpen, tableColumns } = this.state;
        const { vehicles, vehiclesLoading, vehiclesCount, currentUser } = this.props;

        return (
            <div className="list-page bg-gray-50">
                <h2 className="list-page__title">Vehicle List</h2>
                <div className="search-add-row items-center mt-3">
                    <input
                        onChange={this.handleSearch}
                        name="searchPhrase"
                        placeholder={placeholders.VEHICLES_SEARCH}
                        className="bg-white placeholder  placeholder-gray-400 placeholder-font-bold rounded-xl border border-green-500 px-2 py-1 font-medium w-1/3 focus:ring-1 focus:outline-none focus:border-blue-500"
                    ></input>

                    <button
                        className="bg-green-600 py-1 px-2 rounded-2xl font-medium text-base ml-auto"
                        onClick={() => this.setState({ isCreateVehicleModalOpen: !isCreateVehicleModalOpen })}
                    >
                        + Add new
                    </button>
                </div>
                <div className="flex items-center my-2">
                    <div>
                        <h4 className="font-semibold my-2">{vehiclesCount} vehicles found</h4>
                    </div>
                    <div className="ml-auto">
                        <ColumnsPicker
                            onChange={(updatedColumns) => this.updateVisibleColumns(updatedColumns)}
                            columns={
                                currentUser?.userSetting?.vehicleListColumns
                                    ? JSON.parse(currentUser.userSetting.vehicleListColumns)
                                    : tableColumns
                            }
                        />
                    </div>
                </div>
                <VehiclesTable
                    vehicles={vehicles}
                    isLoading={vehiclesLoading}
                    onEdit={(vehicleId: string) => this.editVehicle(vehicleId)}
                    onOrder={(column: string) => this.order(column)}
                    columns={
                        currentUser?.userSetting?.vehicleListColumns
                            ? JSON.parse(currentUser.userSetting.vehicleListColumns)
                            : tableColumns
                    }
                />
                <div className="mt-4 ">
                    <Pagination
                        currentPage={page}
                        pageSize={itemsPerPage}
                        totalItems={vehiclesCount}
                        pageChanged={this.pageChanged}
                    ></Pagination>
                </div>
                {isCreateVehicleModalOpen || isEditVehicleModalOpen ? this.getVehicleModal() : null}
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    vehicles: state.vehicle.vehicles,
    vehiclesLoading: state.vehicle.vehiclesLoading,
    vehiclesCount: state.vehicle.vehiclesCount,
    currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(VehiclesListPage);

const vehicleListColumns: TableColumn[] = [
    {
        name: 'model',
        active: true,
        text: 'Model',
        sortable: true,
        orderStatus: TableColumOrderStatus.None,
    },
    {
        name: 'make',
        active: true,
        text: 'Make',
        sortable: true,
        orderStatus: TableColumOrderStatus.None,
    },
    {
        name: 'series',
        active: true,
        text: 'Series',
        sortable: true,
        orderStatus: TableColumOrderStatus.None,
    },
    {
        name: 'year',
        active: true,
        text: 'Year',
        sortable: true,
        orderStatus: TableColumOrderStatus.None,
    },
    {
        name: 'VIN',
        active: true,
        text: 'VIN',
        sortable: true,
        orderStatus: TableColumOrderStatus.None,
    },
    {
        name: 'notes',
        active: true,
        text: 'Note',
        sortable: false,
        orderStatus: TableColumOrderStatus.None,
    },
    {
        name: 'customer',
        active: true,
        text: 'Customer name',
        sortable: true,
        orderStatus: TableColumOrderStatus.None,
    },
];
