import Price from 'components/common/Price';
import { getPaymentTypeName } from 'helpers/payments/paymentsService';
import Payment from 'models/PaymentModel';
import moment from 'moment';
import React from 'react';

interface Props {
    payment: Payment;
    onEdit: () => void;
}

const PaymentOverview: React.FC<Props> = ({ payment, onEdit }: Props) => {
    return (
        <div className="w-full">
            <div className="text-center pb-3">
                <div className="font-semibold text-xl">
                    <Price price={payment.amountCents / 100} />
                </div>

                <div className="font-semibold text-lg text-gray-600">{getPaymentTypeName(payment.paymentType)}</div>
                <div className="font-semibold text-xl text-gray-600 pb-6">
                    {moment(payment.createdAt).format('MMM Do YYYY h:mm a')}
                </div>

                <div
                    className="border-2 rounded-md text-xl font-semibold py-6 bg-white clickable hover:bg-blue-100"
                    onClick={onEdit}
                >
                    Edit
                </div>
            </div>
            <div className="flex">
                <div className="border-2 rounded-md text-xl font-semibold py-6 bg-white clickable hover:bg-blue-100 w-1/2 text-center mr-4">
                    Print receipt
                </div>
                <div className="border-2 rounded-md text-xl font-semibold py-6 bg-white clickable hover:bg-blue-100 w-1/2 text-center ">
                    Print receipt
                </div>
            </div>
        </div>
    );
};

export default PaymentOverview;
