import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FaEdit, FaEnvelope, FaPhoneSquare, FaSave } from 'react-icons/fa';
import { getSupplier, updateSupplier } from 'actions/supplierActions';
import SupplierModel from 'models/SupplierModel';
import InternalChat from 'components/common/comments/InternalChat';
import Supplier from 'models/SupplierModel';
import SupplierInventoryItems from './tabs/SupplierInventoryItems';

interface Props {
    dispatch: Dispatch;
    supplierLoading: boolean;
    supplierUpdating: boolean;
    supplier: SupplierModel;
}

interface State {
    isEditMode: boolean;
    activeTab: number;
    supplier?: Supplier;
}

class SupplierDetailsPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isEditMode: false,
            activeTab: 0,
        };

        this.getSupplierDetails();
        this.getSupplierDetails = this.getSupplierDetails.bind(this);
        this.getSupplierDetailsForm = this.getSupplierDetailsForm.bind(this);
        this.toggleEditMode = this.toggleEditMode.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getValue = this.getValue.bind(this);
    }

    getSupplierDetails() {
        // @ts-ignore
        const { supplierId } = this.props.match.params;
        const { dispatch } = this.props;
        dispatch<any>(getSupplier(supplierId));
    }

    toggleEditMode() {
        const { isEditMode } = this.state;

        if (isEditMode) {
            const { dispatch } = this.props;
            dispatch<any>(updateSupplier(this.state.supplier!));
        }

        this.setState({
            isEditMode: !isEditMode,
            supplier: isEditMode ? this.state.supplier : this.props.supplier,
        });
    }

    getValue(propertyName: string) {
        // @ts-ignore
        // prettier-ignore
        return this.state?.supplier ? this.state?.supplier[propertyName] : this.props?.supplier[propertyName];
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const { name, value } = event.currentTarget;

        const supplier: Supplier = { ...this.state.supplier!, [name]: value };
        this.setState({ supplier: supplier });
    }

    getSupplierDetailsForm() {
        const FORM_INPUT_CLASS = 'rounded-md border-none font-bold w-3/4 pl-4 py-2 focus:ring-1 focus:outline-none';
        const { isEditMode } = this.state;
        const { supplierLoading, supplierUpdating } = this.props;

        return (
            <div className="mt-8 border-2 rounded-xl bg-gray-100 p-6 w-1/2 relative flex flex-col">
                {!!(supplierLoading || supplierUpdating) && (
                    <div className="absolute left-1/2 top-1/3">
                        <h2 className="font-bold">Loading...</h2>
                    </div>
                )}
                <div className="grid grid-cols-2 mb-4">
                    <div className="w-full my-2 pl-4">
                        <span className="inline-block w-1/4">Name</span>
                        <input
                            className={FORM_INPUT_CLASS}
                            type="text"
                            value={this.getValue('companyName')}
                            name="companyName"
                            disabled={!isEditMode}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="w-full my-2 pl-4">
                        <span className="inline-block w-1/4">Phone Number</span>
                        <input
                            className={FORM_INPUT_CLASS}
                            type="text"
                            value={this.getValue('phone')}
                            name="phone"
                            disabled={!isEditMode}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="w-full my-2 pl-4">
                        <span className="inline-block w-1/4">Email</span>
                        <input
                            className={`${FORM_INPUT_CLASS} pl-6`}
                            type="text"
                            value={this.getValue('email')}
                            name="email"
                            disabled={!isEditMode}
                            onChange={this.handleChange}
                        />
                    </div>
                    <div className="w-full my-2 pl-4">
                        <span className="inline-block w-1/4">Website</span>
                        <input
                            className={`${FORM_INPUT_CLASS} pl-6`}
                            type="text"
                            value={this.getValue('website')}
                            name="website"
                            disabled={!isEditMode}
                            onChange={this.handleChange}
                        />
                    </div>
                </div>
                <button
                    className={`${isEditMode ? 'bg-green-400' : 'bg-gray-400'} rounded-2xl px-4 py-1 font-bold ml-auto`}
                    onClick={() => this.toggleEditMode()}
                >
                    {isEditMode ? <FaSave className="inline mr-2" /> : <FaEdit className="inline mr-2" />}
                    {isEditMode ? 'Save' : 'Edit'}
                </button>
            </div>
        );
    }

    getTabs() {
        return [
            {
                title: 'Inventory Items',
                component: <SupplierInventoryItems inventoryItems={this.props.supplier?.inventoryItems} />,
            },
            {
                title: 'Files',
                component: <div>files</div>,
            },
            {
                title: 'Comments',
                component: <InternalChat chatId={this.props.supplier?.internalChatId} />,
            },
        ];
    }

    render() {
        const { supplierLoading, supplier } = this.props;
        const { activeTab } = this.state;
        const tabs = this.getTabs();
        return (
            <div className="bg-white mb-40">
                <div className="w-full bg-blue-500 p-8 pb-2">
                    <h3 className="text-blue-100">Supplier</h3>
                    <h2 className="text-blue-50 text-2xl font-semibold">{supplier?.companyName}</h2>
                </div>
                <div className="w-full text-white from-blue-700 to-blue-500 px-8 py-2 bg-gradient-to-r">
                    <div className="pb-2">
                        {!!supplier?.email && (
                            <span>
                                <FaEnvelope className="inline align-baseline mr-2" />
                                <a className="mr-2" href={`mailto:${supplier.email}`}>
                                    {supplier.email}
                                </a>
                            </span>
                        )}
                    </div>
                    <div>
                        {supplier?.phoneNumbers?.map((number) => (
                            <span key={number.phoneNumber}>
                                <FaPhoneSquare className="inline align-baseline mr-2" />
                                <a className="mr-2" href={`tel:${number.phoneNumber}`}>
                                    {number.phoneNumber}
                                </a>
                            </span>
                        ))}
                    </div>
                </div>
                <div className="px-8 flex flex-col mt-6">
                    {supplierLoading ? <b>Loading...</b> : null}
                    {!!supplier && this.getSupplierDetailsForm()}
                    <div className="mt-8">
                        {tabs.map((tab, index) => {
                            return (
                                <div
                                    className={`inline-flex px-5 border-b-4 ${
                                        activeTab === index
                                            ? ' border-blue-500 '
                                            : '  border-gray-500 hover:text-blue-400'
                                    }`}
                                    key={index}
                                >
                                    <span
                                        className={`clickable text-lg ${
                                            activeTab === index
                                                ? 'font-bold text-blue-500 border-blue-500 '
                                                : ' font-medium text-gray-400 border-gray-500 hover:text-blue-400'
                                        }`}
                                        onClick={() => this.setState({ activeTab: index })}
                                    >
                                        {tab.title}
                                    </span>
                                </div>
                            );
                        })}
                    </div>
                    {tabs[activeTab].component}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    supplier: state.supplier.supplier,
    supplierLoading: state.supplier.supplierLoading,
    supplierUpdating: state.supplier.supplierUpdating,
});

export default connect(mapStateToProps)(SupplierDetailsPage);
