import React, { ReactNode, useEffect } from 'react';

interface Props {
    isOpen: boolean;
    children: ReactNode;
    onClose: () => void;
    onSave?: () => void;
    onCancel?: () => void;
    isSaveHidden?: boolean;
    isSaveDisabled?: boolean;
    saveText?: string;
    cancelText?: string;
    headerText: string;
    isLoading?: boolean;
    onDelete?: () => void;
    isDeleteHidden?: boolean;
    size?: 'wider' | 'wide';
    isBackgroundWhite?: boolean;
    isWithFooter?: boolean;
}

const Modal = ({
    isOpen,
    children,
    onClose,
    onSave,
    onCancel,
    isSaveHidden = false,
    isSaveDisabled = false,
    saveText,
    cancelText,
    headerText,
    isLoading,
    onDelete,
    isDeleteHidden = false,
    size,
    isBackgroundWhite,
    isWithFooter = true,
}: Props) => {
    useEffect(() => {
        document.getElementById('main-layout-1')?.classList.add('overflow-y-hidden');
        return () => {
            document.getElementById('main-layout-1')?.classList.remove('overflow-y-hidden');
        };
    }, []);
    return (
        <div
            className="modal"
            style={{
                transform: isOpen ? 'translateY(0)' : 'translateY(-100vh)',
                opacity: isOpen ? 1 : 0,
                display: isOpen ? 'block' : 'none',
            }}
        >
            <div className={`modal-content ${size} rounded-lg shadow-2xl`}>
                <div className="p-3 flex bg-blue-600 rounded-t-lg">
                    <h2 className="text-lg text-white font-semibold">{headerText}</h2>
                    <div className="ml-auto clickable" onClick={onClose}>
                        ✖
                    </div>
                </div>
                <div className={`modal-body overflow-y-scroll ${isBackgroundWhite && 'white'}`}>
                    {!!isLoading && (
                        <div className="loading">
                            <div className="spinner"></div>
                        </div>
                    )}
                    {children}
                </div>

                {isWithFooter && (
                    <div className="flex p-2 border-t-2 ">
                        {onDelete && !isDeleteHidden && (
                            <span className="button-delete link warning" onClick={onDelete}>
                                Delete
                            </span>
                        )}
                        <button
                            className="ml-auto rounded-xl px-2 font-semibold border border-gray-500 bg-gray-400 mr-1"
                            onClick={() => {
                                if (onCancel) {
                                    onCancel();
                                } else {
                                    onClose();
                                }
                            }}
                        >
                            {cancelText ? cancelText : 'Cancel'}
                        </button>
                        {onSave && !isSaveHidden && (
                            <button
                                className={`disabled:opacity-50  rounded-xl px-2 font-semibold border border-gray-500  bg-green-500 ${
                                    isSaveDisabled && 'cursor-wait'
                                }}`}
                                onClick={onSave}
                                disabled={isSaveDisabled}
                            >
                                {saveText ? saveText : 'Save'}
                            </button>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Modal;
