import React, { useState } from 'react';
import { useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import axios from '../../helpers/axios';
import config from '../../helpers/config';
import Supplier from '../../models/SupplierModel';

interface Props {
    onChange: (e: React.FormEvent<HTMLInputElement>) => void;
    supplierId: string;
}

const SupplierDropdown = (props: Props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [supplierList, setSupplierList] = useState<Supplier[]>([]);

    useEffect(() => {
        axios.get(`${config.apiUrl}/supplier`).then((res) => {
            setSupplierList(res.data);
            setIsLoading(false);
        });
    }, []);

    //@ts-ignore
    const getSupplierListData = () => {
        return supplierList.map((supplier) => {
            return {
                key: supplier.companyName + supplier.id,
                text: supplier.companyName,
                value: supplier.id,
            };
        });
    };

    return (
        <>
            <Form.Dropdown
                options={getSupplierListData()}
                placeholder="Select a supplier"
                label="Supplier"
                value={props.supplierId}
                selection
                onChange={(e: any, data) => {
                    const { value } = data;
                    props.onChange({ ...e, currentTarget: { name: 'supplierId', value } });
                }}
                className="small"
                clearable
                loading={isLoading}
            ></Form.Dropdown>
        </>
    );
};

export default SupplierDropdown;
