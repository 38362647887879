import axios from 'helpers/axios';
import config from 'helpers/config';
import InternalCommentModel from 'models/InternalCommentModel';
import RepairOrder from 'models/RepairOrderModel';
import React from 'react';
import Avatar from 'react-avatar';
import RelativeTime from '../RelativeTime';
import moment from 'moment';
import dateFormats from 'helpers/dateFormats';
import { connect } from 'react-redux';
import User from 'models/UserModel';

interface Props {
    comment: InternalCommentModel;
    isCreateMode: boolean;
    chatId?: string;
    commentAdded?: Function;

    currentUser: User;
    repairOrder?: RepairOrder;
}

interface State {
    commentContent: string;
}

class InternalComment extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = { commentContent: '' };
    }

    addComment() {
        const message = this.getMessage();
        if (!message.message.length) {
            return;
        }

        if (this.props.repairOrder) {
            // @ts-ignore
            message.repairOrderId = this.props.repairOrder?.id;
        }
        axios.post(`${config.apiUrl}/chat`, message, { params: { createNotification: 1 } }).then((res) => {
            this.props.commentAdded!(res.data);
            this.setState({ commentContent: '' });
        });
    }

    getMessage() {
        return {
            internalChatId: this.props.chatId,
            message: this.state.commentContent,
        };
    }
    render() {
        const { comment, isCreateMode } = this.props;
        const { currentUser } = this.props;
        return (
            <div className="rounded-lg border-2 mb-4">
                <div className="bg-yellow-50 px-6 py-6 border-b">
                    <div>
                        {isCreateMode ? (
                            <textarea
                                className="bg-transparent outline-none w-full"
                                placeholder="Enter a new note that will only be visible internally"
                                autoFocus={true}
                                onChange={(e) => this.setState({ commentContent: e.target.value })}
                                value={this.state.commentContent}
                                rows={4}
                            />
                        ) : (
                            <div className="mb-4 ">
                                <span className="font-semibold text-sm">
                                    <Avatar
                                        name={`${comment.createdBy?.firstName} ${comment.createdBy?.lastName}`}
                                        round
                                        size={'22'}
                                        className="clickable mr-2"
                                    />
                                    {comment.createdBy?.firstName} {comment.createdBy?.lastName}
                                </span>
                            </div>
                        )}
                    </div>
                    <div className="text-gray-500 whitespace-pre-wrap">{comment.message}</div>
                </div>
                <div className="bg-gray-100 px-6 py-2">
                    <div className="flex">
                        {!isCreateMode && (
                            <div className="text-sm text-gray-500 font-bold flex">
                                <div className="mr-1.5">
                                    {moment(comment.createdAt).format(dateFormats.USA_DATETIME)}
                                </div>
                                <div className="mr-0.5">{'('}</div>
                                <RelativeTime dateTime={comment.createdAt} />
                                <div className="ml-0.5">{')'}</div>
                            </div>
                        )}
                        <div className="ml-auto text-sm">
                            {isCreateMode ? (
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.addComment();
                                    }}
                                    className="rounded-lg bg-green-500 font-semibold px-1 hover:bg-opacity-70 clickable"
                                >
                                    Save
                                </button>
                            ) : (
                                currentUser.id === comment.createdBy?.id && (
                                    <span className=" text-red-500 font-semibold underline clickable">Delete</span>
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(InternalComment);
