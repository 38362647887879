import React from 'react';
import { FaSort } from 'react-icons/fa';
import axios from '../../../helpers/axios';
import config from '../../../helpers/config';
import moment from 'moment';
import PhotoManagementModal from '../../common/PhotoManagementModal';
import ConversationModel from 'models/ConversationModel';
import Customer from 'models/CustomerModel';
import ConversationStatusType from 'models/types/ConversationStatusType';
import MessageModel from 'models/MessageModel';
import MessageSenderType from 'models/types/MessageSenderType';
import CustomerPickerModal from 'components/modals/CustomerPickerModal';
import { BiMessageAdd } from 'react-icons/bi';
import Avatar from 'react-avatar';

interface Props {}

interface State {
    registryItems: [];
    trades: any[];
    allTrades: any[];
    registryCode: string;
    isFileModalOpen: boolean;

    isCustomerPickerModalOpen: boolean;

    isCreateAppointmentModalOpen: boolean;
    conversations: ConversationModel[];

    selectedConversation: ConversationModel | null;
}

class DevPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            registryCode: '',
            registryItems: [],
            trades: [],
            allTrades: [],
            isFileModalOpen: false,

            isCustomerPickerModalOpen: false,
            isCreateAppointmentModalOpen: false,
            conversations: [],

            selectedConversation: null,
        };
        this.getBaseRegistryItems = this.getBaseRegistryItems.bind(this);
        this.filter = this.filter.bind(this);

        this.startConversation = this.startConversation.bind(this);
        this.refreshMessages = this.refreshMessages.bind(this);
    }

    componentDidMount() {
        axios.get(`${config.apiUrl}/messaging/conversations`).then((res) => {
            this.setState({ conversations: res.data });
        });

        // @ts-ignore
        const openNewAppointmentModal = this.props.history.location.state?.openNewAppointmentModal;
        if (openNewAppointmentModal) {
            setTimeout(() => this.openCustomerPicker(), 333);
        }
        document.body.style.overflowY = 'hidden';
        this.refreshMessages();
    }

    refreshMessages() {
        if (this.state.selectedConversation?.id) {
            axios
                .get(`${config.apiUrl}/messaging/conversation/${this.state.selectedConversation.id}`)
                .then((res) => this.setState({ selectedConversation: res.data }))
                .catch((err) => console.log(err));
        }
        setTimeout(this.refreshMessages, 2000);
    }

    componentWillUnmount() {
        document.body.style.overflowY = 'auto';
    }

    openCustomerPicker() {
        this.setState({
            isCustomerPickerModalOpen: true,
        });
    }

    startConversation(customer?: Customer) {
        this.setState({ isCustomerPickerModalOpen: false });
        if (!!!customer) return;

        const newConversation: Partial<ConversationModel> = {
            customerId: customer.id!,
            messages: [],
            status: ConversationStatusType.Active,
            customer,
        };
        this.setState({
            conversations: [newConversation as ConversationModel, ...this.state.conversations],
            selectedConversation: newConversation as ConversationModel,
        });
    }

    sendMessage() {
        const { selectedConversation } = this.state;
        // @ts-ignore
        const messageContent = document.getElementsByName('text-message-content')[0].value;
        const message: Partial<MessageModel> = {
            senderType: MessageSenderType.Customer,
            content: messageContent,
        };
        if (!!!selectedConversation?.id) {
            selectedConversation!.messages = [message as MessageModel];

            axios.post(`${config.apiUrl}/messaging/conversation`, selectedConversation).then((res) => {
                const newConversation = res.data as ConversationModel;
                newConversation.customer = selectedConversation!.customer;
                newConversation.customerId = selectedConversation!.customerId;
                this.setState({ selectedConversation: newConversation });
            });
        } else if (selectedConversation.id) {
            axios.post(`${config.apiUrl}/messaging/conversation/${selectedConversation.id}`, message).then((res) => {
                selectedConversation.messages = [...selectedConversation.messages, res.data];
                this.setState({ selectedConversation });
            });
        }
        // @ts-ignore
        document.getElementsByName('text-message-content')[0].value = '';
    }

    getBaseRegistryItems(e: any) {
        e.preventDefault();
        axios
            .get(`${config.apiUrl}/baseRegistry/${this.state.registryCode}`)
            .then((res: any) => this.setState({ registryItems: res.data }));
    }

    openModal() {
        console.log('pog');
    }

    getDate(timestamp: number) {
        const date = new Date(timestamp * 1000);
        const hours = date.getHours();
        const minutes = '0' + date.getMinutes();
        const seconds = '0' + date.getSeconds();
        return hours + ':' + minutes.substr(-2) + ':' + seconds.substr(-2);
    }

    filter(e: any) {
        const { allTrades } = this.state;
        e.preventDefault();
        if (e.target?.value?.length > 0) {
            this.setState({
                trades: allTrades.filter((trade) => trade.base.toLowerCase().includes(e.target.value)),
            });
        } else {
            this.setState({
                trades: allTrades,
            });
        }
    }

    render() {
        const { registryItems, isFileModalOpen } = this.state;
        const { isCustomerPickerModalOpen, conversations, selectedConversation } = this.state;
        return (
            <div className="list-page text-black">
                <div>
                    return (
                    <div className="page  ">
                        {isCustomerPickerModalOpen && <CustomerPickerModal onClose={this.startConversation} />}
                        <div className="flex ">
                            <div className="w-1/6 border-r border-gray-700 h-screen">
                                <div className="flex-row-reverse mb-8 border-b border-gray-600 pb-4">
                                    <div>
                                        <button
                                            className=" mr-4 font-semibold py-2 px-3 rounded-xl hover:bg-green-500 border border-green-500
                                text-green-500 hover:text-white ml-auto
                                "
                                            onClick={() => this.openCustomerPicker()}
                                        >
                                            <BiMessageAdd className="inline mr-2 " />
                                            <span>New message</span>
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    {!!!conversations.length && (
                                        <div>
                                            <span className="text-gray-600 font-semibold">
                                                You do not have any messages yet
                                            </span>
                                        </div>
                                    )}
                                    <div>
                                        {conversations?.map((conversation) => {
                                            return (
                                                <div
                                                    className="w-full clickable hover:bg-gray-100 flex py-3"
                                                    key={conversation.id}
                                                    onClick={() =>
                                                        this.setState({ selectedConversation: conversation })
                                                    }
                                                >
                                                    <div className="mr-2.5">
                                                        <Avatar
                                                            name={conversation.customer.fullName}
                                                            round
                                                            size={'33'}
                                                        />
                                                    </div>
                                                    <div>
                                                        <div className="font-semibold">
                                                            {conversation.customer?.fullName}
                                                        </div>
                                                        <div className="">
                                                            {conversation.customer?.phoneNumbers[0]?.phoneNumber}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="w-5/6 h-full ">
                                {!!!selectedConversation && (
                                    <div className="flex flex-col justify-center items-center h-screen">
                                        <div className="text-center">
                                            <p>Your messages: Send SMS and emails to your customer</p>
                                            <button
                                                className="mt-4 font-semibold py-2 px-3 rounded-xl hover:bg-green-500 border border-green-500
                        text-green-500 hover:text-white"
                                                onClick={() => this.openCustomerPicker()}
                                            >
                                                <BiMessageAdd className="inline mr-2" />
                                                <span>Send message</span>
                                            </button>
                                        </div>
                                    </div>
                                )}

                                {!!selectedConversation && (
                                    <div className="flex-col  px-4">
                                        <div className="" style={{ height: '90%' }}>
                                            {!selectedConversation?.messages?.length && (
                                                <div className="flex flex-col justify-center items-center pt-8 ">
                                                    <div className="text-center">
                                                        <Avatar
                                                            name={selectedConversation.customer?.fullName}
                                                            round
                                                            size={'33'}
                                                        />
                                                        <p>{selectedConversation.customer?.fullName}</p>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="flex-col flex">
                                                {selectedConversation?.messages?.map((message) => {
                                                    return (
                                                        <div key={message.id} className={` w-auto   mb-0.5`}>
                                                            {message.senderType === MessageSenderType.Tenant && (
                                                                <div className="w-1/2"></div>
                                                            )}
                                                            <div
                                                                className={`${
                                                                    message.senderType === MessageSenderType.Tenant
                                                                        ? 'bg-blue-600 text-white ml-96'
                                                                        : 'bg-gray-100 text-black'
                                                                } rounded-xl  px-4 py-1.5`}
                                                            >
                                                                {message.content}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <div className="pb-52 flex">
                                            <textarea
                                                name="text-message-content"
                                                className="bg-gray-50 rounded-md border  px-2 font-medium py-2 focus:ring-1 focus:outline-none border-blue-500 w-full"
                                                placeholder="Send a message"
                                            ></textarea>
                                            <div
                                                className="p-1.5 bg-blue-600 text-white clickable rounded-lg"
                                                onClick={() => this.sendMessage()}
                                            >
                                                SEND
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <b>dev page</b>
                <br />
                <br />
                <br />
                <br />
                <button onClick={this.openModal}>Open modal</button>
                <h2>
                    Bearer toksen: <b>{localStorage.getItem('token')}</b>
                </h2>
                <form>
                    <input type="text" autoFocus onChange={(e) => this.setState({ registryCode: e.target.value })} />
                    <button type="submit" onClick={this.getBaseRegistryItems}>
                        Get base registry items
                    </button>
                </form>
                <>
                    <table>
                        <tr>
                            <th>Id</th>
                            <th>Registry Code</th>
                            <th>Language</th>
                            <th>Value</th>
                        </tr>
                        {registryItems.map((item: any, index) => (
                            <tr key={index}>
                                <td>{item.id}</td>
                                <td>{item.registryCode}</td>
                                <td>{item.language}</td>
                                <td>{item.value}</td>
                            </tr>
                        ))}
                    </table>
                </>
                <br />
                <br />
                <br />
                <br />
                {/* <Button onClick={() => this.setState({ isFileModalOpen: !isFileModalOpen })} color="linkedin">
                    Manage images
                </Button> */}
                {isFileModalOpen && (
                    <PhotoManagementModal
                        relatedEntityTypeName="inventoryItem"
                        relatedEntityId={
                            process.env.NODE_ENV === 'production'
                                ? 'f7e9573b-bd00-443f-bff2-95dee7212909'
                                : '72fee235-640e-45e8-89f7-7a18fa84e57c'
                        }
                        onClose={() => this.setState({ isFileModalOpen: !isFileModalOpen })}
                    ></PhotoManagementModal>
                )}
                <input
                    onChange={this.filter}
                    name="searchPhrase"
                    className="bg-white placeholder  placeholder-gray-400 placeholder-font-bold rounded-xl border border-green-500 px-2 py-1 font-medium w-1/3 focus:ring-1 focus:outline-none focus:border-blue-500"
                ></input>
                <div>
                    <span>{this.state.trades.length} trades</span>
                </div>
                <table className="items-center w-full mt-4">
                    <thead>
                        <tr className="bg-blue-500 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                            <th className="pl-6 py-3 rounded-tl-md">
                                <span className="clickable">
                                    <span>Date</span>
                                </span>
                            </th>

                            <th className="pl-6 py-3 rounded-tl-md">
                                <span className="clickable">
                                    <span>Pair</span>
                                    <FaSort className="inline ml-1" />
                                </span>
                            </th>

                            <th className="pl-6 py-3 rounded-tl-md">
                                <span className="clickable">
                                    <span>Order</span>
                                    <FaSort className="inline ml-1" />
                                </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.trades.map((trade, index) => {
                            return (
                                <tr
                                    className="border-b font-semibold hover:bg-blue-200 clickable bg-white border-r-2 border-l-2"
                                    key={index}
                                >
                                    <td className="px-6 align-middle whitespace-nowrap p-3">
                                        <span className="mr-10">
                                            {moment.unix(trade.unix).format('DD-MM-YYYY HH:mm:ss')}
                                        </span>
                                    </td>
                                    <td className="px-6 align-middle whitespace-nowrap p-3">
                                        <span className="mr-10">{trade.base} / USDT</span>
                                    </td>
                                    <td className="px-6 align-middle whitespace-nowrap p-3">
                                        <span className={trade.order === 'Short' ? 'text-red-400' : 'text-green-500'}>
                                            {trade.order}
                                        </span>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                );
            </div>
        );
    }
}

export default DevPage;
