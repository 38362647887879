import React, { ReactNode, useState } from 'react';

interface Props {
    children: ReactNode;
    content: ReactNode | string;
    // bg: string;
    delay?: number;
    isSmall?: boolean;
}

const HoverTooltip = ({ children, content, delay = 400, isSmall = false }: Props) => {
    let timeout: any;
    const [active, setActive] = useState(false);

    const showTip = () => {
        timeout = setTimeout(() => {
            setActive(true);
        }, delay);
    };

    const hideTip = () => {
        clearInterval(timeout);
        setActive(false);
    };

    return (
        <div className="inline relative" onMouseEnter={showTip} onMouseLeave={hideTip}>
            {children}
            {active && (
                <div
                    className={`font-normal absolute bottom-8 rounded-lg z-40 left-1/2 mb-1.5 transform -translate-x-1/2  border border-blue-800 shadow-2xl ${
                        isSmall ? 'w-48' : 'w-96'
                    }`}
                >
                    <span>{content}</span>
                    <div className={`${isSmall ? 'ml-24' : 'ml-44'} z-50`}>
                        <div className="inline-block absolute w-3 h-3 transform rotate-45 -mt-1.5 border-b border-r border-blue-800 bg-white"></div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default HoverTooltip;
