import React, { SyntheticEvent, useEffect } from 'react';
import { Form } from 'semantic-ui-react';

interface Props {
    placeholder: string;
    label?: string;
    name: string;
    amount: number;

    id?: string;
    onChange: (amount: React.FormEvent<HTMLInputElement>) => void;
    isSemanticUI?: boolean;
    className?: string;
    hasError?: boolean;
}

const PriceInput = (props: Props) => {
    useEffect(() => {
        if (props.amount !== undefined)
            formatCurrency({ currentTarget: { value: props.amount?.toString() } } as SyntheticEvent<any>, true);
    }, []);

    const formatNumber = (n: string) => {
        // format number 1234567 to 1,234,567
        return n?.replace(/\D/g, '')?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const formatCurrency = (input: SyntheticEvent<any>, isBlur: boolean) => {
        let input_val = input.currentTarget.value;

        // don't validate empty input
        if (input_val === '') {
            //@ts-ignore
            props.onChange({
                currentTarget: { name: props.name, value: input_val },
                target: { name: props.name, value: input_val },
            } as React.FormEvent<HTMLInputElement>);
            return;
        }

        // original length
        const original_len = input_val?.length;

        // initial caret position
        const caret_pos = input.currentTarget?.selectionStart;

        // check for decimal
        if (input_val?.indexOf('.') >= 0) {
            const decimal_pos = input_val?.indexOf('.');

            let left_side = input_val?.substring(0, decimal_pos);
            let right_side = input_val?.substring(decimal_pos);

            // add commas to left side of number
            left_side = formatNumber(left_side);

            // validate right side
            right_side = formatNumber(right_side);

            // On blur make sure 2 numbers after decimal
            if (isBlur) {
                right_side += '00';
            }

            // Limit decimal to only 2 digits
            right_side = right_side.substring(0, 2);

            // join number by .
            input_val = left_side + '.' + right_side;
            //@ts-ignore
            props.onChange({
                currentTarget: { name: props.name, value: input_val },
                target: { name: props.name, value: input_val },
            } as React.FormEvent<HTMLInputElement>);
        } else {
            // no decimal entered
            // add commas to number
            // remove all non-digits
            input_val = formatNumber(input_val);
            if (isBlur) {
                input_val += '.00';
            }

            input_val = input_val;
            //@ts-ignore
            props.onChange({
                currentTarget: { name: props.name, value: input_val },
                target: { name: props.name, value: input_val },
            } as React.FormEvent<HTMLInputElement>);
        }

        // put caret back in the right position
        // var updated_len = input_val.length;
        // caret_pos = updated_len - original_len + caret_pos;
        // input[0].setSelectionRange(caret_pos, caret_pos);
    };

    return (
        <>
            {props.isSemanticUI ? (
                <Form.Input
                    placeholder={props.placeholder}
                    label={props.label || props.placeholder}
                    name={props.name}
                    onChange={(e) => formatCurrency(e, false)}
                    onBlur={(e: any) => formatCurrency(e, true)}
                    icon="dollar sign"
                    iconPosition="left"
                    value={props.amount}
                />
            ) : (
                <input
                    placeholder={props.placeholder}
                    name={props.name}
                    onChange={(e) => formatCurrency(e, false)}
                    onBlur={(e: any) => formatCurrency(e, true)}
                    value={props.amount}
                    className={`${props.className} ${props.hasError && 'outline-red-600'}`}
                    id={props.id}
                />
            )}
        </>
    );
};

export default PriceInput;
