import axios from 'helpers/axios';
import config from 'helpers/config';
import store from '../store/store';

export const resolveUserName = (userId: string) =>
    new Promise<string>(async (resolve, reject) => {
        try {
            const currentUser = store.getState().user.currentUser;

            if (currentUser.id === userId) {
                resolve(currentUser.firstName + ' ' + currentUser.lastName);
            } else {
                axios
                    .get(`${config.apiUrl}/user/${userId}`)
                    .then((res) => resolve(res.data.firstName + ' ' + res.data.lastName))
                    .catch(() => resolve(''));
            }
        } catch (error) {
            reject(error);
        }
    });
