import actionTypes from '../../helpers/actionTypes';
import Customer from '../../models/CustomerModel';

class initialState {
    customersList!: Customer[];
    customersLoading!: boolean;
    customer!: Customer;
    customerLoading!: boolean;
    newCustomerLoading!: boolean;
    newCustomer!: Customer;
    customersCount!: number;
    customerUpdating!: boolean;
}

const customerReducer = (state = initialState, action: any): any => {
    switch (action.type) {
        case actionTypes.GET_CUSTOMERS_REQUEST:
            return {
                ...state,
                customersLoading: true,
            };
        case actionTypes.GET_CUSTOMERS_SUCCESS:
            return {
                ...state,
                customersList: action.payload,
                customersLoading: false,
            };
        case actionTypes.GET_CUSTOMER_REQUEST:
            return {
                ...state,
                customerLoading: true,
            };
        case actionTypes.GET_CUSTOMER_SUCCESS:
            return {
                ...state,
                customer: action.payload,
                customerLoading: false,
                newCustomer: action.payload,
            };
        case actionTypes.CREATE_CUSTOMER_REQUEST:
            return {
                ...state,
                newCustomerLoading: true,
            };
        case actionTypes.CREATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                newCustomer: action.payload,
                newCustomerLoading: false,
            };
        case actionTypes.UPDATE_CUSTOMER_REQUEST:
            return {
                ...state,
                customerUpdating: true,
            };

        case actionTypes.UPDATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                customer: action.payload.updatedCustomer,
                newCustomer: action.payload.updatedCustomer,
                customerUpdating: false,
            };

        case actionTypes.GET_CUSTOMERS_OVERVIEW_SUCCESS: {
            return {
                ...state,
                customersCount: action.payload.count,
            };
        }
        default: {
            return state;
        }
    }
};

export default customerReducer;
