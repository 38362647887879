import axios from '../helpers/axios';
import config from '../helpers/config';
import { Dispatch } from 'redux';
import actionTypes from '../helpers/actionTypes';

const { apiUrl } = config;

export const deleteDemoData = () => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.DELETE_DEMO_DATA_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.DELETE_DEMO_DATA_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.DELETE_DEMO_DATA_FAILURE, payload });

    dispatch(request());

    axios
        .put(`${apiUrl}/tenant/deleteDemoData`)
        .then((res) => {
            dispatch(success(res.data));
        })
        .catch((err) => {
            dispatch(failure(err.message));
        });
};

export const getTenantSettings = () => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.GET_TENANT_SETTINGS_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.GET_TENANT_SETTINGS_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.GET_TENANT_SETTINGS_FAILURE, payload });

    dispatch(request());

    axios
        .get(`${config.apiUrl}/tenant/settings`)
        .then((res) => dispatch(success(res.data)))
        .catch((err) => dispatch(failure(err.message)));
};

export const getPricingMatrices = () => (dispatch: Dispatch) => {
    const request = () => ({ type: actionTypes.GET_PRICING_MATRICES_REQUEST });
    const success = (payload: any) => ({ type: actionTypes.GET_PRICING_MATRICES_SUCCESS, payload });
    const failure = (payload: any) => ({ type: actionTypes.GET_PRICING_MATRICES_FAILURE, payload });

    dispatch(request());

    axios
        .get(`${config.apiUrl}/partsMatrix`)
        .then((res) => dispatch(success(res.data)))
        .catch((err) => dispatch(failure(err.message)));
};
