import actionTypes from '../../helpers/actionTypes';

const initialState = {
    loggingIn: false,
    loggedIn: false,
    errorMessage: null,
};

const authReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.LOGIN_REQUEST: {
            return {
                ...state,
                loggingIn: true,
            };
        }
        case actionTypes.LOGIN_FAILURE: {
            return {
                ...state,
                errorMessage: action.payload,
                loggingIn: false,
            };
        }
        case actionTypes.LOGIN_SUCCESS: {
            return {
                ...state,
                loggedIn: true,
                loggingIn: false,
                errorMessage: null,
            };
        }
        default: {
            return state;
        }
    }
};

export default authReducer;
