import React from 'react';
import moment from 'moment';
import Avatar from 'react-avatar';
import { FaCheckCircle, FaEdit, FaRegTrashAlt, FaSort, FaTimesCircle } from 'react-icons/fa';

import RelativeTime from 'components/common/RelativeTime';
import axios from 'helpers/axios';
import history from 'helpers/history';
import config from 'helpers/config';
import RepairOrder from 'models/RepairOrderModel';
import TableColumn from 'models/TableColumnModel';

interface Props {}

interface State {
    repairOrders: RepairOrder[];
    tableColumns: TableColumn[];
}

class DeletedRepairOrdersPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            repairOrders: [],
            tableColumns: repairOrderListColumns,
        };
    }

    componentDidMount() {
        this.getRepairOrders(1);
    }

    getRepairOrders(page: number, itemsPerPage = 50) {
        axios
            .get(`${config.apiUrl}/repairOrder`, { params: { page, itemsPerPage, archivedOnly: true } })
            .then((res) => {
                this.setState({ repairOrders: res.data });
            })
            .catch((_) => {
                console.log(_);
            });
    }

    isColumnActive(columnName: string) {
        return this.state.tableColumns.find((column) => column.name === columnName)?.active;
    }

    render() {
        const { repairOrders, tableColumns } = this.state;

        return (
            <div className="flex">
                <div className="overflow-x-auto">
                    <table className="items-center w-full ">
                        <thead>
                            <tr className=" text-white bg-gray-200 font-extrabold  text-left text-xs uppercase align-middle">
                                {tableColumns.map(
                                    (column, index) =>
                                        column.active && (
                                            <th key={index} className={`pl-6 py-3 ${index === 0 && 'rounded-tl-md'}`}>
                                                <span className="clickable text-blue-500 font-bold ">
                                                    <span>{column.text}</span>
                                                    {!!column.sortable && <FaSort className="inline ml-1" />}
                                                </span>
                                            </th>
                                        ),
                                )}
                                <th className="px-6 py-3 rounded-tr-md"></th>
                            </tr>
                        </thead>

                        <tbody>
                            {!!repairOrders &&
                                repairOrders?.map((RO) => (
                                    <tr
                                        className={`border-b font-semibold hover:bg-blue-200 clickable bg-white border-r-2 border-l-2`}
                                        key={RO.id}
                                        onClick={() => {
                                            history.push(`/repairOrder/${RO.id}`);
                                        }}
                                    >
                                        {this.isColumnActive('repairOrderNumber') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400 ">
                                                <span className="align-baseline">
                                                    <span>#{RO.repairOrderNumber}</span>
                                                </span>
                                            </td>
                                        )}
                                        {this.isColumnActive('orderName') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400">
                                                <span>
                                                    <span>{RO.title}</span>
                                                </span>
                                            </td>
                                        )}
                                        {this.isColumnActive('customer') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3">
                                                <div>
                                                    <div>
                                                        {!!RO.vehicle ? (
                                                            <span>
                                                                {RO.vehicle?.year} {RO.vehicle?.make}{' '}
                                                                {RO.vehicle?.model}
                                                            </span>
                                                        ) : (
                                                            <span className="text-gray-400 font-medium">
                                                                No vehicle
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div>
                                                        {!!RO.customer ? (
                                                            <span className="font-medium">
                                                                {RO.customer?.firstName} {RO.customer?.lastName}
                                                            </span>
                                                        ) : (
                                                            <span className="text-gray-400 font-medium">
                                                                No customer
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </td>
                                        )}
                                        {this.isColumnActive('total') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3">
                                                $
                                                {RO.services
                                                    .map((service) => service.total)
                                                    .reduce((a, b) => +a + +b, 0)
                                                    .toFixed(2)}
                                            </td>
                                        )}
                                        {this.isColumnActive('due') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400">
                                                {!!RO?.dueDate && (
                                                    <span>
                                                        <span>{moment(RO?.dueDate).format('MM/DD/YYYY HH:mm')}</span>
                                                    </span>
                                                )}
                                            </td>
                                        )}

                                        {this.isColumnActive('paidStatus') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400">
                                                {Math.random() < 0.5 ? (
                                                    <span className="bg-green-500 rounded-lg text-white px-2">
                                                        Paid
                                                    </span>
                                                ) : (
                                                    <span className="bg-yellow-500 rounded-lg text-white px-2">
                                                        Unpaid
                                                    </span>
                                                )}
                                            </td>
                                        )}
                                        {this.isColumnActive('workflow') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400">
                                                <span>
                                                    <span>{RO.workflowStatus?.name}</span>
                                                </span>
                                            </td>
                                        )}
                                        {this.isColumnActive('inspectionStatus') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400">
                                                <span>
                                                    <span>{RO.workflowStatus?.name}</span>
                                                </span>
                                            </td>
                                        )}
                                        {this.isColumnActive('orderStatus') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400">
                                                <span>
                                                    <span>{RO?.status === 1 ? 'Invoice' : 'Estimate'}</span>
                                                </span>
                                            </td>
                                        )}
                                        {this.isColumnActive('authorizationStatus') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400">
                                                <span>
                                                    <FaCheckCircle className="text-green-500" />
                                                </span>
                                            </td>
                                        )}
                                        {this.isColumnActive('technicians') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400">
                                                <span>
                                                    {!!(RO?.asignees?.length > 0) &&
                                                        RO.asignees.map((asignee, index) => (
                                                            <Avatar
                                                                name={`${asignee.firstName} ${asignee.lastName}`}
                                                                round
                                                                size={'22'}
                                                                className="clickable"
                                                                key={index}
                                                            />
                                                        ))}
                                                </span>
                                            </td>
                                        )}
                                        {this.isColumnActive('dateCreated') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400">
                                                <span className="float-left">
                                                    <RelativeTime dateTime={RO.createdAt} />
                                                </span>
                                                <br />
                                                <span>
                                                    <span className="text-sm">
                                                        {moment(RO.createdAt).format('MM/DD/YYYY HH:mm')}
                                                    </span>
                                                </span>
                                            </td>
                                        )}
                                        {this.isColumnActive('labels') && (
                                            <td className="px-6 align-middle whitespace-nowrap p-3 text-blue-400">
                                                {RO.labels?.map((label, indx) => (
                                                    <span
                                                        key={indx}
                                                        style={{ backgroundColor: label.color }}
                                                        className="mr-2 px-1 py-1 rounded-lg hover:opacity-80 text-xs"
                                                    >
                                                        <span className="font-semibold text-white inline-flex whitespace-nowrap">
                                                            <FaTimesCircle
                                                                // onClick={() => this.removeLabel(indx)}
                                                                className="inline mr-1 clickable align-baseline text-xs"
                                                            />
                                                            {label.title}
                                                        </span>
                                                    </span>
                                                ))}
                                            </td>
                                        )}

                                        <td className="px-6 align-middle whitespace-nowrap p-3 non-clickable">
                                            <FaRegTrashAlt
                                                size={15}
                                                className="inline clickable mr-1"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    // this.handleDelete(RO);
                                                }}
                                            />
                                            <FaEdit
                                                size={15}
                                                className="inline clickable"
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    // onEdit(RO.id!);
                                                }}
                                            />
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default DeletedRepairOrdersPage;

const repairOrderListColumns: TableColumn[] = [
    {
        name: 'repairOrderNumber',
        active: true,
        text: 'RO #',
        sortable: true,
    },
    {
        name: 'orderName',
        active: true,
        text: 'Order Name',
        sortable: true,
    },
    {
        name: 'customer',
        active: true,
        text: 'Vehicle, Customer',
        sortable: false,
    },
    {
        name: 'total',
        active: true,
        text: 'Total',
        sortable: true,
    },
    {
        name: 'due',
        active: true,
        text: 'Due',
        sortable: true,
    },
    {
        name: 'paidStatus',
        active: true,
        text: 'Paid Status',
        sortable: true,
    },
    {
        name: 'workflow',
        active: true,
        text: 'Workflow',
        sortable: true,
    },
    {
        name: 'inspectionStatus',
        active: true,
        text: 'Inspection Status',
        sortable: true,
    },
    {
        name: 'orderStatus',
        active: true,
        text: 'Order Status',
        sortable: true,
    },

    {
        name: 'authorizationStatus',
        active: true,
        text: 'Authorization Status',
        sortable: true,
    },
    {
        name: 'technicians',
        active: true,
        text: 'Technicians',
        sortable: true,
    },
    {
        name: 'dateCreated',
        active: true,
        text: 'Date Created',
        sortable: true,
    },
    {
        name: 'labels',
        active: true,
        text: 'Labels',
        sortable: true,
    },
];
