import React from 'react';
import { Divider, Form, Grid, Transition } from 'semantic-ui-react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import config from 'helpers/config';
import axios from 'helpers/axios';
import { displayConfirmationModal } from 'helpers/services/removeConfirmationService';
import Customer from 'models/CustomerModel';
import { deleteVehicle } from '../../../actions/vehicleActions';

import ModalStateType from 'models/generic/ModalState';
import VehicleModel from 'models/VehicleModel';
import FileModel from 'models/FileModel';
import PhotoManagementModal from '../../common/PhotoManagementModal';
import Modal from '../../common/Modal';
import VINSearch from './VINSearch';
import { FaUser } from 'react-icons/fa';
import CustomerSearch from 'components/common/CustomerSearch';
import { Link } from 'react-router-dom';

interface Props {
    vehicleId?: string;
    customer?: Customer;
    onClose: (vehicleCreated?: boolean, vehicleUpdated?: boolean, vehicleId?: string) => void;
    dispatch: Dispatch;
}

interface State {
    modalState: ModalStateType;
    modalHeader: string;
    vehicle: VehicleModel;
    isLoading: boolean;
    isFormExpanded: boolean;
    isImagesModalOpen: boolean;
    formErrors: any;

    isVINsearchActive: boolean;
}

class VehicleModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            modalState: this.props.vehicleId ? ModalStateType.Edit : ModalStateType.Create,
            modalHeader: '',
            isLoading: true,
            isImagesModalOpen: false,
            isFormExpanded: false,
            vehicle: {} as VehicleModel,
            formErrors: {},
            isVINsearchActive: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        this.toggleImagesModal = this.toggleImagesModal.bind(this);
    }

    componentDidMount() {
        if (this.state.modalState === ModalStateType.Edit) {
            this.setState({ isLoading: true });
            this.getVehicle();
        } else {
            this.setState({ isLoading: false, isVINsearchActive: true });
            this.setModalHeader();
        }
    }

    getVehicle() {
        axios
            .get(`${config.apiUrl}/vehicle/${this.props.vehicleId}`)
            .then((response) => {
                this.setState({ vehicle: response.data, isLoading: false });
                this.setModalHeader();
            })
            .catch((_) => {
                this.setState({ isLoading: false });
            });
    }

    setModalHeader() {
        if (this.state.modalState === ModalStateType.Edit) {
            const { make, model } = this.state.vehicle!;
            const modalHeader = `${make} ${model}`;
            this.setState({ modalHeader });
        } else {
            const { customer } = this.props;
            const modalHeader = !!customer ? `Create new vehicle for ${customer.fullName}` : 'Create new vehicle';
            this.setState({ modalHeader });
        }
    }

    save() {
        if (!this.validateForm()) {
            return;
        }
        this.setState({ isLoading: true });
        const { vehicle } = this.state;
        if (this.state.modalState === ModalStateType.Create) {
            if (this.props.customer?.id) {
                vehicle.customerId = this.props.customer.id;
            }
            this.createVehicle(vehicle);
        } else {
            this.updateVehicle(vehicle);
        }
    }

    validateForm() {
        const { vehicle } = this.state;
        const errors: any = {};
        if (!vehicle?.make || vehicle?.make?.length < 1) {
            errors.make = 'Make cannot be empty';
        }

        if (!vehicle?.model || vehicle?.model?.length < 1) {
            errors.model = 'Model cannot be empty';
        }

        if (!vehicle.year) {
            errors.year = 'Year specified';
        }

        this.setState({ formErrors: errors });
        const isFormValid = Object.keys(errors).length === 0;
        return isFormValid;
    }

    delete() {
        const { model, make, id } = this.state.vehicle;
        const { dispatch, onClose } = this.props;
        displayConfirmationModal(`${model} ${make}`)
            .then(() => {
                dispatch<any>(deleteVehicle(String(id)));
                onClose(false, true);
            })
            .catch();
    }

    updateVehicle(vehicleData: any) {
        axios
            .put(`${config.apiUrl}/vehicle`, vehicleData)
            .then((_) => this.props.onClose(false, true))
            .catch((_) => {
                this.setState({ isLoading: false });
            });
    }

    createVehicle(vehicleData: any) {
        axios
            .post(`${config.apiUrl}/vehicle`, vehicleData)
            .then((res) => {
                this.props.onClose(true, false, res.data.vehicleId);
            })
            .catch((_) => {
                this.setState({ isLoading: false });
            });
    }

    getValue(name: string) {
        // @ts-ignore
        return this.state.vehicle[name] || '';
    }

    handleChange(event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) {
        const { name, value } = event.currentTarget;

        // @ts-ignore
        const updatedVehicle: VehicleModel = { ...this.state.vehicle, [name]: value };
        this.setState({ vehicle: updatedVehicle });
    }

    toggleImagesModal() {
        const { isImagesModalOpen } = this.state;
        this.setState({ isImagesModalOpen: !isImagesModalOpen });
        if (isImagesModalOpen) {
            axios
                .get(`${config.apiUrl}/file/images/`, {
                    params: { relatedEntityType: 'vehicle', relatedEntityId: this.state.vehicle.id },
                })
                .then((res) =>
                    this.setState((prevState) => ({
                        vehicle: {
                            ...prevState.vehicle,
                            files: res.data,
                        },
                    })),
                );
        }
    }

    render() {
        // prettier-ignore
        const { modalHeader, isLoading, isFormExpanded, vehicle, isImagesModalOpen, modalState, isVINsearchActive, formErrors } =
            this.state;

        return (
            <Modal
                onDelete={modalState === ModalStateType.Edit ? this.delete : undefined}
                headerText={modalHeader}
                isOpen={true}
                onClose={() => this.props.onClose(false, false)}
                onSave={this.save}
                isSaveHidden={isVINsearchActive}
                isLoading={isLoading}
            >
                {isImagesModalOpen && (
                    <PhotoManagementModal
                        onClose={this.toggleImagesModal}
                        relatedEntityTypeName="vehicle"
                        relatedEntityId={vehicle.id}
                        existingImages={vehicle.files}
                    />
                )}
                {isVINsearchActive ? (
                    <VINSearch
                        vehicleSelected={(vehicle) => this.setState({ vehicle, isVINsearchActive: false })}
                        registerManuallySelected={() => this.setState({ isVINsearchActive: false })}
                    />
                ) : (
                    <div>
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column width="16">
                                    <Form>
                                        {modalState === ModalStateType.Create && !this.props?.customer && (
                                            <div className="border-b border-gray-300 pb-4">
                                                <div className="items-baseline flex font-semibold text-sm">
                                                    <FaUser className="inline mr-1.5" />
                                                    <label htmlFor="" className="text-lg">
                                                        <h3>Owner</h3>
                                                    </label>
                                                </div>
                                                <CustomerSearch
                                                    customerSelected={(customer) => {
                                                        this.setState({
                                                            vehicle: { ...vehicle, customerId: customer?.id },
                                                        });
                                                    }}
                                                    displayDetailsWhenSelected={true}
                                                />
                                            </div>
                                        )}

                                        {modalState === ModalStateType.Edit && !!vehicle?.customer && (
                                            <div className="border-b border-gray-300 pb-4">
                                                <div>
                                                    <span className="inline-block font-semibold">Owner: </span>
                                                    <Link
                                                        to={`/customer/${vehicle.customer?.id}`}
                                                        className="font-bold pl-4 py-2 link"
                                                    >
                                                        {vehicle.customer?.firstName} {vehicle.customer?.lastName}
                                                    </Link>
                                                </div>
                                            </div>
                                        )}

                                        {modalState === ModalStateType.Create && this.props?.customer && (
                                            <div className="border-b border-gray-300 pb-4">
                                                <div>
                                                    <span className="inline-block font-semibold">Owner: </span>
                                                    <Link
                                                        to={`/customer/${this.props?.customer?.id}`}
                                                        className="font-bold pl-4 py-2 link"
                                                    >
                                                        {this.props?.customer?.firstName}{' '}
                                                        {this.props?.customer?.lastName}
                                                    </Link>
                                                </div>
                                            </div>
                                        )}
                                        <Form.Group
                                            unstackable
                                            widths={2}
                                            className={
                                                (modalState === ModalStateType.Create && !this.props?.customer) ||
                                                (modalState === ModalStateType.Edit && !!vehicle?.customer) ||
                                                (modalState === ModalStateType.Create && this.props?.customer)
                                                    ? 'pt-4'
                                                    : ''
                                            }
                                        >
                                            <Form.Input
                                                required
                                                name={'make'}
                                                label="Make"
                                                onChange={this.handleChange}
                                                value={this.getValue('make')}
                                                error={formErrors.make}
                                            />
                                            <Form.Input
                                                required
                                                name={'model'}
                                                label="Model"
                                                onChange={this.handleChange}
                                                value={this.getValue('model')}
                                                error={formErrors.model}
                                            />
                                        </Form.Group>
                                        <Form.Group widths={2}>
                                            <Form.Input
                                                name={'series'}
                                                onChange={this.handleChange}
                                                value={this.getValue('series')}
                                                label="Series"
                                            />
                                            <Form.Input
                                                required
                                                name={'year'}
                                                onChange={this.handleChange}
                                                value={this.getValue('year')}
                                                label="Year"
                                                error={formErrors.year}
                                            />
                                        </Form.Group>
                                        <Form.Group widths={2}>
                                            <Form.Input
                                                name={'VIN'}
                                                onChange={this.handleChange}
                                                value={this.getValue('VIN')}
                                                label="VIN"
                                            />
                                            <Form.Input
                                                name={'licensePlate'}
                                                onChange={this.handleChange}
                                                value={this.getValue('licensePlate')}
                                                label="License Plate"
                                            />
                                        </Form.Group>
                                        <Form.Group unstackable widths={2}>
                                            <Form.Input
                                                name={'transmissionType'}
                                                onChange={this.handleChange}
                                                value={this.getValue('transmissionType')}
                                                label="Transmission"
                                            />
                                            <Form.Input
                                                name={'engine'}
                                                onChange={this.handleChange}
                                                value={this.getValue('engine')}
                                                label="Engine"
                                            />
                                        </Form.Group>
                                        <Form.Group unstackable widths={2}>
                                            <Form.Input
                                                name={'color'}
                                                onChange={this.handleChange}
                                                value={this.getValue('color')}
                                                label="Color"
                                            />
                                        </Form.Group>
                                        <Divider horizontal>
                                            <span
                                                className="clickable expand-link"
                                                onClick={() => this.setState({ isFormExpanded: !isFormExpanded })}
                                            >
                                                {isFormExpanded ? 'Less' : 'More'}
                                            </span>
                                        </Divider>
                                        <Transition.Group animation="fade up" duration="200">
                                            {isFormExpanded && (
                                                <>
                                                    <Form.Group>
                                                        <Form.TextArea
                                                            width="16"
                                                            placeholder="Notes"
                                                            label="Notes"
                                                            name="notes"
                                                            onChange={this.handleChange}
                                                            value={this.getValue('notes')}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="field">
                                                        <div className="field modal__photo-preview">
                                                            <div className="modal__photo-preview__header">
                                                                <label>Images</label>
                                                                <span>
                                                                    <small>
                                                                        <span
                                                                            className="link"
                                                                            onClick={this.toggleImagesModal}
                                                                        >
                                                                            Manage images
                                                                        </span>
                                                                    </small>
                                                                </span>
                                                            </div>

                                                            <div className="image-preview">
                                                                {vehicle.files?.map((image: FileModel, index) => (
                                                                    <span key={index} className="image-item">
                                                                        <img
                                                                            src={`${config.fileRootURL}/images/${image.fileName}`}
                                                                            alt={image.fileName}
                                                                            title={image.fileName}
                                                                            width="100%"
                                                                        />
                                                                    </span>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </Form.Group>
                                                </>
                                            )}
                                        </Transition.Group>
                                    </Form>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                )}
            </Modal>
        );
    }
}

export default connect()(VehicleModal);
