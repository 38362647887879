import React, { SyntheticEvent } from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FaEdit, FaPlusCircle, FaTrash } from 'react-icons/fa';

import { createVehicleIssue, deleteVehicleIssue, updateVehicleIssue } from 'actions/repairOrderActions';
import { displayConfirmationModal } from 'helpers/services/removeConfirmationService';
import VehicleIssue from 'models/VehicleIssue';

interface Props {
    vehicleIssues: VehicleIssue[];
    repairOrderId: string;
    dispatch: Dispatch;
}

interface State {
    editableIssueId: string | null;
    description: string | null;
    finding: string | null;
    isCreateNewVehicleIssueActive: boolean;
}

class ConstomerConcerns extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            editableIssueId: null,
            description: '',
            finding: '',
            isCreateNewVehicleIssueActive: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.createNewIssue = this.createNewIssue.bind(this);
        this.updateIssue = this.updateIssue.bind(this);
    }

    createNewIssue() {
        const { description, finding } = this.state;
        const vehicleIssue = {
            description,
            finding,
        };

        const { dispatch } = this.props;
        dispatch<any>(createVehicleIssue(vehicleIssue as VehicleIssue, this.props.repairOrderId));
        this.setState({ description: null, finding: null, isCreateNewVehicleIssueActive: false });
    }

    updateIssue() {
        const { description, finding, editableIssueId } = this.state;
        const vehicleIssue = {
            description,
            finding,
            id: editableIssueId,
        };

        const { dispatch } = this.props;
        dispatch<any>(updateVehicleIssue(vehicleIssue as VehicleIssue));
        this.setState({ description: null, finding: null, editableIssueId: null });
    }

    handleChange(e: SyntheticEvent) {
        // @ts-ignore
        const { name, value } = e.target;
        // @ts-ignore
        this.setState({ [name]: value });
    }

    editVehicleIssue(vehicleIssue: VehicleIssue) {
        this.setState({
            editableIssueId: vehicleIssue.id,
            description: vehicleIssue.description,
            finding: vehicleIssue.finding,
            isCreateNewVehicleIssueActive: false,
        });
    }

    getEditableRow(isEdit: boolean) {
        return (
            <>
                <td className="table-cell">
                    <textarea
                        name="description"
                        className="bg-gray-50 rounded-md border w-full px-2 font-medium py-2 focus:ring-1 focus:outline-none focus:border-blue-500"
                        onChange={this.handleChange}
                        value={this.state.description!}
                        autoFocus
                    ></textarea>
                </td>
                <td className="table-cell">
                    <textarea
                        name="finding"
                        className="bg-gray-50 rounded-md border w-full px-2 font-medium py-2 focus:ring-1 focus:outline-none focus:border-blue-500"
                        onChange={this.handleChange}
                        value={this.state.finding!}
                    ></textarea>
                </td>
                <td className="table-cell">
                    <div>
                        <button
                            onClick={isEdit ? this.updateIssue : this.createNewIssue}
                            className="bg-green-500 rounded-lg font-semibold text-lg text-white px-2 border w-full"
                        >
                            Save
                        </button>
                    </div>
                    <div>
                        <button
                            onClick={() =>
                                this.setState({
                                    editableIssueId: null,
                                    isCreateNewVehicleIssueActive: false,
                                    description: null,
                                    finding: null,
                                })
                            }
                            className="bg-gray-100 rounded-lg font-semibold text-lg text-gray-700 px-2 border w-full"
                        >
                            Cancel
                        </button>
                    </div>
                </td>
            </>
        );
    }

    handleDelete(issue: VehicleIssue) {
        displayConfirmationModal(`customer's concern: "${issue.description}"`)
            .then(() => {
                this.props.dispatch<any>(deleteVehicleIssue(issue.id));
            })
            .catch((_) => _);
    }

    render() {
        const { vehicleIssues } = this.props;
        const { editableIssueId, isCreateNewVehicleIssueActive } = this.state;

        return (
            <div className="">
                <table className="w-full bg-transparent">
                    <thead>
                        <tr className="bg-blue-600  text-white border-none font-semibold text-left text-xs uppercase align-middle ">
                            <th className="pl-6 py-3 rounded-tl-xl">Customer Concerns</th>
                            <th className="pl-6 ">Technician Comment</th>
                            <th className="pl-6 rounded-tr-xl"></th>
                        </tr>
                    </thead>
                    <tbody className="border">
                        {vehicleIssues?.map((vehicleIssue, index) => {
                            return vehicleIssue.id === editableIssueId ? (
                                this.getEditableRow(true)
                            ) : (
                                <tr key={index} className={`text-black font-medium bg-white  hover:bg-blue-100`}>
                                    <td className="px-6 align-middle h-5 text-sm py-4 border-b text-left items-center">
                                        <div className="whitespace-pre-wrap">{vehicleIssue.description}</div>
                                    </td>
                                    <td className="px-6 align-middle h-5 text-sm py-4 border-b">
                                        <div className="whitespace-pre-wrap">{vehicleIssue.finding}</div>
                                    </td>
                                    <td className="px-6 align-middle h-5 text-sm py-4 border-b non-clickable">
                                        <div className="flex text-xl">
                                            <FaEdit
                                                className="clickable mr-2"
                                                onClick={() => this.editVehicleIssue(vehicleIssue)}
                                            />
                                            <FaTrash
                                                className="clickable text-gray-500"
                                                onClick={() => this.handleDelete(vehicleIssue)}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                        {isCreateNewVehicleIssueActive && (
                            <tr className="text-black font-medium bg-white border-b">{this.getEditableRow(false)}</tr>
                        )}
                    </tbody>
                </table>
                <div className="py-4 px-1  justify-center border-b border-l border-r rounded-b-lg bg-white w-full">
                    <button
                        className={`${
                            isCreateNewVehicleIssueActive || editableIssueId ? 'bg-gray-500' : 'bg-green-500'
                        } rounded-lg px-2 py-1 font-semibold text-white mr-auto ml-3`}
                        onClick={() =>
                            this.setState({
                                isCreateNewVehicleIssueActive: !isCreateNewVehicleIssueActive,
                                editableIssueId: null,
                                description: '',
                                finding: '',
                            })
                        }
                    >
                        {isCreateNewVehicleIssueActive || editableIssueId ? (
                            'Cancel'
                        ) : (
                            <>
                                <FaPlusCircle className="inline align-text-top mr-1" />
                                <span>Add Concern</span>
                            </>
                        )}
                    </button>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    vehicleIssues: state.repairOrder.vehicleIssues,
});

export default connect(mapStateToProps)(ConstomerConcerns);
