import React from 'react';

interface Props {}

interface State {}

class EndOfDayReports extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {};
    }
    render() {
        return <div>EOD Reports</div>;
    }
}

export default EndOfDayReports;
