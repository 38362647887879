import React, { useEffect, useState } from 'react';
import { MdOutlineKeyboardArrowDown, MdOutlineKeyboardArrowUp } from 'react-icons/md';
import Label from 'models/LabelModel';
import axios from 'helpers/axios';
import config from 'helpers/config';
import { FaTimesCircle } from 'react-icons/fa';

interface Props {
    onchange: (tags: Label[]) => void;
}

const TagsPicker = (props: Props) => {
    const [isTagsPickerOpen, setIsTagsPickerOpen] = useState<boolean>(false);
    const [RO_tags, setRO_tags] = useState<Label[]>([]);
    const [selectedTags, setSelectedTags] = useState<Label[]>([]);

    const toggleVisibility = () => {
        if (RO_tags.length === 0) {
            axios.get(`${config.apiUrl}/label/order`).then((res) => {
                setRO_tags(res.data);
            });
        }
        setIsTagsPickerOpen(!isTagsPickerOpen);
    };

    useEffect(() => {
        props.onchange(selectedTags);
    }, [selectedTags]);

    return (
        <div className="flex">
            <div className="relative">
                <div
                    className={`mr-1.5 clickable border border-gray-300 rounded-lg py-1 px-4 font-medium bg-gray-100 ${
                        !!isTagsPickerOpen && 'border-blue-500'
                    }`}
                    onClick={(_) => toggleVisibility()}
                >
                    <span className="mr-1 align-middle">Tags</span>
                    <span className="inline-flex align-middle">
                        {isTagsPickerOpen ? <MdOutlineKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}
                    </span>
                </div>

                {!!isTagsPickerOpen && (
                    <div
                        className="absolute bg-white border px-2 py-1 border-gray-500 space-y-1.5 right-0 w-max z-50 clickable rounded mt-2 shadow-lg"
                        id={`tagsPicker`}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <span className="text-sm font-bold text-blue-600">Select tags to filter by</span>
                        {RO_tags.map((tag, _) => (
                            <div
                                className={`px-3 py-2 clickable border rounded-2xl  border-gray-400 align-middle ${
                                    selectedTags.map((i) => i.id).includes(tag.id)
                                        ? 'bg-blue-300 text-white rounded-2xl text-sm'
                                        : 'hover:bg-blue-50 text-blue-400'
                                }}`}
                                key={tag.id}
                                onClick={(_) => {
                                    if (selectedTags.includes(tag)) {
                                        setSelectedTags(selectedTags.filter((t) => t !== tag));
                                    } else {
                                        setSelectedTags([...selectedTags, tag]);
                                    }
                                    // props.onchange(selectedTags);
                                }}
                            >
                                <span className="mb-1 font-semibold ">{tag.title}</span>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {selectedTags?.map((tag, _) => (
                <span
                    key={tag.id}
                    style={{ backgroundColor: tag.color }}
                    className="mr-2 px-4 py-1 rounded-3xl hover:opacity-80 text-xs"
                >
                    <span className="font-semibold text-white inline-flex whitespace-nowrap pt-1.5 ">
                        <FaTimesCircle
                            onClick={() => {
                                setSelectedTags(selectedTags.filter((t) => t !== tag));
                                // props.onchange(selectedTags);
                            }}
                            className="inline mr-1 clickable align-baseline text-xs"
                        />
                        {tag.title}
                    </span>
                </span>
            ))}
        </div>
    );
};

export default TagsPicker;
