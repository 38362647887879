import Modal from 'components/common/Modal';
import axios from 'helpers/axios';
import config from 'helpers/config';
import Authorization from 'models/AuthorizationModel';
import Service from 'models/ServiceModel';
import AuthorizationType from 'models/types/AuthorizationType';
import ServiceAuthorizationStatus from 'models/types/ServiceAuthorizationStatus';
import React from 'react';
import SignatureCanvas from 'react-signature-canvas';

interface Props {
    repairOrderId: string;
    approvedServices: Service[];
    declinedServices: Service[];
    onClose: () => void;
}

interface State {
    sigRef: any;
}

let signatureRef = {};
class SignatureAuthorizationModal extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            sigRef: {},
        };

        this.authorize = this.authorize.bind(this);
    }

    authorize(signature: any) {
        // set authorization status on each approved service
        this.props.approvedServices.forEach((service) => {
            service.authorizationStatus = ServiceAuthorizationStatus.Authorized;
        });

        const authorization: Partial<Authorization> = {
            authorizationSignature: signature,
            repairorderId: this.props.repairOrderId,
            type: AuthorizationType.Customer,
            authorizedServices: JSON.stringify(this.props.approvedServices),
            declinedServices: this.props.declinedServices.map((service) => service.id),
        };

        axios.post(`${config.apiUrl}/authorization`, authorization).then((newAuthorization) => {
            console.log(newAuthorization);
            this.props.onClose();
        });
    }
    render() {
        return (
            <Modal
                headerText={'Signature Authorization'}
                isOpen={true}
                onClose={() => this.props.onClose()}
                isBackgroundWhite={true}
                isDeleteHidden={true}
                saveText={'Authorize'}
                // @ts-ignore
                onSave={() => this.authorize(JSON.stringify(signatureRef.toData()))}
                isSaveHidden={false}
                // @ts-ignore
                onCancel={() => signatureRef.clear()}
                cancelText="Clear"
            >
                <div className="w-full">
                    <SignatureCanvas
                        ref={(ref: any) => {
                            signatureRef = ref;
                        }}
                        backgroundColor="#f0f1fb"
                        penColor="black"
                        canvasProps={{ height: 400, className: 'w-full' }}
                    />
                </div>
            </Modal>
        );
    }
}

export default SignatureAuthorizationModal;
