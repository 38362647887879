import axios from 'helpers/axios';
import config from 'helpers/config';
import WorkflowStatus from 'models/WorkflowStatusModel';
import React, { useEffect, useState } from 'react';

interface Props {
    status: number;
    workflowStatusId: string;
    updateOrderStatus: (status: number) => void;
    updateWorkflowStatus: (workflowStatusId: string) => void;
}

const RepairOrderStatus: React.FC<Props> = (props: Props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isWorkflowDropdownExpanded, setIsWorkflowDropdownExpanded] = useState(false);
    const [workflowStatuses, setWorkflowStatuses] = useState<WorkflowStatus[]>([]);

    useEffect(() => {
        axios
            .get(`${config.apiUrl}/tenant/workflowStatuses`)
            .then((res) => {
                setIsLoading(false);
                setWorkflowStatuses(res.data);
            })
            .catch((err) => console.log(err));
    }, []);

    return (
        <>
            <div className="flex flex-col place-items-center pb-2">
                <div className="w-fit ">
                    <button
                        onClick={() => props.updateOrderStatus(0)}
                        className={`border rounded-lg uppercase py-1 px-6 text-gray-500  rounded-r-none ${
                            props.status === 0 && 'bg-gray-100 text-blue-500 font-bold border-blue-500'
                        }`}
                    >
                        Estimate
                    </button>
                    <button
                        onClick={() => props.updateOrderStatus(1)}
                        className={`border rounded-lg uppercase py-1 px-6 text-gray-500  rounded-l-none ${
                            props.status === 1 && 'bg-gray-100 text-blue-500 font-bold border-blue-500'
                        }`}
                    >
                        Invoice
                    </button>
                </div>
            </div>
            <h2 className="text-gray-600 font-semibold text-lg text-center">Workflow</h2>
            <div className="flex flex-col place-items-center pb-2">
                {!isLoading && (
                    <div className="w-full 2xl:w-2/4 relative">
                        <button
                            id="dropdownDefault"
                            data-dropdown-toggle="dropdown"
                            className="w-full border border-blue-500 bg-white text-blue-500 font-bold   focus:ring-1 focus:outline-none focus:ring-blue-300  rounded-lg  px-4 py-1 text-center inline-flex items-center"
                            type="button"
                            onClick={() => setIsWorkflowDropdownExpanded(!isWorkflowDropdownExpanded)}
                        >
                            {workflowStatuses.find((WS) => WS.id === props.workflowStatusId)?.name}
                            <svg
                                className="ml-auto w-4 h-4"
                                aria-hidden="true"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                            >
                                <path strokeLinecap="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
                            </svg>
                        </button>
                        <div
                            id="dropdown"
                            className={` ${
                                !isWorkflowDropdownExpanded && 'hidden'
                            } w-full z-10 absolute  bg-white rounded divide-y divide-gray-100 shadow  border border-blue-500`}
                        >
                            <ul className="py-1 text-sm text-gray-700" aria-labelledby="dropdownDefault">
                                {workflowStatuses.map((workflowStatus) => (
                                    <li
                                        className={`block clickable ${
                                            workflowStatus.id === props.workflowStatusId
                                                ? 'bg-blue-400 text-white'
                                                : 'hover:bg-gray-100'
                                        } py-2 px-4 font-semibold`}
                                        key={workflowStatus.id}
                                        onClick={() => {
                                            setIsWorkflowDropdownExpanded(false);
                                            props.updateWorkflowStatus(workflowStatus.id);
                                        }}
                                    >
                                        {workflowStatus.name}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default RepairOrderStatus;
