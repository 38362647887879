import moment from 'moment';
import React from 'react';

interface Props {
    disabled?: boolean;

    dateTime?: string | Date;
    handleChange: (value: any) => void;
    isInvalid?: boolean;
    id: string;
}

const DateTimeInput: React.FC<Props> = ({ dateTime, handleChange, id, isInvalid = false }: Props) => {
    const onChange = (_: any) => {
        // @ts-ignore
        const date = document.getElementById(`datetimeInputttDate${id}`)?.value;
        // @ts-ignore
        const time = document.getElementById(`datetimeInputttTime${id}`)?.value;
        const dateTime = `${date}T${time}`;
        const UTC = moment(dateTime).format();
        if (!!UTC) {
            handleChange(UTC);
        }
    };
    return (
        <div>
            <div className="relative flex mb-1">
                <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-gray-500 dark:text-gray-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                            clipRule="evenodd"
                        ></path>
                    </svg>
                </div>
                <input
                    type="date"
                    id={`datetimeInputttDate${id}`}
                    value={moment(dateTime).format('yyyy-MM-DD')}
                    onChange={(e) => onChange(e.target.value)}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5 py-1 mr-1 ${
                        isInvalid && 'border-red-700'
                    }`}
                    placeholder="Select date"
                />
                <input
                    type="time"
                    id={`datetimeInputttTime${id}`}
                    value={moment(dateTime).format('HH:mm')}
                    onChange={(e) => onChange(e.target.value)}
                    className={`bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-1 ${
                        isInvalid && 'border-red-700'
                    }`}
                ></input>
            </div>
        </div>
    );
};

export default DateTimeInput;
