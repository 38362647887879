interface TableColumn {
    name: string;
    active: boolean;
    text: string;
    orderStatus?: TableColumOrderStatus;
    sortable?: boolean;
}

export enum TableColumOrderStatus {
    None = 1,
    Ascending = 2,
    Descending = 3,
}

export default TableColumn;
