import React from 'react';

interface Props {
    price: number;
    // isWithDollarSign?: boolean;
    clasName?: string;
}

const Price = ({ price, clasName = '' }: Props) => {
    return (
        <span className={clasName}>
            {new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(price)}
        </span>
    );
};

export default Price;
