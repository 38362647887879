import React from 'react';
import { connect } from 'react-redux';
import CreateTenantRequest from '../../../models/create/CreateTenantRequest';
import { Grid, Header, Form, Button } from 'semantic-ui-react';
import RegistrationComplete from './RegistrationComplete';
import axios from '../../../helpers/axios';
import config from '../../../helpers/config';
const { apiUrl } = config;

interface ComponentState {
    email: string;
    firstName: string;
    lastName: string;
    tenantId: number;
    password: string;
    isRegisterButtonEnabled: boolean;
    isSubmitted: boolean;
    confirmationPassword: string;
    businessName: string;
    isLoading: boolean;

    formErrors: any;
    [x: string]: any;
}

class RegistrationPage extends React.Component<any, ComponentState> {
    constructor(props: any) {
        super(props);

        //@ts-ignore
        this.state = {
            isSubmitted: false,
            formErrors: {},
        };

        this.handleChange = this.handleChange.bind(this);
        this.submit = this.submit.bind(this);
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const { name, value } = event.currentTarget;
        this.setState({ [name]: value });
    }

    submit() {
        const newTenantRequest: CreateTenantRequest = {
            businessName: this.state.businessName,
            admin: {
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                password: this.state.password,
            },
        };

        this.setState({ isLoading: true });

        axios({
            method: 'post',
            url: `${apiUrl}/auth/registerTenant`,
            data: newTenantRequest,
        })
            .then((_) => {
                setTimeout(() => this.setState({ isSubmitted: true, isLoading: false }), 1000);
            })
            .catch((_) => this.setState({ isLoading: false }));
    }

    render() {
        return (
            <Grid textAlign="center" style={{ height: '100vh', padding: '10%' }} verticalAlign="middle">
                <Grid.Column style={{ maxWidth: 450 }}>
                    {this.state.isSubmitted ? (
                        <RegistrationComplete name={this.state.firstName} email={this.state.email} />
                    ) : (
                        <div>
                            <div className="flex justify-center mb-10">
                                <Header as="h2" color="blue" textAlign="center">
                                    Start Your Free Trial!
                                </Header>
                            </div>
                            <Form
                                size="large"
                                error={this.props.errorMessage?.length > 0}
                                loading={this.state.isLoading}
                            >
                                <Form.Input
                                    fluid
                                    icon="user"
                                    iconPosition="left"
                                    placeholder="First name"
                                    onChange={this.handleChange}
                                    name="firstName"
                                />
                                <Form.Input
                                    fluid
                                    icon="user"
                                    iconPosition="left"
                                    placeholder="Last name"
                                    onChange={this.handleChange}
                                    name="lastName"
                                />
                                <Form.Input
                                    fluid
                                    icon="mail"
                                    iconPosition="left"
                                    placeholder="E-mail address"
                                    onChange={this.handleChange}
                                    name="email"
                                    required={true}
                                    onBlur={() => alert('blured')}
                                />
                                <Form.Input
                                    fluid
                                    icon="user"
                                    iconPosition="left"
                                    placeholder="Company Name"
                                    onChange={this.handleChange}
                                    name="businessName"
                                />
                                <Form.Input
                                    fluid
                                    icon="lock"
                                    iconPosition="left"
                                    placeholder="Password"
                                    type="password"
                                    name="password"
                                    onChange={this.handleChange}
                                />
                                <Form.Input
                                    fluid
                                    icon="lock"
                                    iconPosition="left"
                                    placeholder="Confirm Password"
                                    type="password"
                                    name="password2"
                                    onChange={this.handleChange}
                                />
                                <Button color="blue" fluid size="large" onClick={this.submit}>
                                    Start my free trial
                                </Button>
                            </Form>
                        </div>
                    )}
                </Grid.Column>
            </Grid>
        );
    }
}

export default connect()(RegistrationPage);
