import React from 'react';
import moment from 'moment';

import RelativeTime from 'components/common/RelativeTime';
import UserFullName from 'components/common/UserFullName';
import RepairOrder from 'models/RepairOrderModel';

import history from 'helpers/history';
import { getPaymentTypeName } from 'helpers/payments/paymentsService';
import Price from 'components/common/Price';

interface Props {
    repairOrders: RepairOrder[];
    isForCustomer?: boolean;
}

const CustomerOrVehiclePayments: React.FC<Props> = ({ repairOrders, isForCustomer }: Props) => {
    const payments = repairOrders.flatMap((RO) => RO.payments);

    // @ts-ignore
    payments.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

    return (
        <div className="mt-4 overflow-x-auto">
            <table className="items-center bg-transparent border w-full">
                <thead className="border">
                    <tr className=" bg-blue-600 text-white border-none font-semibold text-left text-xs uppercase align-middle">
                        <th className="pl-6 py-3 rounded-tl-xl">Amount</th>
                        <th className="px-6 py-3">Type</th>
                        <th className="px-6 py-3">Note</th>
                        <th className="px-6 py-3">RO#</th>
                        <th className="px-6 py-3">Date Created</th>
                        <th className="px-6 py-3">Entered By</th>
                        <th className="px-6 py-3 rounded-tr-xl"></th>
                    </tr>
                </thead>

                <tbody>
                    {payments?.map((payment, index) => (
                        <tr
                            className={`border group font-medium hover:bg-blue-200 clickable ${
                                index % 2 === 0 ? 'bg-gray-100' : 'bg-blue-100'
                            }`}
                            key={index}
                        >
                            <td className="px-6 min-w-max align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2 text-left flex items-center">
                                <span className="font-semibold text-green-400">
                                    <Price price={payment.amountCents / 100} />
                                </span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2 font-semibold">
                                <span>{getPaymentTypeName(payment.paymentType)} </span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span>{payment.note} </span>
                            </td>
                            <td className="px-6 align-middle whitespace-nowrap p-3 non-clickable">
                                <span
                                    className="link font-semibold"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        history.push(`/repairOrder/${payment.repairorderId}`);
                                    }}
                                >
                                    #{repairOrders.find((RO) => RO.id === payment.repairorderId)?.repairOrderNumber}
                                </span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                <span className="float-left">
                                    <RelativeTime dateTime={payment.createdAt} />
                                </span>
                                <br />
                                <span>
                                    <span className="text-sm">
                                        {moment(payment.createdAt).format('MM/DD/YYYY HH:mm')}
                                    </span>
                                </span>
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2">
                                {!!payment?.createdById && <UserFullName userId={payment?.createdById} />}
                            </td>
                            <td className="px-6 align-middle border-l-0 border-r-0 text-base whitespace-nowrap py-2"></td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {payments?.length! < 1 && (
                <div className="border py-4 px-4 wb-white text-gray-600 text-lg font-semibold text-center">
                    This {isForCustomer ? 'customer has not made any' : 'vehicle does not have any linked'} payments
                    yet.
                </div>
            )}
        </div>
    );
};

export default CustomerOrVehiclePayments;
