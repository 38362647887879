import React from 'react';
import ModalStateType from 'models/generic/ModalState';
import axios from 'helpers/axios';
import config from 'helpers/config';
import Modal from '../common/Modal';
import { Divider, Form, Transition } from 'semantic-ui-react';
import InventoryItem from 'models/InventoryItemModel';
import FileModel from 'models/FileModel';

import SupplierDropdown from '../common/SupplierDropdown';
import PriceInput from '../common/PriceInput';
import PhotoManagementModal from '../common/PhotoManagementModal';

interface Props {
    inventoryItemId?: string;
    onClose: (inventoryitemCreated?: boolean, inventoryitemEdited?: boolean) => void;
}

interface ComponentState {
    modalState: ModalStateType;
    modalHeader: string;
    inventoryItem: InventoryItem;
    isLoading: boolean;
    isFormExpanded: boolean;
    isImagesModalOpen: boolean;
    formErrors: any;
}

class InventoryItemModal extends React.Component<Props, ComponentState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            modalState: this.props.inventoryItemId ? ModalStateType.Edit : ModalStateType.Create,
            modalHeader: '',
            isLoading: true,
            isFormExpanded: false,
            inventoryItem: {} as InventoryItem,
            formErrors: {},
            isImagesModalOpen: false,
        };

        this.getInventoryItem = this.getInventoryItem.bind(this);
        this.setModalHeader = this.setModalHeader.bind(this);
        this.getValue = this.getValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.save = this.save.bind(this);
        this.toggleImagesModal = this.toggleImagesModal.bind(this);
    }

    componentDidMount() {
        if (this.state.modalState === ModalStateType.Edit) {
            this.setState({ isLoading: true });
            this.getInventoryItem();
        } else {
            this.setState({ isLoading: false });
            this.setModalHeader();
        }
    }

    handleChange(event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) {
        const { name, value } = event.currentTarget;
        const updatedInventoryItem: InventoryItem = { ...this.state.inventoryItem, [name]: value };
        this.setState({ inventoryItem: updatedInventoryItem });
    }

    getInventoryItem() {
        axios
            .get(`${config.apiUrl}/inventory/${this.props.inventoryItemId}`)
            .then((response) => {
                this.setState({ inventoryItem: response.data, isLoading: false });
                this.setModalHeader();
            })
            .catch((err) => console.log(err));
    }

    setModalHeader() {
        if (this.state.modalState === ModalStateType.Edit) {
            const { description } = this.state.inventoryItem!;
            const modalHeader = `Edit ${description}`;
            this.setState({ modalHeader });
        } else {
            this.setState({ modalHeader: 'Create new inventory item' });
        }
    }

    getValue(name: string) {
        // @ts-ignore
        return this.state.inventoryItem[name] || '';
    }

    save() {
        if (!this.validateForm()) {
            return;
        }

        this.setState({ isLoading: true });

        const inventoryItem = this.state.inventoryItem;
        inventoryItem.supplierId = inventoryItem.supplierId ?? null;
        inventoryItem.retailPrice = Number(inventoryItem.retailPrice?.toString().replace(/,/g, ''));
        inventoryItem.cost = Number(inventoryItem.cost?.toString().replace(/,/g, ''));
        if (this.state.modalState === ModalStateType.Create) {
            this.createInventoryItem(this.state.inventoryItem);
        } else {
            this.updateInventoryItem(this.state.inventoryItem);
        }
    }

    validateForm() {
        const { inventoryItem } = this.state;
        const errors: any = {};
        if (!inventoryItem?.description || inventoryItem?.description?.length < 1) {
            errors.description = 'Desription cannot be empty';
        }

        if (!inventoryItem.quantity) {
            errors.quantity = 'Quantity must be specified';
        }

        this.setState({ formErrors: errors });
        const isFormValid = Object.keys(errors).length === 0;
        return isFormValid;
    }

    updateInventoryItem(inventoryitemData: any) {
        axios
            .put(`${config.apiUrl}/inventory`, inventoryitemData)
            .then((_) => this.props.onClose(false, true))
            .catch((_) => {
                this.setState({ isLoading: false });
            });
    }

    createInventoryItem(inventoryitemData: any) {
        axios
            .post(`${config.apiUrl}/inventory`, inventoryitemData)
            .then((_) => {
                this.props.onClose(true, false);
            })
            .catch((_) => {
                this.setState({ isLoading: false });
            });
    }

    toggleImagesModal() {
        const { isImagesModalOpen } = this.state;
        this.setState({ isImagesModalOpen: !isImagesModalOpen });
        if (isImagesModalOpen) {
            axios
                .get(`${config.apiUrl}/file/images/`, {
                    params: { relatedEntityType: 'inventoryItem', relatedEntityId: this.state.inventoryItem.id },
                })
                .then((res) =>
                    this.setState((prevState) => ({
                        inventoryItem: {
                            ...prevState.inventoryItem,
                            files: res.data,
                        },
                    })),
                );
        }
    }

    render() {
        const { modalHeader, isLoading, isFormExpanded, formErrors, isImagesModalOpen, inventoryItem } = this.state;
        return (
            <Modal
                headerText={modalHeader}
                isOpen={true}
                onClose={() => this.props.onClose(false, false)}
                onSave={this.save}
                isLoading={isLoading}
            >
                {isImagesModalOpen && (
                    <PhotoManagementModal
                        onClose={this.toggleImagesModal}
                        relatedEntityTypeName="inventoryItem"
                        relatedEntityId={inventoryItem.id}
                        existingImages={inventoryItem.files}
                    />
                )}
                <div>
                    <Form size="large">
                        <Form.Group unstackable widths={2}>
                            <Form.Input
                                required
                                placeholder="Description"
                                label="Description"
                                name="description"
                                onChange={this.handleChange}
                                value={this.getValue('description')}
                                error={formErrors.description}
                            />
                            <Form.Input
                                placeholder="Item Code"
                                label="Item Code"
                                name="itemCode"
                                onChange={this.handleChange}
                                value={this.getValue('itemCode')}
                            />
                        </Form.Group>
                        <Form.Group unstackable widths={2}>
                            <PriceInput
                                isSemanticUI={true}
                                placeholder="0.00"
                                label="Cost"
                                name="cost"
                                onChange={this.handleChange}
                                amount={this.state.inventoryItem.cost}
                            />
                            <PriceInput
                                isSemanticUI={true}
                                placeholder="0.00"
                                label="Retail Price"
                                name="retailPrice"
                                onChange={this.handleChange}
                                amount={this.state.inventoryItem.retailPrice}
                            />
                        </Form.Group>

                        <Form.Group unstackable widths={2}>
                            <Form.Input
                                placeholder="Brand"
                                label="Brand"
                                name="brand"
                                onChange={this.handleChange}
                                value={this.getValue('brand')}
                            />
                            <Form.Input
                                placeholder="Location"
                                label="Location"
                                name="location"
                                onChange={this.handleChange}
                                value={this.getValue('location')}
                            />
                        </Form.Group>
                        <Form.Group widths={2}>
                            <Form.Input
                                required
                                placeholder="Quantity"
                                label="Quantity"
                                name="quantity"
                                onChange={this.handleChange}
                                value={this.getValue('quantity')}
                                error={formErrors.quantity}
                            />
                            <SupplierDropdown
                                supplierId={this.getValue('supplierId')}
                                onChange={this.handleChange}
                            ></SupplierDropdown>
                        </Form.Group>
                        <Divider horizontal>
                            <span
                                className="clickable expand-link"
                                onClick={() => this.setState({ isFormExpanded: !isFormExpanded })}
                            >
                                {isFormExpanded ? 'Less' : 'More'}
                            </span>
                        </Divider>
                        <Transition.Group animation="fade up" duration="200">
                            {isFormExpanded && (
                                <>
                                    <Form.Group>
                                        <Form.TextArea
                                            width="16"
                                            placeholder="Notes"
                                            label="Notes"
                                            name="note"
                                            onChange={this.handleChange}
                                            value={this.getValue('note')}
                                        />
                                    </Form.Group>
                                    <Form.Group className="field">
                                        <div className="field modal__photo-preview">
                                            <div className="modal__photo-preview__header">
                                                <label>Images</label>
                                                <span>
                                                    <small>
                                                        <span className="link" onClick={this.toggleImagesModal}>
                                                            Manage images
                                                        </span>
                                                    </small>
                                                </span>
                                            </div>

                                            <div className="image-preview">
                                                {inventoryItem.files?.map((image: FileModel, index) => (
                                                    <span key={index} className="image-item">
                                                        <img
                                                            src={`${config.fileRootURL}/images/${image.fileName}`}
                                                            alt={image.fileName}
                                                            title={image.fileName}
                                                            width="100%"
                                                        />
                                                    </span>
                                                ))}
                                            </div>
                                        </div>
                                    </Form.Group>
                                </>
                            )}
                        </Transition.Group>
                    </Form>
                </div>
            </Modal>
        );
    }
}

export default InventoryItemModal;
