import { withRouter } from 'react-router-dom';

const TitleService = (history: any) => {
    const getTitle = (pathName: string) => {
        if (pathName === '/') {
            return '*** AutoFlow Manager ***';
        } else if (pathName === '/vehicles') {
            return 'Vehicles';
        } else if (pathName === '/jobCentre') {
            return 'Job Center';
        } else if (pathName === '/customers') {
            return 'Customers';
        } else if (pathName === '/inventory') {
            return 'Inventory';
        } else if (pathName === '/settings') {
            return 'Settings';
        } else if (pathName === undefined) {
            return '*** AutoFlow Manager ***';
        } else {
            return '*** AutoFlow Manager ***';
        }
    };
    history.history.listen((location: any) => {
        document.title = getTitle(location.pathname);
    });
    return null;
};

export default withRouter(TitleService);
