import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { BsPrinter } from 'react-icons/bs';
import { BiMailSend } from 'react-icons/bi';

import axios from 'helpers/axios';
import config from 'helpers/config';
import InspectionTemplate from 'models/InspectionTemplateModel';
import RepairOrder from 'models/RepairOrderModel';
import Inspection from 'models/InspectionModel';
import InspectionCard from './InspectionCard';
import InspectionTaskStatus from 'models/types/InspectionTaskStatus';
import { getActivityLog } from 'actions/repairOrderActions';
import actionTypes from 'helpers/actionTypes';

interface Props {
    repairOrder: RepairOrder;

    dispatch: Dispatch;
}

interface State {
    inspectionTemplates: InspectionTemplate[];
    inspections: Inspection[];
}

class InspectionsTab extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            inspectionTemplates: [],
            inspections: [],
        };
    }

    componentDidMount() {
        this.getInspectionTemplates();
        this.getInspections();
    }

    getInspectionTemplates() {
        axios
            .get(`${config.apiUrl}/inspection/templates`)
            .then((res) => this.setState({ inspectionTemplates: res.data }))
            .catch((_) => console.log(_));
    }

    getInspections() {
        axios
            .get(`${config.apiUrl}/inspection/all/${this.props.repairOrder.id}`)
            .then((res) => this.setState({ inspections: res.data }))
            .catch((_) => console.log(_));
    }

    createInspection(inspectionTemplateId: string) {
        const RO_ID = this.props.repairOrder?.id;
        axios
            .post(`${config.apiUrl}/inspection`, { inspectionTemplateId, repairOrderId: RO_ID })
            .then((response) => {
                const { dispatch } = this.props;
                dispatch<any>(getActivityLog(RO_ID));

                this.props.dispatch({
                    type: actionTypes.UPDATE_REPAIR_ORDER_SUCCESS,
                    payload: {
                        ...this.props.repairOrder,
                        inspectionsCount: !!this.props.repairOrder.inspectionsCount
                            ? ++this.props.repairOrder.inspectionsCount
                            : 1,
                    },
                });

                const { inspections } = this.state;
                inspections.unshift({ ...response.data, isNew: true });
                this.setState({ inspections });
            })
            .catch((_) => console.log(_));
    }

    updateRating(rating: InspectionTaskStatus, inspectionIndex: number, inspectionTaskIndex: number) {
        const { inspections } = this.state;
        if (inspections[inspectionIndex].tasks[inspectionTaskIndex].rating === rating) {
            inspections[inspectionIndex].tasks[inspectionTaskIndex].rating = null;
        } else {
            inspections[inspectionIndex].tasks[inspectionTaskIndex].rating = rating;
        }
        this.setState({ inspections }, () => this.updateTask(inspectionIndex, inspectionTaskIndex));
    }

    handleTaskChange(event: React.FormEvent<HTMLInputElement>, inspectionIndex: number, inspectionTaskIndex: number) {
        const { name, value } = event.currentTarget;

        const { inspections } = this.state;
        inspections[inspectionIndex].tasks[inspectionTaskIndex] = {
            ...inspections[inspectionIndex].tasks[inspectionTaskIndex],
            [name]: value,
        };
        this.setState({ inspections });
    }

    updateTask(inspectionIndex: number, inspectionTaskIndex: number) {
        const taskToUpdate = this.state.inspections[inspectionIndex].tasks[inspectionTaskIndex];
        const inspections = this.state.inspections;
        axios
            .put(`${config.apiUrl}/inspection/task`, { ...taskToUpdate })
            .then((res) => {
                inspections[inspectionIndex].tasks[inspectionTaskIndex] = res.data;
                this.setState({ inspections });
            })
            .catch((_) => console.log(_));
    }

    completeInspection(inspectionId: string) {
        const inspections = this.state.inspections;
        const inspectionToComplete = inspections.find((inspection) => inspection.id === inspectionId);
        inspectionToComplete!.isCompleted = true;
        const inspectionIndex = inspections.findIndex((inspection) => inspection.id === inspectionId);
        inspections[inspectionIndex] = inspectionToComplete!;
        this.setState({ inspections });

        axios
            .put(`${config.apiUrl}/inspection/complete`, { inspectionId })
            .then((res) => {
                const inspectionIndex = inspections.findIndex((inspection) => inspection.id === inspectionId);
                inspections[inspectionIndex] = res.data;
                this.setState({ inspections });
            })
            .catch((_) => console.log(_));
    }
    render() {
        const { inspectionTemplates, inspections } = this.state;
        return (
            <div>
                <div className="flex">
                    <div className="ml-auto">
                        <button className="mr-2 border-gray-500 border text-gray-500 bg-white uppercase px-3 p-1 rounded-2xl font-medium text-sm ml-auto">
                            <BsPrinter className="inline mr-2 align-baseline" />
                            <span className="align-baseline">Print Inspection</span>
                        </button>

                        <button className="mr-2 border-gray-500 border text-gray-500 bg-white uppercase px-3 p-1 rounded-2xl font-medium text-sm ml-auto">
                            <BiMailSend className="inline mr-2 align-baseline" />
                            <span className="align-baseline">Send Inspection</span>
                        </button>
                    </div>
                </div>
                <div>
                    <div className="bg-white p-2 rounded-lg border mb-6">
                        <h3 className="font-semibold text-lg">Add new inspection:</h3>
                        <div className="grid grid-cols-5 pt-2">
                            {inspectionTemplates?.map((template) => {
                                return (
                                    <div
                                        key={template.id}
                                        className="flex flex-col hover:bg-gray-300 items-center p-2 mr-2 mb-2 border-2 rounded-lg border-orange-400 "
                                    >
                                        <div className="font-semibold text-blue-400">{template.name}</div>
                                        <div className="font-semibold text-gray-600 text-sm">
                                            {template.description}
                                        </div>
                                        <div>
                                            <span className="text-blue-400 font-semibold">
                                                {template.tasks?.length}
                                            </span>{' '}
                                            inspection point{template.tasks?.length > 1 && 's'}
                                        </div>
                                        <button
                                            className="rounded-lg bg-green-400 border border-green-700 px-2 self-center
                                    mt-auto
                                    "
                                            onClick={() => this.createInspection(template.id)}
                                        >
                                            START
                                        </button>
                                    </div>
                                );
                            })}
                        </div>
                    </div>

                    {inspections?.map((inspection, index) => {
                        return (
                            <InspectionCard
                                inspection={inspection}
                                key={inspection.id}
                                ratingUpdated={(rating, taskIndex) => this.updateRating(rating, index, taskIndex)}
                                handleChange={(e, taskIndex) => this.handleTaskChange(e, index, taskIndex)}
                                updateTask={(taskIndex) => this.updateTask(index, taskIndex)}
                                completeInspection={() => this.completeInspection(inspection.id)}
                            />
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default connect()(InspectionsTab);
