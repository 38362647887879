import React from 'react';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { FaCar, FaTimesCircle } from 'react-icons/fa';
import { FiSend } from 'react-icons/fi';

import FormModes from 'helpers/FormModes';
import axios from 'helpers/axios';
import config from 'helpers/config';
import RepairOrder from 'models/RepairOrderModel';

import CustomerVehicleSelection from './CustomerVehicleSelection';
import ServicesTab from './tabs/ServicesTab';
import Inspections from './tabs/InspectionsTab';
import MessagesTab from './tabs/MessagesTab';
import Overlay from 'components/common/Overlay';
import RepairOrderSideView from './sideview/RepairOrderSideView';
import actionTypes from 'helpers/actionTypes';
import LabelPicker from 'components/common/LabelPicker';
import Label from 'models/LabelModel';
import OdometerInOut from './OdometerInOut';
import KeyTag from './KeyTag';
import SummaryTab from './tabs/summary/SummaryTab';
import { updateRepairOrder } from 'actions/repairOrderActions';
import PrintPicker from './PrintPicker';
import AttachmentsTab from './tabs/AttachmentsTab';
import SendEstimateModal from 'components/modals/SendEstimateModal';
import store from 'store/store';
import LoanerBookingModal from 'components/modals/vehicle/LoanerBookingModal';
import CannedJob from 'models/CannedJobModel';

interface Props {
    dispatch: Dispatch;
    repairOrder: RepairOrder;
}

interface State {
    mode: FormModes;
    repairOrder: RepairOrder;
    isLoading: boolean;
    repairOrderId?: string;
    activeTab: number;
    isSendEstimateModalOpen: boolean;
    isLoanerBookingModalOpen: boolean;
    cannedJobs: CannedJob[];
}

class RepairOrderPage extends React.Component<Props, State> {
    state: State;

    constructor(props: any) {
        super(props);
        // @ts-ignore
        const { repairOrderId } = this.props.match.params;

        this.state = {
            mode: FormModes.Edit,
            repairOrder: {} as RepairOrder,
            isLoading: true,
            repairOrderId,
            activeTab: 0,
            isSendEstimateModalOpen: false,
            isLoanerBookingModalOpen: false,

            cannedJobs: [],
        };

        this.handleChange = this.handleChange.bind(this);
        this.updateRepairOrder = this.updateRepairOrder.bind(this);
        this.updateOdometer = this.updateOdometer.bind(this);
        this.updateKeyTag = this.updateKeyTag.bind(this);
    }

    componentDidMount() {
        this.setState({ isLoading: true, mode: FormModes.Edit });
        this.getRepairOrder();
        this.getServices();
        this.getCannedJobs();
    }

    componentWillUnmount() {
        this.props.dispatch({
            type: actionTypes.REPAIR_ORDER_PAGE_CLOSED,
        });
    }

    componentDidUpdate(prevProps: any) {
        // @ts-ignore
        if (prevProps.location.key !== this.props.location.key) {
            this.setState(
                {
                    // @ts-ignore
                    repairOrderId: this.props.match.params.repairOrderId,
                },
                () => {
                    this.props.dispatch({
                        type: actionTypes.REPAIR_ORDER_PAGE_CLOSED,
                    });
                    this.componentDidMount();
                },
            );
        }
    }

    getCannedJobs() {
        axios.get(`${config.apiUrl}/cannedjob`).then((res) => this.setState({ cannedJobs: res.data }));
    }

    // TO DO TODO: this is temporary fix to fix payments component missing the data needed
    // calculations when opening the RO page in any tab other then services.
    getServices() {
        axios
            .get(`${config.apiUrl}/repairOrder/${this.state.repairOrderId}/services`)
            .then((res) => {
                this.props.dispatch({
                    type: actionTypes.GET_REPAIR_ORDER_SERVICES_SUCCESS,
                    payload: res.data,
                });
            })
            .catch((_) => console.log(_));
    }

    getRepairOrder() {
        axios
            .get(`${config.apiUrl}/repairOrder/${this.state.repairOrderId}`)
            .then((response) => {
                this.props.dispatch({
                    type: actionTypes.GET_REPAIR_ORDER_SUCCESS,
                    payload: response.data,
                });
                this.setState({ isLoading: false });
                this.updateRecentRosHistory();
            })
            .catch((err) => console.log(err));
    }

    updateRecentRosHistory() {
        const RO = this.props.repairOrder;
        store.dispatch({
            type: actionTypes.UPDATE_RECENTLY_VIEWED_ROS,
            payload: {
                id: RO.id,
                number: RO.repairOrderNumber,
                name: RO.title,
                customer: RO.customer,
                vehicle: RO.vehicle,
            },
        });
    }

    updateRepairOrder(repairOrder: RepairOrder, updateImmediately = false) {
        if (updateImmediately) {
            this.props.dispatch({
                type: actionTypes.UPDATE_REPAIR_ORDER_SUCCESS,
                payload: repairOrder,
            });
        }
        this.props.dispatch<any>(updateRepairOrder(repairOrder, updateImmediately));
    }

    handleChange(
        event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>,
        update = false,
        updateImmediately = false,
    ) {
        const { name, value } = event.currentTarget;

        const updatedRepairOrder: RepairOrder = { ...this.props.repairOrder, [name]: value };
        if (update) {
            this.updateRepairOrder(updatedRepairOrder, updateImmediately);
        } else if (!update && updateImmediately) {
            this.props.dispatch({
                type: actionTypes.UPDATE_REPAIR_ORDER_SUCCESS,
                payload: updatedRepairOrder,
            });
        }
    }

    getValue(name: string) {
        // @ts-ignore
        return !!this.props.repairOrder ? this.props.repairOrder[name] : '';
    }

    updateLabels(labels: Label[]) {
        this.updateRepairOrder({ ...this.props.repairOrder, labels }, true);
    }

    removeLabel(labelIndex: number) {
        const { labels } = this.props.repairOrder;
        labels.splice(labelIndex, 1);
        this.updateRepairOrder({ ...this.props.repairOrder, labels }, true);
    }

    updateOdometer(odometerIn: number, odometerOut: number) {
        this.updateRepairOrder({ ...this.props.repairOrder, odometerIn, odometerOut });
    }

    updateKeyTag(keyTag: string) {
        this.updateRepairOrder({ ...this.props.repairOrder, keyTag });
    }

    getTabs(repairOrder: RepairOrder) {
        return [
            { title: 'Summary', component: !!repairOrder && <SummaryTab repairOrder={repairOrder} /> },
            {
                title: (
                    <span>
                        <span>Services</span>{' '}
                        {!!repairOrder?.servicesCount && (
                            <span className="bg-blue-600 text-white rounded-full w-6 h-6 inline-flex  items-center justify-center text-sm font-semibold">
                                {repairOrder.servicesCount}
                            </span>
                        )}
                    </span>
                ),
                component: <ServicesTab repairOrderId={this.state.repairOrderId} cannedJobs={this.state.cannedJobs} />,
            },
            {
                title: (
                    <span>
                        <span>Messages </span>
                        {!!repairOrder?.messagesCount && (
                            <span className="bg-blue-600 text-white rounded-full w-6 h-6 inline-flex  items-center justify-center text-sm font-semibold">
                                {repairOrder.messagesCount}
                            </span>
                        )}
                    </span>
                ),
                component: <MessagesTab repairOrder={repairOrder} />,
            },
            {
                title: (
                    <span>
                        <span>Inspections</span>{' '}
                        {!!repairOrder?.inspectionsCount && (
                            <span className="bg-blue-600 text-white rounded-full w-6 h-6 inline-flex  items-center justify-center text-sm font-semibold">
                                {repairOrder.inspectionsCount}
                            </span>
                        )}
                    </span>
                ),
                component: <Inspections repairOrder={repairOrder} />,
            },
            {
                title: (
                    <span>
                        <span>Attachments</span>{' '}
                        {!!repairOrder?.attachmentsCount && (
                            <span className="bg-blue-600 text-white rounded-full w-6 h-6 inline-flex  items-center justify-center text-sm font-semibold">
                                {repairOrder.attachmentsCount}
                            </span>
                        )}
                    </span>
                ),

                component: <AttachmentsTab repairOrder={repairOrder} />,
            },
        ];
    }

    handleSend() {
        this.setState({ isSendEstimateModalOpen: true });
    }

    handleLoanerBookingOpen() {
        this.setState({ isLoanerBookingModalOpen: true });
    }

    render() {
        const { isLoading, activeTab, isSendEstimateModalOpen, isLoanerBookingModalOpen } = this.state;
        const { repairOrder } = this.props;
        const tabs = this.getTabs(repairOrder);
        return (
            <div className="px-8 py-2 pb-8 relative">
                {isLoading && <Overlay />}

                {/* <div className="page-loading">
                    <div className="spinner"></div>
                </div> */}
                {!isLoading && (
                    <CustomerVehicleSelection
                        onChange={(e) => this.handleChange(e, true)}
                        customer={repairOrder?.customer}
                        vehicle={repairOrder?.vehicle}
                    />
                )}
                {isSendEstimateModalOpen && (
                    <SendEstimateModal onClose={() => this.setState({ isSendEstimateModalOpen: false })} />
                )}
                {isLoanerBookingModalOpen && (
                    <LoanerBookingModal
                        onClose={() => this.setState({ isLoanerBookingModalOpen: false })}
                        RO_ID={repairOrder.id}
                        customerId={repairOrder.customer?.id}
                    />
                )}
                <div className="flex">
                    <div className="w-3/4 mt-4">
                        {!isLoading && (
                            <>
                                <OdometerInOut
                                    onSave={this.updateOdometer}
                                    odometerIn={repairOrder.odometerIn}
                                    odometerOut={repairOrder.odometerOut}
                                />
                                <KeyTag onSave={this.updateKeyTag} keyTag={repairOrder.keyTag} />
                            </>
                        )}
                        <div className="flex items-center pr-4">
                            <div className="font-bold text-xl mr-3">
                                {repairOrder?.status === 1 ? 'Invoice:' : 'Estimate:'}
                            </div>
                            <input
                                className="border outline-none w-1/3 placeholder-gray-400 font-bold text-xl py-2 px-5 mt-5 mb-3 rounded-lg"
                                placeholder="Type in the name for this repair order"
                                name="title"
                                value={this.getValue('title')}
                                onChange={(e: any) => this.handleChange(e, false, true)}
                                onBlur={() => this.updateRepairOrder(repairOrder, true)}
                            />

                            <div className="ml-auto">
                                <button
                                    onClick={() => this.handleLoanerBookingOpen()}
                                    className="mr-2 border-gray-500 border text-gray-500 bg-white uppercase px-3 p-1 rounded-2xl font-medium text-base  opacity-80 ml-auto hover:opacity-100"
                                >
                                    <FaCar className="inline mr-2 align-baseline" />
                                    <span>Loaner Car</span>
                                </button>
                                {!isLoading && <PrintPicker RO_ID={repairOrder.id} />}
                                <button
                                    onClick={() => this.handleSend()}
                                    className=" bg-green-500 uppercase px-3 p-1 rounded-2xl font-medium text-base transform opacity-80 transition duration-500 hover:scale-110 ml-auto hover:opacity-100"
                                >
                                    <FiSend className="inline mr-2 align-baseline" />
                                    <span>Send</span>
                                </button>

                                {/* TODO TO DO: issiaiskinti ar tikrai reikia sito buttono */}
                                {/* <button
                                    onClick={() => this.updateRepairOrder(repairOrder)}
                                    className="bg-green-500 uppercase px-3 p-1 rounded-2xl font-medium text-base transform opacity-80 transition duration-500 hover:scale-110 ml-auto hover:opacity-100"
                                >
                                    Save
                                </button> */}
                            </div>
                        </div>
                        <div className="mb-5" onClick={(e) => e.stopPropagation()}>
                            <div className="items-baseline">
                                <span className="inline-flex mr-2">
                                    <LabelPicker
                                        labelsSelected={(labels: any) => this.updateLabels(labels)}
                                        labelType="order"
                                        labelIds={repairOrder?.labels?.map((label) => label.id)}
                                    />
                                </span>

                                {repairOrder?.labels?.map((label, indx) => (
                                    <span
                                        key={indx}
                                        style={{ backgroundColor: label.color }}
                                        className="mr-2 px-1 py-1 rounded-lg hover:opacity-80 text-xs"
                                    >
                                        <span className="font-semibold text-white inline-flex whitespace-nowrap">
                                            <FaTimesCircle
                                                onClick={() => this.removeLabel(indx)}
                                                className="inline mr-1 clickable align-baseline text-xs"
                                            />
                                            {label.title}
                                        </span>
                                    </span>
                                ))}
                            </div>
                        </div>

                        {!isLoading &&
                            tabs.map((tab, index) => {
                                return (
                                    <div
                                        className={`inline-flex px-5 border-b-4 ${
                                            activeTab === index
                                                ? ' border-blue-500 '
                                                : '  border-gray-500 hover:text-blue-400'
                                        }`}
                                        key={index}
                                    >
                                        <span
                                            className={`clickable text-lg ${
                                                activeTab === index
                                                    ? 'font-bold text-blue-500 border-blue-500 '
                                                    : ' font-medium text-gray-400 border-gray-500 hover:text-blue-400'
                                            }`}
                                            onClick={() => this.setState({ activeTab: index })}
                                        >
                                            {tab.title}
                                        </span>
                                    </div>
                                );
                            })}
                        {tabs[activeTab].component}
                    </div>
                    <RepairOrderSideView />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    repairOrder: state.repairOrder.repairOrder,
});

export default connect(mapStateToProps)(RepairOrderPage);
