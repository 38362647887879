import React, { useEffect, useState } from 'react';
import moment from 'moment';

interface Props {
    dateTime: Date;

    floatRight?: boolean;
    displayExactTimePopup?: boolean;
}

const RelativeTime: React.FC<Props> = ({ dateTime, floatRight = true, displayExactTimePopup = true }: Props) => {
    const [dateTimeCopy, setDateTimeCopy] = useState(dateTime);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setDateTimeCopy(new Date(dateTime));
        }, 60000);
        return () => clearInterval(intervalId);
    }, []);

    return (
        <span
            className={`relative group ${floatRight ? 'float-right' : ''}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {isHovered && displayExactTimePopup && (
                <div className="absolute top-1/2 transform -translate-y-1/2 bg-gray-800 ml-2 rounded-lg p-1 font-semibold inline right-24 z-0 group-hover:z-10 opacity-0 group-hover:opacity-100 w-max">
                    <div className="relative z-10 p-2 text-xs text-white shadow-lg inline-block">
                        {moment(dateTime).format('YYYY-MM-DD HH:mm:ss')}
                    </div>
                    <div className="-ml-1 inline-block absolute w-3 h-3 mt-2 transform rotate-45 bg-gray-800"></div>
                </div>
            )}
            {moment(dateTimeCopy).fromNow()}
        </span>
    );
};

export default RelativeTime;
