import axios from 'helpers/axios';
import config from 'helpers/config';
import Customer from 'models/CustomerModel';
import React, { useState } from 'react';
import { FaEnvelope, FaPhoneSquare, FaTimesCircle } from 'react-icons/fa';
import { connect } from 'react-redux';

interface Props {
    customerSelected: (customerId?: Customer) => void;
    displayDetailsWhenSelected?: boolean;

    alreadySelectedCustomer?: Customer;
}

const CustomerSearch: React.FC<Props> = ({
    displayDetailsWhenSelected = false,
    customerSelected,
    alreadySelectedCustomer,
}: Props) => {
    const [customers, setCustomers] = useState<Customer[]>([]);
    const [isSearchActive, setIsSearchActive] = useState(false);
    const [isSearchLocked, setIsSearchLocked] = useState(false);
    const [selectedCustomer, setSelectedCustomer] = useState<Customer | undefined>();

    React.useEffect(() => {
        if (alreadySelectedCustomer) {
            setSelectedCustomer(alreadySelectedCustomer);
        }
    });

    const fetchCustomers = (searchPhrase: string) => {
        const itemsPerPage = 10,
            page = 1;

        axios
            .get(`${config.apiUrl}/customer`, { params: { page, itemsPerPage, searchPhrase } })
            .then((res) => {
                setCustomers(res.data);
            })
            .catch((_) => {
                setCustomers([]);
            });
    };

    const handleCustomerSelect = (customer: Customer) => {
        setSelectedCustomer(customer);
        customerSelected(customer);
        setCustomers([]);
        setIsSearchActive(false);
        setIsSearchLocked(false);
    };

    return (
        <div>
            {!!selectedCustomer ? (
                <div className="rounded-lg px-2 py-1 border border-black font-semibold w-full items-baselin bg-white">
                    <div className="flex w-full">
                        <div>
                            <span className="mr-2">
                                {selectedCustomer.firstName} {selectedCustomer.lastName}
                            </span>
                        </div>
                        <div className="ml-auto">
                            <FaTimesCircle
                                onClick={() => {
                                    setSelectedCustomer(undefined);
                                    customerSelected(undefined);
                                }}
                                className="inline mr-1 clickable  ml-auto "
                            />
                        </div>
                    </div>
                    {displayDetailsWhenSelected && (
                        <div>
                            <div className="text-sm">
                                {selectedCustomer.phoneNumbers?.map((number) => (
                                    <div key={number.id}>
                                        <FaPhoneSquare className="inline align-baseline mr-2" />
                                        <span>{number.phoneNumber}</span>
                                    </div>
                                ))}
                            </div>
                            {selectedCustomer.primaryEmail && (
                                <span className="text-sm">
                                    <FaEnvelope className="inline align-baseline mr-2" />
                                    <a className="mr-2" href={`mailto:${selectedCustomer.primaryEmail}`}>
                                        {selectedCustomer.primaryEmail}
                                    </a>
                                </span>
                            )}
                        </div>
                    )}
                </div>
            ) : (
                <input
                    type="text"
                    className="rounded-lg border-blue-600 border-2 w-full px-2 py-1 "
                    onChange={(e) => fetchCustomers(e.target.value)}
                    onFocus={() => setIsSearchActive(true)}
                    onBlur={() => {
                        setTimeout(() => {
                            if (isSearchLocked) {
                                return;
                            }
                            setIsSearchActive(false);
                        }, 100);
                    }}
                    placeholder="Search by name, phone number or email"
                />
            )}
            {!selectedCustomer && isSearchActive && (
                <div
                    className="border absolute z-50 w-full border-blue-600 rounded-sm divide-y-2 border-t-0"
                    onMouseOver={() => setIsSearchLocked(true)}
                    onMouseLeave={() => setIsSearchLocked(false)}
                >
                    {customers &&
                        customers.map((customer, index) => (
                            <div
                                key={index}
                                className="clickable hover:bg-blue-200 px-4 py-1.5 bg-white"
                                onClickCapture={() => handleCustomerSelect(customer)}
                            >
                                <div className="font-semibold">
                                    {customer.firstName} {customer.lastName}
                                </div>
                                <div className="text-sm">
                                    {customer.phoneNumbers.map((number) => (
                                        <div key={number.id}>{number.phoneNumber}</div>
                                    ))}
                                </div>
                                {customer.primaryEmail && <div className="text-sm">{customer.primaryEmail}</div>}
                            </div>
                        ))}
                    {customers?.length < 1 && (
                        <div className="flex flex-col text-center py-4 font-semibold text-gray-600 bg-white">
                            There are 0 results
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    currentUser: state.user.currentUser,
});

export default connect(mapStateToProps)(CustomerSearch);
