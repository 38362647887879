import React from 'react';

import axios from 'helpers/axios';
import config from 'helpers/config';

import Tenant from 'models/TenantModel';
import FormModes from 'helpers/FormModes';
import { FaEdit } from 'react-icons/fa';
import { BiSave } from 'react-icons/bi';

interface Props {}

interface State {
    companySettings: any;
    tenant: Tenant;
    formState: FormModes;
    isLoading: boolean;
    beforeEdit: any;
}

class CompanySettings extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            companySettings: {},
            tenant: {} as Tenant,
            formState: FormModes.Preview,
            isLoading: false,
            beforeEdit: {},
        };

        this.getValue = this.getValue.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getCompanySettings();
    }

    getCompanySettings() {
        axios
            .get(`${config.apiUrl}/tenant/settings`)
            .then((res) => this.setState({ tenant: res.data, companySettings: res.data.tenantSettings }));
    }

    getValue(name: string) {
        // @ts-ignore
        return this.state?.companySettings[name] || '';
    }

    handleChange(event: React.FormEvent<HTMLInputElement> | React.FormEvent<HTMLTextAreaElement>) {
        const { name, value } = event.currentTarget;

        const updateCompanySettings: any = { ...this.state.companySettings, [name]: value };
        this.setState({ companySettings: updateCompanySettings });
    }

    handleUpdate() {
        const { companySettings } = this.state;
        this.setState({ isLoading: true });
        axios
            .put(`${config.apiUrl}/tenant`, companySettings)
            .finally(() => this.setState({ isLoading: false, formState: FormModes.Preview }));
    }

    render() {
        const { tenant, formState } = this.state;
        const FORM_INPUT_CLASS = `rounded-md ${
            formState === FormModes.Edit && 'border border-blue-200'
        } font-bold w-1/2 pl-4 py-2 focus:ring-1 focus:outline-none focus:ring-blue-500`;

        return (
            <div>
                <div className="border rounded-xl">
                    <h3 className="font-semibold text-lg rounded-t-xl bg-blue-400 border-b px-6 py-3">
                        Company settings
                    </h3>
                    <div className="p-6 bg-white grid grid-cols-2 gap-y-4">
                        <div>
                            <label className="inline-block w-1/4 ml-2 font-semibold py-2 mr-6">Company Name</label>
                            <input
                                required
                                placeholder="Company Name"
                                className={FORM_INPUT_CLASS}
                                name="businessName"
                                onChange={this.handleChange}
                                disabled={formState === FormModes.Preview}
                                value={tenant.businessName || ''}
                            />
                        </div>
                        <div>
                            <label className="inline-block w-1/4 ml-2 font-semibold py-2 mr-6">Currency</label>
                            <input
                                placeholder="Currency"
                                className={FORM_INPUT_CLASS}
                                name="currency"
                                onChange={this.handleChange}
                                value={this.getValue('currency')}
                                disabled={formState === FormModes.Preview}
                            />
                        </div>
                        <div>
                            <label className="inline-block w-1/4 ml-2 font-semibold py-2 mr-6">Website</label>
                            <input
                                placeholder="Website"
                                className={FORM_INPUT_CLASS}
                                name="website"
                                onChange={this.handleChange}
                                value={this.getValue('website')}
                                disabled={formState === FormModes.Preview}
                            />
                        </div>
                        <div>
                            <label className="inline-block w-1/4 ml-2 font-semibold py-2 mr-6">Email</label>
                            <input
                                placeholder="Email"
                                className={FORM_INPUT_CLASS}
                                name="primaryEmail"
                                onChange={this.handleChange}
                                value={this.getValue('primaryEmail')}
                                disabled={formState === FormModes.Preview}
                            />
                        </div>
                        <div>
                            <label className="inline-block w-1/4 ml-2 font-semibold py-2 mr-6">Phone Number</label>
                            <input
                                required
                                placeholder="Phone Number"
                                className={FORM_INPUT_CLASS}
                                name="phoneNumber"
                                onChange={this.handleChange}
                                disabled={formState === FormModes.Preview}
                                value={this.getValue('phoneNumber')}
                            />
                        </div>
                        <div>
                            <label className="inline-block w-1/4 ml-2 font-semibold py-2 mr-6">
                                Business License ID
                            </label>
                            <input
                                placeholder="Business License ID"
                                className={FORM_INPUT_CLASS}
                                name="businessLicenseId"
                                onChange={this.handleChange}
                                value={this.getValue('businessLicenseId')}
                                disabled={formState === FormModes.Preview}
                            />
                        </div>
                        <div>
                            <label className="inline-block w-1/4 ml-2 font-semibold py-2 mr-6">Opening Time</label>
                            <input
                                placeholder="Opening Time"
                                className={FORM_INPUT_CLASS}
                                name="shopOpeningTime"
                                onChange={this.handleChange}
                                value={this.getValue('shopOpeningTime')}
                                disabled={formState === FormModes.Preview}
                            />
                        </div>
                        <div>
                            <label className="inline-block w-1/4 ml-2 font-semibold py-2 mr-6">Closing Time</label>
                            <input
                                placeholder="Closing Time"
                                className={FORM_INPUT_CLASS}
                                name="shopClosingTime"
                                onChange={this.handleChange}
                                value={this.getValue('shopClosingTime')}
                                disabled={formState === FormModes.Preview}
                            />
                        </div>
                    </div>
                    <div className="pb-6 pr-12 flex flex-row-reverse bg-white">
                        {formState === FormModes.Preview && (
                            <button
                                onClick={() => {
                                    this.setState({ formState: FormModes.Edit, beforeEdit: this.state });
                                }}
                                className="font-semibold py-2 px-3 rounded-xl hover:bg-green-500 border border-green-500 text-green-500 hover:text-white"
                            >
                                <FaEdit className="inline mr-2 align-baseline" />
                                <span>Edit</span>
                            </button>
                        )}

                        {formState === FormModes.Edit && (
                            <span>
                                <button
                                    onClick={() => {
                                        this.setState({ ...this.state.beforeEdit, beforeEdit: {} });
                                    }}
                                    className="font-semibold py-2 px-3 rounded-xl hover:bg-gray-500 border border-gray-500 text-gray-500 hover:text-white mr-2"
                                >
                                    <span>Cancel</span>
                                </button>
                                <button
                                    onClick={() => {
                                        this.handleUpdate();
                                    }}
                                    className="font-semibold py-2 px-3 rounded-xl hover:bg-green-500 border border-green-500 text-green-500 hover:text-white"
                                >
                                    <BiSave className="inline mr-2 align-baseline" />
                                    <span>Save Changes</span>
                                </button>
                            </span>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}

export default CompanySettings;
