import React from 'react';
import Avatar from 'react-avatar';
import { FaPlus, FaCheck, FaTimesCircle } from 'react-icons/fa';
import axios from 'helpers/axios';
import config from 'helpers/config';
import User from 'models/UserModel';

interface Props {
    usersSelected: Function;
    userIds?: string[];
    allowMultiple: boolean;
}

interface State {
    users: User[];
    selectedUsers: any[];
    id: string;
    isOpen: boolean;
}

class UserPicker extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            users: [],
            selectedUsers: [],
            id: `userPicker${Math.random().toString(36).substr(2, 9)}`,
            isOpen: false,
        };

        this.onClickOutside = this.onClickOutside.bind(this);
    }

    getUsers() {
        axios.get(`${config.apiUrl}/user`).then((res) => {
            this.setState({ users: res.data }, this.updateSelectedUsers);
        });
    }

    updateSelectedUsers() {
        const { users } = this.state;
        const { userIds } = this.props;
        for (const user of users) {
            user.isSelected = userIds?.includes(user.id);
        }
        this.setState({ users });
    }

    onClickOutside(e: any) {
        const { id } = this.state;
        const target = document.querySelector(`#${id}`);
        const withinBoundaries = e.composedPath().includes(target);

        if (withinBoundaries) {
        } else {
            this.close();
        }
    }

    close() {
        const { id } = this.state;
        this.props.usersSelected(this.state.users.filter((user) => user.isSelected));
        document.getElementById(id)?.classList.toggle('hidden');
        document.removeEventListener('click', this.onClickOutside);
        this.setState({ isOpen: false });
    }

    openPicker() {
        const { users, id, isOpen } = this.state;

        if (!!isOpen) {
            return;
        }

        if (users?.length < 1) {
            this.getUsers();
        } else {
            this.updateSelectedUsers();
        }

        document.getElementById(id)?.classList.toggle('hidden');
        document.addEventListener('click', this.onClickOutside);
        this.setState({ isOpen: true });
    }

    toggleUserSelected(index: number) {
        const { users, selectedUsers } = this.state;

        users[index].isSelected = !users[index].isSelected;
        this.setState({ users, selectedUsers }, () => {
            this.close();
            if (users[index].isSelected) {
                this.props.usersSelected([users[index]]);
            }
        });
    }

    render() {
        const { users, id } = this.state;
        return (
            <div className={`pt-1 ${id}`}>
                <FaPlus onClick={() => this.openPicker()} alignmentBaseline="middle" className="clickable" />
                <div
                    className="bg-white border mr-3 z-50 text-sm max-w-xls w-56 text-left no-underline break-words rounded-lg absolute font-bold hidden"
                    id={id}
                >
                    <div className="text-gray-700 opacity-75 font-semibold p-3 pb-0 mb-0 border-b border-solid border-gray-100 uppercase rounded-t-lg">
                        <div className="flex ">
                            <span>Pick a user</span>

                            <FaTimesCircle
                                onClick={() => this.close()}
                                alignmentBaseline="baseline"
                                className="items-baseline clickable ml-auto mr-2"
                            />
                        </div>
                    </div>
                    {users.map((user, indx) => (
                        <div
                            key={indx}
                            style={{}}
                            className="mx-3 p-2  rounded-lg clickable hover:opacity-80"
                            onClick={() => this.toggleUserSelected(indx)}
                        >
                            <span className="font-semibold clickable">
                                {!!user.isSelected && <FaCheck className="inline mr-3" />}
                                <Avatar
                                    name={`${user.firstName} ${user.lastName}`}
                                    round
                                    size={'22'}
                                    className="clickable"
                                />
                                <span className="ml-2 hover:underline">{`${user.firstName} ${user.lastName}`}</span>
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default UserPicker;
