import AccessPointsTab from 'components/modals/user/AccessPointsTab';
import React from 'react';
import DeletedRepairOrdersPage from './DeletedRepairOrdersPage';

interface Props {}

interface State {
    isLoading: boolean;
    activeTab: number;
}

class DeletedItemsPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            isLoading: false,
            activeTab: 0,
        };
    }

    getTabs() {
        return [
            { title: 'Repair Orders', component: <DeletedRepairOrdersPage /> },
            { title: 'Costomers', component: <AccessPointsTab name="test" /> },
            { title: 'Vehicles', component: <AccessPointsTab name="test" /> },
        ];
    }
    render() {
        const tabs = this.getTabs();
        const { isLoading, activeTab } = this.state;
        return (
            <div className="">
                {!isLoading &&
                    tabs.map((tab, index) => {
                        return (
                            <div
                                className={`inline-flex px-5 border-b-4 ${
                                    activeTab === index ? ' border-blue-500 ' : '  border-gray-500 hover:text-blue-400'
                                }`}
                                key={index}
                            >
                                <span
                                    className={`clickable text-lg ${
                                        activeTab === index
                                            ? 'font-bold text-blue-500 border-blue-500 '
                                            : ' font-medium text-gray-400 border-gray-500 hover:text-blue-400'
                                    }`}
                                    onClick={() => this.setState({ activeTab: index })}
                                >
                                    {tab.title}
                                </span>
                            </div>
                        );
                    })}
                {tabs[activeTab].component}
            </div>
        );
    }
}

export default DeletedItemsPage;
