import axios from 'helpers/axios';
import config from 'helpers/config';
import SalesSummaryReport from 'models/reports/SalesSummaryReportModel';
import React from 'react';
import { FaDollarSign, FaUserAlt } from 'react-icons/fa';

interface Props {}

interface State {
    salesSummary?: SalesSummaryReport;
}

class SalesSummaryReports extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            salesSummary: undefined,
        };
    }

    componentDidMount() {
        axios.get(`${config.apiUrl}/report/salesSummary`).then((res) => this.setState({ salesSummary: res.data }));
    }

    render() {
        const { salesSummary } = this.state;
        return (
            <div className="w-full">
                <h2 className="font-bold text-gray-600 text-xl font-mono">All Payments Report</h2>

                <div className="grid grid-cols-2">
                    <div className="p-4 rounded-lg mr-2 my-2 bg-white hover:bg-blue-200 hover:cursor-pointer clickable border w-80">
                        <div className="flex">
                            <div className="rounded-lg bg-green-500 p-4 inline-block">
                                <FaDollarSign className=" text-white text-xl" />
                            </div>
                            <div className="w-3/4 text-center">
                                <div>
                                    <span>Invoiced</span>
                                </div>
                                <div>
                                    <span className="text-xl font-bold">$2486.40</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-4 rounded-lg mr-2 my-2 bg-white hover:bg-blue-200 hover:cursor-pointer clickable border w-80">
                        <div className="flex">
                            <div className="rounded-lg bg-green-500 p-4 inline-block">
                                <FaDollarSign className=" text-white text-xl" />
                            </div>
                            <div className="w-3/4 text-center">
                                <div>
                                    <span>Payments</span>
                                </div>
                                <div>
                                    <span className="text-xl font-bold">$2486.40</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-4 rounded-lg mr-2 my-2 bg-white hover:bg-blue-200 hover:cursor-pointer clickable border w-80">
                        <div className="flex">
                            <div className="rounded-lg bg-blue-500 p-4 inline-block">
                                <FaUserAlt className=" text-white text-xl" />
                            </div>
                            <div className="w-3/4 text-center">
                                <div>
                                    <span>Paying Customers</span>
                                </div>
                                <div>
                                    <span className="text-xl font-bold">{salesSummary?.payingCustomers}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-4 rounded-lg mr-2 my-2 bg-white hover:bg-blue-200 hover:cursor-pointer clickable border w-80">
                        <div className="flex">
                            <div className="rounded-lg bg-green-500 p-4 inline-block">
                                <FaDollarSign className=" text-white text-xl" />
                            </div>
                            <div className="w-3/4 text-center">
                                <div>
                                    <span>Weeks Sales</span>
                                </div>
                                <div>
                                    <span className="text-xl font-bold">$2486.40</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="p-4 rounded-lg mr-2 my-2 bg-white hover:bg-blue-200 hover:cursor-pointer clickable border w-80">
                        <div className="flex">
                            <div className="rounded-lg bg-green-500 p-4 inline-block">
                                <FaDollarSign className=" text-white text-xl" />
                            </div>
                            <div className="w-3/4 text-center">
                                <div>
                                    <span>Weeks Sales</span>
                                </div>
                                <div>
                                    <span className="text-xl font-bold">$2486.40</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SalesSummaryReports;
