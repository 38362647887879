const config = {
    apiUrl:
        process.env.NODE_ENV === 'production'
            ? process.env.REACT_APP_PRODUCTION_API_URL
            : process.env.REACT_APP_DEVELOPMENT_API_URL,
    fileRootURL:
        process.env.NODE_ENV === 'production'
            ? process.env.REACT_APP_FILE_ROOT_URL_PROD
            : process.env.REACT_APP_FILE_ROOT_URL_DEV,
};

export default config;
