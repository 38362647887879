import React from 'react';
// import { Dispatch } from 'redux';
import { FaClipboardList } from 'react-icons/fa';

// import VehicleModel from 'models/VehicleModel';
// import VehicleStats from './VehicleStats';
// import VehicleDetailsForm from './VehicleDetailsForm';
// import VehicleRepairOrders from './tabs/VehicleRepairOrders';
// import VehicleFiles from './tabs/VehicleFiles';
import axios from 'helpers/axios';
import config from 'helpers/config';
import LaborRate from 'models/LaborRateModel';
// import CustomerOrVehiclePayments from 'components/pages/customers/customerDetails/tabs/CustomerOrVehiclePayments';
import LoanerVehicle from 'models/LoanerVehicleModel';
import VehicleFiles from 'components/pages/vehicles/vehicleDetails/tabs/VehicleFiles';
import LoanerBookingsTab from './tabs/LoanerBookingsTab';
import LoanerOverview from './LoanerOverview';

interface Props {
    vehicleLoading: boolean;
    vehicleUpdating: boolean;
}

interface State {
    isEditMode: boolean;
    activeTab: number;
    loaner?: LoanerVehicle;
    laborRates: LaborRate[];
    isNewRepairOrderLoading: boolean;

    isLoading: boolean;
}

class LoanerDetailsPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            activeTab: 0,
            isEditMode: false,
            laborRates: [],
            isNewRepairOrderLoading: false,
            isLoading: true,
        };

        this.getLoaner();
        this.getLaborRates();
        this.getLoaner = this.getLoaner.bind(this);
        this.handleChange = this.handleChange.bind(this);
        // this.toggleEditMode = this.toggleEditMode.bind(this);
    }

    getLaborRates() {
        axios.get(`${config.apiUrl}/laborRate`).then((res) => {
            this.setState({ laborRates: res.data });
        });
    }

    getLoaner() {
        // @ts-ignore
        const { loanerId } = this.props.match.params;

        axios
            .get(`${config.apiUrl}/loaner/${loanerId}`)
            .then((response) => {
                this.setState({ loaner: response.data, isLoading: false });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    handleChange(event: React.FormEvent<HTMLInputElement>) {
        const { name, value } = event.currentTarget;

        // @ts-ignore
        const updatedLoaner: LoanerVehicle = { ...this.state.loaner, [name]: value };
        this.setState({ loaner: updatedLoaner });

        axios
            .put(`${config.apiUrl}/loaner`, updatedLoaner)
            .then((_) => _)
            .catch((_) => this.setState({ isLoading: false }));
    }

    // toggleEditMode() {
    //     const isEditMode = this.state.isEditMode;

    //     if (isEditMode) {
    //         const { dispatch } = this.props;
    //         dispatch<any>(updateVehicle(this.state.loaner!));
    //     }

    //     this.setState({
    //         isEditMode: !isEditMode,
    //         loaner: this.props.vehicle,
    //     });
    // }

    getTabs() {
        return [
            {
                title: 'Bookings',
                component: <LoanerBookingsTab bookings={this.state.loaner ? this.state.loaner.loanerBookings : []} />,
            },
            {
                title: 'Payments',
                component: <VehicleFiles files={[]} />,

                // component: (
                //     <CustomerOrVehiclePayments repairOrders={this.props.vehicle?.repairOrders!} isForCustomer={false} />
                // ),
            },
            {
                title: 'Files',
                component: <VehicleFiles files={[]} />,
            },
        ];
    }

    render() {
        const { activeTab, isNewRepairOrderLoading, isLoading, loaner } = this.state;
        const tabs = this.getTabs();

        return (
            <div className="bg-white mb-40">
                {!!isLoading && <b>Loading...</b>}
                <div className="w-full bg-blue-500 p-8">
                    <h3 className="text-blue-100">Vehicle</h3>
                    <h2 className="text-blue-50 text-2xl font-semibold">
                        {loaner?.year} {loaner?.make} {loaner?.model}
                    </h2>
                </div>
                <div className="mt-8 px-8">
                    <button
                        disabled={isNewRepairOrderLoading}
                        className="font-semibold py-2 px-3 rounded-xl hover:bg-green-500 border border-green-500 text-green-500 hover:text-white mr-4"
                    >
                        <FaClipboardList className="inline mr-2 align-baseline" />
                        <span>New Invoice / Repair Order</span>
                    </button>
                </div>
                <div className="pl-8">
                    <LoanerOverview loaner={loaner} />
                </div>
                {!isLoading && loaner && (
                    <div className="pl-8 flex flex-col">
                        {/* <VehicleStats vehicle={vehicle} />
                        <VehicleDetailsForm
                            vehicle={isEditMode ? this.state.vehicle! : vehicle}
                            handleChange={this.handleChange}
                            toggleEditMode={this.toggleEditMode}
                            isEditMode={isEditMode}
                        /> */}
                        <div className="mt-8">
                            {tabs.map((tab, index) => {
                                return (
                                    <div
                                        className={`inline-flex px-5 border-b-4 ${
                                            activeTab === index
                                                ? ' border-blue-500 '
                                                : '  border-gray-500 hover:text-blue-400'
                                        }`}
                                        key={index}
                                    >
                                        <span
                                            className={`clickable text-lg ${
                                                activeTab === index
                                                    ? 'font-bold text-blue-500 border-blue-500 '
                                                    : ' font-medium text-gray-400 border-gray-500 hover:text-blue-400'
                                            }`}
                                            onClick={() => this.setState({ activeTab: index })}
                                        >
                                            {tab.title}
                                        </span>
                                    </div>
                                );
                            })}
                        </div>
                        {tabs[activeTab].component}
                    </div>
                )}
            </div>
        );
    }
}

export default LoanerDetailsPage;
