import axios, { InternalAxiosRequestConfig } from 'axios';
import history from './history';
import { notify } from './services/notificationService';
import NotificationTypes from '../models/types/NotificationTypes';

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
    (response) => {
        if (response.data.successMessage) {
            notify(response.data.successMessage, NotificationTypes.Success);
        }

        return response;
    },
    (error) => {
        if (error.message === 'Network Error' || error?.code === 'ERR_NETWORK') {
            notify('Network error! Please check your Internet connection.', NotificationTypes.Error);
        } else if (error.response.status === 401) {
            localStorage.removeItem('token');
            history.push('/login');
        } else if (error.response?.data?.error) {
            notify(error.response?.data?.error, NotificationTypes.Error);
        }
        return Promise.reject({ ...error, message: error.response?.data?.error });
    },
);

axiosInstance.interceptors.request.use((request: InternalAxiosRequestConfig<any>) => {
    const token = localStorage.getItem('token');
    if (token) {
        request!.headers!.Authorization = token;
    }
    return request;
});

export default axiosInstance;
